import { DataBusEffects } from './dataBus.effects';
import { NotificationsEffects } from './notifications.effects';
import { UserEffects } from './user.effects';
import { OfflineDatabusEffects } from './offlineDatabus.effects';

export const effects: any[] = [
    DataBusEffects,
    NotificationsEffects,
    UserEffects,
    OfflineDatabusEffects,
];
