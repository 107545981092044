export const AppConfig = {
    deepLinkPrefix: '',
    environment: 'WEB',
    smartApprovalEnabled: true,
    production: false,
    pageNode: 'hc_dev',
    PCRValidity: 3, //Number of days before the pcr test expires
    trav_public_endpoint: '',
    localTravEndpoint: 'https://hcdev.tvalidator.app/',
    healthCredentialBaseUrl: 'https://dev-api-hc.travizory.ch/',
    flavor: 'VMP',
    refreshContentInterval: 300000,
    dismissLoginAttemptsInterval: 30000,
    updateStreamInterval: 1000,
    flavorProtocol: '',
    googleMapsKey: '',
    cardsFilterChips: {
        SUBMISSION_RECENT: true,
        ARRIVALS_TOMORROW: false,
        ARRIVALS_FUTURE: false,
        ARRIVALS_TODAY: false,
        isGridView: true,
        UPCOMING_FLIGHTS: true,
        RECENT_PAST_FLIGHTS: false,
        EXCLUDE_ARRIVAL_TODAY: false,
        EXCLUDE_DEPARTURE_TODAY: false,
        RECENT_PAST_VESSELS: false,
        UPCOMING_VESSELS: true,
        EXCLUDE_WORKERS: false,
    },
    establishmentsFilters: {},
    streamFilters: {
        TARECEIVED: true,
        DECISION: true,
        ENCOUNTER: true,
        TAACCESSED: true,
    },
    permitCountries: ['HCDEV'],
    userWithVMPAccess: [
        'admin+',
        'agent+',
        'agent++',
        'super_admin',
        'support',
        'support+',
        'viewer',
        'viewer+',
        'agent',
        'admin',
        'establishment_admin',
    ],

    decisionTypes: [],
    initializeMapCoordinates: {},
    establishmentUsersRole: [],
    userRoles: [
        { key: 'admin', label: 'user_roles.admin' },
        { key: 'agent', label: 'user_roles.agent' },
        { key: 'super_admin', label: 'user_roles.super_admin' },
    ],
    establishmentsType: [],
    countriesTypes: [],
    establishmentsFilterChips: [],
    questionCategory: [],
    questionType: [],
    magicTokenBaseUrl: {},
    defaultCountryCode: 'SYC',
    firebaseConfig: {
        apiKey: 'AIzaSyCqTNRP6iFLjOvJM2rmXGTzK91YsZtxDq4',
        authDomain: 'hc-platform-dev.firebaseapp.com',
        databaseURL:
            'https://hc-platform-dev-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'hc-platform-dev',
        storageBucket: 'hc-platform-dev.appspot.com',
        messagingSenderId: '325156866588',
        appId: '1:325156866588:web:44f4d25266c41b3000add9',
        measurementId: 'G-25S3LH3R30',
    },
    vmpAPI: {
        endPoint: 'https://node-hcdev.travizory.ch',
        apiKey: 'd86b55c7-c277-4726-be40-d30ad1eb8c78',
    },
    clientIds: {
        google: '',
    },
    sessionTokenExtension: true,
    professionOptions: [],
    collapseSidebar: ['super_admin', 'establishment_admin'],
    landingUrl: 'https://hcdev.tvalidator.app/',
    randomizeLoginBackground: false,
    authProviders: {
        google: false,
    },
    reportsUrl: 'https://dev-kibana-hc.travizory.ch',
    clickToCall: false,
    showNotifications: false,
    supportOfflineMode: false,
    hidePages: ['nationalityRequirement'],
    defaultLanguages: ['English'],
    getCurrentLocation: false,
};
