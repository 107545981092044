type providerData = {
    uid?: string;
    displayName?: string;
    email?: string;
    providerId?: string;
};
export class UserInfoModel {
    identifier?: string;
    firstName: string;
    lastName: string;
    role: string;
    email?: string;
    contact_email?: string;
    phoneNumber?: string;
    contact_phone?: string;
    disabled: boolean;
    lastSignInTime: string;
    creationTime: string;
    uid?: string;
    permitCountry?: string;
    establishmentPlaceID?: string;
    establishmentName?: string;
    position?: string;
    establishmentPosition?: string;
    userTags?: Array<string>;
    providerIDs?: Array<string>;
    providerData?: providerData[];
    vpn?: boolean;

    constructor(props: UserInfoModel) {
        return {
            identifier: props.identifier || '',
            firstName: props.firstName || '',
            lastName: props.lastName || '',
            role: props.role || '',
            email: props.email
                ? props.email
                : props.contact_email
                ? props.contact_email
                : '',
            phoneNumber: props.phoneNumber
                ? props.phoneNumber
                : props.contact_phone
                ? props.contact_phone
                : '',
            disabled: props.disabled || false,
            lastSignInTime: props.lastSignInTime || '',
            creationTime: props.creationTime || '',
            uid: props.uid || '',
            permitCountry: props.permitCountry || '',
            position: props.position
                ? props.position
                : props.establishmentPosition
                ? props.establishmentPosition
                : '',

            establishmentName: props.establishmentName || '',
            establishmentPlaceID: props.establishmentPlaceID || '',
            userTags: props.userTags
                ? props.userTags
                : props.role &&
                  (props.role == 'establishment_admin' ||
                      props.role == 'establishment_user')
                ? null
                : [],
            providerIDs: props.providerIDs || [],
            providerData: props.providerData || [],
            vpn: props.vpn || false,
        };
    }
}

export interface AllUsersModel {
    [uid: string]: UserInfoModel;
}

export interface GetAllUsersModel {
    data: AllUsersModel;
    status: boolean;
    version: string;
}
