export const healthCredentialsCSVSample = {
    email: 'johnsmith@example.com',
    phoneNumber: '',
    givenNames: 'John',
    lastNames: 'Smith',
    dateOfBirth: '1990-02-25',
    sex: 'X',
    idDocType: 'P',
    idDocNumber: 'X1234567',
    sample_time: '2021-04-15T09:08:51.000Z',
    result: 'N',
    sample_type: 'R',
    test_number: '12345',
};

export const usersCSVSample = {
    email: 'johnsmith@example.com',
    firstName: 'John',
    lastName: 'Smith',
    role: 'Admin',
};
