import 'reflect-metadata';
import '../polyfills';

//@angular
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    HttpClientModule,
    HttpClient,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import {
    HashLocationStrategy,
    LOCATION_INITIALIZED,
    LocationStrategy,
} from '@angular/common';
import {
    APP_INITIALIZER,
    InjectionToken,
    Injector,
    NgModule,
} from '@angular/core';

//Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

// NG Translate
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    TranslateModule,
    TranslateLoader,
    TranslateService,
} from '@ngx-translate/core';

//APP COMPONENT
import { AppComponent } from './app.component';

//Material Components
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

//NGRX
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { combinedReducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { effects } from './store/effects';

import { AppConfig } from 'environments/environment';
import {
    SocialAuthServiceConfig,
    GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { AccessTokenInterceptor } from '@app/interceptors/access-token.interceptor';
import { AngularFireModule } from '@angular/fire/compat';
import { PERSISTENCE } from '@angular/fire/compat/auth';
import {
    AngularFireAnalyticsModule,
    ScreenTrackingService,
} from '@angular/fire/compat/analytics';
import {
    AngularFirePerformanceModule,
    PerformanceMonitoringService,
} from '@angular/fire/compat/performance';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(
        http,
        './assets/i18n/',
        `.json?cb=${new Date().getTime()}`
    );
}

export const ReducerToken = new InjectionToken('Registered Reducer');
export function getReducers(): typeof combinedReducers {
    return combinedReducers;
}

function isBase64(str) {
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

function utf8_to_b64(str) {
    return btoa(unescape(encodeURIComponent(str)));
}

function b64_to_utf8(str) {
    return decodeURIComponent(escape(atob(str)));
}

function isElectron(): boolean {
    return !!(window && window.process && window.process.type);
}

export function localStorageSyncReducer(
    reducer: ActionReducer<any>
): ActionReducer<any> {
    return localStorageSync({
        keys: [
            {
                user: {
                    encrypt: (state) => utf8_to_b64(state),
                    decrypt: (state) =>
                        isBase64(state) ? b64_to_utf8(state) : state,
                },
            },
            {
                dataBus: [
                    'selectedTableRow',
                    'taPrivateVersion',
                    'cms_data',
                    'version',
                    'enrollmentTypes',
                    'biometricSearch',
                ],
            },
            'ui',
            'loginPage',
        ],
        rehydrate: true, //must be true in order for local storage to persist
    })(reducer);
}

export function appInitializerFactory(
    translate: TranslateService,
    injector: Injector
) {
    return (): Promise<any> =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(
                LOCATION_INITIALIZED,
                Promise.resolve(null)
            );
            locationInitialized.then(() => {
                const langToSet = 'en';
                translate.setDefaultLang('en');
                translate.use(langToSet).subscribe(
                    () => {
                        // eslint-disable-next-line
                        console.info(
                            `Successfully initialized '${langToSet}' language.'`
                        );
                    },
                    () => {
                        // eslint-disable-next-line
                        console.error(
                            `Problem with '${langToSet}' language initialization.'`
                        );
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
}

let providerImport: any = {
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
};

let devImports = [StoreDevtoolsModule.instrument({ maxAge: 15 })];

if (AppConfig['production']) {
    devImports = [];
}

if (!isElectron()) {
    providerImport = [];
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,
        MatSnackBarModule,
        MatDialogModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        EffectsModule.forRoot(effects),
        StoreModule.forRoot(ReducerToken, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        // Initialize firebase
        AngularFireModule.initializeApp(AppConfig.firebaseConfig),
        AngularFireAnalyticsModule,
        AngularFirePerformanceModule,

        devImports,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AccessTokenInterceptor,
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            AppConfig.clientIds.google,
                            {
                                oneTapEnabled: false,
                            }
                        ),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        [{ provide: ReducerToken, useFactory: getReducers }],
        providerImport,
        ScreenTrackingService,
        PerformanceMonitoringService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
