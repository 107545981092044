import { Injectable } from '@angular/core';
import * as utc from 'dayjs/plugin/utc';
import * as dayjs from 'dayjs';
dayjs.extend(utc);

@Injectable({
    providedIn: 'root',
})
export class TimeConversionService {
    formatDate(
        date: string | number = undefined,
        dateFormat: string = '',
        parseZone: boolean = true,
        toLocal: boolean = false
    ): string {
        if (dayjs(date).isValid()) {
            if (parseZone) {
                return dayjs.utc(date).format(dateFormat);
            } else if (toLocal) {
                return dayjs.utc(date).local().format(dateFormat);
            }
            return dayjs(date).format(dateFormat);
        } else {
            return 'Invalid Date';
        }
    }

    getDateDifference(date1: any, date2: any, unit?: any): any {
        return dayjs(date1).diff(dayjs(date2), unit);
    }

    getDateFromNow(
        numberOfDaysYears: number,
        dayYear: dayjs.ManipulateType,
        dateFormat: string = 'YYYY-MM-DD'
    ): string {
        return dayjs().add(numberOfDaysYears, dayYear).format(dateFormat);
    }

    getUnixTimestamp(date: string | number): number {
        return dayjs(date).unix();
    }
    addSecondsToDate(date: any, secondsToAdd: number): any {
        return dayjs(date)
            .second(dayjs(date).second() + secondsToAdd)
            .format();
    }
}
