import { AppState, UIState } from '../state';

export const appsUi = (state: AppState): typeof state.ui.loader.apps =>
    state.ui.loader.apps;

export const selectUI = (state: AppState): UIState => state.ui;

export const appsDetailsUI = (
    state: AppState
): typeof state.ui.loader.appDetails => state.ui.loader.appDetails;

export const headerUI = (state: AppState): typeof state.ui.loader.header =>
    state.ui.loader.header;

export const taValidation = (
    state: AppState
): typeof state.ui.loader.taValidation => state.ui.loader.taValidation;

export const loginError = (state: AppState): typeof state.ui.loader.login =>
    state.ui.loader.login;

export const pinCodeError = (state: AppState): typeof state.ui.loader.pinCode =>
    state.ui.loader.pinCode;

export const isSidebarOpen = (state: AppState): typeof state.ui.isSidebarOpen =>
    state.ui.isSidebarOpen;

export const notificationsUiState = (
    state: AppState
): typeof state.ui.notifications => state.ui.notifications;

export const pageFilterUIState = (
    state: AppState
): typeof state.ui.pageFilter => state.ui.pageFilter;

export const offlineAppsUIState = (
    state: AppState
): typeof state.ui.offlineApps => state.ui.offlineApps;
export const biometricSearchUIState = (
    state: AppState
): typeof state.ui.biometricSearch => state.ui.biometricSearch;

export const cmsFormsUIState = (state: AppState): typeof state.ui.cms_forms =>
    state.ui.cms_forms;
export const parentPathState = (state: AppState): typeof state.ui.path =>
    state.ui.path;
export const cmsTabState = (state: AppState): typeof state.ui.cmsTab =>
    state.ui.cmsTab;
