export class AddUserModel {
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: string;
    permitCountry?: string;
    identifier?: string;
    phoneNumber?: string;
    establishmentPlaceID?: string;
    position?: string;
    userTags?: Array<string>;
    providerIDs?: Array<string>;
    vpn?: boolean;

    constructor(props: {
        firstName?: string;
        lastName?: string;
        email?: string;
        role?: string;
        permitCountry?: string;
        identifier?: string;
        phoneNumber?: string;
        establishmentPlaceID?: string;
        position?: string;
        userTags?: Array<string>;
        providerIDs?: Array<string>;
        vpn?: boolean;
    }) {
        return {
            firstName: props.firstName ? props.firstName : null,
            lastName: props.lastName ? props.lastName : null,
            email: props.email ? props.email : null,
            role: props.role ? props.role : null,
            permitCountry: props.permitCountry
                ? props.role == 'super_admin'
                    ? null
                    : props.permitCountry
                : null,
            identifier: props.identifier ? props.identifier : null,
            phoneNumber: props.phoneNumber ? props.phoneNumber : null,
            establishmentPlaceID: props.establishmentPlaceID
                ? props.establishmentPlaceID
                : null,
            position: props.position ? props.position : null,
            userTags:
                props.userTags && props.role != 'super_admin'
                    ? props.userTags
                    : null,
            providerIDs: props.providerIDs || [],
            vpn: props.vpn || false,
        };
    }
}

export class UpdateUserModel {
    uid?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: string;
    disabled?: boolean;
    permitCountry?: string;
    identifier?: string;
    phoneNumber?: string;
    establishmentPlaceID?: string;
    position?: string;
    userTags?: Array<string>;
    providerIDs?: Array<string>;

    constructor(props: UpdateUserModel) {
        return {
            uid: props.uid ? props.uid : null,
            firstName: props.firstName ? props.firstName : null,
            lastName: props.lastName ? props.lastName : null,
            email: props.email ? props.email : null,
            role: props.role ? props.role : null,
            disabled: props.disabled ? props.disabled : false,
            permitCountry: props.permitCountry
                ? props.role == 'super_admin'
                    ? null
                    : props.permitCountry
                : null,
            identifier: props.identifier ? props.identifier : null,
            phoneNumber: props.phoneNumber ? props.phoneNumber : null,
            establishmentPlaceID: props.establishmentPlaceID
                ? props.establishmentPlaceID
                : null,
            position: props.position ? props.position : null,
            userTags:
                props.userTags && props.role != 'super_admin'
                    ? props.userTags
                    : null,
            providerIDs: props.providerIDs || [],
        };
    }
}

export class DeleteUserModel {
    userId?: string;

    constructor(props: { userId?: string }) {
        return {
            userId: props.userId ? props.userId : undefined,
        };
    }
}

export class PatientModel {
    testResult?: string;
    surname?: string;
    number?: string;
    arrivalDate?: string;
    forenames?: string;
    dob?: string;
    mimeType?: string;
    taReference?: string;
    documentNumber?: string;

    constructor(params?: PatientModel) {
        return {
            testResult: params?.testResult || '',
            surname: params?.surname || '',
            number: params?.documentNumber || '',
            arrivalDate: params?.arrivalDate || '',
            forenames: params?.forenames || '',
            dob: params?.dob || '',
            taReference: params?.taReference || '',
        };
    }
}

export class EncounterLogModel {
    deviceInfo: {
        app_version?: string;
        channel?: string;
        deviceID?: string;
        language?: string;
    };
    encounter: {
        encounterTime?: string;
        encounterType?: string;
        location?: {
            lat?: number;
            lon?: number;
        };
        additionalInfo?: {
            approvedStayUntil?: string;
            notes?: string | Array<any>;
        };
    };
    identifier?: {
        Barcode?: string;
        reference?: string;
        IDIssuer?: string;
        IDNumber?: string;
    };
    TARef?: string;
    returnDetails?: boolean;

    constructor(props?: EncounterLogModel) {
        const returnObj = {
            deviceInfo: {
                app_version: props.deviceInfo.app_version,
                channel: props.deviceInfo.channel || 'ELECTRON',
                deviceID: props.deviceInfo.deviceID,
                language: props.deviceInfo.language,
            },
            encounter: {
                encounterTime: props.encounter.encounterTime,
                encounterType: props.encounter.encounterType,
                location: props.encounter.location,
                additionalInfo: props?.encounter?.additionalInfo,
            },
            identifier: props.identifier,
            returnDetails: props.returnDetails || false,
        };
        if (props.identifier) {
            returnObj['identifier'] = props.identifier || {};
        }
        if (props.TARef) {
            returnObj['TARef'] = props.TARef || '';
        }
        return returnObj;
    }
}
