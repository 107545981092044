import { ApplicationDetails } from '@app/models/appDetails';
import { FlightCard } from '@app/models/flightCard';
import { ApplicantCard } from './applicantCard';
import {
    AirportModel,
    AlertBanner,
    ApplicationStatusCheckModel,
    ContactInfoConfiguration,
    CruiseModel,
    EligibilityWidget,
    GroupSubmissionsConfig,
    OtherPreferencesModel,
    PurposeOfVisit,
    QuestionsConfigModel,
    SupportedTemplateModel,
    VisaConfiguration,
} from './database';
import { ProfileData } from './Profiles.model';
import {
    ApplicationModel,
    BiometricSearchResult,
    OtherOverrideConfigModel,
    TripInfoSectionsModel,
} from './services/mappingService.model';

export class DataBusModel {
    cms_data: {
        supportingDocuments: {}[];
        countryWatchList: {}[];
        countryWhiteList: {}[];
        countryLowRisk: {}[];
        countryMediumRisk: {}[];
        countryYellowFever: {}[];
        countryPOX: {}[];
        countryEbola: {}[];
        countryPolio: {}[];
        countryCustoms: {}[];
        countryVisaExemption: {}[];
        countryVisaOnArrival: {}[];
        questions: {}[];
        pages: {}[];
        users: {}[];
        languages: {}[];
        establishments: {}[];
        establishmentUsers: {}[];
        airlines: {}[];
        establishmentsTags: [];
        questionsConfig: QuestionsConfigModel;
        alertBanner: Array<AlertBanner>;
        favoriteCountries: Array<string>;
        airports: Array<AirportModel>;
        supportedTemplates: Array<SupportedTemplateModel>;
        cruises: Array<CruiseModel>;
        purposeVisit: PurposeOfVisit;
        currencies: Array<string>;
        groupConfig: Array<GroupSubmissionsConfig>;
        popularAirlines: Array<string>;
        popularCurrencies: Array<string>;
        popularCruises: Array<string>;
        allowedEnrollmentTypes: Array<string>;
        nationalityRequirement: EligibilityWidget;
        contactInfoConfig: Array<ContactInfoConfiguration>;
        visaConfig: Array<VisaConfiguration>;
        defaultEnrollmentType: string;
        tripInfoSections: Array<TripInfoSectionsModel>;
        applicationStatusCheck: ApplicationStatusCheckModel;
        otherPreferences: Array<OtherPreferencesModel>;
        otherOverrideConfig: OtherOverrideConfigModel;
    };
    selectedTableRow: {
        node: string;
        data: {};
    };
    modifiedSince: string;
    modifiedSinceAllEstablishmentUsers: string;
    modifiedSinceAllUsers: string;
    version: string;
    appConfig: {};
    purchasedItemsConfig: [];
    tierItemsConfig: [];
    appsManager: {
        flightDashboard: {
            timestamp: '';
            index: 0;
            count: 0;
            allData: FlightCard[];
        };
        pending: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        autoApprove: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        active: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        payments: {
            timestamp: string;
            index: number;
            count: number;
            allData: {}[];
        };
        incomplete: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        flightApplications: {
            timestamp: string;
            index: number;
            count: number;
            allData: {}[];
        };
        examine: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        migrated: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        unmigrated: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        question: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        quarantine: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        rejected: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        workation: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        searchResults: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        inCountryVisitors: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        hotelDashboard: {
            timestamp: string;
            index: number;
            count: number;
            allData: {}[];
        };
        hotelApplications: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        encounters: {
            timestamp: string;
            index: number;
            count: number;
            allData: {}[];
        };
        incompleteAuditLogs: {
            timestamp: string;
            index: number;
            count: number;
            allData: {}[];
        };
        emailLog: {
            timestamp: string;
            index: number;
            count: number;
            allData: {}[];
        };
        arrivals: {
            timestamp: '';
            index: 0;
            count: 0;
            allData: [];
        };
        departures: {
            timestamp: '';
            index: 0;
            count: 0;
            allData: [];
        };
        documentMlLog: {
            timestamp: string;
            index: number;
            count: number;
            allData: {}[];
        };
        toReview: {
            timestamp: string;
            index: number;
            count: number;
            allData: ApplicantCard[];
        };
        outbound: {
            timestamp: '';
            index: 0;
            count: 0;
            allData: FlightCard[];
        };
        inReview: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        previousApplications: {
            allData: ApplicationModel[];
            timestamp: string;
            index: number;
            count: number;
        };
        businessVisa: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        diplomaticVisa: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        familyVisa: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        touristVisa: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        transitVisa: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        genericVisa: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        maritime: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
        vessels: {
            inbound: {
                allData: FlightCard[];
                timestamp: string;
                index: number;
                count: number;
            };
            outbound: {
                allData: FlightCard[];
                timestamp: string;
                index: number;
                count: number;
            };
        };
        vesselApplications: {
            allData: ApplicantCard[];
            timestamp: string;
            index: number;
            count: number;
        };
    };
    appDetails: ApplicationDetails;
    taPrivateVersion: string;
    hcProfiles: Array<ProfileData>;
    userFileTypes: Array<{ label: string; key: string }>;
    notifications: {
        expeditedApps: number;
        updatedApps: number;
    };
    enrollmentTypes: Array<{ key: string; label: string }>;
    biometricSearch: Array<BiometricSearchResult>;

    constructor() {
        return {
            cms_data: {
                supportingDocuments: [],
                countryWatchList: [],
                countryWhiteList: [],
                countryLowRisk: [],
                countryMediumRisk: [],
                countryYellowFever: [],
                countryPOX: [],
                countryEbola: [],
                countryPolio: [],
                countryCustoms: [],
                countryVisaExemption: [],
                countryVisaOnArrival: [],
                questions: [],
                pages: [],
                users: [],
                languages: [],
                establishments: [],
                establishmentUsers: [],
                airlines: [],
                establishmentsTags: [],
                questionsConfig: {},
                alertBanner: [],
                favoriteCountries: [],
                airports: [],
                supportedTemplates: [],
                cruises: [],
                purposeVisit: new PurposeOfVisit(),
                currencies: [],
                groupConfig: [],
                popularAirlines: [],
                popularCurrencies: [],
                popularCruises: [],
                allowedEnrollmentTypes: [],
                nationalityRequirement: new EligibilityWidget(),
                contactInfoConfig: [],
                visaConfig: [],
                defaultEnrollmentType: '',
                tripInfoSections: [],
                applicationStatusCheck: new ApplicationStatusCheckModel(),
                otherOverrideConfig: {},
                otherPreferences: [],
            },
            selectedTableRow: {
                node: '',
                data: {},
            },
            appConfig: {},
            purchasedItemsConfig: [],
            tierItemsConfig: [],
            modifiedSince: '',
            modifiedSinceAllEstablishmentUsers: '',
            modifiedSinceAllUsers: '',
            version: '',
            appsManager: {
                pending: {
                    timestamp: '',
                    allData: [],
                    index: 0,
                    count: 0,
                },
                autoApprove: {
                    timestamp: '',
                    allData: [],
                    index: 0,
                    count: 0,
                },
                active: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                payments: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                incomplete: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                encounters: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                incompleteAuditLogs: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                question: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                examine: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                migrated: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                unmigrated: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                workation: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                quarantine: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                flightApplications: {
                    timestamp: '',
                    index: 0,
                    allData: [],
                    count: 0,
                },
                flightDashboard: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                rejected: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                searchResults: {
                    allData: [],
                    timestamp: '',
                    index: 0,
                    count: 0,
                },
                inCountryVisitors: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                hotelDashboard: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                hotelApplications: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                emailLog: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                arrivals: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                departures: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                documentMlLog: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                toReview: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                outbound: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                inReview: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                previousApplications: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                businessVisa: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                diplomaticVisa: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                familyVisa: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                touristVisa: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                transitVisa: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                genericVisa: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                maritime: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
                vessels: {
                    inbound: {
                        timestamp: '',
                        index: 0,
                        count: 0,
                        allData: [],
                    },
                    outbound: {
                        timestamp: '',
                        index: 0,
                        count: 0,
                        allData: [],
                    },
                },
                vesselApplications: {
                    timestamp: '',
                    index: 0,
                    count: 0,
                    allData: [],
                },
            },
            appDetails: new ApplicationDetails(),
            taPrivateVersion: '',
            hcProfiles: [],
            userFileTypes: [],
            notifications: {
                expeditedApps: 0,
                updatedApps: 0,
            },
            enrollmentTypes: [],
            biometricSearch: [],
        };
    }
}
