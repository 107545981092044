import { StreamDataModel } from '@app/models/streamData.model';
import { createAction, props } from '@ngrx/store';
import { StreamState } from '../state/stream.state';
import { types } from './types';

export const onStreamMessage = createAction(
    types.ON_STREAM_MESSAGE,
    props<{ node: string; data: StreamDataModel }>()
);

export const updateStreamEvents = createAction(
    types.UPDATE_STREAM_EVENTS,
    props<StreamState>()
);

export const clearStreamData = createAction(types.CLEAR_STREAM_DATA);
