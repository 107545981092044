import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiService } from '@app/services/api.service';
import { MappingService } from '@app/services/mapping.service';
import { Injectable } from '@angular/core';
import { types } from '../actions';
import { of } from 'rxjs';

@Injectable()
export class NotificationsEffects {
    getNotificationsAppsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_NOTIFICATIONS_APPS_COUNT),
            mergeMap((action: any) => {
                return this.api.getAppCount({ query: action['body'] }).pipe(
                    map((data) => {
                        const counts = this.mappingService.formatNotification({
                            node: action.node,
                            count: data.count,
                        });
                        return {
                            type: types.GET_NOTIFICATIONS_APPS_COUNT_SUCCESS,
                            payload: counts,
                        };
                    }),
                    catchError((err) =>
                        of({
                            type: types.GET_NOTIFICATIONS_APPS_COUNT_FAILED,
                            payload: err,
                        })
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private api: ApiService,
        private mappingService: MappingService
    ) {}
}
