import * as dayjs from 'dayjs';

export const streamDataTypesMapping = {
    TAACCESSED: { groupType: 'agentViews', filterChip: 'TAACCESSED' },
    ENCOUNTER: { groupType: 'encounters', filterChip: 'ENCOUNTER' },
    TARECEIVED: { groupType: 'newApplications', filterChip: 'TARECEIVED' },
    TAAPPROVED: { groupType: 'decisions', filterChip: 'DECISION' },
    TAREJECTED: { groupType: 'decisions', filterChip: 'DECISION' },
} as const;

export class StreamDataModel {
    System?: string;
    Type?: string;
    Who?: string;
    When?: string;
    TaRef?: string;
    TA?: {
        channel?: string;
        surname?: string;
        forenames?: string;
        selfieUrl?: string;
        PublicUpdatedTs?: string;
        SubmittedTs?: string;
        trip_info?: {
            arrivalFlightNumber?: string;
            purposeVisit?: string;
            expedited?: boolean;
            arrivalDate?: string;
            arrivalAt?: string;
            arrivalFrom?: string;
            arrivalCarrier?: string;
        };
        expedited?: boolean;
    };
    Encounter?: {
        location?: {
            lat?: number;
            lon?: number;
        };
        encounterType?: string;
        Who?: {
            establishmentName?: string;
        };
    };
    Note?: string;
    mapped?: {
        fullName?: string;
        eventPerformer?: string;
        eventTime?: string;
        applicationType?: string;
        isUpdated?: boolean;
        groupType?: string;
    };

    constructor(props: StreamDataModel, baseUrl?: string) {
        const streamData: StreamDataModel = {
            System: props.System || '',
            Type: props.Type || '',
            Who: props.Who || '',
            When: props.When || '',
            TaRef: props.TaRef || '',

            TA: {
                channel: props.TA?.channel || '',
                selfieUrl: props.TaRef
                    ? `${baseUrl}taapi/file/x/${props.TaRef}/SELFIE`
                    : '',
                PublicUpdatedTs: props.TA?.PublicUpdatedTs || '',
                SubmittedTs: props.TA?.SubmittedTs || '',
                trip_info: {
                    arrivalFlightNumber:
                        props.TA?.trip_info?.arrivalFlightNumber || '',
                    purposeVisit: props.TA?.trip_info?.purposeVisit || '',
                    expedited: !!props.TA?.trip_info?.expedited,
                    arrivalDate: props.TA?.trip_info?.arrivalDate || '',
                    arrivalAt: props.TA?.trip_info?.arrivalAt || '',
                    arrivalFrom: props.TA?.trip_info?.arrivalFrom || '',
                    arrivalCarrier: props.TA?.trip_info?.arrivalCarrier || '',
                },
                expedited: !!props.TA?.expedited,
            },
            Encounter: {
                location: {
                    lat: props.Encounter?.location?.lat || 0,
                    lon: props.Encounter?.location?.lon || 0,
                },
                encounterType: props.Encounter?.encounterType || '',
                Who: {
                    establishmentName:
                        props.Encounter?.Who?.establishmentName || '',
                },
            },
            Note: props.Note || '',
        };

        streamData.mapped = {
            fullName: `${props?.TA?.surname || ''}${
                (props?.TA?.surname && props?.TA?.forenames ? ', ' : '') +
                (props?.TA?.forenames || '')
            }`,
            eventPerformer:
                streamData.Type === 'TARECEIVED'
                    ? streamData.TA.channel
                    : streamData.Who || '',
            eventTime: dayjs(streamData.When).format('YYYY-MM-DD HH:mm') || '',
            applicationType:
                streamData.TA.trip_info.expedited || streamData.TA.expedited
                    ? 'application_types.expedited'
                    : 'application_types.standard',
            isUpdated:
                dayjs(streamData.TA.PublicUpdatedTs).diff(
                    dayjs(streamData.TA.SubmittedTs)
                ) > 0,
            groupType: streamDataTypesMapping[streamData.Type]?.groupType || '',
        };

        return streamData;
    }
}
