import { Action, createReducer, on } from '@ngrx/store';
import { UIState } from '../state';
import {
    getUserInformationFail,
    getPendingAppsFailed,
    getAppDetailsFailed,
    clearUiState,
    getAllUsersSuccess,
    getAppsFailed,
    getAllUsersFailed,
    getAllUsers,
    getAppDetails,
    getAllEstablishmentUsersSuccess,
    getAllEstablishmentUsers,
    getAllEstablishmentUsersFailed,
    updateHeaderState,
    loadMoreApps,
    getAppsSuccess,
    getPendingAppsSuccess,
    validateTaInfoFail,
    validateTaInfo,
    validateTaInfoSuccess,
    clearTaValidationState,
    validateTestSummary,
    validateTestSummarySuccess,
    validateTestSummaryFailed,
    getHCProfilesSuccess,
    getHCProfilesFailed,
    getHCProfiles,
    updateAppsState,
    setIsSidebarOpen,
    getGroupedAppsSuccess,
    getGroupedAppsFailed,
    getElasticSearchDataSuccess,
    getElasticSearchDataFailed,
    getElasticSearchData,
    setPinCodeFailed,
    getNamedSearchDataSuccess,
    getNamedSearchDataFailed,
    refreshWatchlistMatchesSuccess,
    refreshWatchlistMatches,
    refreshWatchlistMatchesFailed,
    getOfflineApps,
    getOfflineAppsSuccess,
    getOfflineAppsFailed,
    getOfflineAppDetailsFailed,
    getOfflineAppDetailsSuccess,
    biometricSearch,
    biometricSearchSuccess,
    biometricSearchFailed,
    restoreBiometricSearchState,
    setCMSFormSubNode,
    clearCMSFormSubNode,
    updateParentPathState,
    setSelectedTab,
    getContents,
    getContentsSuccess,
    getNamedSearchFailed,
    getNamedSearchSuccess,
    getContentsFailed,
    clearWatchlistMatches,
} from '../actions';
import {
    getNotificationsAppsCount,
    getNotificationsAppsCountSuccess,
    getNotificationsAppsCountFailed,
} from '../actions/notifications.actions';

const initialState: UIState = {
    loader: {
        apps: {
            errorMessage: '',
            isLoading: false,
            status: 0,
        },
        appDetails: {
            errorMessage: '',
            isLoading: false,
            status: 0,
            elasticSearch: {
                errorMessage: '',
                isLoading: false,
                status: 0,
            },
        },
        offlineAppDetails: {
            errorMessage: '',
            isLoading: false,
        },
        login: {
            showError: false,
            isLoading: false,
            errorMessage: '',
        },
        pinCode: {
            showError: false,
            isLoading: false,
            errorMessage: '',
        },
        header: {
            isRefreshing: false,
            isBtnDisabled: false,
            isAdding: true,
            isLoadMore: false,
        },
        taValidation: {
            trip_info: false,
            IDDocSummary: false,
            attachment: {},
            showError: {
                errorMessage: '',
                validationType: '',
            },
        },
        watchlistMatches: {
            isRefreshing: false,
            errorMessage: '',
        },
    },
    getAllUsers: {
        isLoading: false,
    },
    isSidebarOpen: false,
    notifications: {
        isLoading: false,
        errorMessage: '',
    },
    pageFilter: {
        isLoading: false,
        errorMessage: '',
        isSuccess: false,
    },
    offlineApps: {
        showError: false,
        isLoading: false,
        errorMessage: '',
        isSuccess: false,
    },
    biometricSearch: {
        showError: false,
        isLoading: false,
        errorMessage: '',
        isSuccess: false,
    },
    cms_forms: {
        subNode: '',
    },
    path: {
        parentPath: '',
        childPath: '',
    },
    cmsTab: {
        selectedTabType: '',
        cmsSelectedTab: '',
    },
    getContentsSuccess: false,
};

const uiReducer = createReducer(
    initialState,
    on(getPendingAppsFailed, (state, action) => {
        const _u = { ...state };
        _u.loader.apps.errorMessage = 'error_messages.fetch_data_error';
        _u.loader.apps.status = action.payload.status;
        _u.loader.apps.isLoading = false;
        _u.loader.header.isRefreshing = false;
        return _u;
    }),
    on(getAppsFailed, (state, action) => {
        const _u = { ...state };
        _u.loader.apps.errorMessage = 'error_messages.fetch_data_error';
        _u.loader.apps.status = action.payload.status;
        _u.loader.apps.isLoading = false;
        _u.loader.header.isRefreshing = false;

        return _u;
    }),
    on(getAppDetailsFailed, (state, action) => {
        const _u = { ...state };
        _u.loader.appDetails.isLoading = false;
        _u.loader.appDetails.errorMessage = 'error_messages.fetch_data_error';
        _u.loader.appDetails.status = action.payload.status;
        return { ..._u };
    }),
    on(getAppDetails, (state) => {
        const _u = { ...state };
        _u.loader.appDetails.isLoading = true;
        _u.loader.appDetails.errorMessage = '';
        return { ..._u };
    }),
    on(getGroupedAppsSuccess, (state) => {
        const _u = { ...state };
        _u.loader.appDetails.isLoading = false;
        _u.loader.appDetails.errorMessage = '';
        return { ..._u };
    }),
    on(getGroupedAppsFailed, (state) => {
        const _u = { ...state };
        _u.loader.appDetails.isLoading = false;
        _u.loader.appDetails.errorMessage = '';
        return { ..._u };
    }),
    on(getUserInformationFail, (state, action) => {
        const _u = { ...state };
        _u.loader.login.isLoading = false;
        _u.loader.login.showError = true;
        _u.loader.login.errorMessage = action.payload || 'unexpected_error';
        return _u;
    }),
    on(getAllUsersSuccess, (state) => {
        const _u = { ...state };
        _u.loader.login.isLoading = false;
        _u.loader.login.showError = false;
        _u.loader.login.errorMessage = '';
        return _u;
    }),
    on(getAllEstablishmentUsersSuccess, (state) => {
        const _u = { ...state };
        _u.loader.login.isLoading = false;
        _u.loader.login.showError = false;
        _u.loader.login.errorMessage = '';
        return _u;
    }),
    on(clearUiState, (state) => {
        const _u = { ...state };
        _u.loader = {
            apps: {
                errorMessage: '',
                isLoading: false,
                status: 0,
            },
            appDetails: {
                errorMessage: '',
                isLoading: false,
                status: 0,
                elasticSearch: {
                    errorMessage: '',
                    isLoading: false,
                    status: 0,
                },
            },
            offlineAppDetails: {
                errorMessage: '',
                isLoading: false,
            },
            login: {
                showError: false,
                isLoading: false,
                errorMessage: '',
            },
            pinCode: {
                showError: false,
                isLoading: false,
                errorMessage: '',
            },
            header: {
                isRefreshing: false,
                isAdding: true,
                isBtnDisabled: false,
                isLoadMore: false,
            },
            taValidation: {
                trip_info: false,
                IDDocSummary: false,
                attachment: {},
                showError: {
                    errorMessage: '',
                    validationType: '',
                },
            },
            watchlistMatches: {
                isRefreshing: false,
                errorMessage: '',
            },
        };
        return _u;
    }),
    on(getAllUsers, (state) => {
        const _u = { ...state };
        _u.getAllUsers.isLoading = true;
        _u.loader.header.isRefreshing = true;
        _u.loader.header.isBtnDisabled = true;
        return _u;
    }),
    on(getAllEstablishmentUsers, (state) => {
        const _u = { ...state };
        _u.getAllUsers.isLoading = true;
        _u.loader.header.isRefreshing = true;
        _u.loader.header.isBtnDisabled = true;
        return _u;
    }),
    on(getAllUsersSuccess, (state) => {
        const _u = { ...state };
        _u.getAllUsers.isLoading = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.header.isBtnDisabled = false;
        return _u;
    }),
    on(getAllEstablishmentUsersSuccess, (state) => {
        const _u = { ...state };
        _u.getAllUsers.isLoading = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.header.isBtnDisabled = false;
        return _u;
    }),
    on(getAllUsersFailed, (state) => {
        const _u = { ...state };
        _u.getAllUsers.isLoading = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.header.isBtnDisabled = false;
        return _u;
    }),
    on(getAllEstablishmentUsersFailed, (state) => {
        const _u = { ...state };
        _u.getAllUsers.isLoading = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.header.isBtnDisabled = false;
        return _u;
    }),
    on(updateHeaderState, (state, action) => {
        const _u = { ...state };
        _u.loader.header[action.node] = action.value;
        return _u;
    }),
    on(loadMoreApps, (state) => {
        const _u = { ...state };
        _u.loader.header.isLoadMore = true;
        return _u;
    }),
    on(getAppsSuccess, (state) => {
        const _u = { ...state };

        _u.loader.apps.errorMessage = '';
        _u.loader.header.isLoadMore = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.apps.isLoading = false;
        return _u;
    }),
    on(getPendingAppsSuccess, (state) => {
        const _u = { ...state };
        _u.loader.header.isLoadMore = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.apps.isLoading = false;
        return _u;
    }),
    on(validateTaInfo, (state, action) => {
        const _u = { ...state };
        if (action.validationType === 'attachment') {
            _u.loader.taValidation.attachment[
                action.extraParams.payloadParams.AttachmentId
            ] = {
                ..._u.loader.taValidation.attachment[
                    action.extraParams.payloadParams.AttachmentId
                ],
                isLoading: true,
            };
        } else {
            _u.loader.taValidation[action.validationType] = true;
        }
        _u.loader.taValidation.showError.errorMessage = '';
        _u.loader.taValidation.showError.validationType = '';
        return _u;
    }),
    on(validateTaInfoSuccess, (state, action) => {
        const _u = { ...state };
        if (action.validationType === 'attachment') {
            _u.loader.taValidation.attachment[
                action.extraParams.payloadParams.AttachmentId
            ] = {
                ..._u.loader.taValidation.attachment[
                    action.extraParams.payloadParams.AttachmentId
                ],
                isLoading: false,
            };
        } else {
            _u.loader.taValidation[action.validationType] = false;
        }
        return _u;
    }),
    on(validateTaInfoFail, (state, action) => {
        const _u = { ...state };
        _u.loader.taValidation.showError.errorMessage = action.errorMessage;
        _u.loader.taValidation.showError.validationType = action.validationType;
        if (action.validationType === 'attachment') {
            _u.loader.taValidation[action.validationType][
                action.extraParams.payloadParams.AttachmentId
            ] = {
                ..._u.loader.taValidation[action.validationType][
                    action.extraParams.payloadParams.AttachmentId
                ],
                isLoading: false,
            };
        } else {
            _u.loader.taValidation[action.validationType] = false;
        }
        return _u;
    }),
    on(clearTaValidationState, (state, action) => {
        const _u = { ...state };
        //clear only the errors and not the loaders RK
        if (action.clearError) {
            _u.loader.taValidation.showError = {
                errorMessage: '',
                validationType: '',
            };
        } else {
            //clear the entire taValidation state RK
            _u.loader.taValidation = {
                trip_info: false,
                IDDocSummary: false,
                attachment: {},
                showError: {
                    errorMessage: '',
                    validationType: '',
                },
            };
        }
        return _u;
    }),
    on(validateTestSummary, (state, action) => {
        const _u = { ...state };
        _u.loader.taValidation.attachment[action.attachmentId] = {
            ..._u.loader.taValidation.attachment[action.attachmentId],
            isLoading: true,
        };
        return _u;
    }),
    on(validateTestSummarySuccess, (state, action) => {
        const _u = { ...state };
        _u.loader.taValidation.attachment[action.attachmentId] = {
            ..._u.loader.taValidation.attachment[action.attachmentId],
            isLoading: false,
        };
        return _u;
    }),
    on(validateTestSummaryFailed, (state, action) => {
        const _u = { ...state };
        _u.loader.taValidation.attachment[action.attachmentId] = {
            ..._u.loader.taValidation.attachment[action.attachmentId],
            isLoading: false,
        };
        _u.loader.taValidation.showError.errorMessage = action.errorMessage;
        _u.loader.taValidation.showError.validationType = 'attachment';
        return _u;
    }),
    on(getHCProfilesSuccess, (state) => {
        const _u = { ...state };
        _u.loader.header.isLoadMore = false;
        _u.loader.header.isRefreshing = false;

        return _u;
    }),
    on(getHCProfiles, (state) => {
        const _u = { ...state };
        _u.loader.header.isRefreshing = true;
        return _u;
    }),
    on(getHCProfilesFailed, (state) => {
        const _u = { ...state };
        _u.loader.header.isLoadMore = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.apps.isLoading = false;
        _u.loader.apps.errorMessage = 'error_messages.fetch_data_error';

        return _u;
    }),
    on(updateAppsState, (state, action) => {
        const _u = { ...state };
        _u.loader.apps[action.node] = action.value;
        return _u;
    }),
    on(setIsSidebarOpen, (state, action) => {
        return { ...state, isSidebarOpen: !!action.isOpen };
    }),
    on(getNotificationsAppsCount, (state) => {
        let _u = { ...state };
        _u = {
            ..._u,

            notifications: { isLoading: true, errorMessage: '' },
        };
        return _u;
    }),
    on(getNotificationsAppsCountSuccess, (state) => {
        let _u = { ...state };
        _u = {
            ..._u,

            notifications: { isLoading: false, errorMessage: '' },
        };
        return _u;
    }),
    on(getNotificationsAppsCountFailed, (state) => {
        let _u = { ...state };
        _u = {
            ..._u,

            notifications: {
                isLoading: false,
                errorMessage: 'error_messages.notifications_fetch_error',
            },
        };
        return _u;
    }),
    on(getElasticSearchData, (state, action) => {
        const _u = { ...state };
        if (action.dataNode && !action.doNotUpdateAppsUi) {
            _u.loader.apps.errorMessage = '';
            _u.loader.header.isLoadMore = true;
            _u.loader.header.isRefreshing = true;
            _u.loader.apps.isLoading = true;
            _u.pageFilter = {
                isLoading: true,
                errorMessage: '',
                isSuccess: false,
            };
        }
        return _u;
    }),
    on(getElasticSearchDataSuccess, (state, action) => {
        const _u = { ...state };
        if (action.dataNode && !action.doNotUpdateAppsUi) {
            _u.loader.apps.errorMessage = '';
            _u.loader.header.isLoadMore = false;
            _u.loader.header.isRefreshing = false;
            _u.loader.apps.isLoading = false;
            _u.pageFilter = {
                isLoading: false,
                errorMessage: '',
                isSuccess: true,
            };
        }
        return _u;
    }),
    on(getElasticSearchDataFailed, (state, action) => {
        const _u = { ...state };
        if (action.payload && !action.doNotUpdateAppsUi) {
            _u.loader.apps.errorMessage = 'error_messages.fetch_data_error';
            _u.loader.header.isLoadMore = false;
            _u.loader.header.isRefreshing = false;
            _u.loader.apps.isLoading = false;
            _u.pageFilter = {
                isLoading: false,
                errorMessage: 'error_messages.fetch_data_error',
                isSuccess: false,
            };
        }
        return _u;
    }),
    on(setPinCodeFailed, (state, action) => {
        const _u = { ...state };
        if (action.payload.error.message === 'pinCode exist!') {
            _u.loader.pinCode.errorMessage =
                'error_messages.pin_is_inappropriate';
        } else {
            _u.loader.pinCode.errorMessage =
                'error_messages.unexpected_error_occured';
        }
        _u.loader.pinCode.isLoading = false;
        _u.loader.pinCode.showError = true;
        return _u;
    }),
    on(getNamedSearchDataSuccess, (state) => {
        const _u = { ...state };
        _u.loader.header.isLoadMore = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.apps.isLoading = false;
        return _u;
    }),
    on(getNamedSearchDataFailed, (state) => {
        const _u = { ...state };
        _u.loader.header.isLoadMore = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.apps.isLoading = false;
        _u.loader.apps.errorMessage = 'error_messages.fetch_data_error';
        return _u;
    }),
    on(refreshWatchlistMatches, (state) => {
        const _u = { ...state };
        _u.loader.watchlistMatches.isRefreshing = true;
        _u.loader.watchlistMatches.errorMessage = '';
        return _u;
    }),
    on(refreshWatchlistMatchesFailed, (state) => {
        const _u = { ...state };
        _u.loader.watchlistMatches.isRefreshing = false;
        _u.loader.watchlistMatches.errorMessage =
            'error_messages.failed_to_refresh_watchlist';
        return _u;
    }),
    on(refreshWatchlistMatchesSuccess, (state) => {
        const _u = { ...state };
        _u.loader.watchlistMatches.isRefreshing = false;
        _u.loader.watchlistMatches.errorMessage = '';

        return _u;
    }),
    on(clearWatchlistMatches, (state) => {
        const _u = { ...state };
        _u.loader.watchlistMatches.isRefreshing = false;
        _u.loader.watchlistMatches.errorMessage = '';
        return _u;
    }),
    on(getOfflineApps, (state) => {
        const _u = { ...state };
        _u.offlineApps = {
            showError: false,
            isLoading: true,
            errorMessage: '',
            isSuccess: false,
        };
        return _u;
    }),
    on(getOfflineAppsSuccess, (state) => {
        const _u = { ...state };
        _u.offlineApps = {
            showError: false,
            isLoading: false,
            errorMessage: '',
            isSuccess: true,
        };
        _u.loader.apps.errorMessage = '';
        _u.loader.apps.isLoading = false;
        return _u;
    }),
    on(getOfflineAppsFailed, (state) => {
        const _u = { ...state };
        _u.offlineApps = {
            showError: true,
            isLoading: false,
            errorMessage: 'error_messages.failed_to_connect',
            isSuccess: false,
        };
        return _u;
    }),
    on(getOfflineAppDetailsSuccess, (state) => {
        const _u = { ...state };
        _u.loader.offlineAppDetails = {
            isLoading: false,
            errorMessage: '',
        };
        return _u;
    }),
    on(getOfflineAppDetailsFailed, (state) => {
        const _u = { ...state };
        _u.loader.offlineAppDetails = {
            isLoading: false,
            errorMessage: 'error_messages.failed_to_connect',
        };
        return _u;
    }),
    on(biometricSearch, (state) => {
        const _u = { ...state };
        _u.biometricSearch = {
            showError: false,
            isLoading: true,
            errorMessage: '',
            isSuccess: false,
        };
        return _u;
    }),
    on(biometricSearchSuccess, (state) => {
        const _u = { ...state };
        _u.biometricSearch = {
            showError: false,
            isLoading: false,
            errorMessage: '',
            isSuccess: true,
        };
        return _u;
    }),
    on(biometricSearchFailed, (state) => {
        const _u = { ...state };
        _u.biometricSearch = {
            showError: true,
            isLoading: false,
            errorMessage: 'error_messages.failed_to_load_results',
            isSuccess: false,
        };
        return _u;
    }),
    on(restoreBiometricSearchState, (state) => {
        const _u = { ...state };
        _u.biometricSearch = {
            showError: false,
            isLoading: false,
            errorMessage: '',
            isSuccess: false,
        };
        return _u;
    }),
    on(setCMSFormSubNode, (state, action) => {
        const _u = { ...state };
        _u.cms_forms.subNode = action.subNode;
        return _u;
    }),
    on(clearCMSFormSubNode, (state) => {
        const _u = { ...state };
        _u.cms_forms.subNode = '';
        return _u;
    }),
    on(updateParentPathState, (state, action) => {
        const _u = { ...state };
        _u.path.parentPath = action.parentPath;
        _u.path.childPath = action.childPath;
        return _u;
    }),
    on(setSelectedTab, (state, action) => {
        const _u = { ...state };
        _u.cmsTab.selectedTabType = action.tabType;
        _u.cmsTab.cmsSelectedTab = action.tab;
        return _u;
    }),
    on(getContents, (state) => {
        const _u = { ...state };
        _u.getContentsSuccess = false;
        return _u;
    }),
    on(getContentsSuccess, (state) => {
        const _u = { ...state };
        _u.getContentsSuccess = true;
        return _u;
    }),
    on(getContentsFailed, (state) => {
        const _u = { ...state };
        _u.getContentsSuccess = true;
        return _u;
    }),
    on(getNamedSearchSuccess, (state) => {
        const _u = { ...state };
        _u.loader.header.isLoadMore = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.apps.isLoading = false;
        return _u;
    }),
    on(getNamedSearchFailed, (state) => {
        const _u = { ...state };
        _u.loader.header.isLoadMore = false;
        _u.loader.header.isRefreshing = false;
        _u.loader.apps.isLoading = false;
        _u.loader.apps.errorMessage = 'error_messages.fetch_data_error';
        return _u;
    })
);

export default function reducer(state: UIState, action: Action): UIState {
    return uiReducer(state, action);
}
