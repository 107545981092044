import {
    LastUsedSearch,
    PageModel,
    PreferencesModel,
    SettingsModel,
} from '@app/models/preferences.model';
import { SearchRowModel } from '@app/models/search-row.model';
import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const setUserInformation = createAction(
    types.GET_USER_INFO_SUCCESS,
    props<{ payload: any }>()
);

export const getUserInformationFail = createAction(
    types.GET_USER_INFO_FAILED,
    props<{ payload: any }>()
);

export const clearLoginState = createAction(types.CLEAR_LOGIN_INFO);

export const setUserTags = createAction(
    types.SET_USER_TAGS,
    props<{ userTags: Array<string> }>()
);

// twilio
export const setTwilioToken = createAction(
    types.SET_TWILIO_TOKEN,
    props<{ twilioToken: string }>()
);

// preferences
export const getPreferences = createAction(types.GET_PREFERENCES);

export const setPreferences = createAction(
    types.GET_PREFERENCES_SUCCESS,
    props<{
        preferences: PreferencesModel;
        modifiedSinceUserPreference?: string;
    }>()
);

export const getPreferencesFail = createAction(types.GET_PREFERENCES_FAIL);

export const updateSettingsPreferences = createAction(
    types.UPDATE_SETTINGS_PREFERENCES,
    props<{ settings: SettingsModel }>()
);

export const updateSavedSearchesPreferences = createAction(
    types.UPDATE_SAVED_SEARCHES_PREFERENCES,
    props<{
        hideSnackBar?: boolean;
        savedSearches: { [key: string]: SearchRowModel[] };
    }>()
);

export const updateSavedSearchesPreferencesFail = createAction(
    types.UPDATE_SAVED_SEARCHES_FAILED
);

export const renameSavedSearch = createAction(
    types.RENAME_SAVED_SEARCH,
    props<{
        lastUsedSearch: LastUsedSearch;
        savedSearches: { [key: string]: SearchRowModel[] };
    }>()
);

export const updateLastUsedSearchPreferences = createAction(
    types.UPDATE_LAST_USED_SEARCH_PREFERENCES,
    props<{ lastUsedSearch: LastUsedSearch }>()
);

export const updatePagePreferences = createAction(
    types.UPDATE_PAGE_PREFERENCES,
    props<{
        node: string;
        subNode?: string;
        data: PageModel;
        replace?: boolean;
    }>()
);
export const setPinCode = createAction(
    types.SET_PIN_CODE,
    props<{ code: number }>()
);
export const setPinCodeSuccess = createAction(types.SET_PIN_CODE_SUCCESS);
