export const validationPatterns = {
    userName:
        /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/,
    password: /^(?=.*[A-Z])(?=.*[!@#\$%\^\&*\)\(+=._-])(?=.*[a-z]).{6}/,
    taRef: /[a-zA-Z0-9-]/,
    pageKey: /^[A-Z0-9_-]*$/,
    location:
        /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/,
    passportNumber: /^[a-zA-Z0-9]*$/,
    phoneNumber: /^\+?[1-9]\d{1,14}$/,
    whiteSpaceString: /.*[^ ].*/,
    flightNumber: /^\d{0,3}[A-Z0-9]$/,
    PJFlightNumber: /^[0-9,A-Z,-]{1,8}$/,
    numberInput: /\d|Backspace/, //allows only numbers and backspaces to be entered RK
    userIdentifier:
        /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$|^\+?[1-9]\d{1,14}$/, //REGEX for email or phone number validation CA
    cruise: /^[A-Za-z0-9\s&.'-]{3,30}$/,
    // eslint-disable-next-line no-control-regex
    email: /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
    singleInteger: /^([1-9]{1})$/,
    range_0_to_365: /^(?:36[0-5]|3[0-5]\d|[12]\d{2}|[1-9]\d|[1-9]|[0]?)$/,
    range_0_to_30: /^(0|[1-9]|[1-2][0-9]|30)$/,
    range_0_to_12: /^(0|[1-9]|1[0-2])$/,
    range_0_to_99: /^(0|[1-9][0-9]?)$/,
    range_0_to_9_or_M: /^[0-9M]$/,
    ipOrFqdn:
        /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):[0-9]+$/,
    leadingSpace: /^[ ]+|[ ]+$/g,
};
