import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const clearUiState = createAction(types.CLEAR_UI_STATE);

export const updateHeaderState = createAction(
    types.UPDATE_HEADER_STATE,
    props<{ node: string; value: boolean }>()
);

export const validateTaInfoFail = createAction(
    types.VALIDATE_TA_INFO_FAILED,
    props<{ validationType: string; extraParams: any; errorMessage: string }>()
);

export const clearTaValidationState = createAction(
    types.CLEAR_TA_VALIDATION_STATE,
    props<{ clearError: boolean }>()
);

export const updateAppsState = createAction(
    types.UPDATE_APPS_STATE,
    props<{ node: string; value: boolean }>()
);

export const setIsSidebarOpen = createAction(
    types.SET_IS_SIDEBAR_OPEN,
    props<{ isOpen: boolean }>()
);
export const setPinCodeFailed = createAction(
    types.SET_PIN_CODE_FAILED,
    props<{ payload: any }>()
);

export const setCMSFormSubNode = createAction(
    types.SET_CMS_FORM_SUBNODE,
    props<{ subNode: string }>()
);
export const updateParentPathState = createAction(
    types.UPDATE_PARENT_PATH_STATE,
    props<{ parentPath: string; childPath: string }>()
);
export const setSelectedTab = createAction(
    types.SET_SELECTED_TAB,
    props<{ tabType: string; tab: string }>()
);
export const getContents = createAction(types.GET_CONTENT_ATTEMPT);
export const getContentsSuccess = createAction(types.GET_CONTENT_SUCCESS);
export const getContentsFailed = createAction(types.GET_CONTENTS_FAILED);

export const clearCMSFormSubNode = createAction(types.CLEAR_CMS_FORM_SUBNODE);
export const clearWatchlistMatches = createAction(
    types.CLEAR_WATCHLIST_MATCHES
);
