import { ApplicationDetails } from '@app/models/appDetails';
import { DataBusModel } from '@app/models/databus.model';
import { Action, createReducer, on } from '@ngrx/store';
import { dataBusState } from '../state';
import {
    getAllEstablishmentUsersSuccess,
    setDistinctFlightsCountSuccess,
    validateTestSummarySuccess,
    getTAPrivateVersionSuccess,
    getTAPrivateVersionFailed,
    validateTaInfoSuccess,
    getPendingAppsSuccess,
    getHCProfilesSuccess,
    getAppDetailsSuccess,
    getAppsCountSuccess,
    getAppConfigSuccess,
    setAppDetailsParams,
    clearAppManagerNode,
    setApplicationNode,
    selectTableRowData,
    getAllUsersSuccess,
    getContentSuccess,
    updateFlightCount,
    clearDatabusData,
    setTableRowData,
    clearAppDetails,
    updateNodeData,
    getAppsSuccess,
    getAncillariesSuccess,
    updateFlightDashboardNode,
    getUserFileTypesSuccess,
    getSupportedMLTemplatesSuccess,
    getGroupedAppsSuccess,
    getElasticSearchDataSuccess,
    getEnrollmentTypesSuccess,
    getNamedSearchDataSuccess,
    refreshWatchlistMatchesSuccess,
    biometricSearchSuccess,
    getProcessingAppsCountSuccess,
    restoreBiometricSearchState,
    getTiersSuccess,
    getNamedSearchSuccess,
    removeApplication,
} from '../actions';
import { cloneDeep, isPlainObject } from 'lodash-es';

const initialState = new DataBusModel() as dataBusState;

const dataBusReducer = createReducer(
    initialState,
    on(getContentSuccess, (state, action) => {
        const _d = { ...state };
        _d.cms_data.supportingDocuments = action.supportingDocuments;
        _d.cms_data.countryWatchList = action.countryWatchList;
        _d.cms_data.countryWhiteList = action.countryWhiteList;
        _d.cms_data.countryLowRisk = action.countryLowRisk;
        _d.cms_data.countryMediumRisk = action.countryMediumRisk;
        _d.cms_data.countryYellowFever = action.countryYellowFever;
        _d.cms_data.countryPOX = action.countryPOX;
        _d.cms_data.countryEbola = action.countryEbola;
        _d.cms_data.countryPolio = action.countryPolio;
        _d.cms_data.countryCustoms = action.countryCustoms;
        _d.cms_data.countryVisaExemption = action.countryVisaExemption;
        _d.cms_data.countryVisaOnArrival = action.countryVisaOnArrival;
        _d.cms_data.questions = action.questions;
        _d.cms_data.pages = action.pages;
        _d.cms_data.questionsConfig = action.questionsConfig;

        _d.cms_data.languages = action.languages;

        _d.cms_data.establishments = action.establishments;
        _d.cms_data.airlines = action.airlines;

        _d.modifiedSince = action.modifiedSince;
        _d.version = action.version;
        _d.cms_data.establishmentsTags = action.establishmentsTags;
        _d.appConfig = { ..._d.appConfig, ...action.appConfig };
        _d.cms_data.alertBanner = action.alertBanner;
        _d.cms_data.favoriteCountries = action.favoriteCountries;
        _d.cms_data.airports = action.airports;
        _d.cms_data.cruises = action.cruises;
        _d.cms_data.purposeVisit = action.purposeVisit;
        _d.cms_data.currencies = action.currencies;
        _d.cms_data.groupConfig = action.groupConfig;
        _d.cms_data.popularAirlines = action.popularAirlines;
        _d.cms_data.popularCurrencies = action.popularCurrencies;
        _d.cms_data.popularCruises = action.popularCruises;
        _d.cms_data.allowedEnrollmentTypes = action.allowedEnrollmentTypes;
        _d.cms_data.nationalityRequirement = action.nationalityRequirement;
        _d.cms_data.contactInfoConfig = action.contactInfoConfig;
        _d.cms_data.visaConfig = action.visaConfig;
        _d.cms_data.defaultEnrollmentType = action.defaultEnrollmentType;
        _d.cms_data.tripInfoSections = action.tripInfoSections;
        _d.cms_data.applicationStatusCheck = action.applicationStatusCheck;
        _d.cms_data.otherPreferences = action.otherPreferences;
        _d.cms_data.otherOverrideConfig = action.otherOverrideConfig;
        (_d.cms_data as any).searchResults = undefined;
        return _d;
    }),
    on(getAllUsersSuccess, (state, action) => {
        const _d = { ...state };
        _d.cms_data.users = action.users;
        _d.modifiedSinceAllUsers = action.modifiedSinceAllUsers;
        return _d;
    }),
    on(updateNodeData, (state, action) => {
        const _d = { ...state };
        if (
            action.node === 'questionsConfig' ||
            action.node === 'allowedEnrollmentTypes'
        ) {
            _d.cms_data[action.node] = cloneDeep(action.nodeData);
        } else if (
            action.node === 'popularAirlines' ||
            action.node === 'popularCurrencies' ||
            action.node === 'popularCruises'
        ) {
            if (action.nodeData.action === 'delete') {
                const index = _d.cms_data[action.node].findIndex(
                    (elm) => elm === action.nodeData.code
                );
                if (index !== -1) {
                    _d.cms_data[action.node].splice(index, 1);
                }
            } else if (
                action.nodeData.action === 'add' &&
                !_d.cms_data[action.node].includes(action.nodeData.code)
            ) {
                _d.cms_data[action.node].push(action.nodeData.code);
            }
        } else if (action.node === 'defaultEnrollmentType') {
            if (action.nodeData.action === 'set') {
                _d.cms_data[action.node] =
                    action.nodeData.defaultEnrollmentType;
            } else if (action.nodeData.action === 'delete') {
                _d.cms_data[action.node] = '';
            }
            return cloneDeep(_d);
        } else if (action.index?.toString()) {
            const dataNode = _d.cms_data[action.node];
            dataNode[action.index] = cloneDeep(action.nodeData);
        } else if (action.node === 'otherOverrideConfig') {
            if (
                action.nodeData === 'delete' &&
                _d.cms_data[action.node][action.subNode]
            ) {
                delete _d.cms_data[action.node][action.subNode][
                    action.childNode
                ];
                return _d;
            } else if (_d.cms_data[action.node][action.subNode]) {
                if (
                    _d.cms_data[action.node][action.subNode][action.childNode]
                ) {
                    if (action.isPurposeVisitNode) {
                        if (
                            !_d.cms_data[action.node][action.subNode][
                                action.childNode
                            ]['purposeVisit']
                        ) {
                            _d.cms_data[action.node][action.subNode][
                                action.childNode
                            ] = {
                                purposeVisit: [action.nodeData],
                            };
                        } else {
                            _d.cms_data[action.node][action.subNode][
                                action.childNode
                            ]['purposeVisit'].push(action.nodeData);
                        }
                    } else {
                        _d.cms_data[action.node][action.subNode][
                            action.childNode
                        ] = action.nodeData;
                    }
                } else {
                    _d.cms_data[action.node][action.subNode] = {
                        ..._d.cms_data[action.node][action.subNode],
                        ...{
                            [action.childNode]: action.nodeData,
                        },
                    };
                }
            } else {
                _d.cms_data[action.node] = {
                    ..._d.cms_data[action.node],
                    [action.subNode]: {
                        [action.childNode]: action.nodeData,
                    },
                };
            }

            _d.cms_data[action.node] = cloneDeep(_d.cms_data[action.node]);
            return _d;
        } else if (
            (isPlainObject(_d.cms_data[action.node]) && !action.subNode) ||
            (action.subNode &&
                isPlainObject(_d.cms_data[action.node][action.subNode]))
        ) {
            //handles object states RK
            let data = action.nodeData;
            if (
                action.node === 'nationalityRequirement' &&
                action.subNode === 'rules'
            ) {
                const issuer = action.nodeData.issuer;
                const nodeData = cloneDeep(action.nodeData);
                delete nodeData.issuer;
                if (!nodeData.restrictions || !nodeData.restrictions.length) {
                    delete nodeData.restrictions;
                }
                data = { [issuer]: nodeData };
            }

            let dataNode = action.subNode
                ? _d.cms_data[action.node][action.subNode]
                : _d.cms_data[action.node];

            if (action.node === 'purposeVisit') {
                const enrollmentType = action.nodeData.EnrollmentType;
                delete action.nodeData.EnrollmentType;
                if (_d.selectedTableRow.data?.EnrollmentType) {
                    delete _d.selectedTableRow.data.EnrollmentType;
                }
                const enrollmentNode =
                    _d.cms_data[action.node][enrollmentType] || [];
                const exist = enrollmentNode.findIndex(
                    (node) => node.groupLabel === action.nodeData?.groupLabel
                );
                if (exist > -1) {
                    enrollmentNode[exist] = action.nodeData;
                    return _d;
                } else {
                    enrollmentNode.push(action.nodeData);
                    dataNode = {
                        ...dataNode,
                        ...{
                            [enrollmentType]: enrollmentNode,
                        },
                    };
                }
            } else if (action.node === 'applicationStatusCheck') {
                dataNode = action.nodeData;
            } else {
                dataNode = { ...dataNode, ...data };
            }
            if (action.subNode) {
                _d.cms_data[action.node][action.subNode] = cloneDeep(dataNode);
            } else {
                _d.cms_data[action.node] = cloneDeep(dataNode);
            }
        } else {
            const dataNode = action.subNode
                ? _d.cms_data[action.node][action.subNode] || []
                : _d.cms_data[action.node];
            const index = dataNode?.findIndex((node) => {
                if (action.node === 'airlines') {
                    delete _d.selectedTableRow.data.starred;
                }
                return (
                    JSON.stringify(node) ===
                    JSON.stringify(_d.selectedTableRow.data)
                );
            });
            if (index !== -1) {
                dataNode[index] = cloneDeep(action.nodeData);
            } else {
                dataNode.push(action.nodeData);
            }

            if (action.subNode) {
                _d.cms_data[action.node][action.subNode] = dataNode;
            }
        }
        return cloneDeep(_d);
    }),
    on(setTableRowData, (state, action) => {
        const _d = { ...state, cms_data: { ...state.cms_data } };
        if (action.childNode) {
            if (action.updateNode) {
                _d.cms_data[action.node][action.subNode][action.childNode] = {
                    ..._d.cms_data[action.node][action.subNode][
                        action.childNode
                    ],
                    [action.updateNode]: action.rowData,
                };
            }
        } else if (action.subNode) {
            _d.cms_data[action.node][action.subNode] = action.rowData;
        } else {
            _d.cms_data[action.node] = action.rowData;
        }

        return _d;
    }),
    on(selectTableRowData, (state, action) => {
        const _d = { ...state };
        _d.selectedTableRow.node = action.node;
        _d.selectedTableRow.data = action.rowData;
        return _d;
    }),
    on(getAppConfigSuccess, (state, action) => {
        const _d = { ...state };
        _d.appConfig = { ..._d.appConfig, ...action.appConfig };
        return _d;
    }),
    on(getPendingAppsSuccess, (state, action) => {
        let _d = { ...state };

        _d = cloneDeep(_d);

        if (action.isLoadMore) {
            Object.keys(action.apps).forEach((key) => {
                _d.appsManager.pending[key] = [
                    ...(_d.appsManager.pending[key] || []),
                    ...action.apps[key],
                ];
            });
        } else {
            //to keep data dynamic and prevent keeping wrong nodes in the state rk
            _d.appsManager.pending = {
                ...{
                    count: _d.appsManager.pending.count,
                    index: _d.appsManager.pending.index,
                    timestamp: _d.appsManager.pending.timestamp,
                    allData: [],
                },
                ...action.apps,
            };
        }
        _d.appsManager.pending.timestamp = new Date().toISOString();
        _d.appsManager.pending.index = action.index;

        return _d;
    }),
    on(getAppsCountSuccess, (state, action) => {
        const _d = { ...state };
        const storeNode = cloneDeep(_d.appsManager[action.storeNode]);
        storeNode.count = action.count;

        _d.appsManager = {
            ..._d.appsManager,
            ...{ [action.storeNode]: storeNode },
        };
        return _d;
    }),
    on(getAppsSuccess, (state, action) => {
        let _d = { ...state };

        _d = cloneDeep(_d);

        if (action.isLoadMore) {
            if (Array.isArray(action.apps)) {
                _d.appsManager[action.storeNode].apps = [
                    ...(_d.appsManager[action.storeNode].apps || []),
                    ...action.apps,
                ];
            } else {
                Object.keys(action.apps).forEach((key) => {
                    _d.appsManager[action.storeNode][key] = [
                        ...(_d.appsManager[action.storeNode][key] || []),
                        ...action.apps[key],
                    ];
                });
            }
        } else {
            if (Array.isArray(action.apps)) {
                _d.appsManager[action.storeNode].apps = action.apps;
            } else {
                //to keep data dynamic and prevent keeping wrong nodes in the state rk
                _d.appsManager[action.storeNode] = {
                    ...{
                        count: _d.appsManager[action.storeNode].count,
                        index: _d.appsManager[action.storeNode].index,
                        timestamp: _d.appsManager[action.storeNode].timestamp,
                        allData: [],
                    },
                    ...action.apps,
                };
            }
        }

        _d.appsManager[action.storeNode].timestamp = new Date().toISOString();
        _d.appsManager[action.storeNode].index = action.index;
        if (action.notElasticSearch) {
            _d.appsManager[action.storeNode].count = action.apps['allData']
                ? action.apps['allData'].length
                : action.apps.length;
        }

        return _d;
    }),
    on(getAppDetailsSuccess, (state, action) => {
        const _d = { ...state };
        _d.appDetails = action.payload;
        return _d;
    }),
    on(setApplicationNode, (state, action) => {
        const _d = { ...state };
        _d.appDetails[action.node] = action.data;
        return _d;
    }),

    on(getGroupedAppsSuccess, (state, action) => {
        const _d = { ...state };
        _d.appDetails.groupApplications = action.data;
        return _d;
    }),

    on(setAppDetailsParams, (state, action) => {
        const _d = { ...state };
        _d.appDetails.flag = action.flag;
        _d.appDetails.ref = action.code;
        _d.appDetails.SubmittedTs = action.submittedTs;
        _d.appDetails.status = action.applicationStatus;
        _d.appDetails.index = action.index;
        _d.appDetails.flagCode = action.flagCode;

        return _d;
    }),
    on(clearAppDetails, (state) => {
        const _d = { ...state };
        _d.appDetails = new ApplicationDetails();
        return _d;
    }),
    on(clearAppManagerNode, (state, action) => {
        const _d = { ...state };
        if (
            action.subNode &&
            _d.appsManager[action.node] &&
            _d.appsManager[action.node][action.subNode]
        ) {
            _d.appsManager[action.node][action.subNode] = action.dataToClear;
        } else {
            _d.appsManager[action.node] = action.dataToClear;
        }

        return _d;
    }),
    on(clearDatabusData, (state) => {
        let _d = { ...state };
        _d = {
            ...new DataBusModel(),
            taPrivateVersion: _d.taPrivateVersion,
            version: _d.version,
        };

        return _d;
    }),
    on(validateTaInfoSuccess, (state, action) => {
        const _d = { ...state };
        if (action.validationType !== 'attachment') {
            _d.appDetails.taValidation = {
                ..._d.appDetails.taValidation,
                ...action.validationData,
            };
        } else {
            _d.appDetails.taValidation.attachment[
                action.extraParams.payloadParams['AttachmentId']
            ] = {
                ..._d.appDetails.taValidation.attachment[
                    action.extraParams.payloadParams['AttachmentId']
                ],
                ...action.validationData[
                    action.extraParams.payloadParams['AttachmentId']
                ],
            };
        }
        return _d;
    }),
    on(getAllEstablishmentUsersSuccess, (state, action) => {
        const _d = { ...state };
        _d.cms_data.establishmentUsers = action.establishmentUsers;
        _d.modifiedSinceAllEstablishmentUsers =
            action.modifiedSinceAllEstablishmentUsers;
        return _d;
    }),
    on(setDistinctFlightsCountSuccess, (state, action) => {
        const _d = { ...state };
        //  to update the apps counts in the tabs RK
        const currentState = _d.appsManager.flightDashboard;
        Object.keys(_d.appsManager.flightDashboard).forEach((key) => {
            if (
                Array.isArray(currentState[key]) &&
                currentState[key].length > 0
            ) {
                const index = _d.appsManager.flightDashboard[key].findIndex(
                    (node) =>
                        node.title === action.title &&
                        node.subtitle === action.subtitle
                );
                if (index !== -1) {
                    _d.appsManager.flightDashboard[key][index] = {
                        ..._d.appsManager.flightDashboard[key][index],
                        ...action.app,
                    };
                }
            }
        });

        return _d;
    }),

    on(updateFlightCount, (state, action) => {
        const _d = { ...state };

        const index = _d.appsManager.flightDashboard.allData.findIndex(
            (node) =>
                node.title === action.title && node.subtitle === action.subtitle
        );

        if (index !== -1) {
            _d.appsManager.flightDashboard.allData[index].quarantineCount = 0;
            _d.appsManager.flightDashboard.allData[index].approvedCount = 0;
            _d.appsManager.flightDashboard.allData[index].rejectedCount = 0;
            _d.appsManager.flightDashboard.allData[index].questionCount = 0;
            _d.appsManager.flightDashboard.allData[index].pendingCount = 0;
            _d.appsManager.flightDashboard.allData[index].examineCount = 0;
        }

        return _d;
    }),
    on(validateTestSummarySuccess, (state, action) => {
        const _d = { ...state };

        _d.appDetails.taValidation.attachment[action.attachmentId] = {
            ..._d.appDetails.taValidation.attachment[action.attachmentId],
            ...action['testSummaryData'][action.attachmentId],
        };
        return _d;
    }),
    on(getTAPrivateVersionSuccess, (state, action) => {
        let _d = { ...state };

        _d = cloneDeep(_d);

        _d.taPrivateVersion = action.taPrivateVersion;

        return _d;
    }),
    on(getTAPrivateVersionFailed, (state) => {
        const _d = { ...state };

        _d.taPrivateVersion = '';

        return _d;
    }),
    on(getHCProfilesSuccess, (state, action) => {
        const _d = { ...state };

        _d.hcProfiles = action.hcProfiles;

        return _d;
    }),
    on(getAncillariesSuccess, (state, action) => {
        const _d = { ...state };

        _d.purchasedItemsConfig = action.ancillariesItems.slice();

        return _d;
    }),
    on(getTiersSuccess, (state, action) => {
        const _d = { ...state };
        _d.tierItemsConfig = action.tiers.slice();
        return _d;
    }),
    on(updateFlightDashboardNode, (state, action) => {
        const _d = { ...state };
        _d.appsManager.flightDashboard = {
            ..._d.appsManager.flightDashboard,
            ...action.nodes,
        };
        return _d;
    }),

    on(getUserFileTypesSuccess, (state, action) => {
        const _d = { ...state };
        _d.userFileTypes = action.userFileTypes.slice();
        return _d;
    }),
    on(getSupportedMLTemplatesSuccess, (state, action) => {
        const _d = { ...state };
        _d.cms_data.supportedTemplates = action.supportedTemplates;
        return _d;
    }),
    on(getElasticSearchDataSuccess, (state, action) => {
        let _d = { ...state };
        if (action.dataNode) {
            if (action.isLoadMore) {
                _d[action.parentStoreNode][action.storeNode][action.dataNode] =
                    [
                        ..._d[action.parentStoreNode][action.storeNode][
                            action.dataNode
                        ],
                        ...action.data,
                    ];
            } else {
                _d[action.parentStoreNode][action.storeNode][action.dataNode] =
                    action.data;
            }
            _d = cloneDeep(_d);
        } else {
            _d[action.parentStoreNode][action.storeNode] = action.data;
        }

        if (action.count?.toString()) {
            _d[action.parentStoreNode][action.storeNode].count = action.count;
        }
        return _d;
    }),
    on(getEnrollmentTypesSuccess, (state, action) => {
        const _d = { ...state };
        _d.enrollmentTypes = action.data;
        return _d;
    }),
    on(getNamedSearchDataSuccess, (state, action) => {
        let _d = { ...state };

        _d = cloneDeep(_d);
        if (action.dataNode) {
            _d.appsManager[action.storeNode][action.dataNode] = action.apps;
        } else {
            _d[action.parentStoreNode][action.storeNode] = action.apps;
        }
        _d[action.parentStoreNode][action.storeNode].timestamp =
            new Date().toISOString();
        _d[action.parentStoreNode][action.storeNode].count = action.count;
        _d[action.parentStoreNode][action.storeNode].index = action.index;
        return _d;
    }),
    on(refreshWatchlistMatchesSuccess, (state, action) => {
        const _d = { ...state };
        _d.appDetails.watchlistMatches = cloneDeep(action.payload);

        return _d;
    }),
    on(biometricSearchSuccess, (state, action) => {
        const _d = { ...state };
        _d.biometricSearch = action.data;
        return _d;
    }),
    on(getProcessingAppsCountSuccess, (state, action) => {
        const _d = { ...state };
        Object.entries(action.counts).forEach(([key, value]) => {
            if (_d.appsManager[key]) {
                _d.appsManager[key].count = value;
            }
        });
        return _d;
    }),
    on(restoreBiometricSearchState, (state) => {
        const _d = { ...state };
        _d.biometricSearch = [];
        return _d;
    }),
    on(removeApplication, (state, action) => {
        const _d = { ...state };
        _d.appsManager[action.path]['allData'] = _d.appsManager[action.path][
            'allData'
        ].filter((app) => app.appReference !== action.ref);
        _d.appsManager[action.path]['count'] =
            _d.appsManager[action.path]['allData'].length;
        return cloneDeep(_d);
    }),

    on(getNamedSearchSuccess, (state, action) => {
        let _d = { ...state };

        _d = cloneDeep(_d);
        if (
            action.storeNode &&
            action.childStoreNode &&
            _d[action.parentStoreNode][action.storeNode] &&
            _d[action.parentStoreNode][action.storeNode][action.childStoreNode]
        ) {
            if (action.dataNode) {
                _d.appsManager[action.storeNode][action.childStoreNode][
                    action.dataNode
                ] = action.apps;
            } else {
                _d[action.parentStoreNode][action.storeNode][
                    action.childStoreNode
                ] = action.apps;
            }
            _d[action.parentStoreNode][action.storeNode][
                action.childStoreNode
            ].timestamp = new Date().toISOString();
            _d[action.parentStoreNode][action.storeNode][
                action.childStoreNode
            ].count = action.count;
            _d[action.parentStoreNode][action.storeNode][
                action.childStoreNode
            ].index = action.index;
        }
        return _d;
    })
);

export default function reducer(
    state: dataBusState,
    action: Action
): dataBusState {
    return dataBusReducer(state, action);
}
