import * as ssePolyfill from 'event-source-polyfill/src/eventsource.min.js';
import { EventHubService } from '@app/services/event-hub.service';
import { AuthenticationService } from './authentication.service';
import { AppConfig } from '../../environments/environment';
import { user, selectDataBus } from '@app/store/selectors';
import { timeout, catchError, map } from 'rxjs/operators';
import { MappingService } from './mapping.service';
import { getContent } from '@app/store/actions';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    HttpErrorResponse,
    HttpEventType,
    HttpClient,
} from '@angular/common/http';
import {
    EncounterLogModel,
    UpdateUserModel,
    DeleteUserModel,
    AddUserModel,
} from '@app/models/services/apiService.model';
import { PCRTest } from '@app/models/services/mappingService.model';
import { UtilityService } from './utility.service';
import { ApplicationChangesModel } from '@app/models/services/utilityService.model';
import { PreferencesModel } from '@app/models/preferences.model';
import { ElasticSearchDataParams } from '@app/models/elasticSearch';
import { mergeWith } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    isGetContentSuccess = false;
    permitCountry = '';
    isSaving = false;
    baseUrl = '';
    modifiedSince = '';
    modifiedSinceAllEstablishmentUsers = '';
    modifiedSinceAllUsers = '';
    modifiedSinceUserPreference = '';
    userToken: string;
    eventSource: any;
    languages: any;
    cmsData: any;
    //array of active analyzeTestResults subscriptions RK
    testResultSubscriptions = [];

    //the index of the last api call fired RK
    //defaults to 4 since we initially fire 5 api calls
    lastActiveIndex = 4;

    /* Tracks the number of finished api calls
     used to empty the subscription state when all apis are resolved RK */
    resolvedCallsCount = 0;
    appConfig = AppConfig;
    email: string;

    constructor(
        private authService: AuthenticationService,
        private mappingService: MappingService,
        public eventHub: EventHubService,
        private http: HttpClient,
        public store: Store<any>,
        private utilityService: UtilityService
    ) {
        const dataBusSelector = this.store.select(selectDataBus);
        const userState$ = store.select(user);

        userState$.subscribe((userItem) => {
            this.permitCountry = userItem.permitCountry;
            this.userToken = userItem.accessToken;
            this.baseUrl = `${this.appConfig['localTravEndpoint']}`.replace(
                '${this.permitCountry}',
                this.permitCountry ? this.permitCountry.toLowerCase() : ''
            );
            this.email = userItem.username;
            this.modifiedSinceUserPreference =
                userItem.modifiedSinceUserPreference;
        });

        dataBusSelector.subscribe((dataBusItem) => {
            this.modifiedSince = dataBusItem.modifiedSince;
            this.modifiedSinceAllEstablishmentUsers =
                dataBusItem.modifiedSinceAllEstablishmentUsers;
            this.modifiedSinceAllUsers = dataBusItem.modifiedSinceAllUsers;
            this.cmsData = dataBusItem.cms_data;
            this.languages = dataBusItem.cms_data.languages;
        });

        // if (
        //   window.location.origin.startsWith("https://ta-admin.firebaseapp.com") ||
        //   window.location.origin.startsWith("https://vmp-demo.travizory.ch") ||
        //   window.location.origin.startsWith("https://vmp-dev.travizory.ch") ||
        //   window.location.origin.startsWith("http://localhost:4200")
        // ) {
        // }
    }

    searchApplications(code: string = ''): Observable<any> {
        return this.http
            .get<any>(`${this.baseUrl}taapi/api/search/detail/${code}`, {
                headers: { Authorization: `Bearer ${this.userToken}` },
            })
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    approveApplication(
        appNumber: string = null,
        reason: string = null,
        flagCode: string = null,
        notify: boolean = true,
        doGroup: boolean = false,
        smartApprove: boolean = true
    ): Promise<any> {
        const flagCodeToSend =
            flagCode !== 'APPROVE' ? `&flagCode=${flagCode}` : '';
        const group = doGroup ? `&doGroup=${doGroup}` : '';
        const reasonToSend = reason ? `?reason=${reason}` : '';
        const notifyFlag = reason ? `&notify=${notify}` : `?notify=${notify}`;
        const smartApproveFlag =
            smartApprove && AppConfig.smartApprovalEnabled
                ? `&smartApprove=${smartApprove}`
                : '';

        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/decision/approveApplication/${appNumber}${reasonToSend}${notifyFlag}${flagCodeToSend}${group}${smartApproveFlag}`,
                { headers: { Authorization: `Bearer ${this.userToken}` } }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    addNote(
        taRef = '',
        note = '',
        type?: boolean,
        doGroup = false,
        setFlagCode = false,
        flagCode = ''
    ): Promise<any> {
        const body = {
            TAReference: taRef || '',
            note: note || '',
            type: type ? 'ACTIONREQUIRED' : 'REVIEW',
            doGroup,
            setFlagCode,
            flagCode,
        };

        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/validate/addNote`,
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    rejectApplication(
        appNumber: string = '',
        token: string = '',
        reason: string = '',
        notify: boolean = true,
        doGroup: boolean = false
    ): Promise<any> {
        //encode the & character so that it does not escape the rest of the message CA
        const reg = new RegExp('&', 'g');
        reason = reason.replace(reg, '%26');
        return this.http
            .get<any>(
                `${
                    this.baseUrl
                }taapi/api/decision/rejectApplication/${appNumber}${
                    reason ? '?reason=' + reason : ''
                }${reason ? '&notify=' + notify : '?notify=' + notify}${
                    doGroup ? '&doGroup=' + doGroup : ''
                }`,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    reverseApplication(
        taReference: string = '',
        taStatus: string = ''
    ): Promise<any> {
        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/decision/changeStatus/${taReference}/${taStatus}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    getApiCall(apiNode: string, modifiedSince?: string): Observable<any> {
        this.utilityService.gotContentsStatus = '';
        const headerOptions = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                Authorization: `Bearer ${this.userToken}`,
            },
        };
        if (modifiedSince) {
            headerOptions.headers['if-modified-since'] = this[modifiedSince];
            headerOptions.headers['Last-Modified'] = this[modifiedSince];
            headerOptions['observe'] = 'response';
        }

        return this.http
            .get<any>(
                `${this.appConfig['vmpAPI'].endPoint}/${apiNode}`,
                headerOptions
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (apiNode === 'getContents') {
                        const str = error.status.toString();
                        if (
                            str.substring(0, 2) === '40' &&
                            !this.appConfig['pageNode'].includes('hc')
                        ) {
                            this.authService.onSignOut();
                        }
                    }
                    return throwError(error);
                })
            );
    }
    refreshWatchlistMatches(ref: string): Observable<any> {
        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/update/refreshWatchlistMatches/${ref}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }
    generatePinCode(code: number): Observable<any> {
        const body = {
            pinCode: code,
        };
        return this.http
            .post<any>(
                `${this.appConfig['vmpAPI'].endPoint}/generatePinCode`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    verifyPinCode(code: number): Observable<any> {
        const body = {
            pinCode: code,
        };
        return this.http
            .post<any>(
                `${this.appConfig['vmpAPI'].endPoint}/verifyPinCode`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    updatePinCode(code: number): Observable<any> {
        const body = {
            pinCode: code,
        };
        return this.http
            .post<any>(
                `${this.appConfig['vmpAPI'].endPoint}/updatePinCode`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    resetPassword(userEmail: string): Observable<any> {
        const body = {
            email: userEmail,
        };
        return this.http
            .post<any>(
                `${this.appConfig['vmpAPI'].endPoint}/resetPassword`,
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    postApiCall(
        apiNode: string,
        apiBody: any,
        isDisabled?: boolean
    ): Observable<any> {
        let body = apiBody;

        if (apiNode === 'addUser') {
            body = { user: new AddUserModel(apiBody) };
        } else if (apiNode === 'updateUser') {
            if (isDisabled !== undefined) {
                apiBody.disabled = isDisabled;
            }

            body = {
                user: new UpdateUserModel(apiBody),
            };
        } else if (apiNode === 'deleteUser') {
            body = new DeleteUserModel({
                userId: apiBody.data.uid,
            });
        } else if (apiNode === 'checkUserExists') {
            body = {
                email: apiBody,
            };
        }
        return this.http
            .post<any>(
                `${this.appConfig['vmpAPI'].endPoint}/${apiNode}`,
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    postApiCallPromise(
        nodeApi: string,
        node?: string | Array<string>,
        content?: any
    ): Promise<any> {
        let body = content;

        if (nodeApi === 'saveContents') {
            this.isGetContentSuccess = false;
            this.isSaving = true;

            body = {};
            const nodes = Array.isArray(node) ? node : [node];
            nodes.forEach((storeNode) => {
                body = mergeWith(
                    body,
                    this.mappingService.normalizeSaveData(
                        content,
                        this.cmsData,
                        this.languages,
                        storeNode
                    )
                );
            });
        }
        return new Promise((resolve, reject) => {
            this.postApiCall(nodeApi, body).subscribe(
                (response) => {
                    if (nodeApi === 'saveContents') {
                        if (response.status) {
                            this.store.dispatch(getContent());
                            setTimeout(() => {
                                this.isSaving = false;
                                resolve(this.isGetContentSuccess);
                            }, 2000);
                        }
                    } else {
                        resolve(response);
                    }
                },
                (error) => {
                    this.isSaving = false;
                    reject(error);
                }
            );
        });
    }

    addUser(body: any): Observable<any> {
        return this.postApiCall('addUser', body);
    }

    getAppConfig(): Observable<any> {
        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/config/get/PUBLICAPPCONFIG/${this.permitCountry}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getApp(body: any): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}searchapi/search`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getAppCount(body: any): Observable<any> {
        return this.http
            .post<any>(`${this.baseUrl}searchapi/count`, JSON.stringify(body), {
                headers: {
                    Authorization: `Bearer ${this.userToken}`,
                    'Content-Type': 'application/json',
                },
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getAppDetailsJson(ref: string = ''): Observable<any> {
        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/vmp/debug/getUserSubmittedJson/${ref}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    updateApplicationAttachment(body: any): Observable<any> {
        const taRef = body.TAReference ? body.TAReference : '';
        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/update/updateAttachment/${taRef}`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                    reportProgress: true,
                    observe: 'events',
                }
            )
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            return {
                                status: 'progress',
                                message: Math.round(
                                    (100 * event.loaded) / event.total
                                ),
                            };

                        case HttpEventType.Response:
                            return event.body;
                        default:
                            return `Unhandled event: ${event.type}`;
                    }
                }),
                timeout(30000),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    sendNotificationEmail(body: any, key: string = ''): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/notify/${key}`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    updateUserInfo(
        code: string = '',
        field: string = '',
        body: any = undefined
    ): Observable<any> {
        //this api call could be a get (to get trip info) or a post (to update trip info)
        //if there is a body then we need to send it to the post call
        const url = `${this.baseUrl}taapi/api/update/${field}/${code}`;
        const headers = {
            headers: { Authorization: `Bearer ${this.userToken}` },
        };

        const httpRequest = body
            ? this.http.post<any>(url, body, headers)
            : this.http.get<any>(url, headers);

        return httpRequest.pipe(timeout(30000)).pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            })
        );
    }

    getAvailableEnrollmentCode(): Promise<any> {
        return this.http
            .get<any>(
                `${this.appConfig['vmpAPI'].endPoint}/getAvailableEnrollmentCode`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                        'user-token': `${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    getElasticSearchData(params: ElasticSearchDataParams): Observable<any> {
        return this.http
            .post<any>(
                params.apiURL
                    ? `${this.baseUrl}${params.apiURL} `
                    : `${this.baseUrl}tasearch/api/${params.searchType}/search/template`,
                params.body,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type':
                            params.contentType || 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getNamedSearchData(body: any): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}tasearch/api/core/search/template`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getUniqueStatusOnFlight(
        arrivalCarrier: string = '',
        arrivalFlightNumber: string = '',
        arrivalDate: string = ''
    ): Observable<any> {
        const body = {
            _source: ['applicantStatus'],
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                'userSubmittedTravelApplicationRequest.trip_info.arrivalCarrier':
                                    arrivalCarrier,
                            },
                        },
                        {
                            match: {
                                'userSubmittedTravelApplicationRequest.trip_info.arrivalFlightNumber':
                                    arrivalFlightNumber,
                            },
                        },
                        {
                            match: {
                                'userSubmittedTravelApplicationRequest.trip_info.arrivalDate':
                                    arrivalDate,
                            },
                        },
                    ],
                    must_not: [],
                    should: [],
                    minimum_should_match: 0,
                },
            },
            aggs: {
                applicantStatus: {
                    terms: {
                        field: 'taStatus',
                    },
                },
            },
        };
        return this.http
            .post<any>(
                `${this.baseUrl}searchapi/search`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getUniqueFlagCodeOnFlight(
        arrivalCarrier: string = '',
        arrivalFlightNumber: string = '',
        arrivalDate: string = ''
    ): Observable<any> {
        const body = {
            _source: ['applicantStatus'],
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                'userSubmittedTravelApplicationRequest.trip_info.arrivalCarrier':
                                    arrivalCarrier,
                            },
                        },
                        {
                            match: {
                                'userSubmittedTravelApplicationRequest.trip_info.arrivalFlightNumber':
                                    arrivalFlightNumber,
                            },
                        },
                        {
                            match: {
                                'userSubmittedTravelApplicationRequest.trip_info.arrivalDate':
                                    arrivalDate,
                            },
                        },
                    ],
                    must_not: [],
                    should: [],
                    minimum_should_match: 0,
                },
            },
            aggs: {
                applicantStatus: {
                    terms: {
                        field: 'flagCode',
                    },
                },
            },
        };
        return this.http
            .post<any>(
                `${this.baseUrl}searchapi/search`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    validateTA(
        validationType: string = '',
        ref: string = '',
        extraParams: any = undefined
    ): Observable<any> {
        let body: any = {
            TAReference: ref,
            valid: true,
        };
        if (extraParams) {
            body = { ...body, ...extraParams.payloadParams };
        }
        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/validate/${validationType}`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getHotel(body: any): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}searchapi/namedSearch`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getHotelApps(body: any): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}searchapi/namedSearch`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getVisitedCountriesApps(body: any): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}searchapi/namedSearch`,
                JSON.stringify(body),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getStreamData(): void {
        this.eventSource = new ssePolyfill.EventSourcePolyfill(
            `${this.baseUrl}stream/live`,
            {
                headers: {
                    Authorization: `Bearer ${this.userToken}`,
                },
            }
        );
        this.eventSource.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);

            this.utilityService.handleDataStream(parsedData);
            if (this.userToken === '') {
                this.eventSource.close();
            }
        };

        this.eventSource.onerror = () => {
            this.eventSource.close();
        };
    }

    validateTestSummary(body: any): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/validate/testSummary`,
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getTAPrivateVersion(): Observable<any> {
        return this.http
            .get<any>(`${this.baseUrl}taapi/api/health/version`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                },
            })
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    analyzeTestResults(params: PCRTest = {}): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/analyze/testResults`,
                JSON.stringify(params),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                    reportProgress: true,
                    observe: 'events',
                }
            )
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            return {
                                status: 'progress',
                                message: Math.round(
                                    (100 * event.loaded) / event.total
                                ),
                            };

                        case HttpEventType.Response:
                            return {
                                status: event.status,
                                response: event.body,
                            };

                        default:
                            return `Unhandled event: ${event.type}`;
                    }
                }),
                timeout(30000),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    //function that emits an event triggering the next api call in the queue RK
    fireNextApi(): void {
        //running this code means that one of the active api calls has been resolved RK
        this.resolvedCallsCount++;
        //to avoid making api calls undefined subscriptions array calls RK
        if (this.lastActiveIndex < this.testResultSubscriptions.length) {
            //increment the last active index since a new api call is going to be made RK
            this.lastActiveIndex++;

            //trigger the execution of the next api call in the queue RK
            this.eventHub.executeCall.emit({
                testResultIndex: this.lastActiveIndex,
            });
        } else if (
            this.resolvedCallsCount === this.testResultSubscriptions.length
        ) {
            //all api calls have been fired and resolved, reset the api service variables RK
            this.resetApiSubscriptions(true);
        }
    }

    analyzeOCR(file: File): Observable<any> {
        const body = {
            base64File: file,
        };
        return this.http
            .post<any>(
                `${this.appConfig['vmpAPI'].endPoint}/OCRAnalyzer`,
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                        'user-token': `${this.userToken}`,
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    generateBarCode(
        body: any,
        isEUCertificate: boolean = false
    ): Observable<any> {
        let extension = 'api/v1/certificate/create';

        if (isEUCertificate) {
            extension = 'api/v1/certificate/createEU';
        }
        return this.http
            .post<any>(
                `${this.appConfig['healthCredentialBaseUrl']}${extension}`,
                JSON.stringify({ ...body }),
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getHCProfiles(): Observable<any> {
        return this.http
            .get<any>(
                `${this.appConfig['healthCredentialBaseUrl']}api/v1/certificate/availableProfiles`,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    resetApiSubscriptions(emitDone?: boolean): void {
        this.lastActiveIndex = 4;
        this.resolvedCallsCount = 0;
        this.testResultSubscriptions = [];
        if (emitDone) {
            this.eventHub.doneThrottling.emit();
        }
    }

    addPurchasedItem(body: Object = {}, taReference: string): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/redeem/addAncillary/${taReference}`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getAncillaries(): Observable<any> {
        return this.http
            .get<any>(`${this.baseUrl}taapi/api/redeem/getAncillaries`, {
                headers: {
                    Authorization: `Bearer ${this.userToken}`,
                    'Content-Type': 'application/json',
                },
            })
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getTiers(): Observable<any> {
        return this.http
            .get<any>(`${this.baseUrl}taapi/api/config/getPricingTiers`, {
                headers: {
                    Authorization: `Bearer ${this.userToken}`,
                    'Content-Type': 'application/json',
                },
            })
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    deletePurchasedItem(
        taReference: string = '',
        category: string = '',
        packageName: string = ''
    ): Observable<any> {
        return this.http
            .delete<any>(
                `${this.baseUrl}taapi/api/redeem/delRedeemableItem/${taReference}/${category}/${packageName}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    encounterLog(
        body: EncounterLogModel,
        isOffline?: boolean
    ): Observable<any> {
        const headers = {
            Authorization: `Bearer ${this.userToken}`,
            'Content-Type': 'application/json',
        };
        if (isOffline) {
            headers['x-api-key'] = 'ab3ea955-7f6f-4781-a09b-2625e860ee53';
        }
        return this.http
            .post<any>(
                isOffline
                    ? `https://${this.utilityService.ipOrFqdn}/api/encounter/log`
                    : `${this.baseUrl}taapi/api/encounter/log`,
                body,
                {
                    headers,
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getUserFileTypes(): Observable<any> {
        return this.http
            .get<any>(`${this.baseUrl}taapi/api/config/system/userFileTypes`, {
                headers: { Authorization: `Bearer ${this.userToken}` },
            })
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    changeStatusOnTa(
        appNumber: string = null,
        status: string = null,
        doGroup: boolean = false
    ): Promise<any> {
        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/decision/changeStatus/${appNumber}/${status}?doGroup=${doGroup}`,
                { headers: { Authorization: `Bearer ${this.userToken}` } }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    moveIncompleteApplicationToProcessing(
        appRef: string = null,
        doGroup: boolean = false
    ): Promise<any> {
        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/decision/moveIncompleteApplicationToProcessing/${appRef}?doGroup=${doGroup}`,
                { headers: { Authorization: `Bearer ${this.userToken}` } }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    changeFlagCodeOnTa(
        appNumber: string = null,
        flagCode: string = null,
        doGroup: boolean = false
    ): Promise<any> {
        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/decision/flagApplication/${appNumber}/?flagCode=${flagCode}&doGroup=${doGroup}`,
                { headers: { Authorization: `Bearer ${this.userToken}` } }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    applyMultipleDecision(
        selectedApps: Array<ApplicationChangesModel>
    ): Promise<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/decision/changeApplicationsStatuses`,
                JSON.stringify({
                    FailOnError: true,
                    ListOfChanges: selectedApps,
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    getTwilioToken(body?: Record<string, any>): Promise<any> {
        return this.http
            .post<any>(
                `${this.appConfig['vmpAPI'].endPoint}/generateTwilioAccessToken`,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    getSupportedMLTemplates(): Observable<any> {
        return this.http
            .get<any>(
                `${this.appConfig['vmpAPI'].endPoint}/getSupportedTemplates`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    onSSO(authProvider: string, idToken: string): Promise<{ status: boolean }> {
        return this.http
            .post<any>(
                `${this.appConfig['vmpAPI'].endPoint}/${authProvider}SignIn`,
                { idToken },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    getUserPreference(): Observable<any> {
        const headerOptions = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                Authorization: `Bearer ${this.userToken}`,
                'if-modified-since': this.modifiedSinceUserPreference,
                'Last-Modified': this.modifiedSinceUserPreference,
            },
        };
        headerOptions['observe'] = 'response';
        return this.http
            .get<any>(
                `${this.appConfig['vmpAPI'].endPoint}/getUserPreference`,
                headerOptions
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    saveUserPreference(body: {
        preferences: PreferencesModel;
    }): Observable<any> {
        return this.http
            .put<any>(
                `${this.appConfig['vmpAPI'].endPoint}/saveUserPreference`,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${this.appConfig['vmpAPI'].apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getEnrollmentTypes(): Observable<any> {
        return this.http
            .get<any>(`${this.baseUrl}taapi/api/enums/enrollmentTypes`, {
                headers: { Authorization: `Bearer ${this.userToken}` },
            })
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getElasticSearchCount(node: string): Observable<any> {
        return this.http
            .get<any>(`${this.baseUrl}tasearch/api/${node}/count`, {
                headers: {
                    Authorization: `Bearer ${this.userToken}`,
                    'Content-Type': 'application/json',
                },
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getAllDetails(ipOrFqdn: string): Observable<any> {
        return this.http
            .get<any>(`https://${ipOrFqdn}/api/details`, {
                headers: {
                    Authorization: `Bearer ${this.userToken}`,
                    'x-api-key': 'ab3ea955-7f6f-4781-a09b-2625e860ee53',
                    'Content-Type': 'application/json',
                },
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }
    getOfflineAppDetails(taRef: string): Observable<any> {
        return this.http
            .get<any>(
                `https://${this.utilityService.ipOrFqdn}/api/detail/${taRef}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'x-api-key': 'ab3ea955-7f6f-4781-a09b-2625e860ee53',
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    markAsIncomplete(taRef: string = ''): Promise<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/decision/markAsIncomplete/${taRef}`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            )
            .toPromise();
    }

    acceptIncompleteApplication(
        enrollmentReference: string = '',
        paymentIntent: string,
        tier: string = '',
        note: string = `Move to incomplete by ${this.email}`
    ): Observable<any> {
        const body = {
            enrollmentReference,
            note,
            paymentIntent,
            tier,
        };
        if (!tier) {
            delete body.tier;
        }
        return this.http
            .post<any>(
                `${this.baseUrl}taapi/api/decision/forceAcceptIncompleteApplication`,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getSearchData(url: string): Observable<any> {
        return this.http
            .get<any>(`${this.baseUrl}${url}`, {
                headers: { Authorization: `Bearer ${this.userToken}` },
            })
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    addUpdateCarrierLogo(body: {
        fileBytes: string;
        fileName: string;
        mimeType: string;
    }): Observable<any> {
        return this.http
            .post<any>(`${this.baseUrl}taapi/api/logos/carrierLogo`, body, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.userToken}`,
                },
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    deleteAirlineLogo(logoFileName: string): Observable<any> {
        return this.http
            .delete<any>(
                `${this.baseUrl}taapi/api/logos/carrierLogos/delete/${logoFileName}.png`,
                {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    changeLog(taReference: string): Observable<any> {
        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/search/history/${taReference}`,
                {
                    headers: { Authorization: `Bearer ${this.userToken}` },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    permanentlyDeleteApplicant(
        taReference: string = '',
        magicToken: string = ''
    ): Observable<any> {
        return this.http
            .delete<any>(
                `${this.baseUrl}taapi/api/deleteData/permanentlyDeleteTADataAndFiles/${taReference}/${magicToken}`,
                {
                    headers: { Authorization: `Bearer ${this.userToken}` },
                }
            )
            .pipe(timeout(30000))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    cancelAutoApproval(taRef: string = ''): Observable<any> {
        return this.http
            .get<any>(
                `${this.baseUrl}taapi/api/decision/cancelAutoApprove/${taRef}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    generateElasticSearchPrompt(body: any): Observable<any> {
        return this.http
            .post<any>(
                'https://ai-dot-travizory-vmp-syc-dev.ew.r.appspot.com/ai/generateElasticSearchPrompt',
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }
}
