export class FlightCard {
    logo?: string;
    title?: string;
    subtitle?: string;
    totalCount?: number;
    pendingCount?: number;
    approvedCount?: number;
    rejectedCount?: number;
    questionCount?: number;
    examineCount?: number;
    quarantineCount?: number;
    pillIcon?: string;
    arrivalCarrier?: string;
    arrivalCarrierLogo?: string;
    arrivalFlightNumber?: string;
    submittedCount?: number;

    constructor(params: {
        logo?: string;
        title?: string;
        subtitle?: string;
        totalCount?: number;
        pendingCount?: number;
        approvedCount?: number;
        rejectedCount?: number;
        questionCount?: number;
        examineCount?: number;
        quarantineCount?: number;
        pillIcon?: string;
        arrivalCarrier?: string;
        arrivalCarrierLogo?: string;
        arrivalFlightNumber?: string;
        submittedCount?: number;
    }) {
        return {
            logo: params.logo ? params.logo : '',

            title: params.title ? params.title : '',

            subtitle: params.subtitle ? params.subtitle : '',

            totalCount:
                params.totalCount && params.totalCount >= 0
                    ? params.totalCount
                    : 0,

            pendingCount:
                params.pendingCount && params.pendingCount >= 0
                    ? params.pendingCount
                    : 0,

            approvedCount:
                params.approvedCount && params.approvedCount >= 0
                    ? params.approvedCount
                    : 0,

            rejectedCount:
                params.rejectedCount && params.rejectedCount >= 0
                    ? params.rejectedCount
                    : 0,

            questionCount:
                params.questionCount && params.questionCount >= 0
                    ? params.questionCount
                    : 0,

            examineCount:
                params.examineCount && params.examineCount >= 0
                    ? params.examineCount
                    : 0,

            quarantineCount:
                params.quarantineCount && params.quarantineCount >= 0
                    ? params.quarantineCount
                    : 0,

            pillIcon: params.pillIcon ? params.pillIcon : '',

            arrivalCarrier: params.arrivalCarrier ? params.arrivalCarrier : '',

            arrivalCarrierLogo: params.arrivalCarrierLogo
                ? params.arrivalCarrierLogo
                : '',

            arrivalFlightNumber: params.arrivalFlightNumber
                ? params.arrivalFlightNumber
                : '',
            submittedCount: params.submittedCount ? params.submittedCount : 0,
        };
    }
}
