import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: 'taui/page/login',
        loadChildren: () =>
            import('@app/pages/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'taui/page',
        loadChildren: () =>
            import('@app/pages/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            ),
    },
    { path: '', redirectTo: 'taui/page/login', pathMatch: 'full' },
    { path: '**', redirectTo: 'taui/page/login', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            relativeLinkResolution: 'corrected',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
