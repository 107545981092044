import { AppState, dataBusState } from '../state';

export const selectDataBus = (state: AppState): dataBusState => state.dataBus;

export const selectAppManager = (
    state: AppState
): typeof state.dataBus.appsManager => state.dataBus.appsManager;

export const selectPurchasedItemsConfig = (
    state: AppState
): typeof state.dataBus.purchasedItemsConfig =>
    state.dataBus.purchasedItemsConfig;

export const selectTierItemsConfig = (
    state: AppState
): typeof state.dataBus.tierItemsConfig => state.dataBus.tierItemsConfig;

export const selectAppDetails = (
    state: AppState
): typeof state.dataBus.appDetails => state.dataBus.appDetails;

export const selectCmsData = (state: AppState): typeof state.dataBus.cms_data =>
    state.dataBus.cms_data;

export const selectAppDetailsErrorMessage = (
    state: AppState
): typeof state.ui.loader.appDetails.errorMessage =>
    state.ui.loader.appDetails.errorMessage;

export const selectAppConfigVersion = (
    state: AppState
): typeof state.dataBus.appConfig.version => state.dataBus.appConfig.version;

export const selectAppDetailsPassportImageUrl = (
    state: AppState
): typeof state.dataBus.appDetails.details.passportImageUrl =>
    state.dataBus.appDetails.details.passportImageUrl;

export const enrollmentTypesSelector = (
    state: AppState
): typeof state.dataBus.enrollmentTypes => state.dataBus.enrollmentTypes;
export const loader = (
    state: AppState
): typeof state.ui.loader.watchlistMatches => state.ui.loader.watchlistMatches;
