export class documentsActionCardModel {
    tabs?: Tab[];
    footerButtons?: Button[];

    constructor(props: { tabs?: {}[]; footerButtons?: Button[] }) {
        return {
            tabs: props.tabs ? props.tabs : [],
            footerButtons: props.footerButtons ? props.footerButtons : [],
        };
    }
}

class Tab {
    title?: string;
    documentList?: {}[];
    hidden?: boolean;
    emptyStateStr?: string;

    constructor(props: {
        title?: string;
        documentList?: {}[];
        hidden?: boolean;
        emptyStateStr?: string;
    }) {
        return {
            title: props.title ? props.title : '',
            documentList: props.documentList ? props.documentList : [],
            hidden: props.hidden ? props.hidden : false,
            emptyStateStr: props.emptyStateStr ? props.emptyStateStr : '',
        };
    }
}

class Button {
    btnTxt?: string;
    btnStyles?: {};
    btnAction?: string;

    constructor(props: {
        btnTxt?: string;
        btnStyles?: {};
        btnAction?: string;
    }) {
        return {
            btnTxt: props.btnTxt ? props.btnTxt : '',
            btnStyles: props.btnStyles ? props.btnStyles : {},
            btnAction: props.btnAction ? props.btnAction : '',
        };
    }
}
