import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getNamedSearchData } from '@app/store/actions';
import { UtilityService } from './utility.service';
import {
    ElasticQuery,
    QueryType,
} from '@app/models/services/utilityService.model';
import { templateElasticSearches } from '@app/config/templateElasticSearch';
import { TimeConversionService } from './time-conversion.service';

@Injectable({
    providedIn: 'root',
})
export class FlightService {
    flightApplicationHeader;
    constructor(
        private store: Store<any>,
        private api: ApiService,
        private utilityService: UtilityService,
        private timeConversionService: TimeConversionService
    ) {}

    getRelevantDistinctFlight(data: any): void {
        let params;
        let searchName;
        if (data.path === 'hotelDashboard') {
            searchName = 'TA_COUNTS_PER_RESIDENCE';
            params = templateElasticSearches[searchName];
            params['date'] =
                data.params ||
                this.timeConversionService.formatDate(
                    undefined,
                    'YYYY-MM-DD',
                    false
                );
        } else {
            if (data.path === 'outbound') {
                searchName = 'TA_COUNTS_PER_DEPARTURE_FLIGHT';
            } else {
                searchName = 'TA_COUNTS_PER_ARRIVAL_FLIGHT';
            }
            params = templateElasticSearches[searchName];
            if (!this.utilityService.limitFlightsView) {
                if (data.activeFilterChips.UPCOMING_FLIGHTS) {
                    params['fromDate'] =
                        this.timeConversionService.getDateFromNow(-1, 'd');
                    params['toDate'] =
                        this.timeConversionService.getDateFromNow(1, 'y');
                } else if (data.activeFilterChips.RECENT_PAST_FLIGHTS) {
                    params['fromDate'] =
                        this.timeConversionService.getDateFromNow(-8, 'd');
                    params['toDate'] = this.timeConversionService.formatDate(
                        undefined,
                        'YYYY-MM-DD',
                        false
                    );
                } else {
                    params['fromDate'] = this.timeConversionService.formatDate(
                        undefined,
                        'YYYY-MM-DD',
                        false
                    );
                    params['toDate'] =
                        this.timeConversionService.getDateFromNow(1, 'y');
                }
            } else {
                params['fromDate'] = this.timeConversionService.getDateFromNow(
                    -1,
                    'd'
                );
                params['toDate'] = this.timeConversionService.getDateFromNow(
                    1,
                    'd'
                );
            }
        }
        this.generateNamedSearch(searchName, params, data.index);
    }

    generateNamedSearch(templateId: string, params: any, index?: number): void {
        if (!templateId) {
            return;
        }
        const details = templateElasticSearches[templateId];
        const body = {
            templateId,
            params: {
                fromDate: params.fromDate,
                toDate: params.toDate,
                enrollmentTypes: params.enrollmentTypes,
                _source: params._source,
                date: params.date,
                googlePlacesId: params.googlePlacesId,
            },
        };
        Object.entries(body.params).forEach(([key, value]) => {
            if (!value) {
                delete body.params[key];
            }
        });
        this.store.dispatch(
            getNamedSearchData({
                body,
                index,
                parentStoreNode: details.parentStoreNode,
                storeNode: details.storeNode,
                dataNode: details.dataNode,
            })
        );
    }
    formatUpdateFlightCounts(data: any[]): {} {
        const dictionary = {
            QUARANTINE: 'quarantineCount',
            PROCESSING: 'pendingCount',
            QUESTION: 'questionCount',
            INVALID: 'rejectedCount',
            EXAMINE: 'examineCount',
            VALID: 'approvedCount',
        };

        const obj = {};

        data.forEach((node) => {
            if (node.key !== '') {
                obj[dictionary[node.key]] = node.doc_count;
            }
        });

        return obj;
    }

    buildQuery(types: QueryType[], addScope?: any): ElasticQuery {
        const mustNotArray = [];
        const mustArray = [];
        const shouldArray = [];
        for (const type of types) {
            if (type['primaryType'] === 'must') {
                mustArray.push({
                    [type['secondaryType']]: {
                        [type['key']]: type['value'],
                    },
                });
            } else if (type['primaryType'] === 'should') {
                shouldArray.push({
                    [type['secondaryType']]: {
                        [type['key']]: type['value'],
                    },
                });
            } else {
                mustNotArray.push({
                    [type['secondaryType']]: {
                        [type['key']]: type['value'],
                    },
                });
            }
        } // Purpose of this is to build the query parameter for the API according to localization values for each section of the app :hg

        if (addScope && addScope.UPCOMING_FLIGHTS) {
            mustArray.push({
                range: {
                    'userSubmittedTravelApplicationRequest.trip_info.arrivalDate':
                        {
                            gte: 'now-2d',
                        },
                },
            });
        }

        if (addScope && addScope.RECENT_PAST_FLIGHTS) {
            mustArray.push({
                range: {
                    'userSubmittedTravelApplicationRequest.trip_info.arrivalDate':
                        {
                            lt: this.timeConversionService.formatDate(
                                undefined,
                                'YYYY-MM-DD',
                                false
                            ),
                            gte: this.timeConversionService.getDateFromNow(
                                -8,
                                'd'
                            ),
                        },
                },
            });
        }

        return {
            bool: {
                must: mustArray,
                must_not: mustNotArray,
                should: shouldArray,
                minimum_should_match: shouldArray.length > 0 ? 1 : 0,
            },
        };
    }

    getApplicationsAtHotel(data: { index: number; body: any }): void {
        const templateId = 'TAS_IN_RESIDENCE_BY_DATE';
        const params = templateElasticSearches[templateId];
        params['date'] = data.body.date;
        params['googlePlacesId'] = data.body.googlePlaceID;
        this.generateNamedSearch(templateId, params, data.index);
    }
}
