import { Injectable } from '@angular/core';

// If you import a module but never use any of the imported values other than as TypeScript types,
// the resulting javascript file will look as if you never imported the module at all.
import { ipcRenderer, webFrame, shell } from 'electron';
import * as childProcess from 'child_process';
import * as fs from 'fs';
import * as os from 'os';

@Injectable({
    providedIn: 'root',
})
export class ElectronService {
    ipcRenderer: typeof ipcRenderer;
    webFrame: typeof webFrame;
    shell: typeof shell;
    childProcess: typeof childProcess;
    fs: typeof fs;
    os: typeof os;

    constructor() {
        // Conditional imports
        if (this.isElectron) {
            this.ipcRenderer = window.require('electron').ipcRenderer;
            this.webFrame = window.require('electron').webFrame;
            this.shell = window.require('electron').shell;

            this.childProcess = window.require('child_process');
            this.fs = window.require('fs');
            this.os = window.require('os');
        }
    }

    get isElectron(): boolean {
        return !!(window && window.process && window.process.type);
    }

    openUrlInBrowser(url: string): void {
        this.shell.openExternal(url);
    }

    getComputerName = (): string => {
        if (!this.isElectron) {
            return '';
        }
        switch (process.platform) {
            case 'win32':
                return process.env.COMPUTERNAME;
            case 'darwin':
                return this.childProcess
                    .execSync('scutil --get ComputerName')
                    .toString()
                    .trim();
            default:
                return this.os.hostname();
        }
    };
}
