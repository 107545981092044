export enum SearchType {
    emails = 'emails',
    encounters = 'encounters',
    documentlogs = 'documentlogs',
    payments = 'payments',
    core = 'core',
}
export class ElasticSearchPagination {
    from?: number;
    size?: number;
    constructor(props?: ElasticSearchPagination) {
        return {
            from: props?.from || 0,
            size: props?.size || 1000,
        };
    }
}
export class ElasticSearchDate {
    gte?: string; //gte can accept the following formats: yyyy-MM-dd'T'HH:mm:ss.SSSZ OR yyyy-MM-dd OR now/d or now-1d/d
    lte?: string; //lte can accept the following formats: yyyy-MM-dd'T'HH:mm:ss.SSSZ OR yyyy-MM-dd OR now/d or now-1d/d
    constructor(props?: ElasticSearchDate) {
        return {
            gte: props?.gte || 'now-1d/d',
            lte: props?.lte || 'now/d',
        };
    }
}
export class ElasticSearchLocation {
    distance?: string; //defaults to 1m (1 metre)
    lon?: number;
    lat?: number;
    constructor(props: ElasticSearchLocation) {
        return {
            distance: props.distance || '1m',
            lon: props.lon || 0,
            lat: props.lat || 0,
        };
    }
}
export class ArrivalFlightElasticSearch {
    arrivalCarrier?: string;
    arrivalFlightNumber?: string;
    constructor(props: ArrivalFlightElasticSearch) {
        return {
            arrivalCarrier: props.arrivalCarrier || '',
            arrivalFlightNumber: props.arrivalFlightNumber || '',
        };
    }
}
export class DepartureFlightElasticSearch {
    departureCarrier?: string;
    departureFlightNumber?: string;
    constructor(props: DepartureFlightElasticSearch) {
        return {
            departureCarrier: props.departureCarrier || '',
            departureFlightNumber: props.departureFlightNumber || '',
        };
    }
}

export class TemplateElasticSearchParams {
    templateId: string;
    extraParams?: { [key: string]: any };
    constructor(props: TemplateElasticSearchParams) {
        return {
            templateId: props.templateId || '',
            extraParams: props.extraParams || {},
        };
    }
}

export class ElasticSearchBody {
    templateId: string;
    params: {
        _source: {
            includes: Array<string>;
            excludes: Array<string>;
        };
    };
}
export class ElasticSearchDataParams {
    searchType?: string;
    body: ElasticSearchBody | string;
    apiURL?: string;
    contentType?: string;
    constructor(props: ElasticSearchDataParams) {
        return {
            searchType: props.searchType || '',
            body: props.body || '',
            apiURL: props.apiURL || '',
            contentType: props.contentType || '',
        };
    }
}

export class MarkedAsOverstayed {
    must_filters?: {
        encounterType?: {
            types?: Array<string>;
        };
        markedAsOverstay?: {
            overstayed?: boolean;
        };
    };
    overstayed?: boolean;
    constructor(props: MarkedAsOverstayed) {
        return {
            must_filters: {
                encounterType: {
                    types: ['BORDER_EXIT'],
                },
                markedAsOverstay: {
                    overstayed: props.overstayed || false,
                },
            },
        };
    }
}
