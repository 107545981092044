import { OverrideConfigModalComponent } from '@app/components/modals/override-config-modal/override-config-modal.component';
import {
    OtherOverrideConfigModel,
    TripInfoSectionsModel,
} from './services/mappingService.model';

export class SupportingDocumentsModel {
    document_description: {};
    document_title: {};
    fileType: string;
    mandatory: boolean;
    multiple: boolean;
    hidden: boolean;
    visibleFor: supportingDocumentsOptions;
    mandatoryFor: supportingDocumentsOptions;

    constructor(props: {
        document_description?: {};
        document_title?: {};
        fileType?: string;
        mandatory?: boolean;
        multiple?: boolean;
        hidden?: boolean;
        visibleFor?: supportingDocumentsOptions;
        mandatoryFor?: supportingDocumentsOptions;
    }) {
        return {
            document_description: props.document_description || {},
            document_title: props.document_title || {},
            fileType: props.fileType || '',
            mandatory: props.mandatory || false,
            multiple: props.multiple || false,
            hidden: props.hidden || false,
            visibleFor: new supportingDocumentsOptions(props.visibleFor || {}),
            mandatoryFor: new supportingDocumentsOptions(
                props.mandatoryFor || {}
            ),
        };
    }
}

class supportingDocumentsOptions {
    purposeVisit?: Array<string>;
    documentIssuer?: Array<string>;
    departureCountry?: Array<string>;
    arrivalCountry?: Array<string>;
    residentCountry?: Array<string>;
    visitedCountry?: Array<string>;
    questions?: Array<{ answer: string; questionKey: string; type: string }>;
    age?: string;
    exclusions?: Array<{ [key: string]: Array<string> | string }>;
    matchType?: string;
    enrollmentType?: Array<string>;
    countryList?: Array<string>;
    documentIssuerCountryList?: Array<string>;
    departureCountryList?: Array<string>;
    arrivalCountryList?: Array<string>;
    residentCountryList?: Array<string>;
    visitedCountryList?: Array<string>;

    constructor(props?: supportingDocumentsOptions) {
        return {
            purposeVisit: props && props.purposeVisit ? props.purposeVisit : [],
            documentIssuer:
                props && props.documentIssuer ? props.documentIssuer : [],
            departureCountry:
                props && props.departureCountry ? props.departureCountry : [],
            arrivalCountry:
                props && props.arrivalCountry ? props.arrivalCountry : [],
            residentCountry:
                props && props.residentCountry ? props.residentCountry : [],
            visitedCountry:
                props && props.visitedCountry ? props.visitedCountry : [],
            questions: props && props.questions ? props.questions : [],
            age: props?.age || '',
            exclusions: props?.exclusions || [],
            matchType: props?.matchType || 'ALL',
            enrollmentType: props?.enrollmentType || [],
            countryList: props?.countryList || [],
            documentIssuerCountryList: props?.documentIssuerCountryList || [],
            departureCountryList: props?.departureCountryList || [],
            arrivalCountryList: props?.arrivalCountryList || [],
            residentCountryList: props?.residentCountryList || [],
            visitedCountryList: props?.visitedCountryList || [],
        };
    }
}
export class QuestionsModel {
    question: {};
    questionKey: string;
    questionCategory: string;
    questionType: string;
    hidden?: boolean;

    constructor(props: {
        question?: {};
        questionKey?: string;
        questionCategory?: string;
        questionType?: string;
        hidden?: boolean;
    }) {
        return {
            question: props.question || {},
            questionKey: props.questionKey || '',
            questionCategory: props.questionCategory || '',
            questionType: props.questionType || '',
            hidden: props.hidden || false,
        };
    }
}

export class PageModel {
    body: {};
    title: {};
    hidePagefromList?: boolean;
    pageKey?: string;
    hidden?: boolean;
    tags?: Array<string>;

    constructor(props?: PageModel) {
        return {
            body: props?.body || {},
            title: props?.title || {},
            hidePagefromList: props?.hidePagefromList || false,
            pageKey: props?.pageKey || '',
            hidden: props?.hidden || false,
            tags: props?.tags || [],
        };
    }
}

export class AirlinesModel {
    code: string;
    name: string;
    inboundFlightNumbers: Array<string>;
    inboundFlights: Array<FlightsModel>;
    outboundFlightNumbers: Array<string>;
    outboundFlights: Array<FlightsModel>;
    inboundAirports: Array<AirportModel>;
    outboundAirports: Array<AirportModel>;
    hidden: boolean;

    constructor(props: AirlinesModel) {
        const inboundFlightNumbers = [];
        const outboundFlightNumbers = [];

        if (
            props.inboundFlights &&
            Array.isArray(props.inboundFlights) &&
            props.inboundFlights.length > 0
        ) {
            props.inboundFlights.forEach((element) => {
                inboundFlightNumbers.push(element.flightNumber);
            });
        }
        if (
            props.outboundFlights &&
            Array.isArray(props.outboundFlights) &&
            props.outboundFlights.length > 0
        ) {
            props.outboundFlights.forEach((element) => {
                outboundFlightNumbers.push(element.flightNumber);
            });
        }
        return {
            code: props.code || '',
            name: props.name || '',
            inboundFlightNumbers: inboundFlightNumbers || [],
            inboundFlights: props.inboundFlights || [],
            outboundFlightNumbers: outboundFlightNumbers || [],
            outboundFlights: props.outboundFlights || [],
            inboundAirports: props.inboundAirports || [],
            outboundAirports: props.outboundAirports || [],
            hidden: props.hidden || false,
        };
    }
}

class FlightsModel {
    flightNumber: string;
    scheduledArrivalTime: string;
    scheduledDepartureTime: string;
}

export class DatabaseContentModel {
    content_private: {
        country_watch_list: string[];
        country_whitelist: string[];
        country_low_risk: string[];
        country_medium_risk: string[];
        country_yellow_fever: string[];
        country_pox: string[];
        country_ebola: string[];
        country_polio: string[];
        country_customs: string[];
        country_visaexemption: string[];
        country_visaonarrival: string[];
        establishments: {}[];
        establishmentsTags: [];
    };
    content_public: {
        supportingDocuments: SupportingDocumentsModel[];
        questions: QuestionsModel[];
        languages: LanguagesModel;
        pages: PageModel[];
        airlines: AirlinesModel[];
        questionsConfig: QuestionsConfigModel;
        appConfig: AppConfigModel;
        alertBanner: AlertBanner[];
        favoriteCountries: Array<string>;
        airports: Array<AirportModel>;
        cruises: Array<CruiseModel>;
        purposeVisit: PurposeOfVisit;
        currencies: Array<string>;
        groupConfig?: Array<GroupSubmissionsConfig>;
        popularAirlines: Array<string>;
        popularCurrencies: Array<string>;
        popularCruises: Array<string>;
        allowedEnrollmentTypes: Array<string>;
        nationalityRequirement: EligibilityWidget;
        contactInfoConfig: Array<ContactInfoConfiguration>;
        visaConfig?: Array<VisaConfiguration>;
        defaultEnrollmentType: string;
        tripInfoSections: Array<TripInfoSectionsModel>;
        applicationStatusCheck: ApplicationStatusCheckModel;
        otherPreferences: Array<OtherPreferencesModel>;
        otherOverrideConfig: OtherOverrideConfigModel;
    };

    constructor(props: {
        content_private?: {
            country_watch_list?: string[];
            country_whitelist?: string[];
            country_low_risk?: string[];
            country_medium_risk?: string[];
            country_yellow_fever?: string[];
            country_pox?: string[];
            country_ebola?: string[];
            country_polio?: string[];
            country_customs?: string[];
            country_visaexemption: string[];
            country_visaonarrival: string[];
            establishments?: {}[];
            establishmentsTags?: [];
        };
        content_public?: {
            supportingDocuments?: SupportingDocumentsModel[];
            questions?: QuestionsModel[];
            languages?: LanguagesModel;
            pages?: PageModel[];
            airlines?: AirlinesModel[];
            questionsConfig?: QuestionsConfigModel;
            appConfig?: AppConfigModel;
            alertBanner?: AlertBanner[];
            favoriteCountries?: Array<string>;
            airports: Array<AirportModel>;
            cruises?: Array<CruiseModel>;
            purposeVisit?: PurposeOfVisit;
            currencies?: Array<string>;
            groupConfig?: Array<GroupSubmissionsConfig>;
            popularAirlines: Array<string>;
            popularCurrencies: Array<string>;
            popularCruises: Array<string>;
            allowedEnrollmentTypes: Array<string>;
            nationalityRequirement: EligibilityWidget;
            contactInfoConfig: Array<ContactInfoConfiguration>;
            visaConfig?: Array<VisaConfiguration>;
            defaultEnrollmentType: string;
            tripInfoSections: Array<TripInfoSectionsModel>;
            applicationStatusCheck?: ApplicationStatusCheckModel;
            otherPreferences: Array<OtherPreferencesModel>;
            otherOverrideConfig: OtherOverrideConfigModel;
        };
    }) {
        return {
            content_private: {
                country_watch_list:
                    props.content_private?.country_watch_list || [],
                country_whitelist:
                    props.content_private?.country_whitelist || [],
                country_low_risk: props.content_private?.country_low_risk || [],
                country_medium_risk:
                    props.content_private?.country_medium_risk || [],
                country_yellow_fever:
                    props.content_private?.country_yellow_fever || [],
                country_pox: props.content_private?.country_pox || [],
                country_ebola: props.content_private?.country_ebola || [],
                country_polio: props.content_private?.country_polio || [],
                country_customs: props.content_private?.country_customs || [],
                country_visaexemption:
                    props.content_private?.country_visaexemption || [],
                country_visaonarrival:
                    props.content_private?.country_visaonarrival || [],
                establishments: props.content_private?.establishments || [],
                establishmentsTags:
                    props.content_private?.establishmentsTags || [],
            },
            content_public: {
                supportingDocuments:
                    props.content_public?.supportingDocuments || [],
                questions: props.content_public?.questions || [],
                languages: props.content_public?.languages || {},
                pages: props.content_public?.pages || [],
                airlines: props.content_public?.airlines || [],
                questionsConfig: props.content_public?.questionsConfig || {},
                appConfig: props.content_public?.appConfig || {
                    applicationDetails: { showHistory: false },
                },
                alertBanner: props.content_public?.alertBanner || [],
                favoriteCountries:
                    props.content_public?.favoriteCountries || [],
                airports: props.content_public?.airports || [],
                cruises: props.content_public?.cruises || [],
                purposeVisit:
                    props.content_public?.purposeVisit || new PurposeOfVisit(),
                currencies: props.content_public?.currencies || [],
                groupConfig: props.content_public?.groupConfig || [],
                popularAirlines: props.content_public?.popularAirlines || [],
                popularCurrencies:
                    props.content_public?.popularCurrencies || [],
                popularCruises: props.content_public?.popularCruises || [],
                allowedEnrollmentTypes:
                    props.content_public?.allowedEnrollmentTypes || [],
                nationalityRequirement:
                    props.content_public?.nationalityRequirement ||
                    new EligibilityWidget(),
                contactInfoConfig:
                    props.content_public?.contactInfoConfig || [],
                visaConfig: props.content_public?.visaConfig || [],
                defaultEnrollmentType:
                    props.content_public?.defaultEnrollmentType || '',
                tripInfoSections: props.content_public?.tripInfoSections || [],
                applicationStatusCheck:
                    props.content_public?.applicationStatusCheck ||
                    new ApplicationStatusCheckModel(),
                otherOverrideConfig:
                    props.content_public?.otherOverrideConfig || {},
                otherPreferences: props.content_public?.otherPreferences || [],
            },
        };
    }
}

export class GetContentModel {
    body: {
        data: DatabaseContentModel;
        status: boolean;
        version: string;
    };
    headers: {
        get(name: string): string | null;
    };
}

export class MappedLanguages {
    isActive: boolean;
    languageCode: string;
    name: string;
    nativeName: string;

    constructor(props: {
        isActive?: boolean;
        languageCode?: string;
        name?: string;
        nativeName?: string;
    }) {
        return {
            isActive: props.isActive || false,
            languageCode: props.languageCode || '',
            name: props.name || '',
            nativeName: props.nativeName || '',
        };
    }
}

export class LanguagesModel {
    [name: string]: MappedLanguages;
}

export class QuestionsConfigModel {
    showByDefault?: ShowByDefaultModel;
    overrideConfig?: OverrideConfigItemModel;
    questionsOrderFlow?: Array<string>;

    constructor(props?: QuestionsConfigModel) {
        return {
            showByDefault: new ShowByDefaultModel(props?.showByDefault),
            overrideConfig: new OverrideConfigItemModel(props?.overrideConfig),
            questionsOrderFlow: props?.questionsOrderFlow || [
                'enrollmentQuestions',
                'healthCardQuestions',
                'customsCardQuestions',
                'insuranceQuestions',
                'outboundCustomsCardQuestions',
            ],
        };
    }
}

export class QuestionsConfigs {
    healthCardQuestions?: boolean;
    customsCardQuestions?: boolean;
    insuranceQuestions?: boolean;
    outboundCustomsCardQuestions?: boolean;
    enrollmentQuestions?: boolean;
    constructor(props: QuestionsConfigs) {
        return {
            healthCardQuestions: props.healthCardQuestions || false,
            customsCardQuestions: props.customsCardQuestions || false,
            insuranceQuestions: props.insuranceQuestions || false,
            outboundCustomsCardQuestions:
                props.outboundCustomsCardQuestions || false,
            enrollmentQuestions: props.enrollmentQuestions || false,
        };
    }
}

export class ShowByDefaultModel {
    healthCardQuestions?: boolean;
    customsCardQuestions?: boolean;
    insuranceQuestions?: boolean;
    outboundCustomsCardQuestions?: boolean;
    enrollmentQuestions?: boolean;

    constructor(props?: ShowByDefaultModel) {
        return {
            healthCardQuestions:
                !!props && typeof props.healthCardQuestions === 'boolean'
                    ? props.healthCardQuestions
                    : true,
            customsCardQuestions:
                !!props && typeof props.customsCardQuestions === 'boolean'
                    ? props.customsCardQuestions
                    : true,
            insuranceQuestions:
                !!props && typeof props.insuranceQuestions === 'boolean'
                    ? props.insuranceQuestions
                    : true,
            outboundCustomsCardQuestions:
                !!props &&
                typeof props.outboundCustomsCardQuestions === 'boolean'
                    ? props.outboundCustomsCardQuestions
                    : true,
            enrollmentQuestions:
                !!props && typeof props.enrollmentQuestions === 'boolean'
                    ? props.enrollmentQuestions
                    : true,
        };
    }
}

export class OverrideConfigItemModel {
    enrollmentType?: { [key: string]: ShowByDefaultModel };
    purposeOfVisit?: { [key: string]: ShowByDefaultModel };
    documentIssuer?: { [key: string]: ShowByDefaultModel };

    constructor(props: OverrideConfigItemModel) {
        return {
            enrollmentType: props?.enrollmentType || {},
            purposeOfVisit: props?.purposeOfVisit || {},
            documentIssuer: props?.documentIssuer || {},
        };
    }
}

export class AppConfigModel {
    applicationDetails: {
        showHistory: boolean;
    };
    constructor(props?: AppConfigModel) {
        return {
            applicationDetails: {
                showHistory: props?.applicationDetails?.showHistory || false,
            },
        };
    }
}

export class AlertBanner {
    title?: { [key: string]: string };
    body?: { [key: string]: string };
    tags?: Array<string>;
    pageKey?: string;
    icon?: string;
    hidden?: boolean;
    backgroundColor?: string;
    textColor?: string;

    constructor(props?: AlertBanner) {
        return {
            pageKey: props?.pageKey || '',
            title: props?.title || {},
            body: props?.body || {},
            tags: props?.tags || [],
            icon: props?.icon || '',
            hidden: props?.hidden || false,
            backgroundColor: props?.backgroundColor || '#000000',
            textColor: props?.textColor || '#ffffff',
        };
    }
}

export class AirportModel {
    code: string;
    lat: string;
    lon: string;
    name: string;
    city: string;
    country: string;
    tz: string;
    icao: string;

    constructor(props?: AirportModel) {
        return {
            code: props?.code || '',
            lat: props?.lat || '',
            lon: props?.lon || '',
            name: props?.name || '',
            city: props?.city || '',
            country: props?.country || '',
            tz: props?.tz || '',
            icao: props?.icao || '',
        };
    }
}

export class SupportedTemplateModel {
    templateName?: string;
    countryName?: string;
    labName?: string;
    type?: string;

    constructor(props?: SupportedTemplateModel) {
        return {
            templateName: props?.templateName || '',
            countryName: props?.countryName || '',
            labName: props?.labName || '',
            type: props?.type || '',
        };
    }
}

export class CruiseModel {
    cruiseCode?: string;
    vesselName?: string;
    cruiseOperator?: string;
    hidden?: boolean;

    constructor(props?: CruiseModel) {
        return {
            cruiseCode: props?.cruiseCode || '',
            vesselName: props?.vesselName || '',
            cruiseOperator: props?.cruiseOperator || '',
            hidden: props?.hidden || false,
        };
    }
}

export class PurposeOfVisit {
    [key: string]: Array<purposeOfVisitConfig>;
    constructor(props?: PurposeOfVisit) {
        return props || {};
    }
}

export class purposeOfVisitConfig {
    groupLabel?: string;
    purposeVisit: Array<string>;

    constructor(props: purposeOfVisitConfig) {
        return {
            groupLabel: props?.groupLabel || '',
            purposeVisit: props?.purposeVisit || [],
        };
    }
}

export class GroupSubmissionsConfig {
    enrollmentType: string;
    enabled?: boolean;
    maxGroupSize?: number;
    individualDocumentTypes?: Array<string>;
    individualQuestions?: Array<string>;
    constructor(props?: GroupSubmissionsConfig) {
        return {
            enrollmentType: props?.enrollmentType || '',
            enabled: !!props?.enabled,
            maxGroupSize: props?.maxGroupSize || 0,
            individualDocumentTypes: props?.individualDocumentTypes || [],
            individualQuestions: props?.individualQuestions || [],
        };
    }
}
export class ContactInfoConfiguration {
    enrollmentType: string;
    showContactInfoSection?: boolean;
    requireResidenceCountry?: boolean;
    linkCountryAddresstoInstance?: boolean;
    emergencyContactInfo?: boolean;
    homeAddress?: boolean;
    requireOccupation?: boolean;
    occupationList?: Array<string>;

    constructor(props?: ContactInfoConfiguration) {
        return {
            enrollmentType: props?.enrollmentType || '',
            showContactInfoSection: !!props?.showContactInfoSection,
            requireResidenceCountry: !!props?.requireResidenceCountry,
            linkCountryAddresstoInstance: !!props?.linkCountryAddresstoInstance,
            emergencyContactInfo: !!props?.emergencyContactInfo,
            homeAddress: !!props?.homeAddress,
            requireOccupation: !!props?.requireOccupation,
            occupationList: props?.occupationList || [],
        };
    }
}
export class VisaConfiguration {
    enrollmentType: string;
    visaName?: string;
    visaDescription?: string;
    BarcodeType?: string;
    BarcodeEncoding?: string;
    NumberOfEntries?: string;
    MaxStayDurationYears?: number;
    MaxStayDurationMonths?: number;
    MaxStayDurationDays?: number;
    ExpiryYears?: number;
    ExpiryMonths?: number;
    ExpiryDays?: number;
    constructor(props?: VisaConfiguration) {
        return {
            enrollmentType: props?.enrollmentType || '',
            visaName: props?.visaName || '',
            visaDescription: props?.visaDescription || '',
            BarcodeType: props?.BarcodeType || '',
            BarcodeEncoding: props?.BarcodeEncoding || '',
            NumberOfEntries: props?.NumberOfEntries || '',
            MaxStayDurationYears: props?.MaxStayDurationYears || 0,
            MaxStayDurationMonths: props?.MaxStayDurationMonths || 0,
            MaxStayDurationDays: props?.MaxStayDurationDays || 0,
            ExpiryYears: props?.ExpiryYears || 0,
            ExpiryMonths: props?.ExpiryMonths || 0,
            ExpiryDays: props?.ExpiryDays || 0,
        };
    }
}

export class EligibilityWidget {
    hidden: boolean;
    config: Array<{ purposeVisit: Array<string>; enrollmentType: string }>;
    rules: {
        [key: string]: { requirement: string; restrictions?: Array<string> };
    };
    visaExempt?: Array<string>;

    constructor(props?: EligibilityWidget) {
        return {
            hidden: props?.hidden || false,
            config: props?.config || [],
            rules: props?.rules || {},
            visaExempt: props?.visaExempt || [],
        };
    }
}

export class ApplicationStatusCheckModel {
    checkApplication: boolean;
    retrieveApplication: boolean;
    constructor(props?: ApplicationStatusCheckModel) {
        return {
            checkApplication: props?.checkApplication.toString()
                ? props.checkApplication
                : true,
            retrieveApplication: props?.retrieveApplication.toString()
                ? props.retrieveApplication
                : true,
        };
    }
}

export class OtherPreferencesModel {
    enrollmentType: string;
    marketingOptInConsent: boolean;
    declarationConsent: boolean;
    userCommentEnabled: boolean;

    constructor(props: Partial<OtherPreferencesModel>) {
        return {
            enrollmentType: props.enrollmentType || 'TA',
            marketingOptInConsent: !!props.marketingOptInConsent,
            declarationConsent: !!props.declarationConsent,
            userCommentEnabled: !!props.userCommentEnabled,
        };
    }
}
