import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    menuItems;
    pageName = '';
    url = '';
    constructor(public analytics: AngularFireAnalytics) {}

    onLangChange(language: string): void {
        this.analytics.logEvent('language_change', {
            event_category: 'User_Preferences',
            selected_language: language,
        });
    }
    onThemeChange(theme: string): void {
        this.analytics.logEvent('theme_change', {
            event_category: 'User_Preferences',
            selected_theme: theme,
        });
    }
    onGoogleLogin(email: string): void {
        this.analytics.logEvent('google_login', {
            event_category: 'User-Preferences',
            email_used: email,
        });
    }
    onEmailLogin(email: string): void {
        this.analytics.logEvent('email_login', {
            event_category: 'User-Preferences',
            email_used: email,
        });
    }
    onLoginFailure(email: string): void {
        this.analytics.logEvent('login_failure', {
            event_category: 'User_Preferences',
            email_used: email,
        });
    }
    onSignOut(): void {
        this.analytics.logEvent('sign_out', {
            event_category: 'User_Preferences',
        });
    }
    onApplicationSelect(ref: string): void {
        this.analytics.logEvent('application_select', {
            event_category: 'Application',
            selected_ref: ref,
        });
    }
    onFlightSelect(ref: string): void {
        this.analytics.logEvent('flight_select', {
            event_category: 'Application',
            selected_flight: ref,
        });
    }
    onDeleteRow(path: string): void {
        this.analytics.logEvent('delete_row', {
            event_category: 'CMS',
            node_path: path,
        });
    }
    onAddData(path: string): void {
        this.analytics.logEvent('add_data', {
            event_category: 'CMS',
            node_path: path,
        });
    }
    onSaveClick(path: string): void {
        this.analytics.logEvent('save_click', {
            event_category: 'CMS',
            node_path: path,
        });
    }
    onPerformDecision(ref: string): void {
        this.analytics.logEvent('perform_decision', {
            event_category: 'Application',
            reference: ref,
        });
    }
}
