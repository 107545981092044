import { AngularFireAuth } from '@angular/fire/compat/auth';
import { EventHubService } from './event-hub.service';
import { AppConfig } from 'environments/environment';
import { Injectable, Injector } from '@angular/core';
import { UtilityService } from './utility.service';
import { user } from '@app/store/selectors';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
    getUserInformationFail,
    setUserInformation,
    clearLoginState,
    clearAppDetails,
    clearStreamData,
    getPreferences,
} from '@app/store/actions';
import {
    SocialAuthService,
    GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    accessToken: string;
    alertMessage: string;
    isLoading: boolean;
    showAlert: boolean;
    authProviders = { google: GoogleLoginProvider.PROVIDER_ID };

    isAccessTokenRenewing$ = new Subject<void>();
    isAccessTokenRenewing = false;
    currentUser$ = new Subject<void>();
    currentUser = false;
    isPreferencesInitiated = false;
    signInProvider = '';

    constructor(
        private socialAuthService: SocialAuthService,
        private utilityService: UtilityService,
        public firebaseAuth: AngularFireAuth,
        private eventHub: EventHubService,
        private injector: Injector,
        private store: Store<any>,
        private router: Router,
        private googleAnalyticService: GoogleAnalyticsService
    ) {
        firebaseAuth.onAuthStateChanged((currentUser) => {
            this.currentUser = !!currentUser;
            if (currentUser) {
                this.currentUser$.next();
            }
        });

        this.store
            .select(user)
            .subscribe((userItem) => (this.accessToken = userItem.accessToken));
    }

    handleError(error?: any): void {
        this.store.dispatch(
            getUserInformationFail({
                payload: error?.code,
            })
        );
        this.onSignOut();
    }

    private setUserInformation(idTokenResult: any): void {
        this.store.dispatch(
            setUserInformation({
                payload: {
                    userToken: idTokenResult.token,
                    username: idTokenResult.claims.email,
                    role: idTokenResult.claims.role,
                    permitCountry: idTokenResult.claims.permitCountry,
                    establishmentPlaceID:
                        idTokenResult.claims.establishmentPlaceID || '',
                    establishmentName:
                        idTokenResult.claims.establishmentName || '',
                    userTags: idTokenResult.claims.userTags || [],
                    imageUrl: idTokenResult.claims.picture || '',
                    provider: idTokenResult.signInProvider,
                    hasPinCode: idTokenResult.claims.hasPinCode,
                },
            })
        );
        if (!this.isPreferencesInitiated) {
            this.store.dispatch(getPreferences());
            this.isPreferencesInitiated = true;
        }
    }

    handleUserCredential(signInPromise: () => Promise<any>): Promise<void> {
        return signInPromise()
            .then((credential) => {
                credential.user
                    .getIdTokenResult()
                    .then((idTokenResult: any) => {
                        this.signInProvider = idTokenResult.signInProvider;
                        if (
                            (
                                idTokenResult.claims.userTags as Array<string>
                            )?.includes('BORDER_SUPERVISOR') &&
                            !idTokenResult.claims.hasPinCode &&
                            idTokenResult.claims.role !== 'super_admin'
                        ) {
                            this.eventHub.openPinModal.emit();
                        }
                        this.firebaseAuth.currentUser.then((firebaseUser) => {
                            const isPassword = firebaseUser.providerData.find(
                                (elm) => elm.providerId === 'password'
                            );
                            if (
                                isPassword !== undefined &&
                                !(
                                    idTokenResult.claims.providerIDs as string[]
                                )?.includes('password')
                            ) {
                                if (
                                    idTokenResult.signInProvider ===
                                    'google.com'
                                ) {
                                    firebaseUser.unlink('password');
                                }
                            }
                        });
                        if (
                            !AppConfig.userWithVMPAccess.includes(
                                idTokenResult.claims.role
                            )
                        ) {
                            this.handleError({
                                code: 'auth/role-permission-denied',
                            });
                        } else if (
                            (idTokenResult.claims.providerIDs as string[])
                                ?.length &&
                            !(
                                idTokenResult.claims.providerIDs as string[]
                            ).includes(idTokenResult.signInProvider)
                        ) {
                            if (idTokenResult.signInProvider === 'password') {
                                this.firebaseAuth.currentUser.then(
                                    (firebaseUser) => {
                                        firebaseUser.unlink('password');
                                    }
                                );
                            }
                            this.handleError({
                                code: 'wrong-password',
                            });
                        } else {
                            this.setUserInformation(idTokenResult);
                        }
                    })
                    .catch((error) => this.handleError(error));
            })
            .catch((error) => this.handleError(error));
    }

    onSignIn(email: string, password: string): Promise<void> {
        this.googleAnalyticService.onEmailLogin(email);
        return this.handleUserCredential(() =>
            this.firebaseAuth.signInWithEmailAndPassword(email, password)
        );
    }

    onSignOut(): void {
        if (this.signInProvider === 'password') {
            this.firebaseAuth.signOut().catch(() => {});
        } else {
            this.socialAuthService.signOut().catch(() => {});
        }
        this.store.dispatch(clearLoginState());
        this.store.dispatch(clearAppDetails());
        this.store.dispatch(clearStreamData());
        document.body.className = '';
        this.utilityService.didAppGetData = false;
        this.router.navigate(['taui/page/login']);
        this.isPreferencesInitiated = false;
        this.googleAnalyticService.onSignOut();
    }

    //Checks User token and manages it
    checkToken(): void {
        this.isAccessTokenRenewing = true;
        this.isAccessTokenRenewing$.next();
        this.firebaseAuth.currentUser.then((currentUser) => {
            if (currentUser) {
                currentUser.getIdTokenResult().then((idTokenResult) => {
                    if (
                        idTokenResult.token === this.accessToken ||
                        AppConfig.sessionTokenExtension
                    ) {
                        this.setUserInformation(idTokenResult);
                    }
                    setTimeout(() => {
                        this.isAccessTokenRenewing = false;
                        this.isAccessTokenRenewing$.next();
                    }, 0);
                });
            }
        });
    }

    signInWithCredential(credential: any): Promise<void> {
        return this.handleUserCredential(() =>
            this.firebaseAuth.signInWithCredential(credential)
        );
    }
}
