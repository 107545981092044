import { GalleryAttachmentModel } from './galleryAttachment';
import { EmailLogModel } from './services/mappingService.model';
import {
    AnswersModel,
    PaymentDetails,
    PaymentInfo,
} from './services/utilityService.model';
import { WatchlistMatches } from './services/mappingService.model';
import { UtilityService } from '@app/services/utility.service';

export class ApplicationDetails {
    ref: string;
    groupReference: string;
    enrollmentReference: string;
    TAReferences: Array<string>;
    status: string;
    isUpdated: boolean;
    flag: string;
    submissionInformation: { [key: string]: any }[];
    DecisionTs: string | number;
    UpdatedTs: string | number;
    SubmittedTs: string | number;
    flagCode: string;
    precheckInfo: {
        notes: { [key: string]: any }[];
        statusInfo: { [key: string]: any }[];
    };
    attachments: { [key: string]: any }[];
    paymentInfo: Array<PaymentInfo>;
    healthAttachments: any;
    visitedCountries: { [key: string]: any }[];
    flaggedAnswers: string[];
    answers: {
        healthAnswers: Array<string>;
    };
    decisionInfo: { [key: string]: any }[];
    taValidation: {
        tripInfo: { [key: string]: any };
        IDDocSummary: { [key: string]: any };
        attachment: { [key: string]: any };
    };
    matchRecords: { [key: string]: any }[];
    addressInCountry: { [key: string]: any }[];
    emergencyContactInfo: { [key: string]: any }[];
    details: {
        city: string;
        region: string;
        departureSeatNumber: string;
        arrivalSeatNumber: string;
        originCountry: string;
        personUrl: { [key: string]: any };
        passportImageUrl: string;
        surname: string;
        forenames: string;
        sex: string;
        dob: string;
        age: number;
        ageCategory: string;
        nationality: string;
        passportNumber: string;
        expiry: string;
        type: string;
        purposeVisit: string;
        arrivalDate: string;
        arrivalCarrier: string;
        arrivalFlightNumber: string;
        arrivalFrom: string;
        arrivalAt: string;
        arrivalLogo?: string;
        departureDate: string;
        departureCarrier: string;
        departureFlightNumber: string;
        departureFrom: string;
        departureTo: string;
        departureLogo?: string;
        addressInCountry: { [key: string]: any };
        applicationDeclaration: {
            selfDeclared: boolean;
            agentName: string;
            agentPhone: string;
            agentEmail: string;
        };
        barCode: string;
        phone: string;
        email: string;
        address: string;
        issuer: string;
        secondAddressLine: string;
        country: string;
        addressGooglePin: { [key: string]: any };
        profession: string;
        nationalIDNumber: string;
        nextDestination: string;
        intRef: number;
        magicToken: string;
        enrollmentType: string;
        isVaccinated?: boolean;
        pcrTestResult?: string;
        tier?: string;
        marketingOptInConsent?: boolean;
    };
    encounters: { [key: string]: any }[];
    groupApplications: { [key: string]: any }[];
    language?: string;
    galleryAttachments: GalleryAttachmentModel[];
    index?: number;
    purchasedItems?: Array<PaymentDetails | string>;
    tripInfo?: { [key: string]: any };
    applicantInfo?: { [key: string]: any };
    contactInfo?: { [key: string]: any };
    emailLogs?: Array<EmailLogModel>;
    hasFlaggedEmails?: boolean;
    placeOfBirth: string;
    watchlistMatches: WatchlistMatches;
    autoApproveAt: string | number;
    smartApprovedAt: string | number;
    smartApprovedByUsername: string;
    marital_status?: string;
    modifiedTs?: string | number;
    health_info?: { answers?: AnswersModel[] };
    customs_info?: { answers?: AnswersModel[] };
    insurance_info?: { answers?: AnswersModel[] };
    enrollment_info?: { answers?: AnswersModel[] };

    constructor() {
        return {
            encounters: [],
            groupApplications: [],
            ref: '',
            groupReference: '',
            enrollmentReference: '',
            TAReferences: [],
            status: '',
            flag: '',
            submissionInformation: [],
            SubmittedTs: null,
            DecisionTs: null,
            UpdatedTs: null,
            isUpdated: false,
            emergencyContactInfo: [],
            flagCode: '',
            precheckInfo: {
                notes: [],
                statusInfo: [],
            },
            attachments: [],
            paymentInfo: [],
            healthAttachments: {},
            visitedCountries: [],
            flaggedAnswers: [],
            answers: {
                healthAnswers: [],
            },
            decisionInfo: [],
            taValidation: {
                tripInfo: {},
                IDDocSummary: {},
                attachment: {},
            },
            matchRecords: [],
            addressInCountry: [],
            details: {
                city: '',
                region: '',
                departureSeatNumber: '',
                arrivalSeatNumber: '',
                originCountry: '',
                personUrl: {},
                passportImageUrl: '',
                surname: '',
                forenames: '',
                sex: '',
                dob: '',
                age: null,
                ageCategory: '',
                nationality: '',
                passportNumber: '',
                expiry: '',
                type: '',
                purposeVisit: '',
                arrivalDate: '',
                arrivalCarrier: '',
                arrivalFlightNumber: '',
                arrivalFrom: '',
                arrivalAt: '',
                arrivalLogo: '',
                departureDate: '',
                departureCarrier: '',
                departureFlightNumber: '',
                departureFrom: '',
                departureTo: '',
                departureLogo: '',
                addressInCountry: {},
                applicationDeclaration: {
                    selfDeclared: false,
                    agentName: '',
                    agentPhone: '',
                    agentEmail: '',
                },
                barCode: '',
                phone: '',
                email: '',
                address: '',
                issuer: '',
                secondAddressLine: '',
                country: '',
                addressGooglePin: {},
                profession: '',
                nationalIDNumber: '',
                nextDestination: '',
                intRef: null,
                magicToken: '',
                enrollmentType: '',
                isVaccinated: false,
                pcrTestResult: '',
                tier: '',
                marketingOptInConsent: false,
            },
            language: '',
            galleryAttachments: [],
            index: 0,
            purchasedItems: [],
            tripInfo: {},
            applicantInfo: {},
            contactInfo: {},
            emailLogs: [],
            hasFlaggedEmails: false,
            placeOfBirth: '',
            watchlistMatches: { timestamp: null, visas: [], watchlists: [] },
            autoApproveAt: null,
            smartApprovedAt: null,
            smartApprovedByUsername: '',
            marital_status: '',
            modifiedTs: '',
            health_info: { answers: [] },
            customs_info: { answers: [] },
            insurance_info: { answers: [] },
            enrollment_info: { answers: [] },
        };
    }
}

export class ChangesLogModel {
    Timestamp?: string | number;
    Who?: string;
    What?: string;
    Old?: {
        trip_info?: { [key: string]: any };
        IDDocSummary?: { [key: string]: any };
        applicant_contact_info?: { [key: string]: any };
        emergencycontact_info?: Array<{ [key: string]: any }>;
        Status?: { [key: string]: any };
        Note?: string;
        health_info?: { [key: string]: any };
        customs_info?: { [key: string]: any };
        insurance_info?: { [key: string]: any };
    };
    New?: {
        trip_info?: { [key: string]: any };
        IDDocSummary?: { [key: string]: any };
        applicant_contact_info?: { [key: string]: any };
        emergencycontact_info?: Array<{ [key: string]: any }>;
        Status?: { [key: string]: any };
        Watchlists?: { [key: string]: any };
        Note?: string;
        health_info?: { [key: string]: any };
        customs_info?: { [key: string]: any };
        insurance_info?: { [key: string]: any };
    };
    index?: number;
    constructor(params: ChangesLogModel) {
        return {
            Timestamp: params.Timestamp || '',
            Who: params.Who || '',
            What: params.What || '',
            Old: params.Old || {},
            New: params.New || {},
            index: params.index,
        };
    }
}

export class MappedChangeLogsModel {
    trip_info?: { [key: string]: any };
    IDDocSummary?: { [key: string]: any };
    applicant_contact_info?: { [key: string]: any };
    emergencycontact_info?: Array<{ [key: string]: any }>;
    Status?: { [key: string]: any };
    Note?: string;
    health_info?: { [key: string]: any };
    customs_info?: { [key: string]: any };
    insurance_info?: { [key: string]: any };
    constructor(
        params: MappedChangeLogsModel,
        utilityService?: UtilityService
    ) {
        return {
            trip_info:
                params.trip_info &&
                utilityService.airlineCodes?.find(
                    (element) =>
                        element.key === params.trip_info.departureCarrier ||
                        element.key === params.trip_info.arrivalCarrier
                )
                    ? {
                          ...params.trip_info,
                          departureCarrierLogo: `${utilityService.baseUrl}${
                              !utilityService.baseUrl.includes('taapi/api/')
                                  ? 'taapi/api/'
                                  : ''
                          }logos/carrierLogos/${
                              params.trip_info.departureCarrier
                          }.png`,
                          arrivalCarrierLogo: `${utilityService.baseUrl}${
                              !utilityService.baseUrl.includes('taapi/api/')
                                  ? 'taapi/api/'
                                  : ''
                          }logos/carrierLogos/${
                              params.trip_info.arrivalCarrier
                          }.png`,
                      }
                    : null,
            IDDocSummary: params.IDDocSummary || null,
            applicant_contact_info: params.applicant_contact_info || null,
            emergencycontact_info: params.emergencycontact_info || null,
            Status: params.Status || null,
            Note: params.Note || null,
            health_info: params.health_info || null,
            customs_info: params.customs_info || null,
            insurance_info: params.insurance_info || null,
        };
    }
}

export class MapChangeLog {
    Old?: { Status: { [key: string]: any }; Note: string };
    New?: { Status: { [key: string]: any }; Note: string };
    constructor(props: ChangesLogModel, utilityService: UtilityService) {
        return {
            Old: {
                Status: props.Old?.Status
                    ? {
                          status: utilityService.getApplicationStatus({
                              status: props.Old.Status.Status || '',
                              flagCode: props.Old.Status.Flag || '',
                          }),
                          taStatus: props.Old.Status.Status || '',
                          flag: props.Old.Status.Status || '',
                          flagCode: props.Old.Status.Flag || '',
                      }
                    : null,
                Note: props.Old?.Note || '',
            },
            New: {
                Status: props.New?.Status
                    ? {
                          status: utilityService.getApplicationStatus({
                              status: props.New.Status.Status || '',
                              flagCode: props.New.Status.Flag || '',
                          }),
                          taStatus: props.New.Status.Status || '',
                          flag: props.New.Status.Status || '',
                          flagCode: props.New.Status.Flag || '',
                      }
                    : null,
                Note: props.New?.Note || '',
            },
        };
    }
}
