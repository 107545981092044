import { SearchRowModel } from './search-row.model';

export interface LastUsedSearch {
    searchOptions: SearchRowModel[];
    selectedSavedSearchName?: string;
    isSavedSearchChanged?: boolean;
    isDefaultSearch?: boolean;
}

export class SettingsModel {
    themeColorMode?: string;
    language?: string;
    isSoundNotificationOn?: boolean;
    isAppListeningToStream?: boolean;
    notificationsPanelAutoShow?: boolean;
    constructor(props: SettingsModel) {
        return {
            themeColorMode: props.themeColorMode || 'dark-mode',
            language: props.language || 'en',
            isSoundNotificationOn: !!props.isSoundNotificationOn,
            isAppListeningToStream: !!props.isAppListeningToStream,
            notificationsPanelAutoShow:
                props.notificationsPanelAutoShow === undefined ||
                props.notificationsPanelAutoShow,
        };
    }
}

export class SearchQueriesModel {
    lastUsedSearch?: LastUsedSearch;
    savedSearches?: {
        [key: string]: SearchRowModel[];
    };

    constructor(props: SearchQueriesModel) {
        return {
            lastUsedSearch: props.lastUsedSearch,
            savedSearches: props.savedSearches || {},
        };
    }
}

export type PageModel = {
    filterChips?: { [key: string]: boolean | string };
    columnDefs?: { [key: string]: any }[];
    activeFilters?: { templateId?: string; [key: string]: any };
    columnState?: { [key: string]: any }[];
};

export class PreferencesModel {
    settings?: SettingsModel;
    searchQueries?: SearchQueriesModel;
    pages?: { [page: string]: PageModel | any };

    constructor(props: PreferencesModel) {
        return {
            settings: new SettingsModel(props.settings || {}),
            searchQueries: new SearchQueriesModel(props.searchQueries || {}),
            pages: props.pages || {},
        };
    }
}
