export const types = {
    GET_USER_INFO_SUCCESS: 'successfully logged in',
    GET_USER_INFO_FAILED: 'failed to get user info',

    SET_USER_TAGS: 'SET USER TAGS',

    CLEAR_LOGIN_INFO: 'clears login info',
    SET_APP_DETAILS_STATUS: 'set appdetails status',

    CLEAR_APP_DETAILS: 'clear app details',
    SET_TWILIO_TOKEN: 'set twilio token',

    GET_PREFERENCES: 'get preferences',
    GET_PREFERENCES_SUCCESS: 'successfully got preferences',
    GET_PREFERENCES_FAIL: 'failed to get preferences',
    UPDATE_SETTINGS_PREFERENCES: 'update settings preferences',
    UPDATE_SAVED_SEARCHES_PREFERENCES: 'update saved searches preferences',
    UPDATE_SAVED_SEARCHES_FAILED: 'failed to update saved searches preferences',
    UPDATE_LAST_USED_SEARCH_PREFERENCES: 'update last used search preferences',
    UPDATE_PAGE_PREFERENCES: 'update page preferences',
    SET_LOGIN_SELECTED_LANGUAGE: 'set login selected language',

    // UI
    CLEAR_UI_STATE: 'Clear UI state',
    SWITCH_THEME_MODE: 'Switch theme mode',
    SWITCH_LANGUAGE: 'Switch app language',
    UPDATE_HEADER_STATE: 'UPDATE HEADER STATE',
    CLEAR_TA_VALIDATION_STATE: 'Clear the ta validation ui state',
    TOGGLE_SWITCH: '[UI] Toggle Switch',
    UPDATE_APPS_STATE: '[UI] UPDATE APPS STATE',
    SET_IS_SIDEBAR_OPEN: '[UI] SET IS SIDEBAR OPEN',

    //DataBus
    GET_HC_PROFILES_SUCCESS: '[DATABUS] GET Health Credential SUCCESS',
    GET_HC_PROFILES_FAILED: '[DATABUS] GET Health Credential FAILED',
    GET_HC_PROFILES: '[DATABUS] GET Health Credential',

    GET_CONTENT_SUCCESS: '[DATABUS] GET Content Success',
    GET_CONTENT_ATTEMPT: '[DATABUS] GET Content Attempt',
    GET_CONTENT_FAILED: '[DATABUS] GET  Content Failed',
    GET_CONTENTS_FAILED: '[UI] Get Contents Failed', //in case of error 304 we need to keep getContents to be success

    GET_APP_CONFIG_ATTEMPT: '[DATABUS] GET Config Attempt',
    GET_APP_CONFIG_SUCCESS: '[DATABUS] GET Config Success',
    GET_APP_CONFIG_FAILED: '[DATABUS] GET Config Failed',

    GET_ALL_USERS_SUCCESS: '[DATABUS] GET All Users Success',
    GET_ALL_USERS_ATTEMPT: '[DATABUS] GET All Users Attempt',
    GET_ALL_USERS_FAILED: '[DATABUS] GET  All Users Failed',

    UPDATE_NODE_DATA: '[DATABUS] Update  NODE Data in the state',
    SET_TABLE_ROW_DATA: '[DATABUS] Set table row data in the state',

    GET_PENDING_APPS_SUCCESS: '[DATABUS] Getting pending applications success',
    GET_PENDING_APPS_FAILED: '[DATABUS] Failed to get pending applications',
    GET_PENDING_APPS: '[DATABUS] Get all pending applications',

    GET_APPS_SUCCESS: '[DATABUS] Get Apps Success',
    GET_APPS_FAILED: '[DATABUS] Get Apps Failed',
    GET_APPS: '[DATABUS] Get Apps Attempt',

    SET_APPLICATION_NODE: '[DATABUS] Set application node',

    LOAD_MORE_APPS: '[DATABUS] loading more apps',

    GET_APPS_COUNT_SUCCESS: '[DATABUS] Get Apps count Success',
    GET_APPS_COUNT_FAILED: '[DATABUS] Get Apps count Failed',
    GET_APPS_COUNT: '[DATABUS] Get Apps count Attempt',

    GET_APP_DETAILS_ATTEMPT: '[DATABUS] Get Application Details Attempt',
    GET_APP_DETAILS_SUCCESS: '[DATABUS] Get Application Details Success',
    GET_APP_DETAILS_FAILED: '[DATABUS] Get Application Details Failed',

    SET_APP_DETAILS_PARAMS:
        '[DATABUS] set params like code, token.. that are needed to search for the app',
    VALIDATE_TA_INFO_ATTEMPT: '[DATABUS] Validate Ta Agent Attempt',
    VALIDATE_TA_INFO_SUCCESS: '[DATABUS] Validate Ta Agent Success',
    VALIDATE_TA_INFO_FAILED: '[DATABUS] Validate Ta Agent Failed',

    ANALYZE_TEST_RESULT_ATTEMPT: '[DATABUS] Analyze Test Result Attempt',
    ANALYZE_TEST_RESULT_SUCCESS: '[DATABUS] Analyze Test Result Success',
    ANALYZE_TEST_RESULT_FAILED: '[DATABUS] Analyze Test Result Failed',

    GET_APPS_NAME_SEARCH: '[DATABUS] Get APPS from Name Search API',

    GET_HOTELS_SUCCESS: '[DATABUS] Get Hotel Success',
    GET_HOTELS_FAILED: '[DATABUS] Get Hotel Failed',
    GET_HOTELS: '[DATABUS] Get Hotel',
    REFRESH_WATCHLIST_MATCHES: '[DATABUS] Refresh Watchlist Matches',
    REFRESH_WATCHLIST_MATCHES_FAILED:
        '[DATABUS] Refresh Watchlist Matches Failed',
    REFRESH_WATCHLIST_MATCHES_SUCCESS:
        '[DATABUS] Refresh Watchlist Matches Success',
    CLEAR_WATCHLIST_MATCHES: '[UI] Clear Watchlist Matches',

    //GET TA Private Version
    GET_TA_PRIVATE_VERSION_SUCCESS: '[DATABUS] GET TA Private Version Success',
    GET_TA_PRIVATE_VERSION_FAILED: '[DATABUS] GET TA Private Version Failed',
    GET_TA_PRIVATE_VERSION: '[DATABUS] GET TA Private Version',

    UPDATE_FLIGHT_COUNT_ATTEMPT: '[DATABUS] UPDATE_FLIGHT_COUNT_ATTEMPT',

    SET_DISTINCT_FLIGHTS_COUNT_SUCCESS:
        '[DATABUS] SET Distinct Flights Count Success',
    SET_DISTINCT_FLIGHTS_COUNT_FAILED:
        '[DATABUS] SET Distinct Flights Count Failed',
    SET_DISTINCT_FLIGHTS_COUNT: '[DATABUS] SET Distinct Flights Count',

    SET_FLIGHT_DASHBOARD_NODES: '[DATABUS] SET Child Nodes For FlightDashboard',

    CLEAR_DATABUS_DATA:
        '[DATABUS] Clears all data from databus and sets it to the initial state',

    CLEAR_APP_MANAGER_NODE: '[DATABUS] Clears all data from AppManager Node',

    UPDATE_HOTEL_DATA: '[DATABUS] Update Hotel Data',

    //Table State
    SET_ROW_DATA: '[TS] Set table row data in the state',
    SELECT_TABLE_ROW_DATA: '[TS] Save selected  table row data in the state',

    //Establishment Users
    GET_ALL_ESTABLISHMENT_USERS_ATTEMPT:
        '[DATABUS] GET All Establishment Users Attempt ',
    GET_ALL_ESTABLISHMENT_USERS_SUCCESS:
        '[DATABUS] GET All Establishment Users Success',
    GET_ALL_ESTABLISHMENT_USERS_FAILED:
        '[DATABUS] GET  All Establishment Users Failed',

    //stream
    ON_STREAM_MESSAGE: '[STREAM] On Stream Message Receive',
    UPDATE_STREAM_EVENTS: '[STREAM] Update Stream Events',
    CLEAR_STREAM_DATA: '[STREAM] Clear Stream Data',

    //Test Summary
    VALIDATE_TEST_SUMMARY_ATTEMPT: '[DATABUS] validate test summary Attempt',
    VALIDATE_TEST_SUMMARY_SUCCESS: '[DATABUS] validate test summary Success',
    VALIDATE_TEST_SUMMARY_FAILED: '[DATABUS] validate test summary Failed',

    //PURCHASED ITEMS
    GET_ANCILLARIES_SUCCESS: '[DATABUS] Get Ancillaries Items Success',
    GET_ANCILLARIES_FAILED: '[DATABUS] Get Ancillaries Items Failed',

    //Tiers
    GET_TIERS_SUCCESS: '[DATABUS] Get Tiers Success',
    GET_TIERS_FAILED: '[DATABUS] Get Tiers Failed',

    //User FIle Types
    GET_USER_FILE_TYPE_SUCCESS: '[DATABUS] Get User File Types Success',
    GET_USER_FILE_TYPE_FAILED: '[DATABUS] Get User File Types Failed',

    GET_NOTIFICATIONS_APPS_COUNT: '[DATABUS] Get Notifications Apps Count',
    GET_NOTIFICATIONS_APPS_COUNT_SUCCESS:
        '[DATABUS] Get Notifications Apps Count Success',
    GET_NOTIFICATIONS_APPS_COUNT_FAILED:
        '[DATABUS] Get Notifications Apps Count Failed',
    SET_SPECIFIC_NOTIFICATIONS_APPS_COUNT:
        '[DATABUS] Set Specific Notifications Apps Count',
    //Get Email Logs by Reference
    GET_REFERENCE_EMAIL_LOGS_FAILED: '[DATABUS] Get User Email Logs Failed',

    //Get Supported ML Templates
    GET_SUPPORTED_ML_TEMPLATES: '[DATABUS] Get Supported ML Templates Attempt',
    GET_SUPPORTED_ML_TEMPLATES_SUCCESS:
        '[DATABUS] Get Supported ML Templates Success',
    GET_SUPPORTED_ML_TEMPLATES_FAILED:
        '[DATABUS] Get Supported ML Templates FAILED',

    GET_GROUPED_APPS_SUCCESS: '[DATABUS] Set Grouped Apps Success',
    GET_GROUPED_APPS_FAILED: '[DATABUS] Set Grouped Apps Failed',

    GET_ELASTIC_SEARCH_DATA: '[DATABUS] Get Elastic Search Data',
    GET_ELASTIC_SEARCH_DATA_SUCCESS:
        '[DATABUS] Get Elastic Search Data Success',
    GET_ELASTIC_SEARCH_DATA_FAILED: '[DATABUS] Get Elastic Search Data Failed',
    GET_ENROLLMENT_TYPES_ATTEMPT: '[DATABUS] Get Enrollment Types Attempt',
    GET_ENROLLMENT_TYPES_SUCCESS: '[DATABUS] Get Enrollment Types Success',
    GET_ENROLLMENT_TYPES_FAILED: '[DATABUS] Get Enrollment Types Failed',

    SET_PIN_CODE: '[DATABUS] Set Pin Code',
    SET_PIN_CODE_SUCCESS: '[DATABUS] Set Pin Code Success',
    SET_PIN_CODE_FAILED: '[DATABUS] Set Pin Code Failed',
    SET_PAGE_FILTERS: '[DATABUS] Set Page Filters',

    GET_NAMED_SEARCH_DATA: '[DATABUS] Get Named Search Data',
    GET_NAMED_SEARCH_DATA_SUCCESS: '[DATABUS] Get Named Search Data Success',
    GET_NAMED_SEARCH_DATA_FAILED: '[DATABUS] Get Named Search Data Failed',

    GET_ELASTIC_SEARCH_COUNT: '[DATABUS] Get Elastic Search Count',
    SET_OFFLINE_IP_PORT: '[OFFLINE] Set Offline IP Port',
    CLEAR_OFFLINE_DATABUS_DATA:
        '[OFFLINEDATABUS] Clears all data from offline databus and sets it to the initial state',

    CLEAR_OFFLINE_APP_MANAGER_NODE:
        '[OFFLINEDATABUS] Clears all data from Offline AppManager Node',
    GET_OFFLINE_APPS: '[OFFLINEDATABUS] Get Offline Databus',
    GET_OFFLINE_APPS_SUCCESS: '[OFFLINEDATABUS] Get Offline Databus Success',
    GET_OFFLINE_APPS_FAILED: '[OFFLINEDATABUS] Get Offline Databus Failed',

    GET_OFFLINE_APP_DETAILS: '[OFFLINEDATABUS] Get Offline App Details',
    GET_OFFLINE_APP_DETAILS_SUCCESS:
        '[OFFLINEDATABUS] Get Offline App Details Success',
    GET_OFFLINE_APP_DETAILS_FAILED:
        '[OFFLINEDATABUS] Get Offline App Details Failed',
    SET_OFFLINE_APP_DETAILS_PARAMS:
        '[OFFLINEDATABUS] Set Offline App Details Params',

    CLEAR_OFFLINE_APP_DETAILS: '[OFFLINEDATABUS] Clear Offline App Details',
    BIOMETRIC_SEARCH: '[DATABUS] BIOMETRIC SEARCH',
    BIOMETRIC_SEARCH_SUCCESS: '[DATABUS] BIOMETRIC SEARCH Success',
    BIOMETRIC_SEARCH_FAILED: '[DATABUS] BIOMETRIC SEARCH Failed',
    GET_PROCESSING_APPS_COUNTS: '[DATABUS] Get Processing Apps Counts',
    GET_PROCESSING_APPS_COUNTS_SUCCESS:
        '[DATABUS] Get Processing Apps Counts Success',
    GET_PROCESSING_APPS_COUNTS_Failed:
        '[DATABUS] Get Processing Apps Counts Failed',
    RESTORE_BIOMETRIC_SEARCH_STATE: '[DATABUS] RESTORE BIOMETRIC SEARCH ',
    SET_CMS_FORM_SUBNODE: '[ui] Set CMS Form SubNode',
    CLEAR_CMS_FORM_SUBNODE: '[ui] Clear CMS Form SubNode',
    UPDATE_PARENT_PATH_STATE: '[ui] Update Parent Path',

    RENAME_SAVED_SEARCH: '[UI] Rename Saved Search',
    SET_SELECTED_TAB: '[DATABUS] Set Selected Tab',

    GET_NAMED_SEARCH: '[DATABUS] Get Named Search',
    GET_NAMED_SEARCH_SUCCESS: '[DATABUS] Get Named Search Success',
    GET_NAMED_SEARCH_FAILED: '[DATABUS] Get Named Search Failed',

    REMOVE_APPLICATION: '[DATABUS] Remove Application',
};
