import { Action, createReducer, on } from '@ngrx/store';
import {
    clearOfflineDatabusData,
    getOfflineAppDetailsSuccess,
    getOfflineAppsSuccess,
    setOfflineAppDetailsParams,
    setOfflineIpPort,
} from '../actions';
import { offlineDatabusState } from '../state/offlineDatabus.state';
import { OfflineDatabusModel } from '../../models/offlineDatabus.model';

const initialState = new OfflineDatabusModel() as offlineDatabusState;

const offlineDatabusReducer = createReducer(
    initialState,

    on(clearOfflineDatabusData, (state) => {
        let _d = { ...state };
        _d = {
            ...new OfflineDatabusModel(),
            ipPort: _d.ipPort,
        };
        return _d;
    }),
    on(getOfflineAppsSuccess, (state, action) => {
        const _d = { ...state };
        _d.appsManager = action.apps;
        return _d;
    }),
    on(getOfflineAppDetailsSuccess, (state, action) => {
        const _d = { ...state };
        _d.appDetails = action.data;
        _d.appDetails.details.passportImageUrl = `https://${_d.ipPort}/api/passport/${action.data.ref}`;
        _d.appDetails.details.personUrl[
            'url'
        ] = `https://${_d.ipPort}/api/selfie/${action.data.ref}`;
        return _d;
    }),
    on(setOfflineAppDetailsParams, (state, action) => {
        const _d = { ...state };
        _d.appDetails.flag = action.flag;
        _d.appDetails.ref = action.code;
        _d.appDetails.SubmittedTs = action.submittedTs;
        _d.appDetails.status = action.applicationStatus;
        _d.appDetails.index = action.index;

        return _d;
    }),
    on(setOfflineIpPort, (state, action) => {
        const _d = { ...state };
        _d.ipPort = action.ipPort;
        return _d;
    })
);

export default function reducer(
    state: offlineDatabusState,
    action: Action
): offlineDatabusState {
    return offlineDatabusReducer(state, action);
}
