import { NotificationsState } from '../state/notifications.state';
import { notificationsModel } from '@app/models/notifications.model';
import { Action, createReducer, on } from '@ngrx/store';
import {
    getNotificationsAppsCountSuccess,
    setSpecificNotificationsAppsCount,
} from '../actions/notifications.actions';

const initialState = new notificationsModel() as NotificationsState;

const notificationsReducer = createReducer(
    initialState,
    on(getNotificationsAppsCountSuccess, (state, action) => {
        let _n = { ...state };
        _n = {
            ..._n,
            ...action.payload,
        };
        return _n;
    }),
    on(setSpecificNotificationsAppsCount, (state, action) => {
        const _n = { ...state };
        _n[action.storeNode].count = action.count;
        return _n;
    })
);

export default function reducer(
    state: NotificationsState,
    action: Action
): NotificationsState {
    return notificationsReducer(state, action);
}
