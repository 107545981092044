export class documentHandlerModel {
    title?: {};
    icons?: Icon[];
    styleProps?: {};
    URL?: string;
    id?: string;
    attachmentSource?: string;
    fileType?: string;
    mimeType?: string;
    isMachineVerified?: boolean;
    validation?: {
        [key: string]: any;
        validationData?: { [key: string]: any };
    };
    createdMs?: string | number;

    constructor(props: documentHandlerModel) {
        return {
            title: props.title ? props.title : {},
            icons: props.icons ? props.icons : [],
            styleProps: props.styleProps ? props.styleProps : {},
            URL: props.URL ? props.URL : '',
            id: props.id ? props.id : '',
            attachmentSource: props.attachmentSource
                ? props.attachmentSource
                : '',
            fileType: props.fileType ? props.fileType : '',
            mimeType: props.mimeType ? props.mimeType : '',
            isMachineVerified: props.isMachineVerified || false,
            validation: props.validation ? props.validation : {},
            createdMs: props.createdMs || '',
        };
    }
}

export class Icon {
    name?: string;
    clickFnName?: string;
    fnParams?: {};
    iconClass?: {};
    tooltipText?: string;
    hasLoader?: boolean;
    hideInOffline?: boolean;

    constructor(props: {
        name?: string;
        clickFnName?: string;
        fnParams?: {};
        iconClass?: {};
        tooltipText?: string;
        hasLoader?: boolean;
        hideInOffline?: boolean;
    }) {
        return {
            name: props.name ? props.name : '',
            clickFnName: props.clickFnName ? props.clickFnName : '',
            iconClass: props.iconClass ? props.iconClass : {},
            tooltipText: props.tooltipText ? props.tooltipText : '',
            fnParams: props.fnParams ? props.fnParams : {},
            hasLoader: props.hasLoader ? props.hasLoader : false,
            hideInOffline: props.hideInOffline ? props.hideInOffline : false,
        };
    }
}
