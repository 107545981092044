import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

/*Models for functions in the utility service */
export class Badge {
    processingBadge?: boolean;
    invalidBadge?: boolean;
    validBadge?: boolean;

    constructor(props: {
        processingBadge?: boolean;
        invalidBadge?: boolean;
        validBadge?: boolean;
    }) {
        return {
            processingBadge: props.processingBadge
                ? props.processingBadge
                : false,
            invalidBadge: props.invalidBadge ? props.invalidBadge : false,
            validBadge: props.validBadge ? props.validBadge : false,
        };
    }
}

export class DispatchParams {
    status: string;
    storeNode: string;
    flagCode: string;

    constructor(props: {
        status?: string;
        storeNode?: string;
        flagCode?: string;
    }) {
        return {
            status: props.status || '',
            storeNode: props.storeNode || '',
            flagCode: props.flagCode || '',
        };
    }
}

export class Note {
    note?: string;
    level?: string;
    dateTime?: string;
    timestamp?: string;
    who?: string;
    time?: string;
    date?: string;

    constructor(props: {
        note?: string;
        level?: string;
        dateTime?: string;
        timestamp?: string;
        who?: string;
    }) {
        const date = props.dateTime ? props.dateTime : props.timestamp;
        return {
            note: props.note ? props.note : '-',
            level: props.level ? props.level : '-',
            time: date ? dayjs(date).format('HH:mm') : '-',
            date: date ? dayjs(date).format('MMM DD, YYYY') : '-',
            who: props.who ? props.who : '-',
        };
    }
}

export class VisitedCountriesBackgroundColor {
    countryCode?: string;
    countryName?: string;
    inLowRisk: boolean;
    inMediumRisk: boolean;
    inWatchList: boolean;
    inWhiteList: boolean;
    inYellowFever: boolean;
    inPox: boolean;
    inEbola: boolean;
    inPolio: boolean;
    inCountryCustoms: boolean;
    inCountryVisaExemption: boolean;
    inCountryVisaOnArrival: boolean;
}

export class PreCheckInfo {
    label: string;
    score: string;
    result: string;

    constructor(props: { label: string; score?: string; result: string }) {
        return {
            label: props.label,
            score: props.score ? `${props.score} %` : '',
            result: props.result,
        };
    }
}

export class VisitedCountries {
    countryCode: string;
    countryName: string;
    inLowRisk: boolean;
    inMediumRisk: boolean;
    inWatchList: boolean;
    inWhiteList: boolean;
    inYellowFever: boolean;
    inPox: boolean;
    inEbola: boolean;
    inPolio: boolean;
    inCountryCustoms: boolean;
    inCountryVisaExemption: boolean;
    inCountryVisaOnArrival: boolean;
}

export class AttachmentModel {
    Validation?: TaValidationInfo;
    OriginalFileUrl?: string;
    attachmentSource?: string;
    contentType?: string;
    taReference?: string;
    createdMs?: number;
    fileName?: string;
    fileType?: string;
    mimeType?: string;
    URL?: string;
    id?: string;
    title?: {};
    constructor(props: {
        Validation?: TaValidationInfo;
        OriginalFileUrl?: string;
        attachmentSource?: string;
        contentType?: string;
        taReference?: string;
        createdMs?: number;
        fileName?: string;
        fileType?: string;
        mimeType?: string;
        URL?: string;
        id?: string;
        title?: {};
    }) {
        return {
            OriginalFileUrl: props.OriginalFileUrl ? props.OriginalFileUrl : '',
            URL: props.URL ? props.URL : '',
            attachmentSource: props.attachmentSource
                ? props.attachmentSource
                : '',
            contentType: props.contentType ? props.contentType : '',
            taReference: props.taReference ? props.taReference : '',
            mimeType: props.mimeType ? props.mimeType : '',
            createdMs: props.createdMs ? props.createdMs : 0,
            fileName: props.fileName ? props.fileName : '',
            fileType: props.fileType ? props.fileType : '',
            Validation: props.Validation || {},
            id: props.id ? props.id : '',
            title: props.title ? props.title : {},
        };
    }
}

export class CountryList {
    displayNumber: string | number;
    dialNumber: string | number;
    flagCode: string;
    text: string;
    label: string;
    key: string;
    group: string;
}

export class ProfilePicInfo {
    url: string;
    type: string;
    matchPercentage: number;
    totalSelfies: number;
    totalMatchPercentage: number;

    constructor(props: {
        url?: string;
        type?: string;
        matchPercentage?: number;
        totalSelfies?: number;
        totalMatchPercentage?: number;
    }) {
        return {
            url: props.url || '',
            type: props.type || '',
            matchPercentage: props.matchPercentage || 0,
            totalSelfies: props.totalSelfies || 0,
            totalMatchPercentage: props.totalMatchPercentage || 0,
        };
    }
}

export class CountryModel {
    Country_Name: string;
    ISO3166_1_Alpha_2: string;
    ISO3166_1_Alpha_3: string;
    Dial: string;
    Display: string;
}

export class NotificationType {
    key: string;
    label: string;
    status: string;
    availableFor: Array<string>;
    [key: string]: string | Array<string>;
}

export class CountryAddress {
    residenceInCountry?: {}[];
    addressInCountry?: { main_text?: string; secondary_text?: string };

    constructor(props: {
        residenceInCountry?: {}[];
        addressInCountry?: { main_text?: string; secondary_text?: string };
    }) {
        return {
            residenceInCountry: props.residenceInCountry
                ? props.residenceInCountry
                : [],
            addressInCountry: props.addressInCountry
                ? props.addressInCountry
                : {},
        };
    }
}

export class PaymentInfo {
    cardBrand?: string;
    cardCountry?: string;
    cardExpiry?: string;
    cardFingerprint?: { key?: string; label?: string };
    cardLast4Digits?: string;
    currency?: string;
    intentId?: string;
    paymentAmount?: number;
    paymentBreakdown?: Array<PaymentItem>;
    timestamp?: string;
    paymentStatus?: string;

    constructor(props?: PaymentInfoAPI) {
        const paymentItems = props?.PaymentBreakdown?.map((item) => {
            return new PaymentItem(item);
        });
        return {
            cardBrand: props?.CardBrand || '',
            cardCountry: props?.CardCountry || props.CardIssuer || '',
            cardExpiry: props?.CardExpiry || '',
            cardFingerprint: props?.CardFingerprint
                ? { label: props.CardFingerprint, key: 'fingerPrint' }
                : {},
            cardLast4Digits: props?.CardLast4Digits || props.CardLast4 || '',
            currency: props?.Currency?.toUpperCase() || '',
            intentId: props?.IntentId || '',
            paymentAmount: props?.PaymentAmount || 0,
            paymentBreakdown: paymentItems || [],
            paymentStatus: props.PaymentStatus || '',
            timestamp: props?.Timestamp || '',
        };
    }
}

export interface PaymentInfoAPI {
    CardBrand?: string;
    CardCountry?: string;
    CardExpiry?: string;
    CardFingerprint?: string;
    CardLast4Digits?: string;
    Currency?: string;
    IntentId?: string;
    PaymentAmount?: number;
    PaymentBreakdown?: Array<PaymentItemAPI>;
    Timestamp?: string;
    CardIssuer?: string;
    CardLast4?: string;
    PaymentStatus?: string;
}

export class PaymentDetails {
    type?: string;
    label?: string;
    package?: string;
    value?: number;
    isRedeemable?: boolean;
    name?: string;
    taReference?: string;
    url?: string;

    constructor(props: {
        type?: string;
        label?: string;
        package?: string;
        value?: number;
        isRedeemable?: boolean;
        name?: string;
        taReference?: string;
        url?: string;
    }) {
        // sometime package comes under 'name' property like for redeemed items RK
        const selectedPackage = props.package ? props.package : props.name;
        return {
            type: props.type || '',
            label: props.label || '',
            package: selectedPackage || '',
            name: props.name || '',
            value: props.value || 0,
            isRedeemable: props.isRedeemable || false,
            taReference: props.taReference || '',
            url: props.url || '',
        };
    }
}

export class TaValidationInfo {
    who?: string;
    dateTime?: string;
    timestamp?: string;
    type?: string;
    valid?: boolean;
    validationData?: ValidationDataModel;

    constructor(props: {
        who?: string;
        dateTime?: string;
        timestamp?: string;
        type?: string;
        valid?: boolean;
        validationData?: ValidationDataModel;
    }) {
        const dateTime = props.dateTime ? props.dateTime : props.timestamp;
        return {
            who: props.who || '',
            dateTime: dateTime || '',
            type: props.type || '',
            valid: props.valid || false,
            validationData: props.validationData || {},
        };
    }
}

export class DecisionNote {
    note?: string;
    level?: string;
    dateTime?: string;
    timestamp?: string;
    who?: string;
    time?: string;
    date?: string;
    type?: string;

    constructor(props: {
        note?: string;
        level?: string;
        dateTime?: string;
        timestamp?: string;
        who?: string;
        type?: string;
    }) {
        const date = props.dateTime ? props.dateTime : props.timestamp;
        return {
            note: props.note ? props.note : '',
            level: props.level ? props.level : '',
            dateTime: date ? dayjs(date).format('MMM DD, YYYY HH:mm') : '',
            timestamp: date ? date : '',
            who: props.who ? props.who : '',
            type: props.type || 'Other',
        };
    }
}

export class ColumnDefs {
    headerName?: string;
    field?: string;
    headerValueGetter?: string;
    editable?: boolean;
    sortable?: boolean;
    checkboxSelection?: boolean;
    headerCheckboxSelection?: boolean;
    hide?: boolean;

    constructor(props: {
        headerName?: string;
        field?: string;
        headerValueGetter?: string;
        editable?: boolean;
        sortable?: boolean;
        checkboxSelection?: boolean;
        headerCheckboxSelection?: boolean;
        hide?: boolean;
    }) {
        return {
            headerName: props.headerName || '',
            field: props.field || '',
            headerValueGetter: props.headerValueGetter || '',
            editable: props.editable || false,
            sortable: props.sortable || false,
            checkboxSelection: props.checkboxSelection || false,
            headerCheckboxSelection: props.headerCheckboxSelection || false,
            hide: props.hide || false,
        };
    }
}
export class AppDataFlaggedAnswers {
    customs_info?: { answers?: Array<AnswersModel> };
    health_info?: { answers?: Array<AnswersModel> };
    insurance_info?: { answers?: Array<AnswersModel> };
    customInfo?: { answers?: Array<AnswersModel> };
    healthInfo?: { answers?: Array<AnswersModel> };
    insuranceInfo?: { answers?: Array<AnswersModel> };
    outboundCustoms_info?: {
        answers: Array<AnswersModel>;
    };
    enrollment_info?: { answers?: Array<AnswersModel> };

    constructor(props: {
        customs_info?: {
            answers?: Array<AnswersModel>;
        };
        health_info?: {
            answers?: Array<AnswersModel>;
        };
        insurance_info?: {
            answers?: Array<AnswersModel>;
        };
        customInfo?: {
            answers?: Array<AnswersModel>;
        };
        healthInfo?: {
            answers?: Array<AnswersModel>;
        };
        insuranceInfo?: {
            answers?: Array<AnswersModel>;
        };
        outboundCustoms_info?: {
            answers: Array<AnswersModel>;
        };
        enrollment_info?: {
            answers?: Array<AnswersModel>;
        };
    }) {
        return {
            customInfo: props
                ? props.customInfo
                    ? props.customInfo
                    : props.customs_info
                    ? props.customs_info
                    : { answers: [] }
                : { answers: [] },
            healthInfo: props
                ? props.healthInfo
                    ? props.healthInfo
                    : props.health_info
                    ? props.health_info
                    : { answers: [] }
                : { answers: [] },
            insuranceInfo: props
                ? props.insuranceInfo
                    ? props.insuranceInfo
                    : props.insurance_info
                    ? props.insurance_info
                    : { answers: [] }
                : { answers: [] },
            outboundCustoms_info: props?.outboundCustoms_info
                ? props.outboundCustoms_info
                : { answers: [] },
            enrollment_info: props?.enrollment_info || {
                answers: [],
            },
        };
    }
}

export class AnswersModel {
    answer?: string;
    question?: string;
    questionKey?: string;
    type?: string;

    constructor(props: {
        answer?: string;
        question?: string;
        questionKey?: string;
        type?: string;
    }) {
        return {
            answer: props.answer || '',
            question: props.question || '',
            questionKey: props.questionKey || '',
            type: props.type || '',
        };
    }
}

export class MatchRecord {
    Confidence?: number;
    ImageId?: string;
    Matched?: boolean;

    constructor(props: {
        Confidence?: number;
        ImageId?: string;
        Matched?: boolean;
    }) {
        return {
            Confidence: props.Confidence || 0,
            ImageId: props.ImageId || '',
            Matched: props.Matched || false,
        };
    }
}

export class ImageRecordInfo {
    statusColor?: string;
    matchPercentage?: string;

    constructor(props: { statusColor?: string; matchPercentage?: string }) {
        return {
            statusColor: props.statusColor || '',
            matchPercentage: props.matchPercentage || '',
        };
    }
}

export class UserPreferences {
    colId?: string;
    hide?: boolean;
    selected?: boolean;
    headerName?: string;

    constructor(props: UserPreferences) {
        return {
            colId: props.colId || '',
            hide: props.hide || false,
            selected: props.selected,
            headerName: props.headerName,
        };
    }
}

export class NestedQueryType {
    key?: string;
    primaryType?: string;
    secondaryType?: string | NestedQueryType[];
    value?: any;

    constructor(props: NestedQueryType) {
        return {
            key: props.key || '',
            primaryType: props.primaryType || '',
            secondaryType: props.secondaryType || '',
            value: props.value,
        };
    }
}

export class QueryType {
    key?: string;
    primaryType?: string;
    secondaryType?: string;
    value?: string | boolean;

    constructor(props: {
        key?: string;
        primaryType?: string;
        secondaryType?: string;
        value?: string | boolean;
    }) {
        return {
            key: props.key || '',
            primaryType: props.primaryType || '',
            secondaryType: props.secondaryType || '',
            value:
                typeof props.value === 'boolean'
                    ? props.value
                    : props.value
                    ? props.value
                    : '',
        };
    }
}

export class FilterChips {
    ARRIVALS_FUTURE?: boolean;
    ARRIVALS_TODAY?: boolean;
    ARRIVALS_TOMORROW?: boolean;
    EXCLUDE_ARRIVAL_TODAY?: boolean;
    EXCLUDE_DEPARTURE_TODAY?: boolean;
    EXCLUDE_WORKERS?: boolean;
    RECENT_PAST_FLIGHTS?: boolean;
    SUBMISSION_RECENT?: boolean;
    UPCOMING_FLIGHTS?: boolean;
    RECENT_PAST_VESSELS?: boolean;
    UPCOMING_VESSELS?: boolean;
    isGridView?: boolean;
}

export class ElasticQuery {
    bool?: {
        must?: {}[];
        must_not?: {}[];
        should?: {}[];
        minimum_should_match?: number;
    };

    constructor(props: {
        bool?: {
            must?: {}[];
            must_not?: {}[];
            should?: {}[];
            minimum_should_match?: number;
        };
    }) {
        return {
            bool: props.bool ? props.bool : {},
        };
    }
}

//representing attachments returned by the api before any formatting is done RK
export class RawAttachment {
    id?: string;
    Validation?: TaValidationInfo;

    constructor(props: { id?: string; Validation?: TaValidationInfo }) {
        return {
            id: props.id || '',
            Validation: props.Validation || {},
        };
    }
}

export class TestSummary {
    attachmentID?: string;
    testResult?: string;
    testResultDate?: string;
    testType?: string;
    testUID?: string;
    when?: string;
    who?: string;

    constructor(props: {
        attachmentID?: string;
        testResult?: string;
        testResultDate?: string;
        testType?: string;
        testUID?: string;
        when?: string;
        who?: string;
    }) {
        return {
            attachmentID: props.attachmentID || '',
            testResult: props.testResult || '',
            testResultDate: props.testResultDate || '',
            testType: props.testType || '',
            testUID: props.testUID || '',
            when: props.when || '',
            who: props.who || '',
        };
    }
}

export class ValidationInfo {
    [key: string]: {
        dateTime?: string;
        isPCRNegative?: boolean;
        testSummary?: {}[];
        type?: string;
        valid?: boolean;
        who?: string;
    };
}

export class AncillariesItem {
    type?: string;
    category?: string;
    package?: string;
    currency?: string;
    amount?: number;
    quantityType?: string;
    quantity?: number;
    isRedeemable?: boolean;
    provider?: string;
    url?: string;
    label?: string;
    key?: string;
    taReference?: string;

    constructor(props: AncillariesItem) {
        return {
            type: props.type || '',
            category: props.category || '',
            package: props.package || '',
            currency: props.currency || '',
            amount: props.amount || 0,
            quantityType: props.quantityType || '',
            quantity: props.quantity || 0,
            isRedeemable: props.isRedeemable || false,
            provider: props.provider || '',
            url: props.url || '',
            label: props.package || '',
            key: props.package || '',
            taReference: props.taReference || '',
        };
    }
}

export class TierItems {
    tier?: string;
    key?: string;
    label?: string;

    constructor(props: TierItems, translateService: TranslateService) {
        return {
            label: translateService.instant(
                `tier_types.${props.tier.toLowerCase()}`
            )
                ? `tier_types.${props.tier.toLowerCase()}`
                : props.tier,
            key: props.tier || '',
        };
    }
}

export class ElasticQueryBuild {
    from: number;
    size: number;
    _source: Array<string>;
    sort: {}[];
    query: ElasticQuery;
}

export class PaymentItem {
    amount?: number;
    purchase?: string;

    constructor(props?: PaymentItemAPI) {
        return {
            amount: props?.Amount || 0,
            purchase: props?.Purchase || '',
        };
    }
}

export interface PaymentItemAPI {
    Amount?: number;
    Purchase?: string;
}

export interface OverrideConfigNode {
    name: string;
    children?: Array<OverrideConfigNode>;
}

export interface FlatNode {
    parentNode?: string;
    expandable?: boolean;
    label: string;
    name: string;
    level?: number;
    children?: Array<FlatNode>;
}

export class ApplicationChangesModel {
    FlagCode: string;
    Notify: boolean;
    SetFlag: boolean;
    TaReference: string;
    TaStatus: string;
    Reason: string;

    constructor(props?: ApplicationChangesModel) {
        return {
            FlagCode: props?.FlagCode || '',
            Notify: props?.Notify || false,
            SetFlag: props.SetFlag || false,
            TaReference: props?.TaReference || '',
            TaStatus: props?.TaStatus || '',
            Reason: props?.Reason || '',
        };
    }
}

export class ValidationDataModel {
    vaccinations?: Array<VaccinationDocumentModel>;
    tests?: Array<TestDocumentModel>;
    dateOfBirth?: string;
    validated?: boolean;
    forenames?: string;
    fullName?: string;
    dateTime?: string;
    surname?: string;
    format?: string;

    constructor(props?: {
        vaccinations?: Array<VaccinationDocumentModel>;
        tests?: Array<TestDocumentModel>;
        validated?: boolean;
        dateTime?: string;
        format?: string;
        person?: {
            dateOfBirth: string;
            names: { normalised: { first: string; last: string } };
        };
    }) {
        return {
            forenames: props?.person?.names?.normalised?.first || '',
            surname: props?.person?.names?.normalised?.last || '',
            fullName: `${
                props?.person?.names?.normalised?.last
                    ? props?.person?.names?.normalised?.last + ', '
                    : ''
            }${props?.person?.names?.normalised?.first || ''}`,
            dateOfBirth: props?.person?.dateOfBirth || '',
            vaccinations: props?.vaccinations || [],
            validated: props?.validated || false,
            dateTime: props?.dateTime
                ? dayjs(props.dateTime).format('YYYY-MM-DD')
                : '',
            format: props?.format || '',
            tests: props?.tests || [],
        };
    }
}

export class TestDocumentModel {
    collectionTime?: string;
    country?: string;
    disease?: string;
    identifier?: string;
    issuer?: string;
    result?: string;
    type?: string;
    constructor(props?: TestDocumentModel) {
        return {
            collectionTime: props?.collectionTime
                ? dayjs(props.collectionTime).format('YYYY-MM-DD')
                : '',
            country: props?.country || '',
            disease: props?.disease || '',
            identifier: props?.identifier || '',
            issuer: props?.issuer || '',
            result: props?.result || '',
            type: props?.type || '',
        };
    }
}

export class VaccinationDocumentModel {
    country: string;
    date: string;
    disease: string;
    doseNumber: number;
    doses: number;
    identifier: string;
    issuer: string;
    manufacturer: string;
    product: string;
    vaccine: string;

    constructor(props?: VaccinationDocumentModel) {
        return {
            country: props?.country || '',
            date: props?.date || '',
            disease: props?.disease || '',
            doseNumber: props?.doseNumber || 0,
            doses: props?.doses || 0,
            identifier: props?.identifier || '',
            issuer: props?.issuer || '',
            manufacturer: props?.manufacturer || '',
            product: props?.product || '',
            vaccine: props?.vaccine || '',
        };
    }
}

export class IncompleteAuditLogsParams {
    constructor(params: {
        enrollmentTypes: Array<string>;
        fromDate?: string;
        toDate?: string;
    }) {
        const body = {
            must_filters: {
                paymentAgentOverrideDate: {
                    fromDate: params.fromDate,
                    toDate: params.toDate,
                },
            },
        };
        if (params.enrollmentTypes?.length) {
            body['must_filters']['enrollmentType'] = {
                enrollmentTypes: params.enrollmentTypes,
            };
        }
        return body;
    }
}
