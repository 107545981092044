import { documentsActionCardModel } from '@app/models/documentsActionCard';
import { ElasticSearchParams } from '@app/models/applicationActionModal';
import {
    dropdownOptionsConfig,
    emailNotificationTypes,
    eligibilityRequirements,
} from '@app/config/dropdownOptionsConfig';
import { GalleryAttachmentModel } from '@app/models/galleryAttachment';
import { PatientModel } from '@app/models/services/apiService.model';
import { user, selectDataBus, selectStream } from '@app/store/selectors';
import { documentHandlerModel } from '@app/models/documentHandler';
import { ApplicationDetails } from '@app/models/appDetails';
import { NavigationStart, Router } from '@angular/router';
import { imagesArray } from '@app/config/loginPageImages';
import {
    getAppsNameSearch,
    getPendingApps,
    getAppsCount,
    getApps,
    getContent,
    getAppConfig,
    getSupportedMLTemplates,
    getAppsCountSuccess,
    setSpecificNotificationsAppsCount,
    clearAppDetails,
    getAppDetails,
    updateStreamEvents,
    updatePagePreferences,
    getElasticSearchData,
    getEnrollmentTypes,
} from '@app/store/actions';
import { ApplicantCard } from '@app/models/applicantCard';
import { Observable, merge, of, fromEvent, ObservableInput } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { AppConfig } from 'environments/environment';
import { ElectronService } from '@app/core/services';
import { menuConfig } from '@app/config/menuConfig';
import {
    ElasticQueryBuild,
    AppDataFlaggedAnswers,
    NotificationType,
    TaValidationInfo,
    UserPreferences,
    ImageRecordInfo,
    ValidationInfo,
    CountryAddress,
    PaymentDetails,
    RawAttachment,
    DecisionNote,
    CountryModel,
    PreCheckInfo,
    ElasticQuery,
    PaymentInfo,
    TestSummary,
    FilterChips,
    MatchRecord,
    ColumnDefs,
    QueryType,
    PaymentInfoAPI,
    FlatNode,
    ApplicationChangesModel,
    NestedQueryType,
    VisitedCountriesBackgroundColor,
    VisitedCountries,
    AttachmentModel,
    DispatchParams,
    ProfilePicInfo,
    Badge,
    Note,
    AncillariesItem,
} from '@app/models/services/utilityService.model';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { menuItem } from '@app/models/menuItem';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { cloneDeep, isEqual, intersection, isPlainObject } from 'lodash-es';
import {
    MatTreeFlatDataSource,
    MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AirportModel } from '@app/models/database';
import jwtDecode from 'jwt-decode';
import {
    StreamDataModel,
    streamDataTypesMapping,
} from '@app/models/streamData.model';
import { StreamState } from '@app/store/state/stream.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventHubService } from './event-hub.service';
import { PageModel as PageModelPreference } from '@app/models/preferences.model';
import { validationPatterns } from '@app/validators/regular-expressions';
import { pagesConfig } from '@app/config/pagesConfig';
import { templateElasticSearches } from '@app/config/templateElasticSearch';
import { TemplateElasticSearchParams } from '@app/models/elasticSearch';
import { tabFilterModel } from '@app/models/cardHeader';
import { TimeConversionService } from './time-conversion.service';
import * as dayjs from 'dayjs';

const FileSaver = require('file-saver');
declare const google: any;

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    countryCodes = require('../../assets/resources/countries_codes.json');
    applicationStatuses = require('../../assets/resources/application_statuses.json');
    airports = require('../../assets/resources/airports_list.json');
    currencies = require('../../assets/resources/currencies.json');
    isFormSaveBtnDisabled = true;
    menuConfig: Array<menuItem> = menuConfig;
    isAppListeningToStream = false;
    isSoundNotificationOn = false;
    streamQueue: StreamState = {
        streamEvents: [],
    };
    streamEvents: StreamDataModel[] = [];
    pages: { [page: string]: PageModelPreference };
    backgroundImages: Array<string> = [];
    title = 'internet-connection-check';
    establishmentPlaceID = '';
    isRefreshingData = false; // used to identify refresh events from init event in app display grid RK
    appDetails: ApplicationDetails;
    establishmentName = '';
    formattedCountryList = [];
    formattedApplicationStatusList = [];
    appsSelectedTab = '';
    $online: Observable<boolean>;
    healthAttachmentsObj = {};
    taPrivateVersion: string;
    watchListCountries: any;
    whiteListCountries: any;
    yellowFeverCountry: any;
    countryCustoms: any;
    countryVisaExemption: any;
    countryVisaOnArrival: any;
    countryPox: any;
    countryEbola: any;
    countryPolio: any;
    afterLoginRoute: string;
    audio: HTMLAudioElement;
    mediumRiskCountry: any;
    lowRiskCountries: any;
    didAppGetData = false;
    establishments: any;
    themeColorMode = '';
    accessToken: string;
    permitCountry: any;
    pcrBase64Files = {};
    email = '';
    isConnected = true;
    cfVersion: string;
    status = 'ONLINE'; //initializing as online by default
    userRole: string;
    signInMethod: string;
    languages: any;
    userTags = [];
    topCountries = [];
    currentLocation: { lat: number; long: number };
    occupiedUserFileTypes: Array<string> = [];
    batchUploadImage;
    cmsNodes: Array<string> = [];

    appConfig = AppConfig;
    expiredToken = ''; //value that will hold the expired token
    isOffline = false;
    gotContentsStatus = '';
    taRefNavigatedFrom = '';
    showAppNavigatedFrom = false;
    formattedAirports = [];
    reflectingChanges = false;
    baseUrl: string;
    publicUrl: string;
    limitFlightsView = false;
    emailNotification = emailNotificationTypes;
    formattedCurrenciesObj = {};
    cmsSelectedTab = '';
    currentRefnb = '';
    currentApps = [];
    airlineCodes = [];
    siteNameParams = {
        title: '',
        params: {},
        footer: '',
    };
    appFlavor = this.appConfig['pageNode'];
    selectedTemplateId = '';
    ipOrFqdn = '';
    isFormValueChanged = false;
    enrollmentTypes = [];
    defaultEnrollmentType = '';
    selectedTabType = '';
    userTagsOptions = [];
    redeemableOptions = [];
    flightApplicationsData: any;
    mappedEnrollmentTypes: Array<{
        key: string;
        label: string;
        color: string;
    }> = [];
    cardApplicationsData: any;
    systemDocuments: any;

    constructor(
        public electronService: ElectronService,
        public translate: TranslateService,
        private titleService: Title,
        public store: Store<any>,
        private http: HttpClient,
        public dialog: MatDialog,
        private router: Router,
        public eventHub: EventHubService,
        public snackBar: MatSnackBar,
        public timeConversionService: TimeConversionService
    ) {
        this.setSiteName();
        const dataBusState$ = store.select(selectDataBus);
        const userState$ = store.select(user);

        translate.onLangChange.subscribe(() => {
            const splitUrl = location.hash.split('/');
            const routeName =
                splitUrl[splitUrl.length - 1] === 'edit' ||
                splitUrl[splitUrl.length - 1] === 'new'
                    ? splitUrl[splitUrl.length - 2]
                    : splitUrl[splitUrl.length - 1];

            titleService.setTitle(this.getPageTitle(routeName));
        });

        userState$.subscribe((userItem) => {
            this.userRole = userItem.role.toLowerCase();
            this.accessToken = userItem.accessToken;
            this.signInMethod = userItem.provider;
            this.permitCountry = userItem.permitCountry;
            this.email = userItem.username;
            this.establishmentPlaceID = userItem.establishmentPlaceID || '';
            this.establishmentName = userItem.establishmentName || '';
            this.userTags = userItem.userTags || [];
            if (
                this.userTags.length > 0 &&
                !this.userTags.includes('TA') &&
                this.userTags.includes('BORDER_CONTROL')
            ) {
                this.limitFlightsView = true;
            } //when user tags are updated in the users page, update the limitFlightsViews if it was previously true CA
            else {
                this.limitFlightsView = false;
            }
            if (userItem.preferences?.settings) {
                this.isAppListeningToStream =
                    !!userItem.preferences.settings.isAppListeningToStream;
                this.isSoundNotificationOn =
                    userItem.preferences.settings.isSoundNotificationOn;
            }

            this.baseUrl = `${this.appConfig['localTravEndpoint']}`.replace(
                '${this.permitCountry}',
                userItem.permitCountry
            );

            this.publicUrl =
                `${this.appConfig['trav_public_endpoint']}`.replace(
                    '${this.permitCountry}',
                    userItem.permitCountry
                );
        });

        dataBusState$.subscribe((dataBusItem) => {
            //enrollmentTypes api is fired after subscription, so we always need userTagsOptions to have its initial value CA
            this.userTagsOptions = dropdownOptionsConfig.slice();
            dataBusItem.enrollmentTypes?.forEach((element) => {
                if (
                    element.key !== 'TACITIZEN' &&
                    element.key !== 'TAMTCITIZEN'
                ) {
                    this.userTagsOptions.push({
                        key: element.key,
                        label: element.label,
                        group: 'dropdown_group.sections',
                    });
                }
            });
            this.topCountries = dataBusItem.appConfig.popularCountries;
            this.watchListCountries = dataBusItem.cms_data.countryWatchList;
            this.whiteListCountries = dataBusItem.cms_data.countryWhiteList;
            this.lowRiskCountries = dataBusItem.cms_data.countryLowRisk;
            this.mediumRiskCountry = dataBusItem.cms_data.countryMediumRisk;
            this.yellowFeverCountry = dataBusItem.cms_data.countryYellowFever;
            this.countryPox = dataBusItem.cms_data.countryPOX;
            this.countryEbola = dataBusItem.cms_data.countryEbola;
            this.countryPolio = dataBusItem.cms_data.countryPolio;
            this.countryCustoms = dataBusItem.cms_data.countryCustoms;
            this.countryVisaExemption =
                dataBusItem.cms_data.countryVisaExemption;
            this.countryVisaOnArrival =
                dataBusItem.cms_data.countryVisaOnArrival;
            this.languages = dataBusItem.cms_data.languages;

            this.establishments = dataBusItem.cms_data.establishments;
            this.cfVersion = dataBusItem.version || '';
            this.taPrivateVersion = dataBusItem.taPrivateVersion || '';

            if (dataBusItem.appConfig.supportingDocuments) {
                dataBusItem.appConfig.supportingDocuments.forEach((node) => {
                    this.healthAttachmentsObj[node.fileType] = { ...node };
                });
            }

            if (dataBusItem.cms_data.supportingDocuments?.length > 0) {
                this.occupiedUserFileTypes =
                    dataBusItem.cms_data.supportingDocuments.map(
                        (document) => document.fileType
                    );
            }

            this.appDetails = dataBusItem.appDetails;
            if (this.topCountries && dataBusItem.appConfig.version) {
                this.setFormattedCountryList();
            }
            this.setFormattedCurrencies();

            this.cmsNodes = Object.keys(dataBusItem.cms_data).filter((node) => {
                return (
                    node !== 'users' &&
                    node !== 'establishmentUsers' &&
                    node !== 'healthCredentials'
                );
            }); //holds value for cms nodes used in tableService RK

            this.airlineCodes = dataBusItem.cms_data.airlines
                ?.slice()
                .map((airline) => {
                    //add the key label properties used by ng select RK
                    const formattedAirline = {
                        ...airline,
                        ...{
                            label: airline.code,
                            key: airline.code,
                        },
                    };
                    //remove duplicate code RK
                    delete formattedAirline.code;
                    return formattedAirline;
                });
            this.enrollmentTypes = dataBusItem.enrollmentTypes?.slice() || [];
            this.defaultEnrollmentType =
                dataBusItem.cms_data.defaultEnrollmentType;
        });

        this.$online = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(map(() => true)),
            fromEvent(window, 'offline').pipe(map(() => false))
        );
        this.onlineSubscription();

        if (router.events) {
            router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    if (
                        ((!this.accessToken &&
                            event.url.includes('submitterIP')) ||
                            (!this.accessToken &&
                                event.url.includes('appVersion')) ||
                            (!this.accessToken &&
                                event.url.includes('taBarcode')) ||
                            (!this.accessToken &&
                                event.url.includes('channel')) ||
                            (!this.accessToken &&
                                event.url.includes('ferries')) ||
                            (!this.accessToken &&
                                event.url.includes('flight')) ||
                            (!this.accessToken &&
                                event.url.includes('document')) ||
                            (!this.accessToken &&
                                event.url.includes('fingerPrint'))) &&
                        !this.appConfig['pageNode'].includes('hc')
                    ) {
                        this.afterLoginRoute = event.url.substring(1);
                    }
                }
            });
        }

        this.setFormattedApplicationStatusList();
        if (this.appConfig['randomizeLoginBackground']) {
            this.backgroundImages = imagesArray.slice();
        }
        this.setFormattedCountryList();
        this.setFormattedAirportsList();

        this.store.select(selectStream).subscribe((stream) => {
            this.streamEvents = stream?.streamEvents || [];
        });

        setInterval(() => {
            if (this.streamQueue.streamEvents.length) {
                this.store.dispatch(updateStreamEvents(this.streamQueue));
            }

            this.streamQueue.streamEvents = [];
        }, this.appConfig['updateStreamInterval']);
    }
    onlineSubscription(): void {
        this.$online.subscribe((res) => {
            if (this.isOffline && res && this.accessToken) {
                //if the user was offline and then they went online, get cms contents RK
                this.getContents();
            }
            this.isOffline = !res;
            if (this.isOffline) {
                this.dialog.openDialogs?.forEach(
                    (dialogRef: { [key: string]: any }) => {
                        //in offline mode, close all dialogs that are not allowed to be opened rk
                        if (
                            (dialogRef.id === 'applicationDetailsModal' &&
                                !this.appConfig['supportOfflineMode']) ||
                            dialogRef.id === 'connectIPModal' ||
                            (dialogRef.id === 'applicationActionModal' &&
                                !dialogRef.componentInstance.data.modelConfig
                                    .hideInOffline) ||
                            (dialogRef.id === 'InfoModal' &&
                                dialogRef.componentInstance.data.view ===
                                    'magicToken')
                        ) {
                            return;
                        } else {
                            dialogRef.close();
                        }
                    }
                );
            }
        });
    }
    setIsReflectingChanges(): void {
        this.reflectingChanges = true;
        setTimeout(() => {
            this.reflectingChanges = false;
        }, 300);
    }

    isValidFormat(formatType: string, charStr: string): boolean {
        return validationPatterns[formatType]
            ? validationPatterns[formatType].test(charStr)
            : false;
    }

    setFormattedCurrencies(): void {
        this.currencies.forEach((currency) => {
            this.formattedCurrenciesObj[currency.cc] = currency;
        });
    }

    setFormattedApplicationStatusList(): void {
        const formattedStatuses = [];
        this.applicationStatuses?.forEach((element) => {
            formattedStatuses.push({
                label: 'applications.' + element.status,
                key: element.status.toUpperCase(),
                status: 'applications.' + element.status,
                flag: element.flag,
                flagCode: element.flagCode,
                taStatus: element.taStatus || element.flag?.toUpperCase(),
            });
        });
        this.sortArrayOfObjects(formattedStatuses, 'label');
        this.formattedApplicationStatusList = formattedStatuses.slice();
    }

    setFormattedCountryList(): void {
        const routerSplit = this.router.routerState.snapshot.url.split('/');
        const isFavoriteCountriesPage =
            routerSplit.includes('favoriteCountries');
        const formattedCodes = [];
        const topCountriesArray = [];
        if (this.countryCodes) {
            this.countryCodes.forEach((element) => {
                if (
                    this.topCountries &&
                    this.topCountries.includes(element.ISO3166_1_Alpha_3) &&
                    !isFavoriteCountriesPage
                ) {
                    topCountriesArray.push({
                        label: element.ISO3166_1_Alpha_3,
                        key: element.ISO3166_1_Alpha_3,
                        text: `${element.Country_Name}`,
                        flagCode: element.ISO3166_1_Alpha_2.toLowerCase(),
                        dialNumber: element.Dial,
                        displayNumber: element.Display,
                        group: 'dropdown_group.popular_countries',
                    });
                } else {
                    formattedCodes.push({
                        label: element.ISO3166_1_Alpha_3,
                        key: element.ISO3166_1_Alpha_3,
                        text: `${element.Country_Name}`,
                        flagCode: element.ISO3166_1_Alpha_2.toLowerCase(),
                        dialNumber: element.Dial,
                        displayNumber: element.Display,
                        group: 'dropdown_group.all_countries',
                    });
                }
            });
        }
        this.sortArrayOfObjects(formattedCodes, 'text');
        this.formattedCountryList = formattedCodes.slice();
        this.formattedCountryList = topCountriesArray.concat(
            this.formattedCountryList
        );
    }

    setFormattedAirportsList(): void {
        const formattedAirports = [];
        this.airports.forEach((airport) => {
            formattedAirports.push({
                key: new AirportModel(airport),
                label: `${airport.code} - ${airport.name}, ${airport.city}, ${airport.country}`,
            });
        });
        this.formattedAirports = formattedAirports.slice();
    }

    getCountryNameByCode(code: string = '', isAlpha2 = false): string {
        let countryObj: CountryModel;

        if (code) {
            countryObj = this.countryCodes.find(
                (node) =>
                    node[
                        !isAlpha2 ? 'ISO3166_1_Alpha_3' : 'ISO3166_1_Alpha_2'
                    ]?.toLowerCase() === code.toLocaleLowerCase()
            );
        }

        return countryObj?.Country_Name || '';
    }
    getCountryFlag(name: string): string {
        let countryObj: CountryModel;

        if (name) {
            countryObj = this.countryCodes.find(
                (node) =>
                    node['ISO3166_1_Alpha_3']?.toLowerCase() ===
                    name.toLocaleLowerCase()
            );
            if (!countryObj) {
                countryObj = this.countryCodes.find(
                    (node) =>
                        node['Country_Name']?.toLowerCase() ===
                        name.toLocaleLowerCase()
                );
            }
        }
        return countryObj ? countryObj.ISO3166_1_Alpha_2 : '';
    }

    getAge(dob: string): number {
        const diff_ms = Date.now() - new Date(dob).getTime();
        const result = Math.abs(new Date(diff_ms).getUTCFullYear() - 1970);

        if (isNaN(result)) {
            return null;
        } else {
            return result;
        }
    }

    shouldClassShow(status: string): Badge {
        const badges = new Badge({});

        switch (status) {
            case 'INVALID':
                badges.invalidBadge = true;
                return badges;
            case 'VALID':
                badges.validBadge = true;
                return badges;
            default:
                badges.processingBadge = true;
                return badges;
        }
    }

    getMenuitem(): Array<menuItem> {
        const menuItems = [];
        const _m = cloneDeep([...this.menuConfig]);
        const userSectionTags = this.userTags.filter(
            (tag) =>
                (!tag.includes('AGENT_') &&
                    !dropdownOptionsConfig.find(
                        (element) => element.key === tag
                    )) ||
                tag === 'AGENT_SUPERVISOR'
        );
        for (const config of _m) {
            if (config.hideInOffline && this.isOffline) {
                continue;
            }
            if (
                this.establishmentName.toLowerCase().includes('lab') &&
                config.state === 'testUploader'
            ) {
                menuItems.push(config);
                continue;
            }
            if (
                !config.viewTags ||
                config.viewTags.length === 0 ||
                (userSectionTags?.length === 0 &&
                    config.viewTags.includes('TA')) ||
                (this.userTags.includes('BORDER_CONTROL') &&
                    config.viewTags.includes('BORDER_CONTROL')) ||
                (userSectionTags.length > 0 &&
                    this.checkCommonItemsInArray(
                        userSectionTags,
                        config.viewTags
                    ))
            ) {
                if (
                    !config.children &&
                    (config.viewRoles.includes(this.userRole) ||
                        (config.forceTags?.length &&
                            this.checkCommonItemsInArray(
                                config.forceTags,
                                userSectionTags
                            )))
                ) {
                    menuItems.push(config);
                } else if (config.children && config.children.length > 0) {
                    const children = [];
                    const itemChildren =
                        config.section === 'cms' ||
                        config.section === 'appConfig'
                            ? config.children.sort((child1, child2) => {
                                  const nameA = this.translate
                                      .instant(child1.name)
                                      .toUpperCase(); // ignore upper and lowercase
                                  const nameB = this.translate
                                      .instant(child2.name)
                                      .toUpperCase(); // ignore upper and lowercase

                                  if (nameA < nameB) {
                                      return -1;
                                  }
                                  if (nameA > nameB) {
                                      return 1;
                                  }

                                  // names must be equal
                                  return 0;
                              })
                            : config.children;
                    itemChildren.forEach((_child, index) => {
                        //if last child
                        if (index === itemChildren.length - 1) {
                            itemChildren[index].hasSeparator = true;
                            itemChildren[index].hasChildSeparator = false;
                        } else {
                            itemChildren[index].hasSeparator = false;
                            itemChildren[index].hasChildSeparator = true;
                        }
                    });
                    itemChildren.forEach((element) => {
                        if (config.section !== 'inProcess') {
                            if (
                                ((element.viewRoles.includes(this.userRole) &&
                                    !this.isOffline) ||
                                    (this.isOffline &&
                                        !element.hideInOffline) ||
                                    (element.forceTags?.length &&
                                        this.checkCommonItemsInArray(
                                            element.forceTags,
                                            userSectionTags
                                        ))) &&
                                !this.appConfig['hidePages'].includes(
                                    element.state
                                )
                            ) {
                                children.push(element);
                            }
                        } else {
                            if (
                                !element.viewTags ||
                                element.viewTags.length === 0 ||
                                (userSectionTags?.length === 0 &&
                                    element.viewTags.includes('TA')) ||
                                (this.userTags.includes('BORDER_CONTROL') &&
                                    element.viewTags.includes(
                                        'BORDER_CONTROL'
                                    )) ||
                                (userSectionTags.length > 0 &&
                                    this.checkCommonItemsInArray(
                                        userSectionTags,
                                        element.viewTags
                                    ))
                            ) {
                                if (
                                    (element.viewRoles.includes(
                                        this.userRole
                                    ) ||
                                        (element.forceTags?.length &&
                                            this.checkCommonItemsInArray(
                                                element.forceTags,
                                                userSectionTags
                                            ))) &&
                                    !this.appConfig['hidePages'].includes(
                                        element.state
                                    )
                                ) {
                                    children.push(element);
                                }
                            }
                        }
                    });

                    if (
                        (config.section === 'logs' ||
                            config.section === 'inProcess') &&
                        children.length === 1
                    ) {
                        menuItems.push({
                            ...children[0],
                            ...{ hasSeparator: true },
                        });
                    } else if (children.length > 0) {
                        menuItems.push({ ...config, children });
                    }
                }
            }
        }

        return menuItems;
    }
    setDocumentTitles(attachment: AttachmentModel): {} {
        if (attachment.fileType) {
            let fileTitles = {};
            if (this.healthAttachmentsObj[attachment.fileType]) {
                fileTitles =
                    this.healthAttachmentsObj[attachment.fileType]
                        .document_title;
                return fileTitles;
            } else {
                this.languages.forEach((languageNode) => {
                    let langTitle = '';
                    if (
                        this.hasTranslationKey(
                            `file_types.${attachment.fileType}`
                        )
                    ) {
                        langTitle = `file_types.${attachment.fileType}`;
                    } else {
                        let filename = '';
                        if (attachment.fileName) {
                            filename = ` - ${attachment.fileName}`;
                        }
                        langTitle = `${attachment.fileType}${filename}`;
                    }
                    fileTitles[languageNode.languageCode] = langTitle;
                });
                return fileTitles;
            }
        }
        return null;
    }

    prepareAttachmentFiles(
        attachments: Array<AttachmentModel> = [],
        deletedAttachments: Array<AttachmentModel> = []
    ): documentsActionCardModel {
        const attachmentTypes = {
            user: [],
            system: [],
            deleted: [],
        };

        const filteredAttachmentsArray = [];
        attachments.forEach((node: AttachmentModel) => {
            //Step 1 : Exclude SELFIE and PROFILEPIC from attachments:hg
            if (node.fileType !== 'PROFILEPIC' && node.fileType !== 'SELFIE') {
                filteredAttachmentsArray.push(node);
            }
        });
        //add a deleted flag to distinguish deleted attachments RK
        deletedAttachments.forEach((attachment) => {
            filteredAttachmentsArray.push({
                ...attachment,
                attachmentSource: 'deleted',
            });
        });

        /*Step 2 : sort the array of images before separating
      them into user and system categories RK */
        filteredAttachmentsArray.sort((a, b) => b.createdMs - a.createdMs);

        //Step 3 : Prepare and map the dataset
        filteredAttachmentsArray.forEach((node: AttachmentModel) => {
            if (!node.fileType) {
                return;
            } // if no fileType return:hg

            const sourceType = node.attachmentSource?.toLowerCase(); //sets what type of attachment source:hg

            //title should be replaced by required document in the CMS in case exists:hg
            let fileTitles = {};
            if (this.healthAttachmentsObj[node.fileType]) {
                fileTitles =
                    this.healthAttachmentsObj[node.fileType].document_title;
            } else {
                //otherwise, the title should be set either from the translations or using the document's info RK
                this.languages.forEach((languageNode) => {
                    let langTitle = '';
                    if (this.hasTranslationKey(`file_types.${node.fileType}`)) {
                        langTitle = `file_types.${node.fileType}`;
                    } else {
                        let filename = '';
                        if (node.fileName) {
                            filename = ` - ${node.fileName}`;
                        }
                        langTitle = `${node.fileType}${filename}`;
                    }
                    fileTitles[languageNode.languageCode] = langTitle;
                });
                if (!Object.keys(fileTitles).length) {
                    if (this.hasTranslationKey(`file_types.${node.fileType}`)) {
                        fileTitles[
                            this.translate.currentLang
                        ] = `file_types.${node.fileType}`;
                    } else {
                        let filename = '';
                        if (node.fileName) {
                            filename = ` - ${node.fileName}`;
                        }
                        fileTitles[
                            this.translate.currentLang
                        ] = `${node.fileType}${filename}`;
                    }
                }
            }

            attachmentTypes[sourceType].push(
                new documentHandlerModel({
                    title: fileTitles,
                    URL: node.OriginalFileUrl || node.URL,
                    id: node.id,
                    attachmentSource: node.attachmentSource,
                    fileType: node.fileType,
                    mimeType: node.mimeType || node.contentType,
                    isMachineVerified:
                        node.Validation?.type === 'MACHINE_REVIEW',
                    validation: node.Validation ? node.Validation : {},
                    createdMs: node.createdMs,
                })
            );
        });

        return this.getHealthAttachments(attachmentTypes);
    }

    getVisitedCountries(
        healthInfoAnswers: Array<any> = []
    ): Array<VisitedCountries> {
        const visitedCountries = [];

        const res = healthInfoAnswers.find(
            (node) => node.questionKey?.toUpperCase() === 'VISITEDCOUNTRIES'
        );

        const countries = res && res.answer ? res.answer.split(',') : [];

        countries.forEach((node) => {
            this.countryCodes.find((countryNode) => {
                if (countryNode.ISO3166_1_Alpha_3 === node) {
                    visitedCountries.push({
                        countryName: countryNode.Country_Name,
                        countryCode: countryNode.ISO3166_1_Alpha_3,
                        inWatchList: false,
                        inWhiteList: false,
                        inLowRisk: false,
                        inMediumRisk: false,
                        inYellowFever: false,
                        inPox: false,
                        inEbola: false,
                        inPolio: false,
                        inCountryCustoms: false,
                        inCountryVisaExemption: false,
                        inCountryVisaOnArrival: false,
                    });
                }
            });
        });

        const countriesCategory = [
            'watchListCountries',
            'yellowFeverCountry',
            'mediumRiskCountry',
            'lowRiskCountries',
            'whiteListCountries',
            'countryPox',
            'countryEbola',
            'countryPolio',
            'countryCustoms',
            'countryVisaExemption',
            'countryVisaOnArrival',
        ];

        countriesCategory.forEach((category) => {
            this[category].forEach((element) => {
                //handled case of duplicate visited countries CA
                visitedCountries.forEach((code, index) => {
                    if (code.countryCode === element) {
                        visitedCountries[index].inWatchList = visitedCountries[
                            index
                        ].inWatchList
                            ? true
                            : category === 'watchListCountries';
                        visitedCountries[index].inWhiteList = visitedCountries[
                            index
                        ].inWhiteList
                            ? true
                            : category === 'whiteListCountries';
                        visitedCountries[index].inLowRisk = visitedCountries[
                            index
                        ].inLowRisk
                            ? true
                            : category === 'lowRiskCountries';
                        visitedCountries[index].inMediumRisk = visitedCountries[
                            index
                        ].inMediumRisk
                            ? true
                            : category === 'mediumRiskCountry';
                        visitedCountries[index].inYellowFever =
                            visitedCountries[index].inYellowFever
                                ? true
                                : category === 'yellowFeverCountry';

                        visitedCountries[index].inPox = visitedCountries[index]
                            .inPox
                            ? true
                            : category === 'countryPox';

                        visitedCountries[index].inEbola = visitedCountries[
                            index
                        ].inEbola
                            ? true
                            : category === 'countryEbola';
                        visitedCountries[index].inPolio = visitedCountries[
                            index
                        ].inPolio
                            ? true
                            : category === 'countryPolio';
                        visitedCountries[index].inCountryCustoms =
                            visitedCountries[index].inCountryCustoms
                                ? true
                                : category === 'countryCustoms';
                        visitedCountries[index].inCountryVisaExemption =
                            visitedCountries[index].inCountryVisaExemption
                                ? true
                                : category === 'countryVisaExemption';
                        visitedCountries[index].inCountryVisaOnArrival =
                            visitedCountries[index].inCountryVisaOnArrival
                                ? true
                                : category === 'countryVisaOnArrival';
                    }
                });
            });
        });
        return visitedCountries;
    }
    vaccineAnswers(appData: AppDataFlaggedAnswers): Array<string> {
        const answers = appData.health_info?.answers
            ? appData.health_info?.answers
            : [];
        let splittedAnswer = [];
        const vaccineAnswer = answers.find((node) => {
            return node.type === 'yes-no-vaccine';
        });
        if (vaccineAnswer?.answer) {
            const answer = vaccineAnswer.answer;
            splittedAnswer = answer.split(',');
            if (
                splittedAnswer[0]?.toLowerCase() === 'yes' &&
                splittedAnswer.length > 1
            ) {
                splittedAnswer.splice(0, 1);
            }
        }
        return splittedAnswer;
    }

    flaggedAnswers(appData: AppDataFlaggedAnswers): Array<string> {
        const flaggedAnswers = new AppDataFlaggedAnswers(appData);

        const insuranceInfoAnswers = flaggedAnswers.insuranceInfo.answers;
        const healthInfoAnswers = flaggedAnswers.healthInfo.answers;
        const customInfoAnswers = flaggedAnswers.customInfo.answers;
        const outboundCustomsAnswers =
            flaggedAnswers.outboundCustoms_info.answers;
        const enrollmentAnswers = flaggedAnswers.enrollment_info.answers;
        const questionArray = [];
        let answers = [];

        if (healthInfoAnswers && healthInfoAnswers.length > 0) {
            answers = answers.concat(healthInfoAnswers);
        }
        if (customInfoAnswers && customInfoAnswers.length > 0) {
            answers = answers.concat(customInfoAnswers);
        }
        if (outboundCustomsAnswers && outboundCustomsAnswers.length > 0) {
            answers = answers.concat(outboundCustomsAnswers);
        }
        if (enrollmentAnswers && enrollmentAnswers.length > 0) {
            answers = answers.concat(enrollmentAnswers);
        }

        answers.forEach((node) => {
            if (
                node?.type === 'yes-no' &&
                node.answer &&
                node.answer.toLowerCase() === 'yes'
            ) {
                questionArray.push(node.questionKey);
            } else if (node?.type === 'yes-no-currency' && node.answer) {
                const currencyInfo = node.answer.split(',');

                if (currencyInfo && currencyInfo.length > 2) {
                    questionArray.push(
                        `${node.questionKey} ${currencyInfo[2]} ${currencyInfo[1]}`
                    );
                }
            } else if (
                node?.type === 'yes-no-currency-plus-breakdown' &&
                node.answer
            ) {
                const currencyInfo = node.answer.split(',');
                currencyInfo.shift(); //remove the Yes from the answer
                if (currencyInfo && currencyInfo.length >= 3) {
                    for (let i = 0; i < currencyInfo.length - 2; i = i + 3) {
                        questionArray.push(
                            `${node.questionKey} ${currencyInfo[i + 1]} ${
                                currencyInfo[i]
                            } ${currencyInfo[i + 2]}`
                        );
                    }
                }
            }
        });

        if (insuranceInfoAnswers && insuranceInfoAnswers.length > 0) {
            insuranceInfoAnswers.forEach((node) => {
                if (
                    node?.type === 'yes-no' &&
                    node.answer?.toLowerCase() === 'no'
                ) {
                    questionArray.push(node.questionKey);
                }
            });
        }

        if (questionArray.length === 0) {
            questionArray.push('NONE');
        }

        return questionArray;
    }

    getStatus(appData: AppDataFlaggedAnswers, statusKey: string): string {
        const answers = new AppDataFlaggedAnswers(appData);
        let status = {
            answer: '',
        };
        Object.keys(answers).forEach((element) => {
            const value = answers[element]?.answers?.find(
                (answer) =>
                    answer.questionKey?.toUpperCase() ===
                    statusKey.toUpperCase()
            );
            if (value) {
                status = value;
            }
        });
        return status?.answer || '';
    }

    showCopyMessage(tooltip: MatTooltip): void {
        tooltip.show();
        setTimeout(() => {
            tooltip.hide();
        }, 3000);
    }
    getApplicationStatus(
        passportInfo: { status: string; flagCode: string } = {
            status: '',
            flagCode: '',
        }
    ): string {
        let applicationStatus = '';

        if (passportInfo.status || passportInfo.flagCode) {
            if (
                (passportInfo.flagCode &&
                    passportInfo.status !== 'PROCESSING' &&
                    passportInfo.status !== 'INVALID') ||
                passportInfo.flagCode === 'IN_REVIEW'
            ) {
                applicationStatus = this.hasTranslationKey(
                    `applications.${passportInfo.flagCode.toLowerCase()}`
                )
                    ? `applications.${passportInfo.flagCode.toLowerCase()}`
                    : passportInfo.flagCode;
            } else {
                applicationStatus = this.hasTranslationKey(
                    `applications.${passportInfo.status.toLowerCase()}`
                )
                    ? `applications.${passportInfo.status.toLowerCase()}`
                    : passportInfo.status;
            }
        }

        return applicationStatus;
    } //todo remove?

    copyMessageToClipboard(message: string | number = ''): void {
        const listener = (e: ClipboardEvent) => {
            e.clipboardData.setData(
                'text/plain',
                message ? message.toString().trim() : ''
            );
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        navigator.clipboard.writeText(message.toString());
        document.removeEventListener('copy', listener);
    }

    checkCurrentConnection(): boolean {
        if (!navigator.onLine) {
            return false;
        } else {
            return true;
        }
    }

    compareData(
        firstArray: Array<string> | {}[] | {} = [],
        secondArray: Array<string> | {}[] | {} = []
    ): boolean {
        return isEqual(firstArray, secondArray);
    }

    getPageTitle(path: string = ''): string {
        let siteName = '';
        const appFlavor = this.appConfig['pageNode'];

        if (this.appConfig['flavor'] === 'VMP') {
            siteName = this.translate.instant(`main.${appFlavor}`);
        } else {
            siteName = this.translate.instant('main.site_name');
        }

        //condition to find non existing path => returns the key instead of a translation: RK
        if (!this.hasTranslationKey(`page_title.${path}`)) {
            return `${siteName}`;
        }
        const key = `page_title.${path}`;
        return `${this.translate.instant(key)} - ${siteName}`;
    }

    getPreCheckLogs(notes: Note[] = []): Note[] {
        const formattedLogs = [];

        notes.forEach(({ level, note, who, dateTime } = new Note({})) => {
            formattedLogs.push(
                new Note({
                    level,
                    note,
                    who,
                    dateTime,
                })
            );
        });

        return formattedLogs;
    }

    getPreCheckInfo(
        preCheckData: Record<string, any> = {}
    ): Array<PreCheckInfo> {
        const preCheckInfoResult = [];

        for (const key in preCheckData) {
            if (preCheckData[key].Result) {
                const label = `tabs.label_precheck_${key?.toLocaleLowerCase()}`;
                const pillLabel = this.hasTranslationKey(label) ? label : key;
                preCheckInfoResult.push(
                    new PreCheckInfo({
                        label: pillLabel,
                        score: preCheckData[key].Score,
                        result: preCheckData[key].Result,
                    })
                );
            }
        }

        return preCheckInfoResult.slice();
    }

    //gets profile image url
    getProfileUrl(
        attachments: {}[] = [],
        records: {}[] = [],
        appReference: string = ''
    ): ProfilePicInfo {
        let profilePicInfo;
        const selfies = this.getAttachmentInfoByType(attachments, 'SELFIE');
        if (selfies && selfies.totalAttachments !== 0) {
            const selfie = selfies.attachments[0];
            profilePicInfo = new ProfilePicInfo({
                url: this.isOffline
                    ? `https://${this.ipOrFqdn}/api/selfie/${appReference}`
                    : selfie.previewUrl,
                totalSelfies: selfies.totalAttachments,
                type: 'SELFIE',
                matchPercentage: selfie.matchPercentage
                    ? parseInt(selfie.matchPercentage.split('%')[0], 10)
                    : 0,
                totalMatchPercentage: this.getTotalMatchPercentage(records),
            });
        } else {
            profilePicInfo = new ProfilePicInfo({
                url: this.isOffline
                    ? `https://${this.ipOrFqdn}/api/selfie/${appReference}`
                    : `${this.baseUrl}taapi/file/x/${appReference}/SELFIE`,
                type: 'PERSONURL',
            });
        }

        return profilePicInfo;
    }

    //flattens an object by one level rk
    //used in getAppDetails function rk
    normalizeObj(obj: Record<string, any> = {}): Record<string, any> {
        const normalizedObj = cloneDeep(obj);
        if (obj) {
            Object.entries(obj).forEach(([key, value]) => {
                //checks if the main object has sub-objects rk
                if (
                    typeof value === 'object' &&
                    Object.keys(value).length > 0
                ) {
                    Object.keys(value).forEach((subKey) => {
                        //if the main object does not have the specified key
                        //add it to the root level with its value rk
                        if (!normalizedObj[subKey]) {
                            normalizedObj[subKey] = value[subKey];
                        }
                    });
                    //delete the sub object rk
                    delete normalizedObj[key];
                }
            });
        }
        return normalizedObj;
    }

    getGender(gender: string = ''): string {
        if (gender) {
            switch (gender.toLocaleLowerCase()) {
                case 'm':
                    return 'profile_labels.male';
                case 'male':
                    return 'profile_labels.male';
                case 'f':
                    return 'profile_labels.female';
                case 'female':
                    return 'profile_labels.female';
                default:
                    return 'profile_labels.unspecified';
            }
        }
        return 'profile_labels.unspecified';
    }

    getAddressInCountry(
        tripInfo: CountryAddress = new CountryAddress({})
    ): Array<{}> {
        const formattedAddress = [];

        const { residenceInCountry = [], addressInCountry = {} } = tripInfo;

        if (residenceInCountry?.length > 0) {
            residenceInCountry.forEach((element) => {
                if (element && element['address_line_1']) {
                    formattedAddress.push(element);
                }
            });
        } else if (Object.keys(addressInCountry).length > 0) {
            let addressStr = '';
            addressStr += addressInCountry.main_text;

            if (addressInCountry.secondary_text) {
                addressStr += `, ${addressInCountry.secondary_text}`;
            }

            formattedAddress.push({ address_line_1: addressStr });
        }

        return formattedAddress;
    }

    hasTranslationKey(key: string): boolean {
        const translation = this.translate.instant(key);
        return translation !== key && translation !== '';
    }

    getSearchParams(
        status: string = '',
        flag: string = '',
        customNode: string = ''
    ): DispatchParams {
        const dispatchParams = new DispatchParams({ status });
        if (customNode) {
            dispatchParams.storeNode = customNode;
        } else if (status === 'PROCESSING') {
            dispatchParams.storeNode =
                flag && (flag === 'IN_REVIEW' || flag === 'NOTE')
                    ? 'inReview'
                    : 'pending';
        } else if (
            status === 'VALID' &&
            (!flag || flag === 'APPROVE' || flag === 'CLEAR_FLAG')
        ) {
            dispatchParams.storeNode = 'active';
        } else if (status === 'INVALID') {
            dispatchParams.storeNode = 'rejected';
        } else if (status === 'EMBCARD_SUBMITTED') {
            dispatchParams.storeNode = 'toReview';
        } else if (status === 'INCOMPLETE') {
            dispatchParams.storeNode = status.toLowerCase();
        } else {
            dispatchParams.storeNode = flag?.toLowerCase();
            dispatchParams.flagCode = flag;
        }

        return dispatchParams;
    }

    visitedCountriesBackgroundColor(
        countryDetails: VisitedCountriesBackgroundColor
    ): string {
        if (countryDetails.inWatchList) {
            return 'var(--opacity-black)'; //black
        } else if (countryDetails.inMediumRisk) {
            return 'var(--examine-color)'; //orange
        } else if (countryDetails.inLowRisk) {
            return 'var(--approve-with-action-color)'; //cyan
        } else if (
            countryDetails.inWhiteList ||
            countryDetails.inCountryVisaExemption ||
            countryDetails.inCountryVisaOnArrival
        ) {
            return 'var(--approve-color)'; //green
        } else {
            return 'var(--quarantine-color)'; //default red
        }
    }

    elasticSearchQueryBuilder(
        data: ElasticSearchParams,
        returnBodyOnly: boolean = false
    ): ElasticQueryBuild {
        if (!data) {
            return;
        }

        let filterChips: {};
        if (typeof data.activeFilterChips === 'object') {
            filterChips = data.activeFilterChips;
        }
        const body: ElasticQueryBuild = {
            from: data.index,
            size: 250,
            _source: [
                'userSubmittedTravelApplicationRequest.trip_info.arrivalFlightNumber',
                'userSubmittedTravelApplicationRequest.trip_info.departureFlightNumber',
                'userSubmittedTravelApplicationRequest.trip_info.arrivalCarrier',
                'userSubmittedTravelApplicationRequest.trip_info.departureCarrier',
                'userSubmittedTravelApplicationRequest.trip_info.originCountry',
                'userSubmittedTravelApplicationRequest.applicant_contact_info',
                'userSubmittedTravelApplicationRequest.trip_info.arrivalDate',
                'userSubmittedTravelApplicationRequest.trip_info.departureDate',
                'userSubmittedTravelApplicationRequest.trip_info.purposeVisit',
                'userSubmittedTravelApplicationRequest.trip_info.arrivalFrom',
                'userSubmittedTravelApplicationRequest.trip_info.departureFrom',
                'userSubmittedTravelApplicationRequest.outboundCustoms_info',
                'userSubmittedTravelApplicationRequest.trip_info.expedited',
                'userSubmittedTravelApplicationRequest.trip_info.arrivalAt',
                'userSubmittedTravelApplicationRequest.insurance_info',
                'userSubmittedTravelApplicationRequest.enrollment_info',
                'userSubmittedTravelApplicationRequest.customs_info',
                'userSubmittedTravelApplicationRequest.health_info',
                'userSubmittedTravelApplicationRequest.enrollmentType',
                'userSubmittedTravelApplicationRequest.tier',
                'userSubmittedTravelApplicationRequest.marketingOptInConsent',
                'userSubmittedTravelApplicationRequest.applicationDeclaration.selfDeclared',
                'taApproverInfo.TAValidation',
                'passportSummary.nationality',
                'passportSummary.forenames',
                'loggedCallerData.RemoteIP',
                'passportSummary.surname',
                'passportSummary.type',
                'passportSummary.number',
                'passportSummary.issuer',
                'passportSummary.dob',
                'passportSummary.sex',
                'paymentData.CardFingerprint',
                'publicUpdatedTs',
                'redeemableItems',
                'taApproverInfo',
                'enrollmentType',
                'redeemedItems',
                'taReference',
                'createdTs',
                'taStatus',
                'flagCode',
                'enrollmentReference',
                'decisionTs',
                'SubmittedTs',
                'modifiedTs',
                'autoApproveAt',
                'smartApprovedAt',
                'smartApprovedByUsername',
            ],
            sort: data.sort || [
                {
                    decisionTs: {
                        order: 'desc',
                    },
                },
            ],
            query:
                data.path && pagesConfig[data.path]?.nestedSearch
                    ? this.buildNestedQuery(
                          data.queries,
                          data.path,
                          filterChips || data.params
                      )
                    : this.buildQuery(
                          data.path,
                          data.queries as QueryType[],
                          filterChips || data.params
                      ),
        };

        if (returnBodyOnly) {
            return body;
        }

        if (
            data.isCount &&
            data.path !== 'agent_exit' &&
            data.path !== 'agent_entry'
        ) {
            delete body._source;
            delete body.from;
            delete body.size;
            delete body.sort;
            this.store.dispatch(
                getAppsCount({
                    body,
                    storeNode:
                        data.path === 'search' ? 'searchResults' : data.path,
                })
            );
        } else if (data.path === 'pending') {
            body.size = 750;
            this.store.dispatch(
                getPendingApps({
                    body,
                    index: data.index,
                })
            );
        } else {
            let moreInfo;
            if (
                data &&
                data.queries &&
                Array.isArray(data.queries) &&
                data.queries.length > 0
            ) {
                if (data.queries.length === 1) {
                    moreInfo = {
                        key: data.queries[0].key,
                        type: data.queries[0].value,
                        value: null,
                    };
                } else {
                    let value;
                    if (
                        data.queries[1] &&
                        (data.queries[1].key === 'redeemableItems.package' ||
                            data.queries[1].key === 'redeemedItems.package')
                    ) {
                        value = data.queries[1].value;
                    } else if (data.queries[2]) {
                        value = data.queries[2].value;
                    } else {
                        value = null;
                    }
                    moreInfo = {
                        key: data.queries[0].key,
                        type: data.queries[0].value,
                        //these conditions are due if we provide range date or not :SF
                        value,
                    };
                }
            } else {
                moreInfo = null;
            }
            this.store.dispatch(
                getApps({
                    storeNode:
                        data.path === 'search' ? 'searchResults' : data.path,
                    index: data.index,
                    body,
                    moreInfo,
                })
            );
        }
    }

    namedSearchQueryBuilder(
        formData: Array<{ key: string; value: string }> = []
    ): void {
        const body = {
            elasticsearch_named_search_name: formData[0]?.key
                ? formData[0]?.key
                : '',
            params: {
                arrivalStartDate: formData[1]?.value ? formData[1].value : '',
                arrivalEndDate: formData[2]?.value ? formData[2].value : '',
            },
        };
        if (formData[0]?.key === 'TAS_BY_VISITED_COUNTRY') {
            body.params['countries'] = formData[0].value;
        }

        this.store.dispatch(
            getAppsNameSearch({
                index: 0,
                storeNode: 'searchResults',
                body,
                searchNode: formData[0]?.key ? formData[0]?.key : '',
            })
        );
    }

    buildNestedQuery(
        types: NestedQueryType[],
        path?: string,
        addScope?: FilterChips
    ): ElasticQuery {
        const queryObject = {
            must: [],
            should: [],
            must_not: [],
        };
        for (const tmp of types) {
            const type = new NestedQueryType(tmp);
            let tmpObject:
                | { [key: string]: { [key: string]: string | boolean } }
                | ElasticQuery;
            if (typeof type.secondaryType === 'string') {
                tmpObject = {
                    [type.secondaryType]: {
                        [type.key]: type.value,
                    },
                };
            } else {
                tmpObject = this.buildNestedQuery(type.secondaryType);
            }
            queryObject[type.primaryType].push(tmpObject);
        }

        let queryPayload = queryObject;
        if (path && !pagesConfig[path]?.noFilters) {
            queryPayload = this.setFilterChipsParams(
                path,
                addScope,
                queryObject
            );
        }
        let shouldMatchCounter = 1;
        if (path && path === 'search' && queryPayload.should.length) {
            shouldMatchCounter = 0;
            queryPayload.should.forEach((query) => {
                if (Object.keys(query)[0] === 'bool') {
                    shouldMatchCounter += 1;
                }
            });
        }
        return new ElasticQuery({
            bool: {
                must: queryPayload.must,
                must_not: queryPayload.must_not,
                should: queryPayload.should,
                minimum_should_match: queryPayload.should.length
                    ? shouldMatchCounter || 1
                    : 0,
            },
        });
    }

    buildQuery(
        path: string,
        types: Array<QueryType> = [],
        addScope?: FilterChips
    ): ElasticQuery {
        const typesObj = {
            must: [],
            should: [],
            must_not: [],
        };

        for (const tmp of types) {
            const type = new QueryType(tmp);
            // push values to the array whose key == primaryType (must, must_not, should) RK
            typesObj[type['primaryType']].push({
                [type['secondaryType']]: {
                    [type['key']]: type['value'],
                },
            });
        } // Purpose of this is to build the query parameter for the API according to localization values for each section of the app :hg

        const queryPayload = this.setFilterChipsParams(
            path,
            addScope,
            typesObj
        );

        return new ElasticQuery({
            bool: {
                must: queryPayload.must,
                must_not: queryPayload.must_not,
                should: queryPayload.should,
                minimum_should_match: queryPayload.should.length > 0 ? 1 : 0,
            },
        });
    }

    formatPaymentInfo(
        paymentInfo: Array<PaymentInfoAPI> = []
    ): Array<PaymentInfo> {
        //takes in the payment info and sets the currency and amount rk
        const formattedInfo: Array<PaymentInfo> = [];

        paymentInfo.forEach((paymentItem) => {
            formattedInfo.push(new PaymentInfo(paymentItem));
        });
        return formattedInfo;
    }
    getNotificationTypes(status: string): Array<NotificationType> {
        const notificationTypes = [];
        const redeemableIndex: number =
            this.appDetails.purchasedItems?.findIndex(
                (item) => (item as PaymentDetails).isRedeemable
            );
        const receiptDocuments = this.systemDocuments?.filter(
            (document) => document.fileType === 'RECEIPTPDF'
        );

        this.emailNotification.forEach((emailType) => {
            switch (emailType.status) {
                case 'approved':
                case 'rejected':
                case 'acknowledgement':
                    if (
                        emailType.availableFor[0] === 'ALL' ||
                        emailType.availableFor.indexOf(status) !== -1
                    ) {
                        notificationTypes.push(emailType);
                    }
                    break;
                case 'ancillaryEmail':
                    if (
                        (emailType.availableFor[0] === 'ALL' ||
                            emailType.availableFor.indexOf(status) !== -1) &&
                        redeemableIndex > -1
                    ) {
                        notificationTypes.push(emailType);
                    }
                    break;
                default:
                    break;
            }
        });
        if (receiptDocuments?.length) {
            receiptDocuments.forEach((document) => {
                notificationTypes.push({
                    key: document.id,
                    label: document.title.en,
                    status: 'receipt',
                    availableFor: ['ALL'],
                });
            });
        }
        return notificationTypes;
    }

    sortDecisionInfo(
        info: DecisionNote[] = [],
        userCommentInfo: DecisionNote = new DecisionNote({})
    ): DecisionNote[] {
        let sortedNotes = [];

        //map the info elements to decision notes RK
        sortedNotes = info.map((a) => {
            return new DecisionNote(a);
        });

        //sort the array by date RK
        sortedNotes = sortedNotes.sort((a, b) => {
            return this.timeConversionService.getDateDifference(
                b.timestamp,
                a.timestamp
            );
        });

        if (userCommentInfo.note !== '') {
            //add the UserComment to the end of the decision info
            sortedNotes.push(new DecisionNote(userCommentInfo));
        }
        return sortedNotes;
    }

    getTypeColor(type: string): string {
        switch (type) {
            case 'EMBCARD_SUBMITTED':
            case 'PROCESSING':
            case 'APPLICANTS':
                return 'var(--processing-primary-color)';
            case 'APPROVED':
            case 'VALID':
            case 'APPROVALS':
                return 'var(--approve-color)';
            case 'INVALID':
            case 'REJECTED':
            case 'DENIALS':
                return 'var(--denied-color)';
            case 'OUTBOUND_QUESTION':
            case 'QUESTION':
                return 'var(--question-color)';
            case 'OUTBOUND_EXAMINE':
            case 'ISFLAGGED':
                return 'var(--warning-primary-color)';
            case 'EXAMINE':
                return 'var(--examine-color)';
            case 'QUARANTINE':
                return 'var(--quarantine-color)';
            case 'PENDING':
            case 'AMOUNT_DONATED':
            case 'purchased_items':
            case 'AUTOAPPROVE':
            case 'MARITIME':
                return 'var(--processing-secondary-color)';
            default:
                return 'var(--black-color)';
        }
    }

    hasAddEditAccess(routeNode: string): boolean {
        if (
            routeNode !== 'flightApplications' &&
            routeNode !== 'flightDashboard' &&
            routeNode !== 'outbound' &&
            routeNode !== 'searchResults' &&
            routeNode !== 'appDetails'
        ) {
            const item = this.menuConfig.find(
                (node) =>
                    node.state === routeNode ||
                    node.children?.find(
                        (element) => element.state === routeNode
                    )
            );
            if (item && item.children) {
                const child = item.children.find(
                    (element) => element.state === routeNode
                );

                return child
                    ? child.addEditRoles.indexOf(this.userRole) > -1
                    : true;
            } else {
                return item
                    ? item.addEditRoles.indexOf(this.userRole) > -1
                    : true;
            }
        } else {
            return true;
        }
    }

    sortArrayOfObjects(
        data: {}[] = [],
        searchField: string = '',
        subSearchField: string = ''
    ): {}[] {
        if (searchField) {
            return data.sort((a, b) => {
                let compareField1;
                let compareField2;

                if (subSearchField) {
                    compareField1 =
                        a[searchField][subSearchField].toUpperCase();
                    compareField2 =
                        b[searchField][subSearchField].toUpperCase();
                } else if (typeof a[searchField] !== 'object') {
                    //checks if searchField is not a sub-object or a sub-array RK
                    compareField1 = a[searchField].toUpperCase();
                    compareField2 = b[searchField].toUpperCase();
                }

                if (compareField1 < compareField2) {
                    return -1;
                }
                if (compareField1 > compareField2) {
                    return 1;
                }
                // names must be equal
                return 0;
            });
        }

        return data;
    }

    getPositionFromGoogleId(
        googlePlaceId: string = ''
    ): Promise<{ lat: string; lng: string; id: string }> {
        return new Promise((resolve, reject) => {
            const geo = new google.maps.Geocoder();
            geo.geocode({ placeId: googlePlaceId }, (results) => {
                if (results && results[0]) {
                    resolve({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                        id: googlePlaceId,
                    });
                } else {
                    reject(undefined);
                }
            });
        });
    }

    getCurrentLocation(): Promise<{ lat: number; long: number }> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                    });
                },
                () => {
                    reject('was not able to get location');
                }
            );
        });
    }

    shareDeepLink(path: string = ''): void {
        this.copyMessageToClipboard(
            this.electronService.isElectron
                ? `${this.appConfig['deepLinkPrefix']}://taui/page/search${path}`
                : `${window.location.origin}/taui/page/search${path}`
        );
    }
    getUserColumnDefs(
        columnDefs: ColumnDefs[] = [],
        userPreferences: UserPreferences[] = [],
        nodeName?: string,
        subNode?: string
    ): Array<ColumnDefs> | { [key: string]: any } {
        let nodeData = {};

        let userColumns: Array<any> | { [key: string]: any };
        let preferences = userPreferences;
        if (isPlainObject(columnDefs)) {
            Object.entries(columnDefs)?.forEach(([key, value]) => {
                const columnsPreferences = this.getUserColumnPreferences(
                    value && !isPlainObject(value) ? (value as Array<any>) : [],
                    userPreferences
                );
                preferences[key] = {
                    columnDefs: cloneDeep(columnsPreferences.preferences),
                };
                if (!userColumns) {
                    userColumns = {};
                }
                userColumns[key] = cloneDeep(columnsPreferences.userColumns);

                nodeData[key] = {
                    columnDefs: cloneDeep(columnsPreferences.preferences),
                };
            });
        } else {
            const columnsPreferences = this.getUserColumnPreferences(
                columnDefs,
                userPreferences
            );
            preferences = columnsPreferences.preferences;
            userColumns = columnsPreferences.userColumns?.slice();
            nodeData = { columnDefs: cloneDeep(preferences) };
        }

        if (!this.compareData(userPreferences, preferences) && nodeName) {
            this.store.dispatch(
                updatePagePreferences({
                    node: nodeName,
                    subNode,
                    data: cloneDeep(nodeData),
                })
            );
        }

        return userColumns;
    }

    getValidationInformation(
        validationType: string = '',
        validationInfo: Array<TaValidationInfo> = []
    ): TaValidationInfo {
        const targetSearch = validationInfo.filter((info) => {
            return info.type === validationType;
        });

        return targetSearch?.length > 0 ? targetSearch[0] : {};
    }

    snakeToCamelCase(label: string = ''): string {
        let camelCaseStr = '';
        let tempArray: Array<string> = [];

        if (label) {
            tempArray = label.split('_');
            tempArray = tempArray.map((item, index) => {
                return index > 0
                    ? `${item.charAt(0).toUpperCase()}${item.slice(1)}`
                    : item;
            });
            camelCaseStr = tempArray.join('');
        }
        return camelCaseStr;
    }

    getTaNodeData(
        validationType: string = '',
        validationData: TaValidationInfo[] = []
    ): Record<string, any> {
        const field = this.snakeToCamelCase(validationType);
        const fieldData = this.getValidationInformation(
            validationType,
            validationData
        );
        return field ? { [field]: new TaValidationInfo(fieldData) } : {};
    }

    setupPCRAttachments(attachments: AttachmentModel[] = []): void {
        attachments.forEach((attachment) => {
            if (
                attachment.fileType === 'C19HCERT' &&
                attachment.URL &&
                attachment.id
            ) {
                this.urlToBase64(attachment.URL, (base64Encode) => {
                    this.pcrBase64Files[attachment.id] = base64Encode;
                });
            }
        });
    }

    urlToBase64(url: string, callback: (base64Encode) => void): void {
        if (url) {
            this.http
                .get(url, {
                    responseType: 'arraybuffer',
                })
                .subscribe((res) => {
                    //transforms arrayBuffer to base64
                    const base64File = btoa(
                        [].reduce.call(
                            new Uint8Array(res),
                            function (p, c) {
                                return p + String.fromCharCode(c);
                            },
                            ''
                        )
                    );

                    callback(base64File);
                });
        }
    }

    //merges two objects without overwriting the contents of similar keys RK
    getAttachmentsValidationInfo(
        attachments: RawAttachment[] = [],
        testSummary: TestSummary[] = []
    ): ValidationInfo {
        //objects having unique IDs
        const attachmentIds = {};
        const testSummaryIds = {};

        //formatted object to return
        let attachmentsInfo = {};

        //array holding all possible attachment ids
        let attachmentsKeys;

        //holds the ids of the current attachments regardless of them being valid
        const allAttachments = [];

        //fill attachmentIds with valid attachments info
        attachments.forEach((attachment) => {
            if (attachment?.id) {
                allAttachments.push(attachment.id);
            }
            if (attachment.id && attachment?.Validation?.valid) {
                attachmentIds[attachment.id] = attachment.Validation;
            }
        });

        //fill testSummary with test results
        testSummary.forEach((item) => {
            if (
                item &&
                item['attachmentID'] &&
                allAttachments.indexOf(item.attachmentID) > -1
            ) {
                if (!testSummaryIds[item.attachmentID]) {
                    testSummaryIds[item.attachmentID] = {
                        isPCRNegative: item.testResult === 'NEGATIVE',
                        valid: true,
                        who: item.who,
                        dateTime: item.testResultDate || item.when,
                        testSummary: [
                            { ...item, ...{ type: 'machine_generated' } },
                        ],
                    };
                    if (attachmentIds[item.attachmentID]) {
                        testSummaryIds[item.attachmentID].testSummary.push({
                            when: attachmentIds[item.attachmentID].timestamp
                                ? attachmentIds[item.attachmentID].timestamp
                                : attachmentIds[item.attachmentID].dateTime,
                            who: attachmentIds[item.attachmentID].who,
                            type: attachmentIds[item.attachmentID].type,
                            valid: attachmentIds[item.attachmentID].valid,
                        });
                    }
                } else {
                    testSummaryIds[item.attachmentID].testSummary.push(item);
                }
            }
        });

        if (attachmentIds) {
            attachmentsKeys = Object.keys(attachmentIds);
        }

        if (attachmentsKeys && attachmentsKeys.length > 0) {
            //if there are no testSummary info, return only attachment info
            if (!testSummaryIds || Object.keys(testSummaryIds).length === 0) {
                attachmentsInfo = cloneDeep(attachmentIds);
            } else {
                attachmentsKeys.forEach((key) => {
                    //look for common attachment ids
                    if (testSummaryIds[key]) {
                        //merge the attachment IDs to avoid overwriting them
                        attachmentsInfo[key] = {
                            ...attachmentIds[key],
                            ...testSummaryIds[key],
                        };

                        //delete the merged keys to avoid overwriting them later
                        delete attachmentIds[key];
                        delete testSummaryIds[key];
                    }
                });
                //merge whatever uncommon keys are left into the formatted object
                attachmentsInfo = {
                    ...attachmentsInfo,
                    ...attachmentIds,
                    ...testSummaryIds,
                };
            }
        } else {
            //if no valid attachments are found, return testSummary only if defined
            if (testSummaryIds && Object.keys(testSummaryIds).length > 0) {
                attachmentsInfo = cloneDeep(testSummaryIds);
            }
        }
        //return the formatted object
        return attachmentsInfo;
    }

    isApplicationValid(
        application: ApplicantCard = new ApplicantCard()
    ): boolean {
        return application.taValidation &&
            application.taValidation.IDDocSummary &&
            application.taValidation.tripInfo
            ? true
            : false;
    }

    getChipsStyles(
        type: string = '',
        chips:
            | VisitedCountries[]
            | Array<string>
            | Array<Record<string, any>> = []
    ): {} {
        const styles = {};
        if (chips && chips.length > 0) {
            chips.forEach((chip) => {
                if (type === 'visitedCountries' || type === 'countryOrigin') {
                    styles[chip.countryCode] = {
                        label: {
                            'background-color':
                                this.visitedCountriesBackgroundColor(chip),
                            border: chip.inWatchList
                                ? 'var(--quarantine-color) 3px solid'
                                : null,
                        },
                        text: {
                            color: 'var(--white-color)',
                        },
                    };
                } else if (type === 'flaggedAnswers') {
                    if (chip === 'NONE') {
                        styles[chip] = {
                            label: {
                                'background-color': 'var(--approve-color)',
                            },
                            text: { color: 'var(--white-color)' },
                        };
                    } else {
                        styles[chip] = {};
                    }
                }
            });
        }

        return styles;
    }

    getBase64(file: File): Promise<string | ArrayBuffer | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    getHealthAttachments(
        attachments: { user: {}[]; system: {}[]; deleted: {}[] } = {
            user: [],
            system: [],
            deleted: [],
        }
    ): documentsActionCardModel {
        const acceptedRoles = ['admin', 'admin+', 'agent', 'agent+', 'agent++'];
        if (
            attachments.user?.length > 0 ||
            attachments.system?.length > 0 ||
            attachments.deleted?.length > 0
        ) {
            const documents = new documentsActionCardModel({
                tabs: [
                    {
                        title: 'tabs.documents_user',
                        documentList: attachments.user,
                        emptyStateStr: 'tabs.no_user_documents',
                    },
                    {
                        title: 'tabs.documents_system',
                        documentList: attachments.system,
                        emptyStateStr: 'tabs.no_system_documents',
                    },
                ],
                footerButtons:
                    acceptedRoles.indexOf(this.userRole) >= 0
                        ? [
                              {
                                  btnTxt: 'profile_labels.upload_new_documents',
                                  btnAction: 'openUploadDropZone',
                              },
                          ]
                        : [],
            });
            if (attachments.deleted?.length > 0) {
                documents.tabs.push({
                    title: 'tabs.documents_deleted',
                    documentList: attachments.deleted,
                    emptyStateStr: 'tabs.no_deleted_documents',
                });
            }
            if (
                documents.tabs[1].title === 'tabs.documents_system' &&
                documents.tabs[1].documentList?.length > 0
            ) {
                this.systemDocuments = documents.tabs[1].documentList;
            }
            return documents;
        }

        return new documentsActionCardModel({});
    }

    saveFile(
        url: string | Blob = '',
        fileName: string = '',
        fileData: string[] = [],
        sampleData = []
    ): void {
        if (url) {
            FileSaver.saveAs(url, fileName);
        } else {
            let csv = fileData.join(',');
            if (sampleData.length > 0) {
                csv += '\n' + sampleData.join(',');
            }

            FileSaver.saveAs(new Blob([csv], { type: 'text/csv' }), fileName);
        }
    }

    getImageRecordInfo(
        attachment: AttachmentModel = new AttachmentModel({}),
        records: MatchRecord[] = []
    ): ImageRecordInfo {
        const matchInfo = new ImageRecordInfo({});

        const attachmentRecord = records.find((record) => {
            return record.ImageId === attachment.id;
        });

        if (attachmentRecord) {
            matchInfo.matchPercentage = `${attachmentRecord.Confidence?.toFixed(
                2
            )}% ${this.translate.instant('profile_labels.match')}`;
            matchInfo.statusColor = attachmentRecord.Matched
                ? 'var(--approve-color)'
                : 'var(--quarantine-color)';
        }

        return matchInfo;
    }

    getTotalMatchPercentage(records: MatchRecord[] = []): number {
        let matchCount = 0;

        records.forEach((record) => {
            if (record.Matched) {
                matchCount++;
            }
        });

        return matchCount > 0
            ? (matchCount * 100) / records.length
            : matchCount;
    }

    getAttachmentInfoByType(
        galleryAttachments: Array<GalleryAttachmentModel> = [],
        type: string = ''
    ): {
        attachments: Array<GalleryAttachmentModel>;
        totalAttachments: number;
    } {
        const attachments = galleryAttachments.filter(
            (attachment) => attachment.type === type
        );

        const infoObj = {
            attachments: attachments.slice(),
            totalAttachments: attachments.length,
        };

        return { ...infoObj };
    }

    trimLeadingSpace(str: string = ''): string {
        return str.replace(validationPatterns.leadingSpace, '');
    }

    convertTextToUnderscoreSeparator(textStr: string = ''): string {
        if (textStr) {
            return textStr
                .replace(/\.?\s?([A-Z])/g, (x, y) => {
                    return '_' + y.toLowerCase();
                })
                .replace(/^_/, '');
        } else {
            return '';
        }
    }

    randomBackgroundImage(): string {
        if (
            this.backgroundImages === undefined ||
            this.backgroundImages.length === 0
        ) {
            this.backgroundImages = imagesArray.slice();
        }

        const randomNum = Math.floor(
            Math.random() * this.backgroundImages.length
        );
        const element = this.backgroundImages.splice(randomNum, 1);
        return element[0];
    }
    getPurchasedItems(
        taReference: string,
        redeemableItems: Array<AncillariesItem> = [],
        redeemedItems: Array<PaymentDetails> = []
    ): Array<PaymentDetails | string> {
        const purchasedItems: Array<PaymentDetails | string> = [];

        redeemableItems.forEach((item) => {
            if (item) {
                purchasedItems.push(
                    new AncillariesItem({
                        ...item,
                        ...{ isRedeemable: true, taReference },
                    })
                );
            }
        });

        redeemedItems.forEach((item) => {
            if (item) {
                purchasedItems.push(new PaymentDetails(item));
            }
        });
        if (purchasedItems.length === 0) {
            purchasedItems.push('NONE');
        }
        return purchasedItems;
    }

    buildTestResultQuery(
        fields: Array<string> = [],
        searchInfo: PatientModel = new PatientModel()
    ): ElasticQueryBuild {
        const queries = [
            {
                secondaryType: 'match',
                primaryType: 'must',
                key: `taStatus`,
                value: 'PROCESSING',
            },
        ];

        const searchBody = {
            from: 0,
            size: 250,
            _source: [
                'taReference',
                'passportSummary.forenames',
                'passportSummary.surname',
                'userSubmittedTravelApplicationRequest.trip_info.arrivalDate',
            ],
            sort: [{ decisionTs: { order: 'desc' } }],
            query: null,
        };

        fields.forEach((field: string) => {
            if (field === 'documentNumber') {
                queries.push({
                    secondaryType: 'match',
                    primaryType: 'must',
                    key: `passportSummary.number`,
                    value: searchInfo[field],
                });
            } else if (field === 'name') {
                queries.push({
                    secondaryType: 'match',
                    primaryType: 'must',
                    key: `passportSummary.forenames`,
                    value: searchInfo.forenames,
                });
                queries.push({
                    secondaryType: 'match',
                    primaryType: 'must',
                    key: `passportSummary.surname`,
                    value: searchInfo.surname,
                });
            }
        });

        searchBody.query = this.buildQuery('', queries);
        return searchBody;
    }

    assetToBase64(path: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(path, { responseType: 'blob' }).subscribe({
                next: (res) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        let img;
                        const base64data = JSON.stringify(reader.result);
                        img = base64data.split('base64,')[1]
                            ? base64data.split('base64,')[1]
                            : '';

                        img = `${img.substring(0, img.length - 4)}A==`;
                        resolve(img);
                    };
                    reader.readAsDataURL(res);
                },
                error: (err) => reject(err),
            });
        });
    }

    normalizeString(textStr: string): string {
        let normalizedString = '';

        if (textStr) {
            normalizedString = textStr
                .normalize('NFD') //unicode normalization of the string RK
                .replace(/[\u0300-\u036f]/g, ''); //remove the accents RK
        }

        return normalizedString;
    }

    checkCommonItemsInArray(
        array_1: Array<string>,
        array_2: Array<string>
    ): boolean {
        return intersection(array_1, array_2)
            ? intersection(array_1, array_2).length > 0
            : false;
    }

    filterUserFileTypes(
        userFiles: Array<{ label: string; key: string }> = [],
        additionalType?: string
    ): Array<{ label: string; key: string }> {
        let filteredUserTypes: Array<{ label: string; key: string }> = [];
        filteredUserTypes = userFiles.filter((type) => {
            return (
                this.occupiedUserFileTypes.indexOf(type.key) < 0 ||
                type.key === additionalType
            );
        });

        return filteredUserTypes;
    }

    hasValidAnswer(
        answer: string,
        data: Array<{ answer: string }> = []
    ): boolean {
        const correctAnswer = data.find((item) => {
            return item.answer?.toLowerCase() === answer?.toLocaleLowerCase();
        });

        return !!correctAnswer;
    }
    isUpdated(
        createdTs: string | Date | number,
        publicUpdatedTs: string | Date | number,
        modifiedTs: string | Date | number
    ): boolean {
        if (!createdTs || !publicUpdatedTs || !modifiedTs) {
            return false;
        }
        return (
            this.timeConversionService.getDateDifference(
                createdTs,
                publicUpdatedTs
            ) < 0 &&
            this.timeConversionService.getDateDifference(
                modifiedTs,
                this.timeConversionService.addSecondsToDate(publicUpdatedTs, 20)
            ) < 0
        );
    }

    isFlaggedCard(
        enrollmentType: string,
        {
            customs_info = { answers: [] },
            health_info = { answers: [] },
            insurance_info = { answers: [] },
            outboundCustoms_info = { answers: [] },
            enrollment_info = { answers: [] },
        } = {}
    ): boolean {
        //return not flagged if wrong enrollment type RK
        if (enrollmentType !== 'EMBARKATIONCARD') {
            return false;
        }

        //if customs info has yes answer => return flagged RK
        for (let i = 0; i < customs_info.answers?.length; i++) {
            const answer = customs_info.answers[i].answer?.slice(0, 3);
            if (answer.toLowerCase() === 'yes') {
                return true;
            }
        }

        //if health info has yes answer return flagged RK
        for (let i = 0; i < health_info.answers?.length; i++) {
            const answer = health_info.answers[i].answer?.slice(0, 3);
            if (answer.toLowerCase() === 'yes') {
                return true;
            }
        }

        //if insurance info has no answer => return flagged RK
        for (let i = 0; i < insurance_info.answers?.length; i++) {
            const answer = insurance_info.answers[i].answer?.slice(0, 2);
            if (answer.toLowerCase() === 'no') {
                return true;
            }
        }

        //if outbound customs info has yes answer => return flagged RK
        for (let i = 0; i < outboundCustoms_info.answers?.length; i++) {
            const answer = outboundCustoms_info.answers[i].answer?.slice(0, 3);
            if (answer.toLowerCase() === 'yes') {
                return true;
            }
        }

        //if enrollment info has yes answer => return flagged HAK
        for (let i = 0; i < enrollment_info.answers?.length; i++) {
            const answer = enrollment_info.answers[i].answer?.slice(0, 3);
            if (answer.toLowerCase() === 'yes') {
                return true;
            }
        }

        //if no wrong answer was found => return not flagged RK
        return false;
    }

    //returns the tree datasource for questions override config RK
    getOverrideConfigNodes(
        treeControl: FlatTreeControl<FlatNode>,
        treeFlattener: MatTreeFlattener<any, any>,
        formData: { [key: string]: any } = {}
    ): { [key: string]: MatTreeFlatDataSource<any, any> } {
        const dataSources = {};
        Object.entries(formData).forEach(([key, value]) => {
            const data: Array<FlatNode> = [];

            Object.entries(value).forEach(([subKey, subValue]) => {
                data.push({
                    name: subKey,
                    label:
                        key === 'documentIssuer'
                            ? `${this.getCountryNameByCode(subKey)} (${subKey})`
                            : `override_config.${subKey}`,
                    children: Object.keys(subValue).map((item) => {
                        return {
                            name: item,
                            label: `override_config.${item}`,
                            parentNode: subKey,
                        };
                    }),
                });
            });
            dataSources[key] = new MatTreeFlatDataSource(
                treeControl,
                treeFlattener
            );
            dataSources[key].data = data;
        });

        return dataSources;
    }

    formatSelectedApps(
        selectedApps: Array<ApplicantCard> = [],
        taStatus: string = '',
        flagCode: string = '',
        notify: boolean = true,
        reason: string = ''
    ): Array<ApplicationChangesModel> {
        let flag = '';

        if (flagCode && flagCode !== 'NOTE' && flagCode !== 'INCOMPLETE') {
            flag = flagCode.startsWith('OUTBOUND')
                ? flagCode.split('OUTBOUND_')[1]
                : flagCode;
        }

        const formattedApps: Array<ApplicationChangesModel> = [];

        selectedApps.forEach((app) => {
            formattedApps.push(
                new ApplicationChangesModel({
                    TaReference: app.appReference,
                    TaStatus: taStatus,
                    FlagCode: flag,
                    SetFlag: !!flag || flagCode === 'INCOMPLETE',
                    Notify: notify,
                    Reason: reason,
                })
            );
        });
        return formattedApps;
    }

    getContents(getConfig: boolean = true): void {
        this.store.dispatch(getContent());
        this.store.dispatch(getSupportedMLTemplates());
        if (getConfig) {
            this.store.dispatch(getAppConfig());
            this.store.dispatch(getEnrollmentTypes());
        }
    }

    notifyStreamUser(message: string): void {
        this.snackBar.open(
            message,
            `${this.translate.instant('stream_display.dismiss')}`,
            {
                duration: 6000,
                panelClass: 'snack-bar',
            }
        );
        if (this.isSoundNotificationOn) {
            if (!this.audio) {
                this.audio = new Audio();
                this.audio.src = 'assets/audio/notification.mp3';
                this.audio.load();
            }
            this.audio.currentTime = 0;
            this.audio.play();
            setTimeout(() => {
                this.audio.pause();
            }, 1000);
        }
    }

    checkForRecentStreamViews(taReference: string): void {
        let prevEvent: StreamDataModel;
        for (const event of this.streamEvents) {
            const currentTime = dayjs().unix();
            const eventTime = dayjs(event.When).unix();
            if (
                event.Type === 'TAACCESSED' &&
                event.TaRef === taReference &&
                event.Who &&
                event.Who !== this.email &&
                currentTime - eventTime <= 300
            ) {
                prevEvent = event;
                break;
            } else if (currentTime - eventTime > 300) {
                break;
            }
        }

        if (prevEvent) {
            this.notifyStreamUser(
                `${this.translate.instant('stream_display.ta_viewed_by')} ${
                    prevEvent.Who
                } ${this.translate.instant('stream_display.recently')} `
            );
        }
    }

    handleApplicationsCount(
        buckets?: { key: string; doc_count: number }[]
    ): void {
        if (!buckets) {
            return;
        }
        const actions: {
            [key: string]: {
                action: typeof getAppsCountSuccess;
                node: string;
            };
        } = {
            WORKATION: { action: getAppsCountSuccess, node: 'workation' },
            PROCESSING: { action: getAppsCountSuccess, node: 'pending' },
            EMBCARD_SUBMITTED: {
                action: getAppsCountSuccess,
                node: 'toReview',
            },
            EXPEDITED: {
                action: setSpecificNotificationsAppsCount,
                node: 'expedited',
            },
            UPDATED: {
                action: setSpecificNotificationsAppsCount,
                node: 'updated',
            },
        };

        buckets.forEach((bucket) => {
            const action = actions[bucket.key];
            if (!action) {
                return;
            }
            this.store.dispatch(
                action.action({
                    count: bucket.doc_count,
                    storeNode: action.node,
                })
            );
        });
    }

    handleDataStream(data: StreamDataModel): void {
        if (!data) {
            return;
        }
        if (data.Type === 'APPLICATION_COUNTS') {
            this.handleApplicationsCount(
                (data as any).aggregations?.applicationsCounts?.buckets
            );
        }

        data = new StreamDataModel(data, this.baseUrl);

        //Not reading excluded event:hg
        if (!streamDataTypesMapping[data.Type]) {
            return;
        }

        //If two user viewing same application logic:hg
        if (data.TaRef === this.appDetails?.ref && this.email !== data.Who) {
            if (data.Type === 'TAACCESSED') {
                this.notifyStreamUser(
                    `${this.translate.instant('stream_display.ta_viewed_by')} ${
                        data.Who
                    } ${this.translate.instant('stream_display.just_now')} `
                );
            } else {
                this.store.dispatch(clearAppDetails());
                this.store.dispatch(
                    getAppDetails({
                        code: data.TaRef,
                        clearTaChanges: true,
                    })
                );
            }
        }

        //Filling up the queue with the events start:hg
        if (['admin', 'admin+'].includes(this.userRole)) {
            this.streamQueue.streamEvents.push(data);
        }
        //Filling up the queue with the events end:hg

        //TARECEIVED snackbar logic start:hg
        if (data.Type === 'TARECEIVED') {
            if (data.TA.trip_info.expedited || data.TA.expedited) {
                this.eventHub.isExpeditedReceived.emit();
            } else {
                this.notifyStreamUser(
                    `${this.translate.instant(
                        'stream_display.ta_received_from'
                    )} ${data.TA.channel} : ${this.translate.instant(
                        'stream_display.view'
                    )} ${data.TaRef}`
                );
            }
        }
        //TARECEIVED snackbar logic done:hg
    }

    isJWTTokenExpired(token: string, buffer = 0): boolean {
        const decoded: any = jwtDecode(token);
        return (
            this.timeConversionService.getUnixTimestamp(undefined) + buffer >
            decoded.exp
        );
    }

    catchError(type: any, error?: any): ObservableInput<any> {
        if (error) {
            return of({
                type,
                payload: error,
            });
        }
        return of({
            type,
        });
    }

    getEncountersChips(
        encountersData: Array<{ [key: string]: any }>
    ): Array<string> {
        const encounterChips = [];
        let filteredArray;
        let count;

        //sort the encounter in descending order according to encounterTime CA

        encountersData?.sort((a, b) =>
            this.timeConversionService.getDateDifference(
                b.encounterTime,
                a.encounterTime
            )
        );

        //remove duplicates rk
        const encounterTypes = encountersData?.map(
            (element) => element.encounterType
        );
        const borderEntryIndex = encountersData?.findIndex(
            (encounter) => encounter.encounterType === 'BORDER_ENTRY'
        );
        const borderEntryCount = encountersData?.filter(
            (encounter) => encounter.encounterType === 'BORDER_ENTRY'
        ).length;
        if (encountersData?.length > 3 || borderEntryCount > 1) {
            filteredArray = encounterTypes.filter((element, index) => {
                return encounterTypes.indexOf(element) === index;
            });

            count = encountersData.length - filteredArray.length;
        } else {
            filteredArray = encounterTypes;
        }
        //add translations and approved stay until chip rk

        filteredArray?.forEach((encounter) => {
            if (encounter) {
                encounterChips.push(
                    this.hasTranslationKey(`encounter_types.${encounter}`)
                        ? this.translate.instant(`encounter_types.${encounter}`)
                        : encounter
                );
            }
            if (
                encounter === 'BORDER_ENTRY' &&
                borderEntryIndex >= 0 &&
                encountersData[borderEntryIndex]?.approvedStayUntil
            ) {
                encounterChips.push(
                    `${this.translate.instant(
                        'additional_notes.approved_until'
                    )}: ${encountersData[borderEntryIndex].approvedStayUntil}`
                );
            }
        });

        if (count) {
            encounterChips.push(
                this.translate.instant('profile_labels.encounter_count', {
                    count,
                })
            );
        }

        return encounterChips;
    }

    setFilterChipsParams(
        path: string,
        addScope: FilterChips,
        typesObj: {
            must: Array<any>;
            should: Array<any>;
            must_not: Array<any>;
        }
    ): {
        must: Array<any>;
        should: Array<any>;
        must_not: Array<any>;
    } {
        if (
            addScope &&
            addScope.SUBMISSION_RECENT &&
            path !== 'inCountryVisitors' &&
            path !== 'toReview' &&
            path !== 'inReview' &&
            path !== 'incomplete' &&
            !pagesConfig[path]?.noFilters
        ) {
            if (path !== 'pending') {
                typesObj.must.push({
                    range: {
                        createdTs: {
                            gte: 'now-5d',
                            lt: 'now',
                        },
                    },
                });
            } else {
                typesObj.must.push({
                    bool: {
                        should: [
                            {
                                range: {
                                    createdTs: {
                                        gte: 'now-5d',
                                        lt: 'now',
                                    },
                                },
                            },
                            {
                                range: {
                                    publicUpdatedTs: {
                                        gte: 'now-5d',
                                        lt: 'now',
                                    },
                                },
                            },
                        ],
                    },
                });
            }
        }
        if (addScope && addScope.EXCLUDE_ARRIVAL_TODAY) {
            typesObj.must_not.push({
                match: {
                    'userSubmittedTravelApplicationRequest.trip_info.arrivalDate':
                        this.timeConversionService.formatDate(
                            undefined,
                            'YYYY-MM-DD',
                            false
                        ),
                },
            });
        }
        if (addScope && addScope.EXCLUDE_DEPARTURE_TODAY) {
            typesObj.must_not.push({
                match: {
                    'userSubmittedTravelApplicationRequest.trip_info.departureDate':
                        this.timeConversionService.formatDate(
                            undefined,
                            'YYYY-MM-DD',
                            false
                        ),
                },
            });
        }

        if (addScope && addScope.EXCLUDE_WORKERS) {
            typesObj.must_not.push({
                match: {
                    'userSubmittedTravelApplicationRequest.trip_info.purposeVisit':
                        'EMPLOYMENT_LONG',
                },
            });
            typesObj.must_not.push({
                match: {
                    'userSubmittedTravelApplicationRequest.trip_info.purposeVisit':
                        'EMPLOYMENT_SHORT',
                },
            });
        }

        if (addScope && addScope.ARRIVALS_TODAY) {
            typesObj.should.push({
                range: {
                    'userSubmittedTravelApplicationRequest.trip_info.arrivalDate':
                        {
                            gte: this.timeConversionService.formatDate(
                                undefined,
                                'YYYY-MM-DD',
                                false
                            ),
                            lt: this.timeConversionService.getDateFromNow(
                                1,
                                'd'
                            ),
                        },
                },
            });
        }
        if (addScope && addScope.ARRIVALS_TOMORROW) {
            typesObj.should.push({
                range: {
                    'userSubmittedTravelApplicationRequest.trip_info.arrivalDate':
                        {
                            gte: this.timeConversionService.getDateFromNow(
                                1,
                                'd'
                            ),
                            lt: this.timeConversionService.getDateFromNow(
                                2,
                                'd'
                            ),
                        },
                },
            });
        }
        if (addScope && addScope.ARRIVALS_FUTURE) {
            typesObj.should.push({
                range: {
                    'userSubmittedTravelApplicationRequest.trip_info.arrivalDate':
                        {
                            gte: this.timeConversionService.getDateFromNow(
                                2,
                                'd'
                            ),
                            lt: this.timeConversionService.getDateFromNow(
                                1,
                                'y'
                            ),
                        },
                },
            });
        }

        if (path === 'arrivals') {
            typesObj.must.push({
                range: {
                    ['userSubmittedTravelApplicationRequest.trip_info.arrivalDate']:
                        {
                            gte:
                                addScope && addScope['startDate']
                                    ? addScope['startDate']
                                    : this.timeConversionService.formatDate(
                                          undefined,
                                          'YYYY-MM-DD',
                                          false
                                      ),
                            lt:
                                addScope && addScope['endDate']
                                    ? addScope['endDate']
                                    : this.timeConversionService.getDateFromNow(
                                          1,
                                          'd'
                                      ),
                        },
                },
            });
        }

        if (path === 'departures') {
            typesObj.must.push({
                range: {
                    ['userSubmittedTravelApplicationRequest.trip_info.departureDate']:
                        {
                            gte:
                                addScope && addScope['startDate']
                                    ? addScope['startDate']
                                    : this.timeConversionService.getDateFromNow(
                                          -1,
                                          'd'
                                      ),
                            lt:
                                addScope && addScope['endDate']
                                    ? addScope['endDate']
                                    : this.timeConversionService.formatDate(
                                          undefined,
                                          'YYYY-MM-DD',
                                          false
                                      ),
                        },
                },
            });
        }
        if (pagesConfig[path]?.rangeFilterField) {
            typesObj.must.push({
                range: {
                    [pagesConfig[path].rangeFilterField]: {
                        gte:
                            addScope && addScope['startDate']
                                ? addScope['startDate']
                                : this.timeConversionService.getDateFromNow(
                                      pagesConfig[path].defaultRange || -1,
                                      'd'
                                  ),
                        lte:
                            addScope && addScope['endDate']
                                ? addScope['endDate']
                                : this.timeConversionService.formatDate(
                                      undefined,
                                      'YYYY-MM-DD',
                                      false
                                  ),
                    },
                },
            });
        }
        return typesObj;
    }

    generateElasticSearchBody(
        data: TemplateElasticSearchParams,
        loadAppsManagerPath?: string,
        excludeSource?: boolean
    ): void {
        if (!data) {
            return;
        }
        const details = templateElasticSearches[data.templateId];
        const body = {
            templateId: data.templateId,
            params: {
                _source: {
                    includes: details.includes || [],
                    excludes: details.excludes || [],
                },
            },
        };
        if (data.extraParams) {
            body.params = { ...body.params, ...data.extraParams };
        }
        if (excludeSource) {
            delete body.params._source;
        }
        this.store.dispatch(
            getElasticSearchData({
                body,
                searchType: details.searchType,
                parentStoreNode: loadAppsManagerPath
                    ? 'appsManager'
                    : details.parentStoreNode,
                storeNode: loadAppsManagerPath
                    ? loadAppsManagerPath
                    : details.storeNode,
                dataNode: loadAppsManagerPath ? 'allData' : details.dataNode,
                doNotUpdateAppsUi: details.doNotUpdateAppsUi,
            })
        );
    }

    getActiveFilterChipLabel(
        filterParams: { [key: string]: string | Array<string> } = {}
    ): string {
        const templateId = filterParams.templateId;
        let label = '';
        if (!templateId) {
            return label;
        }
        label = filterParams.templateId
            ? this.translate.instant(`pages_filters.${filterParams.templateId}`)
            : '';

        switch (templateId) {
            case 'ENCOUNTERS_BY_DATE':
                label += ` ${this.translate.instant('pages_filters.between')} ${
                    filterParams.gte
                } ${this.translate.instant('pages_filters.and')} ${
                    filterParams.lte
                }`;
                break;
            case 'ENCOUNTERS_BY_ARRIVAL_FLIGHT_AND_DATE': {
                const from = filterParams.gte;
                const to = filterParams.lte;

                label += ` = ${filterParams.arrivalCarrier} ${
                    filterParams.arrivalFlightNumber
                } ${
                    from
                        ? this.translate.instant(
                              from !== to
                                  ? 'pages_filters.between'
                                  : 'pages_filters.on'
                          )
                        : ''
                } ${from ? filterParams.gte : ''} ${
                    to
                        ? from !== to
                            ? this.translate.instant('pages_filters.and') +
                              ' ' +
                              filterParams.lte
                            : ''
                        : ''
                }`;
                break;
            }
            case 'ENCOUNTERS_BY_DEPARTURE_FLIGHT_AND_DATE': {
                const from = filterParams.gte;
                const to = filterParams.lte;

                label += ` = ${filterParams.departureCarrier} ${
                    filterParams.departureFlightNumber
                } ${this.translate.instant(
                    from
                        ? from !== to
                            ? 'pages_filters.between'
                            : 'pages_filters.on'
                        : ' '
                )} ${from ? filterParams.gte : ''} ${
                    from && to && from !== to
                        ? this.translate.instant('pages_filters.and') +
                          ' ' +
                          filterParams.lte
                        : ''
                }`;
                break;
            }
            case 'EXCLUDE_ENCOUNTERS_BY_TYPE':
            case 'ENCOUNTERS_BY_TYPE':
                label += ` = ${
                    this.hasTranslationKey(
                        'encounter_types.' + filterParams.encounterType
                    )
                        ? this.translate.instant(
                              'encounter_types.' + filterParams.encounterType
                          )
                        : filterParams.encounterType
                }`;
                break;
            case 'ENCOUNTERS':
                label += ` = ${this.translate.instant(
                    'forms.' + filterParams.overstayed
                )}`;
                break;
            case 'ENCOUNTERS_BY_LOCATION':
                label =
                    this.translate.instant('pages_filters.location') +
                    ` = ` +
                    this.translate.instant('pages_filters.lat') +
                    `: ${filterParams.lat}, ` +
                    this.translate.instant('pages_filters.lon') +
                    `: ${filterParams.lon}`;
                break;
            case 'PAYMENTS_BY_ORDER_STATUS':
                label += ` = ${
                    this.hasTranslationKey(
                        'payments_by_order_status.' + filterParams.orderStatus
                    )
                        ? this.translate.instant(
                              'payments_by_order_status.' +
                                  filterParams.orderStatus
                          )
                        : filterParams.orderStatus
                }`;
                break;
            case 'PAYMENTS_BY_TYPE':
                label += ` = ${
                    this.hasTranslationKey(
                        'payments_by_payment_type.' + filterParams.paymentType
                    )
                        ? this.translate.instant(
                              'payments_by_payment_type.' +
                                  filterParams.paymentType
                          )
                        : filterParams.paymentType
                }`;
                break;
            case 'TAS_NON_PAYMENT_OVERRIDE':
                label = `${this.translate.instant(
                    'profile_labels.from_date'
                )} ${filterParams.fromDate} ${this.translate.instant(
                    'profile_labels.to_date'
                )} ${filterParams.toDate} ${
                    filterParams.enrollmentTypes.length
                        ? this.translate.instant('common.and') +
                          ' ' +
                          this.translate.instant(
                              'profile_labels.enrollmentType'
                          ) +
                          ' = ' +
                          (filterParams.enrollmentTypes as Array<string>).join(
                              ', '
                          )
                        : ''
                }`;
                break;
            default:
                label += ' = ';
                Object.entries(filterParams).forEach(([key, value]) => {
                    if (
                        key !== 'templateId' &&
                        key !== 'from' &&
                        key !== 'size'
                    ) {
                        label += ` ${value}`;
                    }
                });
                break;
        }
        return label;
    }
    setSiteName(): void {
        const flavorParams = this.appFlavor.split('_');
        this.siteNameParams.title = `main.${flavorParams[0]}_site_name`;
        if (flavorParams[0] === 'slb') {
            this.siteNameParams.footer = `main.${flavorParams[0]}_site_name_footer`;
        }
        this.siteNameParams.params = {
            env:
                flavorParams[1] === 'prod'
                    ? ''
                    : flavorParams[0] === 'hc'
                    ? flavorParams[1]?.toUpperCase()
                    : `(${flavorParams[1]?.toUpperCase()})`,
        };
        if (
            flavorParams[0] === 'hc' &&
            flavorParams[1] === 'nam' &&
            flavorParams.length === 3
        ) {
            this.siteNameParams.title = 'main.hc_nam_site_name';
        } else if (this.appFlavor.includes('hc') && flavorParams.length <= 2) {
            this.siteNameParams.title = `main.hc_main_site_name`;
        } else if (flavorParams.length === 1) {
            this.siteNameParams.title = `main.ta_site_name`;
            this.siteNameParams.params = {
                env:
                    flavorParams[0] === 'prod'
                        ? ''
                        : `(${flavorParams[0].toUpperCase()})`,
            };
        }
    }

    base64ToBlob(base64: string, type: string): Blob {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type });
    }

    getEnrollmentTabs(routeNode?: string): Array<tabFilterModel> {
        return this.enrollmentTypes?.map((item) => {
            const isDefault = item.key === this.defaultEnrollmentType;
            return {
                label: item.label,
                key: item.key,
                forceShow: true,
                icon: {
                    isIconSelected: isDefault,
                    url:
                        routeNode === 'enrollmentTypes'
                            ? isDefault
                                ? 'assets/styles/icons/material-icons/filled-star.svg'
                                : 'assets/styles/icons/material-icons/star.svg'
                            : undefined,
                    iconClickAction: 'onStarClick',
                    customClasses: {
                        'star-color': !isDefault,
                        'filled-star-color': isDefault,
                    },
                },
            };
        });
    }

    getEligibilityRequirements(): Array<any> {
        return eligibilityRequirements?.filter((element) => {
            return (
                !element.enrollmentType ||
                (element.enrollmentType === 'VISA' &&
                    this.enrollmentTypes.find((enType) =>
                        (enType as { [key: string]: any })?.key
                            ?.toLowerCase()
                            ?.includes('visa')
                    )) ||
                this.enrollmentTypes.find(
                    (enType) =>
                        (enType as { [key: string]: any })?.key ===
                        element.enrollmentType
                )
            );
        });
    }

    getUserColumnPreferences(
        columnDefs: Array<any>,
        userPreferences: Array<any>
    ): {
        userColumns: Array<any>;
        preferences: Array<any>;
    } {
        const userColumns = [];
        const preferences = userPreferences;
        columnDefs.forEach((column) => {
            const userCol = userPreferences.find((preference) => {
                return column['field'] === preference['colId'];
            });
            if (userCol) {
                userColumns.push({ ...column, hide: userCol.hide });
            } else {
                //column is in columnDefs and not in preferences, add it to userColumn to display it and add it to preferences to update the preferences CA
                userColumns.push({ ...column, hide: !!column['hide'] });
                preferences.push({
                    colId: column['field'],
                    hide: !!column['hide'],
                    selected: !column['hide'],
                    headerName: column['headerName'],
                });
            }
        });

        return { userColumns, preferences };
    }

    setValidityInfo(expiry: string = ''): string | number {
        const yearsLeft = this.timeConversionService.getDateDifference(
            expiry,
            undefined,
            'years'
        );
        if (
            yearsLeft === 0 &&
            this.timeConversionService.getDateDifference(expiry, undefined) >= 0
        ) {
            return '<1 ';
        } else if (
            this.timeConversionService.getDateDifference(expiry, undefined) < 0
        ) {
            return `${this.translate.instant('profile_labels.expired')}`;
        } else {
            return yearsLeft;
        }
    }

    mapVisitedCountriesLogs(health_info = []): Array<any> {
        let visitedCountriesLogs = [];
        const arr = health_info
            .filter(
                (element) =>
                    !this.compareData(
                        element.New.health_info.answers.filter(
                            (elm) =>
                                elm.questionKey?.toUpperCase() ===
                                'VISITEDCOUNTRIES'
                        ),
                        element.Old.health_info.answers.filter(
                            (elm) =>
                                elm.questionKey?.toUpperCase() ===
                                'VISITEDCOUNTRIES'
                        )
                    )
            )
            .sort((elementA, elementB) => {
                return new Date(elementA.Timestamp) <
                    new Date(elementB.Timestamp)
                    ? 1
                    : -1;
            })
            .map((elm, i) => {
                return { ...elm, index: i };
            });
        if (arr.length) {
            visitedCountriesLogs = arr;
            visitedCountriesLogs.push({
                New: {
                    health_info:
                        arr[arr.length - 1]?.Old?.health_info ||
                        this.appDetails.health_info,
                },
                Timestamp: this.appDetails.SubmittedTs,
                index: visitedCountriesLogs.length,
            });
        }
        return visitedCountriesLogs;
    }

    mapFlaggedAnswersLogs(
        health_info = [],
        customs_info = [],
        insurance_info = []
    ): Array<any> {
        //map the flagged answers (health_info, customs_info, insurance_info) on each tacked change CA
        const flaggedAnswersLogs = [];
        //removing logs that do not have changes - case when clicking on Update in TA web without making any changes CA
        health_info = health_info.filter(
            (element) =>
                !this.compareData(
                    element.New.health_info.answers.filter(
                        (elm) =>
                            elm.questionKey?.toUpperCase() !==
                            'VISITEDCOUNTRIES'
                    ),
                    element.Old.health_info.answers.filter(
                        (elm) =>
                            elm.questionKey?.toUpperCase() !==
                            'VISITEDCOUNTRIES'
                    )
                )
        ); //check only for questions that are not related to visited countries CA
        customs_info = customs_info.filter(
            (element) =>
                !this.compareData(
                    element.New.customs_info,
                    element.Old.customs_info
                )
        );
        insurance_info = insurance_info.filter(
            (element) =>
                !this.compareData(
                    element.New.insurance_info,
                    element.Old.insurance_info
                )
        );
        const arrays = health_info
            .concat(customs_info, insurance_info)
            .sort((elementA, elementB) => {
                return new Date(elementA.Timestamp) <
                    new Date(elementB.Timestamp)
                    ? 1
                    : -1;
            })
            .map((elm, i) => {
                return { ...elm, index: i };
            });
        if (arrays.length) {
            const tempArray = arrays.slice();
            const arr = [
                'health_info',
                'customs_info',
                'insurance_info',
                'enrollment_info',
            ];
            tempArray.forEach((log, index) => {
                const temp = {
                    New: {
                        ...log.New,
                    },
                    Timestamp: log.Timestamp,
                    Who: log.Who,
                    What: log.What,
                    index,
                };
                const first = arrays.slice(0, index).reverse();
                const last = arrays.slice(index);
                arr.forEach((elm) => {
                    if (Object.keys(log.New)[0] !== elm) {
                        temp.New = {
                            ...temp.New,
                            [elm]:
                                last.find((element) => element.New[elm])?.New[
                                    elm
                                ] ||
                                first.find((element) => element.New[elm])?.Old[
                                    elm
                                ] ||
                                this.appDetails[elm],
                        };
                    }
                });
                flaggedAnswersLogs.push(temp);
            });
            if (flaggedAnswersLogs.length) {
                flaggedAnswersLogs.push({
                    New: {
                        health_info:
                            health_info[health_info.length - 1]?.Old
                                ?.health_info || this.appDetails.health_info,
                        customs_info:
                            customs_info[customs_info.length - 1]?.Old
                                ?.customs_info || this.appDetails.customs_info,
                        insurance_info:
                            insurance_info[insurance_info.length - 1]?.Old
                                ?.insurance_info ||
                            this.appDetails.insurance_info,
                        enrollment_info: this.appDetails.enrollment_info,
                    },
                    Timestamp: this.appDetails.SubmittedTs,
                    index: flaggedAnswersLogs.length,
                });
            }
        }

        return flaggedAnswersLogs;
    }

    sortColumnDefs(
        columnDefs: Array<any> = [],
        columnState: Array<any> = []
    ): Array<any> {
        const sortedDefs = [];
        columnState.forEach((column) => {
            const element = columnDefs.find(
                (elm) => elm.field === column.colId
            );
            if (element) {
                sortedDefs.push(element);
            }
        });
        return sortedDefs;
    }

    getTime(dateDifference: any): {
        countDownSeconds: string;
        countDownMinutes: string;
        countDownHours: string;
    } {
        let countDownSeconds = null;
        let countDownMinutes = null;
        let countDownHours = null;
        countDownSeconds = Math.floor(dateDifference / 1000);
        countDownMinutes = Math.floor(countDownSeconds / 60);
        countDownHours = Math.floor(countDownMinutes / 60);
        countDownMinutes = countDownMinutes - countDownHours * 60;
        countDownSeconds = (
            '0' +
            (countDownSeconds -
                countDownHours * 60 * 60 -
                countDownMinutes * 60)
        ).slice(-2);
        countDownMinutes = ('0' + countDownMinutes).slice(-2);
        countDownHours = ('0' + countDownHours).slice(-2);
        return {
            countDownSeconds,
            countDownMinutes,
            countDownHours,
        };
    }
}
