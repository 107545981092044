import dataBusReducer from './dataBus.reducer';
import streamReducer from './stream.reducer';
import userReducer from './user.reducer';
import loginPageReducer from './loginPage.reducer';
import uiReducer from './ui.reducer';
import notificationsReducer from './notifications.reducer';
import offlineDatabusReducer from './offlineDatabus.reducer';

export const combinedReducers = {
    notifications: notificationsReducer,
    dataBus: dataBusReducer,
    stream: streamReducer,
    user: userReducer,
    loginPage: loginPageReducer,
    ui: uiReducer,
    offlineDatabus: offlineDatabusReducer,
};
