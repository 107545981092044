export class notificationsModel {
    expedited: {
        title: string;
        subtitle: string;
        count: number;
    };
    updated: {
        title: string;
        subtitle: string;
        count: number;
    };

    constructor() {
        return {
            expedited: {
                title: '',
                subtitle: '',
                count: 0,
            },
            updated: {
                title: '',
                subtitle: '',
                count: 0,
            },
        };
    }
}
