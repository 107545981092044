import { Action, createReducer, on } from '@ngrx/store';
import { setLoginSelectedLanguage } from '../actions/loginPage.actions';
import { LoginPageState } from '../state';

const initialState: LoginPageState = {
    loginSelectedLanguage: 'en',
};

const loginPageReducer = createReducer(
    initialState,
    on(setLoginSelectedLanguage, (state, action) => {
        const _u = { ...state };
        _u.loginSelectedLanguage = action.loginSelectedLanguage;
        return _u;
    })
);

export default function reducer(
    state: LoginPageState,
    action: Action
): LoginPageState {
    return loginPageReducer(state, action);
}
