import { AppState } from '../state';
import { offlineDatabusState } from '../state/offlineDatabus.state';

export const selectOfflineDataBus = (state: AppState): offlineDatabusState =>
    state.offlineDatabus;

export const selectOfflineAppsManager = (
    state: AppState
): typeof state.offlineDatabus.appsManager => state.offlineDatabus.appsManager;

export const selectOfflineAppDetails = (
    state: AppState
): typeof state.offlineDatabus.appDetails => state.offlineDatabus.appDetails;

export const selectIpPort = (state: AppState): string =>
    state.offlineDatabus.ipPort;
