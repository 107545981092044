import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppConfig } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';

if (AppConfig.production) {
    enableProdMode();
    if (window) {
        window.console.log = function () {};
    }
}

platformBrowserDynamic().bootstrapModule(AppModule, {
    preserveWhitespaces: false,
});
