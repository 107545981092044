import { AppState, UserState } from '../state';

export const user = (state: AppState): UserState => state.user;

// preferences
export const languageSelector = (
    state: AppState
): typeof state.user.preferences.settings.language =>
    state.user.preferences?.settings.language;

export const themeColorModeSelector = (
    state: AppState
): typeof state.user.preferences.settings.themeColorMode =>
    state.user.preferences?.settings.themeColorMode;

export const isSoundNotificationOnSelector = (
    state: AppState
): typeof state.user.preferences.settings.isSoundNotificationOn =>
    state.user.preferences?.settings.isSoundNotificationOn;

export const isAppListeningToStreamSelector = (
    state: AppState
): typeof state.user.preferences.settings.isAppListeningToStream =>
    state.user.preferences?.settings.isAppListeningToStream;

export const notificationsPanelAutoShowSelector = (
    state: AppState
): typeof state.user.preferences.settings.notificationsPanelAutoShow =>
    state.user.preferences?.settings.notificationsPanelAutoShow;
export const pagesSelector = (
    state: AppState
): typeof state.user.preferences.pages => state.user.preferences?.pages;

export const lastUsedSearchSelector = (
    state: AppState
): typeof state.user.preferences.searchQueries.lastUsedSearch =>
    state.user.preferences?.searchQueries?.lastUsedSearch;

export const savedSearchesSelector = (
    state: AppState
): typeof state.user.preferences.searchQueries.savedSearches =>
    state.user.preferences?.searchQueries?.savedSearches;
