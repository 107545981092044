import { menuItem } from '@app/models/menuItem';
import { AppConfig } from 'environments/environment';

const mappedConfig = AppConfig;

export const menuConfig: menuItem[] = [
    {
        addEditRoles: ['admin', 'agent'],
        viewRoles: ['admin', 'agent'],
        name: 'side_menu.health_credentials',
        state: 'healthCredentials',
        icon: 'account_box',
        hasSeparator: true,
        hasChildSeparator: false,
        hasCount: false,
        page_title: '',
    },
    {
        addEditRoles: ['admin', 'agent'],
        viewRoles: ['admin', 'agent'],
        name: 'side_menu.reports',
        state: '',
        externalUrl: mappedConfig['reportsUrl'],
        icon: 'open_in_new',
        hasSeparator: true,
        hasChildSeparator: false,
        hasCount: false,
        page_title: '',
    },
    {
        addEditRoles: ['super_admin', 'admin'],
        viewRoles: ['super_admin', 'admin'],
        name: 'side_menu.user_item',
        hasSeparator: false,
        hasChildSeparator: false,
        hasCount: false,
        page_title: '',
        state: 'users',
        icon: 'group',
    },
];
