import { healthCredentialsCSVSample } from '@app/config/CSVSampleTemplates';

export class ProfilesDataModel {
    [key: string]: ProfileData;
}

export class ProfileData {
    pubKeyX?: string;
    name?: string;
    issuerPublicData?: {
        name?: string;
        address?: string;
        website?: string;
        phone?: string;
        email?: string;
    };
    patientInfo?: Array<MappedInputAttributes>;
    healthCredential?: Array<MappedInputAttributes>;
    isEUCertificate?: boolean;
    constructor(props?: HCProfileAPI) {
        return {
            pubKeyX: props?.pubKeyX ? props.pubKeyX : '',
            name: props?.name ? props.name : '',
            isEUCertificate: props?.euCertificate ? props.euCertificate : false,
            issuerPublicData: {
                name: props?.issuerPublicData?.Name
                    ? props.issuerPublicData.Name
                    : '',
                address: props?.issuerPublicData?.Address
                    ? props.issuerPublicData.Address
                    : '',
                website: props?.issuerPublicData?.Website
                    ? props.issuerPublicData.Website
                    : '',
                phone: props?.issuerPublicData?.Phone
                    ? props.issuerPublicData.Phone
                    : '',
                email: props?.issuerPublicData?.Email
                    ? props.issuerPublicData.Email
                    : '',
            },
            patientInfo: props?.patientInfo ? props.patientInfo : [],
            healthCredential: props?.healthCredential
                ? props.healthCredential
                : [],
        };
    }
}

export class HCProfileAPI {
    name: string;
    issuerPublicData: {
        Name: string;
        Address: string;
        Website: string;
        Phone: string;
        Email: string;
        props?: {
            [key: string]: string;
        };
    };
    codeMappings: {
        lifeTimeInDays: number;
        mrzType: InputAttributes;
        healthDocType: InputAttributes;
        issuingCountryISOCode: InputAttributes;
        givenNames: InputAttributes;
        lastNames: InputAttributes;
        dateOfBirth: InputAttributes;
        sex: InputAttributes;
        idDocType: InputAttributes;
        idDocNumber: InputAttributes;
        mrzLine2: Array<InputAttributes>;
        defaultLocation: {
            lat: number;
            long: number;
            radius: number;
        };
    };
    pubKeyX: string;
    euCertificate?: boolean;
    patientInfo?: Array<MappedInputAttributes>;
    healthCredential?: Array<MappedInputAttributes>;
}

export class InputAttributes {
    required: boolean;
    visible: boolean;
    name: string;
    type: string;
    description: string;
    minLength: number;
    maxLength: number;
    defaultValue: string | number;
    selectOptions?: Array<{ value: string; description: string }>;
    options?: Array<{ key: string; label: string }>;
    formControl?: string;
}

export class MappedInputAttributes {
    required: boolean;
    visible: boolean;
    label: string;
    type: string;
    description: string;
    minLength: number;
    maxLength: number;
    defaultValue: string | number;
    options?: Array<{ key: string; label: string }>;
    formControl: string;

    constructor(props: InputAttributes) {
        return {
            required: props.required ? props.required : false,
            visible: props.visible ? props.visible : false,
            label: props.name ? props.name : '',
            type: props.type ? props.type : '',
            description: props.description ? props.description : '',
            minLength: props.minLength ? props.minLength : 0,
            maxLength: props.maxLength ? props.maxLength : 1000,
            defaultValue: props.defaultValue ? props.defaultValue : '',
            options: props.options ? props.options : [],
            formControl: props.formControl ? props.formControl : '',
        };
    }
}

export class GenerateAttributesModel {
    pubKeyX?: string;
    selectedCodeMappings?: {
        mrzType?: string;
        healthDocType?: string;
        issuingCountryISOCode?: string;
        givenNames?: string;
        lastNames?: string;
        sex?: string;
        dateOfBirth?: string;
        idDocType?: string;
        idDocNumber?: string;
        line2?: Array<string>;
        ccAddress?: string;
        bccAddress?: string;
    };
    notifications?: {
        emailAddress: string;
        whatsApp: string;
    };
}

export class GenerateModel {
    pubKeyX?: string;
    selectedCodeMappings?: {
        mrzType?: string;
        healthDocType?: string;
        issuingCountryISOCode?: string;
        givenNames?: string;
        lastNames?: string;
        sex?: string;
        dateOfBirth?: string;
        idDocType?: string;
        idDocNumber?: string;
        line2?: Array<string>;
        ccAddress?: string;
        bccAddress?: string;
    };
    notifications?: {};

    constructor(props: GenerateAttributesModel) {
        let notification = {};
        if (props.notifications?.emailAddress) {
            notification['emailAddress'] = props.notifications.emailAddress;
        }
        if (props.notifications?.whatsApp) {
            notification['whatsApp'] = props.notifications.whatsApp;
        }

        const returnObj = {
            pubKeyX: props.pubKeyX || '',
            selectedCodeMappings: {
                mrzType: props.selectedCodeMappings?.mrzType || '',
                healthDocType: props.selectedCodeMappings?.healthDocType || '',
                issuingCountryISOCode:
                    props.selectedCodeMappings?.issuingCountryISOCode || '',
                givenNames: props.selectedCodeMappings?.givenNames || '',
                lastNames: props.selectedCodeMappings?.lastNames || '',
                sex: props.selectedCodeMappings?.sex || '',
                dateOfBirth: props.selectedCodeMappings?.dateOfBirth || '',
                idDocType: props.selectedCodeMappings?.idDocType || '',
                idDocNumber: props.selectedCodeMappings?.idDocNumber || '',
                line2: props.selectedCodeMappings?.line2 || [],
            },
            notifications: notification,
        };

        if (Object.keys(healthCredentialsCSVSample).includes('ccAddress')) {
            returnObj.selectedCodeMappings['ccAddress'] =
                props.selectedCodeMappings?.ccAddress || '';
        }
        if (Object.keys(healthCredentialsCSVSample).includes('bccAddress')) {
            returnObj.selectedCodeMappings['bccAddress'] =
                props.selectedCodeMappings?.bccAddress || '';
        }
        return returnObj;
    }
}
