import {
    updateStreamEvents,
    clearStreamData,
    onStreamMessage,
} from '../actions';
import { Action, createReducer, on } from '@ngrx/store';
import { StreamState } from '../state';
import { cloneDeep } from 'lodash-es';
const initialState: StreamState = {
    streamEvents: [],
};

const streamReducer = createReducer(
    initialState,
    on(onStreamMessage, (state, action) => {
        if (!state[action.node]) {
            return state;
        }

        const _s = { ...state };
        _s[action.node].unshift(action.data);

        if (_s[action.node].length >= 600) {
            _s[action.node] = _s[action.node].slice(0, 600);
        }
        return _s;
    }),
    on(updateStreamEvents, (state, action) => {
        const data: { [key: string]: any } = cloneDeep(action);
        delete data.type;
        const _s = { ...state };

        Object.entries(data).forEach(([key, value]) => {
            if (!_s[key]) {
                return;
            }

            _s[key] = _s[key].reverse();
            _s[key].push(...value);
            _s[key] = _s[key].reverse();

            if (_s[key].length >= 600) {
                _s[key] = _s[key].slice(0, 600);
            }
        });
        return _s;
    }),
    on(clearStreamData, () => {
        return {
            streamEvents: [],
        };
    })
);

export default function reducer(
    state: StreamState,
    action: Action
): StreamState {
    return streamReducer(state, action);
}
