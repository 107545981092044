import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const clearOfflineDatabusData = createAction(
    types.CLEAR_OFFLINE_DATABUS_DATA
);

export const clearOfflineAppDetails = createAction(types.CLEAR_APP_DETAILS);

export const clearOfflineAppManagerNode = createAction(
    types.CLEAR_OFFLINE_APP_MANAGER_NODE,
    props<{ node: string; dataToClear: {} }>()
);

export const getOfflineApps = createAction(
    types.GET_OFFLINE_APPS,
    props<{ ipOrFqdn: string }>()
);

export const getOfflineAppsSuccess = createAction(
    types.GET_OFFLINE_APPS_SUCCESS,
    props<{ apps: any }>()
);

export const getOfflineAppsFailed = createAction(
    types.GET_OFFLINE_APPS_FAILED,
    props<{ payload: any }>()
);

export const getOfflineAppDetails = createAction(
    types.GET_OFFLINE_APP_DETAILS,
    props<{ taRef: string }>()
);
export const getOfflineAppDetailsSuccess = createAction(
    types.GET_OFFLINE_APP_DETAILS_SUCCESS,
    props<{ data: any }>()
);
export const getOfflineAppDetailsFailed = createAction(
    types.GET_OFFLINE_APP_DETAILS_FAILED,
    props<{ payload: any }>()
);

export const setOfflineAppDetailsParams = createAction(
    types.SET_OFFLINE_APP_DETAILS_PARAMS,
    props<{
        flag: string;
        applicationStatus: string;
        submittedTs: string | number;
        code: string;
        index: number;
    }>()
);

export const setOfflineIpPort = createAction(
    types.SET_OFFLINE_IP_PORT,
    props<{
        ipPort: string;
    }>()
);
