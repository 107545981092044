import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '@app/services/api.service';
import { Injectable } from '@angular/core';
import {
    getPreferencesFail,
    setPreferences,
    types,
    updateSavedSearchesPreferencesFail,
} from '../actions';
import {
    LastUsedSearch,
    PreferencesModel,
} from '@app/models/preferences.model';
import { EventHubService } from '@app/services/event-hub.service';
import { of } from 'rxjs';
import { SearchRowModel } from '@app/models/search-row.model';

@Injectable()
export class UserEffects {
    getUserPreference$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_PREFERENCES),
            mergeMap(() =>
                this.api.getUserPreference().pipe(
                    map((response: any) => {
                        const modifiedSinceUserPreference =
                            response.headers.get('last-modified');
                        return setPreferences({
                            modifiedSinceUserPreference,
                            preferences: new PreferencesModel(
                                response.body.data.preferences
                            ),
                        });
                    }),
                    catchError(() => of(getPreferencesFail()))
                )
            )
        )
    );

    saveUserPreference$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    types.UPDATE_SETTINGS_PREFERENCES,
                    types.UPDATE_PAGE_PREFERENCES,
                    types.UPDATE_LAST_USED_SEARCH_PREFERENCES
                ),
                mergeMap((action: { type: string; [key: string]: any }) => {
                    const type = action.type;
                    delete action.type;

                    let body: { preferences: PreferencesModel };
                    switch (type) {
                        case types.UPDATE_SETTINGS_PREFERENCES:
                            body = {
                                preferences: action as any,
                            };
                            break;
                        case types.UPDATE_PAGE_PREFERENCES:
                            if (action.subNode) {
                                body = {
                                    preferences: {
                                        pages: {
                                            [action.node]: {
                                                [action.subNode]: action.data,
                                            },
                                        },
                                    },
                                };
                            } else {
                                body = {
                                    preferences: {
                                        pages: { [action.node]: action.data },
                                    },
                                };
                            }
                            break;
                        case types.UPDATE_LAST_USED_SEARCH_PREFERENCES:
                            body = {
                                preferences: {
                                    searchQueries: action as any,
                                },
                            };
                            break;
                    }

                    return this.api.saveUserPreference(body).pipe();
                })
            ),
        { dispatch: false }
    );

    savedSearches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.UPDATE_SAVED_SEARCHES_PREFERENCES),
            mergeMap(
                (action: {
                    type: string;
                    hideSnackBar?: boolean;
                    savedSearches: { [key: string]: SearchRowModel[] };
                }) => {
                    delete action.type;
                    const hideSnackBar = action.hideSnackBar || false;
                    delete action.hideSnackBar;
                    return this.api
                        .saveUserPreference({
                            preferences: { searchQueries: action },
                        })
                        .pipe(
                            map((response: any) => {
                                const [key, value] = Object.entries(
                                    action.savedSearches
                                )[0];
                                if (!hideSnackBar) {
                                    this.eventHub.isSavedSearchUpdated.emit({
                                        action: value ? 'save' : 'delete',
                                        searchName: key,
                                    });
                                } else {
                                    this.eventHub.isSavedSearchUpdated.emit({
                                        action: 'rename',
                                    });
                                }

                                return setPreferences({
                                    preferences: response.data.preferences,
                                });
                            }),
                            catchError(() => {
                                this.eventHub.isSavedSearchUpdated.emit();
                                return of(updateSavedSearchesPreferencesFail());
                            })
                        );
                }
            )
        )
    );

    renameSavedSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.RENAME_SAVED_SEARCH),
            mergeMap(
                (action: {
                    type: string;
                    lastUsedSearch: LastUsedSearch;
                    savedSearches: { [key: string]: SearchRowModel[] };
                }) => {
                    delete action.type;
                    return this.api
                        .saveUserPreference({
                            preferences: { searchQueries: action },
                        })
                        .pipe(
                            map((response: any) => {
                                this.eventHub.isSavedSearchUpdated.emit({
                                    action: 'rename',
                                    showSnackBar: true,
                                });
                                return setPreferences({
                                    preferences: response.data.preferences,
                                });
                            }),
                            catchError(() => {
                                this.eventHub.isSavedSearchUpdated.emit();
                                return of(updateSavedSearchesPreferencesFail());
                            })
                        );
                }
            )
        )
    );

    setPinCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.SET_PIN_CODE),
            mergeMap((action: any) =>
                this.api.generatePinCode(action.code).pipe(
                    map((response: any) => {
                        if (response.status) {
                            return {
                                type: types.SET_PIN_CODE_SUCCESS,
                            };
                        }
                    }),
                    catchError((error) =>
                        of({
                            type: types.SET_PIN_CODE_FAILED,
                            payload: error,
                        })
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private api: ApiService,
        private eventHub: EventHubService
    ) {}
}
