import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const getNotificationsAppsCount = createAction(
    types.GET_NOTIFICATIONS_APPS_COUNT,
    props<{ body: any; node: string }>()
);

export const getNotificationsAppsCountSuccess = createAction(
    types.GET_NOTIFICATIONS_APPS_COUNT_SUCCESS,
    props<{ payload: any }>()
);

export const getNotificationsAppsCountFailed = createAction(
    types.GET_NOTIFICATIONS_APPS_COUNT_FAILED,
    props<{ token: string }>()
);
export const setSpecificNotificationsAppsCount = createAction(
    types.SET_SPECIFIC_NOTIFICATIONS_APPS_COUNT,
    props<{ storeNode: 'expedited' | 'updated'; count: number }>()
);
