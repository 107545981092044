import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
    valueError = false;
    eventName = '';
    value = '';
    detailsPage = false;
    isLoading = false;
    isResetSuccess = false;
    isResetFail = false;

    constructor(
        public dialogRef: MatDialogRef<AlertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        if (this.data.appDetail) {
            this.detailsPage = this.data.appDetail;
        } else {
            this.detailsPage = false;
        }

        this.eventName = this.data.event;
    }

    onBtnClick(action: string): void {
        if (action === 'reset') {
            this.isLoading = true;
            this.data.apiService.resetPassword(this.value).subscribe({
                next: () => {
                    this.isResetSuccess = true;
                    setTimeout(() => {
                        this.dialogRef.close();
                        this.isLoading = false;
                        this.isResetSuccess = false;
                    }, 3000);
                },
                error: () => {
                    this.isResetFail = true;
                    setTimeout(() => {
                        this.dialogRef.close();
                        this.isLoading = false;
                        this.isResetFail = false;
                    }, 3000);
                },
            });
        } else {
            this.dialogRef.close({
                action,
                originalEvent: this.eventName,
                value: this.value,
            });
        }
    }
}
