export const dropdownOptionsConfig = [];

export const purposeOfVisit = [];
export const flagCode = [];
export const gender = [];
export const taPurposeOfVisit = [];

export const embCardPurposeOfVisit = [];
export const searchPageDropdownOptions = [];

export const redeemStatus = [];

export const bannerIcons = [];
export const bannerTags = [];

export const watchlistMatchesCategories = [];
export const documentAgeChip = [];
export const exclusionOptions = [];
export const emailNotificationTypes = [];
//used in purpose of visit config form
export const purposeVisitConfig = [];
export const signInMethods = [];
export const languagesDropdown = [
    { key: 'en', text: 'forms.english', flagCode: 'us' },
    { key: 'es', text: 'forms.spanish', flagCode: 'es' },
    { key: 'fr', text: 'forms.french', flagCode: 'fr' },
    { key: 'pt', text: 'forms.portuguese', flagCode: 'pt' },
];
export const purposeVisitGroups = [];
export const individualQuestions = [];
export const paymentsByOrderStatus = [];
export const paymentsByPaymentType = [];
export const booleanOptions = [
    { key: 'false', label: 'forms.false' },
    { key: 'true', label: 'forms.true' },
];
export const rangePickerOptions = [];
export const tripInfoDates = [];
export const eligibilityRequirements = [];
export const configTypes = [];
