import { SearchType } from '@app/models/elasticSearch';

export const templateElasticSearches: {
    [key: string]:
        | {
              includes?: Array<string>;
              excludes?: Array<string>;
              searchType: string;
              parentStoreNode: string;
              storeNode: string;
              childStoreNode?: string;
              dataNode: string;
              default?: boolean;
              doNotUpdateAppsUi?: boolean;
          }
        | { [key: string]: any };
} = {
    RECENT_ENCOUNTERS: {
        excludes: ['_class', 'id'],
        searchType: SearchType.encounters,
        parentStoreNode: 'appsManager',
        storeNode: 'encounters',
        dataNode: 'allData',
        default: true,
    },
    ENCOUNTERS_BY_TYPE: {
        excludes: ['id'],
        searchType: SearchType.encounters,
        parentStoreNode: 'appsManager',
        storeNode: 'encounters',
        dataNode: 'allData',
        default: false,
    },
    ENCOUNTERS_BY_DATE: {
        excludes: ['id'],
        searchType: SearchType.encounters,
        parentStoreNode: 'appsManager',
        storeNode: 'encounters',
        dataNode: 'allData',
        default: false,
    },
    ENCOUNTERS_BY_TA_REFERENCE: {
        excludes: [
            'id',
            'arrivalCarrier',
            'arrivalFlightNumber',
            'nationality',
        ],
        searchType: SearchType.encounters,
        parentStoreNode: 'appDetails',
        storeNode: 'encounters',
        dataNode: '',
        default: false,
    },
    ENCOUNTERS_BY_LOCATION: {
        excludes: ['id'],
        searchType: SearchType.encounters,
        parentStoreNode: 'appsManager',
        storeNode: 'encounters',
        dataNode: 'allData',
        default: false,
    },
    ENCOUNTERS_BY_ARRIVAL_FLIGHT: {
        excludes: ['id'],
        searchType: SearchType.encounters,
        parentStoreNode: 'appsManager',
        storeNode: 'encounters',
        dataNode: 'allData',
        default: false,
    },
    ENCOUNTERS_BY_ARRIVAL_FLIGHT_AND_DATE: {
        excludes: ['id'],
        searchType: SearchType.encounters,
        parentStoreNode: 'appsManager',
        storeNode: 'encounters',
        dataNode: 'allData',
        default: false,
    },
    ENCOUNTERS_BY_DEPARTURE_FLIGHT_AND_DATE: {
        excludes: ['id'],
        searchType: SearchType.encounters,
        parentStoreNode: 'appsManager',
        storeNode: 'encounters',
        dataNode: 'allData',
        default: false,
    },
    EXCLUDE_ENCOUNTERS_BY_TYPE: {
        excludes: ['id'],
        searchType: SearchType.encounters,
        parentStoreNode: 'appsManager',
        storeNode: 'encounters',
        dataNode: 'allData',
        default: false,
    },
    ENCOUNTERS: {
        searchType: SearchType.encounters,
        parentStoreNode: 'appsManager',
        storeNode: 'encounters',
        dataNode: 'allData',
        default: false,
    },
    EMAILS: {
        excludes: ['id'],
        searchType: SearchType.emails,
        parentStoreNode: 'appsManager',
        storeNode: 'emailLog',
        dataNode: 'allData',
        default: true,
    },
    EMAILS_BY_TA_REFERENCE: {
        excludes: ['id'],
        searchType: SearchType.emails,
        parentStoreNode: 'appDetails',
        storeNode: 'emailLogs',
        dataNode: '',
        default: false,
    },
    EMAILS_BY_EMAIL_ADDRESS: {
        excludes: ['id'],
        searchType: SearchType.emails,
        parentStoreNode: 'appsManager',
        storeNode: 'emailLogs',
        dataNode: 'allData',
        default: false,
    },
    TA_COUNTS_PER_ARRIVAL_FLIGHT: {
        fromDate: '',
        toDate: '',
        enrollmentTypes: ['TA', 'TACITIZEN'],
        parentStoreNode: 'appsManager',
        storeNode: 'flightDashboard',
        dataNode: '',
    },
    TA_COUNTS_PER_DEPARTURE_FLIGHT: {
        fromDate: '',
        toDate: '',
        enrollmentTypes: ['EMBARKATIONCARD'],
        parentStoreNode: 'appsManager',
        storeNode: 'outbound',
        dataNode: '',
    },
    TA_COUNTS_PER_RESIDENCE: {
        date: '',
        enrollmentTypes: ['TA', 'TACITIZEN'],
        parentStoreNode: 'appsManager',
        storeNode: 'hotelDashboard',
        dataNode: 'allData',
    },
    TAS_IN_RESIDENCE_BY_DATE: {
        googlePlacesId: '',
        date: '',
        enrollmentTypes: ['TA', 'TACITIZEN'],
        _source: {
            includes: [
                'enrollmentType',
                'userSubmittedTravelApplicationRequest.trip_info.arrivalFlightNumber',
                'userSubmittedTravelApplicationRequest.trip_info.arrivalCarrier',
                'userSubmittedTravelApplicationRequest.trip_info.originCountry',
                'userSubmittedTravelApplicationRequest.applicant_contact_info',
                'userSubmittedTravelApplicationRequest.trip_info.purposeVisit',
                'taApproverInfo',
                'userSubmittedTravelApplicationRequest.trip_info.residenceInCountry',
                'userSubmittedTravelApplicationRequest.trip_info.arrivalDate',
                'userSubmittedTravelApplicationRequest.trip_info.expedited',
                'passportSummary.issuer',
                'passportSummary.number',
                'passportSummary.nationality',
                'passportSummary.forenames',
                'passportSummary.surname',
                'passportSummary.expiry',
                'passportSummary.dob',
                'passportSummary.sex',
                'taReference',
                'createdTs',
                'taStatus',
                'flagCode',
            ],
        },
        parentStoreNode: 'appsManager',
        storeNode: 'hotelApplications',
        dataNode: '',
    },
    PAYMENTS: {
        excludes: [],
        includes: [],
        searchType: SearchType.payments,
        parentStoreNode: 'appsManager',
        storeNode: 'payments',
        dataNode: 'allData',
        default: true,
    },
    PAYMENTS_BY_ENROLLMENT_REFERENCE: {
        excludes: [],
        searchType: SearchType.payments,
        parentStoreNode: 'appsManager',
        storeNode: 'payments',
        dataNode: 'allData',
        default: false,
    },
    PAYMENTS_BY_ORDER_STATUS: {
        excludes: [],
        searchType: SearchType.payments,
        parentStoreNode: 'appsManager',
        storeNode: 'payments',
        dataNode: 'allData',
        default: false,
    },
    PAYMENTS_BY_PAYMENT_INTENT: {
        excludes: [],
        searchType: SearchType.payments,
        parentStoreNode: 'appsManager',
        storeNode: 'payments',
        dataNode: 'allData',
        default: false,
    },
    PAYMENTS_BY_TYPE: {
        excludes: [],
        searchType: SearchType.payments,
        parentStoreNode: 'appsManager',
        storeNode: 'payments',
        dataNode: 'allData',
        default: false,
    },
    TA_PREVIOUS_APPLICATIONS: {
        searchType: SearchType.core,
        parentStoreNode: 'appsManager',
        storeNode: 'previousApplications',
        dataNode: 'allData',
        default: false,
        doNotUpdateAppsUi: true,
    },
    DOCUMENT_PARSE_LOGS: {
        excludes: ['format', 'id'],
        searchType: SearchType.documentlogs,
        parentStoreNode: 'appsManager',
        storeNode: 'documentMlLog',
        dataNode: 'allData',
        default: true,
    },
    TAS_NON_PAYMENT_OVERRIDE: {
        searchType: SearchType.core,
        parentStoreNode: 'appsManager',
        storeNode: 'incompleteAuditLogs',
        dataNode: 'allData',
    },
    vessels: {
        inbound: {
            TA_COUNTS_PER_ARRIVAL_FLIGHT: {
                fromDate: '',
                toDate: '',
                enrollmentTypes: ['TAMT', 'TAMTCITIZEN'],
                parentStoreNode: 'appsManager',
                storeNode: 'vessels',
                childStoreNode: 'inbound',
                dataNode: '',
            },
        },
        outbound: {
            TA_COUNTS_PER_DEPARTURE_FLIGHT: {
                fromDate: '',
                toDate: '',
                enrollmentTypes: ['TAMT', 'TAMTCITIZEN'],
                parentStoreNode: 'appsManager',
                storeNode: 'vessels',
                childStoreNode: 'outbound',
                dataNode: '',
            },
        },
    },
};
