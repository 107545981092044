import {
    Note,
    TaValidationInfo,
    TestSummary,
} from './services/utilityService.model';

export class ApplicantCard {
    age?: number;
    status?: string;
    personUrl?: string;
    appReference?: string;
    appCreation?: string;
    surname?: string;
    forenames?: string;
    type?: string;
    issuer?: string;
    number?: string;
    sex?: string;
    nationality?: string;
    expiry?: string;
    statusClass?: string;
    arrivalDate?: string;
    departureDate?: string;
    taStatus?: string;
    expedited?: boolean;
    healthInfo?: GeneralInfoModel;
    customsInfo?: GeneralInfoModel;
    insuranceInfo?: GeneralInfoModel;
    flaggedAnswers?: string[];
    flagCode?: string;
    countryOrigin?: string;
    arrivalFlightNumber?: string;
    departureFlightNumber?: string;
    arrivalCarrier?: string;
    departureCarrier?: string;
    arrivalCarrierLogo?: string;
    departureCarrierLogo?: string;
    applicantContactInfo?: {
        addressLineOne: string;
        addressLineTwo?: string;
        phoneNumber: string;
        country: string;
        email: string;
    };
    taValidation?: {
        IDDocSummary: boolean;
        tripInfo: boolean;
    };
    notes?: string;
    arrivalFrom?: string;
    arrivalAt?: string;
    publicUpdatedTs?: string;
    isUpdated?: boolean;
    index: number;
    purposeVisit?: string;
    departureFrom?: string;
    visitedCountries?: Array<string>;
    redeemItems?: Array<any>;
    submitterIP?: string;
    isFlagged?: boolean;
    enrollmentType?: string;
    outboundCustomsInfo?: GeneralInfoModel;
    paymentData?: { CardFingerprint: string };
    decisionTs?: string | number;
    originCountryCode?: string;
    tier?: string;
    marketingOptInConsent?: boolean;
    taApproverInfo?: {
        Notes?: Array<Note>;
        TAValidation?: Array<TaValidationInfo>;
        TestSummary?: Array<TestSummary>;
    };
    autoApproveAt?: string | number;

    constructor() {
        return {
            index: 0,
            age: 0,
            status: '',
            personUrl: '',
            appReference: '',
            appCreation: '',
            surname: '',
            forenames: '',
            type: '',
            issuer: '',
            number: '',
            sex: '',
            nationality: '',
            expiry: '',
            statusClass: '',
            arrivalDate: '',
            departureDate: '',
            taStatus: '',
            expedited: false,
            healthInfo: {
                answers: [],
                healthCardVersion: '',
                tags: [],
            },
            customsInfo: { answers: [], healthCardVersion: '', tags: [] },
            insuranceInfo: { answers: [], healthCardVersion: '', tags: [] },
            flaggedAnswers: [],
            flagCode: '',
            countryOrigin: '',
            arrivalFlightNumber: '',
            departureFlightNumber: '',
            arrivalCarrier: '',
            departureCarrier: '',
            arrivalCarrierLogo: '',
            departureCarrierLogo: '',
            applicantContactInfo: {
                addressLineOne: '',
                addressLineTwo: '',
                phoneNumber: '',
                country: '',
                email: '',
            },
            taValidation: {
                IDDocSummary: false,
                tripInfo: false,
            },
            notes: '',
            arrivalFrom: '',
            arrivalAt: '',
            publicUpdatedTs: '',
            isUpdated: false,
            purposeVisit: '',
            departureFrom: '',
            visitedCountries: [],
            redeemItems: [],
            submitterIP: '',
            isFlagged: false,
            enrollmentType: '',
            outboundCustomsInfo: {
                answers: [],
                healthCardVersion: '',
                tags: [],
            },
            paymentData: { CardFingerprint: '' },
            decisionTs: '',
            originCountryCode: '',
            tier: '',
            marketingOptInConsent: false,
            taApproverInfo: {
                Notes: [],
                TAValidation: [],
                TestSummary: [],
            },
            autoApproveAt: '',
        };
    }
}

class GeneralInfoModel {
    answers: Array<{
        answer?: string;
        question?: string;
        questionKey?: string;
        type?: string;
    }>;
    healthCardVersion?: string;
    tags?: [];
}
