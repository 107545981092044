import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpEvent,
    HttpRequest,
    HttpHandler,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '@app/services/utility.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/state';
import { user } from '@app/store/selectors';
import { AuthenticationService } from '@app/services/authentication.service';
import { first, mergeMap } from 'rxjs/operators';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
    constructor(
        private utilityService: UtilityService,
        private store: Store<AppState>,
        private authenticationService: AuthenticationService
    ) {}

    intercept(
        httpRequest: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const accessToken = httpRequest.headers
            ?.get('Authorization')
            ?.replace('Bearer ', '');
        if (accessToken) {
            if (!this.authenticationService.currentUser) {
                return this.authenticationService.currentUser$
                    .pipe(first())
                    .pipe(
                        mergeMap(() => {
                            return this.intercept(httpRequest, next);
                        })
                    );
            }
            if (this.authenticationService.isAccessTokenRenewing) {
                return this.authenticationService.isAccessTokenRenewing$
                    .pipe(first())
                    .pipe(
                        mergeMap(() =>
                            this.store
                                .select(user)
                                .pipe(first())
                                .pipe(
                                    mergeMap((userItem) => {
                                        return next.handle(
                                            httpRequest.clone({
                                                setHeaders: {
                                                    Authorization:
                                                        'Bearer ' +
                                                        userItem.accessToken,
                                                },
                                            })
                                        );
                                    })
                                )
                        )
                    );
            }
            if (this.utilityService.isJWTTokenExpired(accessToken)) {
                this.authenticationService.checkToken();
                return this.intercept(httpRequest, next);
            }
            return next.handle(httpRequest);
        }
        return next.handle(httpRequest);
    }
}
