/* eslint-disable id-blacklist */
import {
    AncillariesItem,
    AttachmentModel,
    MatchRecord,
    TierItems,
} from '@app/models/services/utilityService.model';
import { GalleryAttachmentModel } from '@app/models/galleryAttachment';
import { selectDataBus } from '@app/store/selectors';
import { AllUsersModel, UserInfoModel } from '@app/models/user';
import { Languages } from '../../assets/json-files/languages';
import { ApplicationDetails } from '@app/models/appDetails';
import { ApplicantCard } from '@app/models/applicantCard';
import { TranslateService } from '@ngx-translate/core';
import { showTabsFor } from '@app/config/showTabsFor';
import { AppConfig } from 'environments/environment';
import { FlightCard } from '@app/models/flightCard';
import { UtilityService } from './utility.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    PaymentFormattedData,
    EncountersDataModel,
    MappedEncounters,
    GroupedAppsModel,
    PaymentAPIData,
    HotelDataModel,
    HotelBodyModel,
    CountryLists,
    CMSDataModel,
    searchResultsTabs,
    ApplicationModel,
    EmailLogAPIData,
    EmailLogModel,
    ApplicationDataModel,
    ContactInfoModel,
    flightCounts,
    DocumentMlLog,
    WatchlistMatches,
    NamedSearchData,
    NamedSearchItem,
    BiometricSearchResult,
    OfflineApplicationModel,
    IncompleteAuditLogModel,
    MappedIncompleteAuditLogModel,
} from '@app/models/services/mappingService.model';
import {
    MappedInputAttributes,
    InputAttributes,
    HCProfileAPI,
    ProfileData,
} from '@app/models/Profiles.model';
import {
    SupportingDocumentsModel,
    DatabaseContentModel,
    MappedLanguages,
    QuestionsModel,
    LanguagesModel,
    AirlinesModel,
    PageModel,
    SupportedTemplateModel,
    EligibilityWidget,
} from '@app/models/database';
import { setApplicationNode } from '@app/store/actions';
import { AppState } from '@app/store/state';
import { cloneDeep } from 'lodash-es';
import { TimeConversionService } from './time-conversion.service';
import { enrollmentTypesColors } from '@app/config/constants';
import { MaritimeCard } from '@app/models/maritimeCard';

@Injectable({
    providedIn: 'root',
})
export class MappingService {
    establishments = [];
    appConfig = AppConfig;

    constructor(
        private utilityService: UtilityService,
        private translate: TranslateService,
        public store: Store<AppState>,
        private timeConversionService: TimeConversionService
    ) {
        const dataBusSelector$ = store.select(selectDataBus);

        dataBusSelector$.subscribe((data) => {
            this.establishments = data.cms_data.establishments;
        });
    }

    mappingUsers(userData: AllUsersModel): Array<UserInfoModel> {
        const userArray: Array<UserInfoModel> = [];
        if (
            this.utilityService.userRole === 'admin' ||
            this.utilityService.userRole === 'admin+'
        ) {
            userData = userData ? this.hideSuperAdmins(userData) : {};
        }
        // eslint-disable-next-line guard-for-in
        for (const i in userData) {
            userArray.push(new UserInfoModel({ ...userData[i], uid: i }));
        }

        return userArray;
    }

    mappingLanguages(languages: LanguagesModel): Array<MappedLanguages> {
        const languagesArray: Array<MappedLanguages> = [];

        if (languages) {
            // eslint-disable-next-line
            Object.entries(languages).forEach(([key, value]) => {
                languagesArray.push(new MappedLanguages(value));
            });
        }

        return languagesArray;
    }

    mappingPages(pages: PageModel[]): PageModel[] {
        const pagesArray: PageModel[] = [];
        if (pages) {
            pages.forEach((element) => {
                const titleObj = {};
                const bodyObj = {};

                Object.entries(element.title).forEach(([key, value]) => {
                    titleObj[`title_${key}`] = value;
                });
                Object.entries(element.body).forEach(([key, value]) => {
                    bodyObj[`body_${key}`] = value;
                });

                pagesArray.push(
                    new PageModel({
                        ...element,
                        title: titleObj,
                        body: bodyObj,
                    })
                );
            });
        }
        return pagesArray;
    }

    mappingDocuments(
        documents: Array<SupportingDocumentsModel> = []
    ): SupportingDocumentsModel[] {
        const documentsArray: Array<SupportingDocumentsModel> = [];

        documents.forEach((element) => {
            const descriptionObj = {};

            if (element.document_description) {
                Object.entries(element.document_description).forEach(
                    ([key, value]) => {
                        descriptionObj[`description_${key}`] = value || '';
                    }
                );
            }

            const documentsObj: SupportingDocumentsModel =
                new SupportingDocumentsModel({
                    ...element,
                    document_description: descriptionObj,
                });

            documentsArray.push(new SupportingDocumentsModel(documentsObj));
        });

        return documentsArray;
    }

    normalizeLanguages(languages: MappedLanguages[]): LanguagesModel {
        const languageObject: LanguagesModel = {};

        if (languages) {
            languages.forEach((element) => {
                languageObject[element.languageCode] = element;
            });
        }

        return languageObject;
    }

    normalizePages(
        pages: Array<PageModel> = [],
        languages: Array<MappedLanguages> = []
    ): Array<PageModel> {
        const pagesArray: Array<PageModel> = [];

        pages.forEach((element) => {
            const titleObj = {};
            const bodyObj = {};

            Object.entries(element.title).forEach(([key, value]) => {
                const key_code = key.split('_')[1];
                titleObj[key_code] = value;
                if (key_code !== 'en' && value === '') {
                    titleObj[key_code] = element.title['title_en'];
                }
            });
            Object.entries(element.body).forEach(([key, value]) => {
                const key_code = key.split('_')[1];
                bodyObj[key_code] = value;
                if (key_code !== 'en' && value === '') {
                    bodyObj[key_code] = element.body['body_en'];
                }
            });

            languages.forEach((language) => {
                if (!titleObj[language.languageCode]) {
                    titleObj[language.languageCode] = titleObj['en'];
                }

                if (!bodyObj[language.languageCode]) {
                    bodyObj[language.languageCode] = bodyObj['en'];
                }
            });

            pagesArray.push(
                new PageModel({
                    ...element,
                    title: titleObj,
                    body: bodyObj,
                })
            );
        });

        return pagesArray;
    }

    normalizeQuestions(
        questions: Array<QuestionsModel> = [],
        languages: Array<MappedLanguages> = []
    ): QuestionsModel[] {
        const questionsArray: QuestionsModel[] = [];

        questions.forEach((element) => {
            const questionObj = {};

            Object.entries(element.question).forEach(([key, value]) => {
                questionObj[key] = value;

                if (key !== 'en' && value === '') {
                    questionObj[key] = element.question['en'];
                }
            });

            languages.forEach((language) => {
                if (!questionObj[language.languageCode]) {
                    questionObj[language.languageCode] = questionObj['en'];
                }
            });

            questionsArray.push(
                new QuestionsModel({
                    ...element,
                    question: questionObj,
                })
            );
        });

        return questionsArray;
    }

    normalizeDocuments(
        documents: Array<SupportingDocumentsModel> = [],
        languages: Array<MappedLanguages> = []
    ): SupportingDocumentsModel[] {
        const documentsArray: SupportingDocumentsModel[] = [];

        documents.forEach((element) => {
            const descriptionObj = {};
            const titleObj = {};

            Object.entries(element.document_description).forEach(
                ([key, value]) => {
                    const key_code = key.split('_')[1];
                    descriptionObj[key_code] = value;
                    if (key_code !== 'en' && value === '') {
                        descriptionObj[key_code] =
                            element.document_description['description_en'];
                    }
                }
            );

            Object.entries(element.document_title).forEach(([key, value]) => {
                titleObj[key] = value;
                if (key !== 'en' && value === '') {
                    titleObj[key] = element.document_title['en'];
                }
            });

            languages.forEach((language) => {
                if (!titleObj[language.languageCode]) {
                    titleObj[language.languageCode] = titleObj['en'];
                }

                if (!descriptionObj[language.languageCode]) {
                    descriptionObj[language.languageCode] =
                        descriptionObj['en'];
                }
            });
            documentsArray.push({
                ...element,
                document_description: descriptionObj,
                document_title: titleObj,
            });
        });

        return documentsArray;
    }

    normalizeSaveData(
        data: CountryLists,
        cmsData: CMSDataModel = new CMSDataModel({}),
        languages: Array<MappedLanguages> = [],
        node: string = ''
    ): { data: Object } {
        //Getting node data
        let nodeData = cmsData[node];

        if (node === 'supportingDocuments') {
            nodeData = this.normalizeDocuments(
                cmsData.supportingDocuments,
                languages
            );
        } else if (node === 'pages') {
            nodeData = this.normalizePages(cmsData.pages, languages);
        } else if (node === 'questions') {
            nodeData = this.normalizeQuestions(cmsData.questions, languages);
        } else if (node === 'languages') {
            nodeData = this.normalizeLanguages(cmsData.languages);
        } else if (node === 'airlines') {
            nodeData = this.mappingAirlines(cmsData.airlines);
        }

        const databaseStructure: DatabaseContentModel =
            new DatabaseContentModel({});

        const bodyData = { data: {} };
        if (databaseStructure.content_public[node] !== undefined) {
            bodyData.data = {
                content_public: {
                    [node]: nodeData,
                },
            };
        } else if (node === 'country_lists') {
            bodyData.data = {
                content_private: {
                    country_watch_list: data.countryWatchList,
                    country_whitelist: data.countryWhiteList,
                    country_low_risk: data.countryLowRisk,
                    country_medium_risk: data.countryMediumRisk,
                    country_yellow_fever: data.countryYellowFever,
                    country_pox: data.countryPOX,
                    country_ebola: data.countryEbola,
                    country_polio: data.countryPolio,
                    country_customs: data.countryCustoms,
                    country_visaexemption: data.countryVisaExemption,
                    country_visaonarrival: data.countryVisaOnArrival,
                },
            };
        } else if (node === 'establishments') {
            bodyData.data = {
                content_private: {
                    establishments: cmsData.establishments,
                    establishmentsTags: data || [],
                },
            };
        } else if (databaseStructure.content_private[node]) {
            bodyData.data = {
                content_private: {
                    [node]: nodeData,
                },
            };
        }
        return bodyData;
    }

    groupApplications(
        apps:
            | Array<FlightCard>
            | Array<ApplicantCard>
            | Array<searchResultsTabs> = [],
        path: string = '',
        node?: string,
        subPath?: string
    ): GroupedAppsModel {
        const tabKeys = subPath
            ? showTabsFor({ utilityService: this.utilityService })[path][
                  subPath
              ]
            : showTabsFor({ utilityService: this.utilityService })[path] || {};

        const applications = {};
        apps.forEach((element) => {
            //added the correct index for the tabs since the original index corresponds to the total number of elements RK
            Object.entries(tabKeys).forEach(([key, value]: any) => {
                //strings and boolean values check RK
                if (key !== 'showAllData' && !value.isDynamic) {
                    if (
                        (typeof value[0] === 'boolean' && element[key]) ||
                        value.indexOf(element[key]) >= 0
                    ) {
                        if (applications[key]) {
                            applications[key].push(element);
                        } else {
                            applications[key] = [element]; // Creates the key inside the object if it doesn't exist :HG
                        }
                    } else if (
                        element[key] &&
                        Array.isArray(element[key]) &&
                        element[key].length > 0
                    ) {
                        //This if else handles the case where we are looking and comparing to an array of any - such as visitedCountries :HG
                        const data = element[key];
                        //returns an array of common items between config's array and application's array rk
                        const matches = value.filter((val) =>
                            data.includes(val)
                        );
                        if (matches.length > 0) {
                            if (applications[key]) {
                                applications[key].push(element);
                            } else {
                                applications[key] = [element]; // Creates the key inside the object if it doesn't exist :HG
                            }
                        }
                    } else if (
                        value.length > 0 &&
                        ((value[0].field && element[value[0].field]) ||
                            (value[0].value && value[0].value.length > 0))
                    ) {
                        //compares objects with nested values RK
                        value.forEach((item) => {
                            const data = element[item.field];
                            let shouldPush = true; //determines whether to add the app or not. RK
                            let index = 0;
                            for (const val of item.value) {
                                //if one of the conditions is not met, the app should not be pushed RK
                                let compareResult = false;
                                if (item.value[index].compare) {
                                    if (item.value[index].compare === '>') {
                                        compareResult =
                                            element[val.key] >
                                            val.expectedValue;
                                    } else if (
                                        item.value[index].compare === '!=='
                                    ) {
                                        compareResult =
                                            element[val.key] !==
                                            val.expectedValue;
                                    } else {
                                        compareResult =
                                            element[val.key] <
                                            val.expectedValue;
                                    }
                                }

                                if (
                                    (!data &&
                                        element[val.key] !==
                                            val.expectedValue &&
                                        !item.value[index].compare) ||
                                    (!data &&
                                        item.value[index].compare &&
                                        !compareResult) ||
                                    (data &&
                                        data[val.key] !== val.expectedValue)
                                ) {
                                    shouldPush = false;
                                    break;
                                }
                                index++;
                            }

                            if (shouldPush) {
                                //push the app to the array if the conditions are valid RK
                                // eslint-disable-next-line
                                if (applications[key]) {
                                    applications[key].push(element);
                                } else {
                                    applications[key] = [element]; // Creates the key inside the object if it doesn't exist :HG
                                }
                            }
                        });
                    } else if (
                        value.length > 0 &&
                        value[0].field &&
                        element[value[0].field]
                    ) {
                        //compares objects with nested values RK
                        value.forEach((item) => {
                            const data = element[item.field];
                            let shouldPush = true; //determines whether to add the app or not. RK

                            for (const val of item.value) {
                                //if one of the conditions is not meet, the app should not be pushed RK
                                if (data[val.key] !== val.expectedValue) {
                                    shouldPush = false;
                                    break;
                                }
                            }

                            if (shouldPush) {
                                //push the app to the array if the conditions are valid RK
                                // eslint-disable-next-line
                                if (applications[key]) {
                                    applications[key].push(element);
                                } else {
                                    applications[key] = [element]; // Creates the key inside the object if it doesn't exist :HG
                                }
                            }
                        });
                    }
                } else {
                    //using node ot match specific pages RK
                    if (
                        typeof value === 'object' &&
                        ((value.node && node === value.node) || !value.node)
                    ) {
                        //push all data into the applications object RK
                        element[value.readField].forEach((item) => {
                            if (!applications['allData']) {
                                applications['allData'] = [
                                    { ...item, index: 0 },
                                ];
                            } else if (
                                applications['allData'].findIndex(
                                    (application) =>
                                        application.appReference ===
                                        item.appReference
                                ) < 0
                            ) {
                                //avoid pushing duplicate apps RK

                                applications['allData'].push({
                                    ...item,
                                    index: applications['allData'].length,
                                });
                            }
                        });

                        //push the tabs into the applications object, concatKey is currently used
                        //to add the ancillaries values to the key RK
                        applications[
                            `${value.concatKey ? value.concatKey : ''}${
                                element[value.readKey]
                            }`
                        ] = element[value.readField];
                    }
                }
            });
        });

        return applications;
    }

    hideSuperAdmins(userData: AllUsersModel): AllUsersModel | {} {
        let users = {};

        if (userData) {
            users = cloneDeep(userData);
            Object.entries(users).forEach(([key, value]) => {
                if (value['role'] === 'super_admin') {
                    delete users[key];
                }
            });
        }
        return users;
    }

    getAppDetails(
        appData: ApplicationDataModel = new ApplicationDataModel({})
    ): ApplicationDetails {
        const applicationDetails: ApplicationDetails = new ApplicationDetails();
        const data: ApplicationDataModel = new ApplicationDataModel(appData);
        const normalizedData = this.utilityService.normalizeObj(data);
        this.utilityService.setupPCRAttachments(data.attachments);

        Object.keys(applicationDetails.details).forEach((key) => {
            if (normalizedData[key]) {
                applicationDetails.details[key] = normalizedData[key];
            }
        });

        applicationDetails.isUpdated = this.utilityService.isUpdated(
            data.SubmittedTs,
            data.PublicUpdatedTs,
            data.modifiedTs
        );
        applicationDetails.emergencyContactInfo =
            data.emergencycontact_info?.filter((item) => {
                const values = Object.values(item);
                for (const value of values) {
                    if (value) {
                        return true;
                    }
                }
                return false;
            });

        applicationDetails.matchRecords = data.PreCheckInfo?.MatchRecords || [];

        applicationDetails.decisionInfo = this.utilityService.sortDecisionInfo(
            data.ApproverInfo?.Notes,
            {
                note: data.UserComment,
                who: this.translate.instant('profile_labels.applicant'),
                dateTime: data.SubmittedTs
                    ? this.timeConversionService.formatDate(
                          data.SubmittedTs,
                          'MMM DD, YYYY HH:mm'
                      )
                    : '',
            }
        );

        applicationDetails.taValidation.tripInfo = data.ApproverInfo
            ?.TAValidation
            ? this.utilityService.getValidationInformation(
                  'trip_info',
                  data.ApproverInfo.TAValidation
              )
            : {};

        applicationDetails.taValidation.IDDocSummary = data.ApproverInfo
            ?.TAValidation
            ? this.utilityService.getValidationInformation(
                  'IDDocSummary',
                  data.ApproverInfo.TAValidation
              )
            : {};

        applicationDetails.taValidation.attachment = data.attachments?.length
            ? this.utilityService.getAttachmentsValidationInfo(
                  data.attachments,
                  data.ApproverInfo?.TestSummary
              )
            : {};

        applicationDetails.groupReference = data.groupReference;
        applicationDetails.enrollmentReference = data.enrollmentReference;
        applicationDetails.TAReferences = data.TAReferences;
        applicationDetails.attachments = data.attachments;
        applicationDetails.SubmittedTs =
            data.SubmittedTs === 0 ? '' : data.SubmittedTs;
        applicationDetails.DecisionTs =
            data.DecisionTs === 0 ? '' : data.DecisionTs;
        applicationDetails.UpdatedTs =
            data.PublicUpdatedTs === 0 ? '' : data.PublicUpdatedTs;
        applicationDetails.flag = data.Status;
        applicationDetails.ref = data.code;
        applicationDetails.autoApproveAt = data.autoApproveAt || '';
        applicationDetails.smartApprovedByUsername =
            data.smartApprovedByUsername || '';
        applicationDetails.smartApprovedAt = data.smartApprovedAt || '';
        applicationDetails.modifiedTs = data.modifiedTs || '';
        if (data.app_version) {
            applicationDetails.submissionInformation.push({
                key: 'appVersion',
                label: data.app_version,
            });
        }
        if (data.channel) {
            applicationDetails.submissionInformation.push({
                key: 'channel',
                label: data.channel,
            });
        }
        if (data.submitterIP) {
            applicationDetails.submissionInformation.push({
                key: 'submitterIP',
                label: data.submitterIP,
            });
        }
        if (data.Payments?.length > 0 && data.Payments[0].CardFingerprint) {
            applicationDetails.submissionInformation.push({
                key: 'fingerPrint',
                label: data.Payments[0].CardFingerprint,
            });
        }

        applicationDetails.paymentInfo = this.utilityService.formatPaymentInfo(
            data.Payments
        );

        applicationDetails.purchasedItems =
            this.utilityService.getPurchasedItems(
                data.code,
                data.RedeemableItems,
                data.RedeemedItems
            );

        applicationDetails.status = this.utilityService.getApplicationStatus({
            status: data.Status,
            flagCode: data.FlagCode,
        });

        applicationDetails.flagCode = data.FlagCode;

        applicationDetails.healthAttachments =
            this.utilityService.prepareAttachmentFiles(
                data.attachments,
                data.deletedAttachments
            );

        applicationDetails.visitedCountries =
            this.utilityService.getVisitedCountries(data.health_info?.answers);
        applicationDetails.placeOfBirth = this.utilityService.getStatus(
            appData,
            'BIRTH-COUNTRY'
        );
        applicationDetails.flaggedAnswers =
            this.utilityService.flaggedAnswers(appData);
        applicationDetails.answers.healthAnswers =
            this.utilityService.vaccineAnswers(appData);
        applicationDetails.marital_status = this.utilityService.getStatus(
            appData,
            'MARITAL-STATUS'
        );
        applicationDetails.galleryAttachments = this.mapGalleryAttachments(
            data.attachments,
            applicationDetails.matchRecords,
            applicationDetails.details.surname
        );
        applicationDetails.addressInCountry =
            this.utilityService.getAddressInCountry(data.trip_info);
        applicationDetails.customs_info = appData.customs_info || {
            answers: [],
        };
        applicationDetails.health_info = appData.health_info || { answers: [] };
        applicationDetails.insurance_info = appData.insurance_info || {
            answers: [],
        };
        applicationDetails.enrollment_info = appData.enrollment_info || {
            answers: [],
        };
        //preCheckStuff START

        applicationDetails.precheckInfo.statusInfo =
            this.utilityService.getPreCheckInfo(data.PreCheckInfo);

        applicationDetails.precheckInfo.notes =
            this.utilityService.getPreCheckLogs(
                data.PreCheckInfo ? data.PreCheckInfo.Notes : []
            );

        //preCheckStuff END

        //Details START
        const age = this.utilityService.getAge(data.PassportSummary.dob);

        if (
            applicationDetails.details.departureCarrier !== '' &&
            this.utilityService.airlineCodes?.find(
                (element) =>
                    element.key === applicationDetails.details.departureCarrier
            )
        ) {
            applicationDetails.details['departureLogo'] = `${
                this.utilityService.baseUrl
            }${
                !this.utilityService.baseUrl.includes('taapi/api/')
                    ? 'taapi/api/'
                    : ''
            }logos/carrierLogos/${
                applicationDetails.details.departureCarrier
            }.png`;
        }
        if (
            applicationDetails.details.arrivalCarrier !== '' &&
            this.utilityService.airlineCodes?.find(
                (element) =>
                    element.key === applicationDetails.details.arrivalCarrier
            )
        ) {
            applicationDetails.details['arrivalLogo'] = `${
                this.utilityService.baseUrl
            }${
                !this.utilityService.baseUrl.includes('taapi/api/')
                    ? 'taapi/api/'
                    : ''
            }logos/carrierLogos/${
                applicationDetails.details.arrivalCarrier
            }.png`;
        }

        applicationDetails.details.ageCategory = age >= 18 ? 'adult' : 'child';
        applicationDetails.details.barCode =
            data.Receipt && data.Receipt.BCode ? data.Receipt.BCode : '';
        applicationDetails.details.passportNumber = data.number;
        applicationDetails.details.age = age;

        applicationDetails.details.type = this.translate.instant(
            `application_types.${
                data.trip_info?.expedited ? 'expedited' : 'standard'
            }`
        );

        applicationDetails.details.arrivalSeatNumber = data.trip_info
            ?.arrivalSeatNumber
            ? data.trip_info.arrivalSeatNumber
            : '';

        applicationDetails.details.departureSeatNumber = data.trip_info
            ?.departureSeatNumber
            ? data.trip_info.departureSeatNumber
            : '';

        applicationDetails.details.applicationDeclaration = {
            ...data.applicationDeclaration,
        };

        applicationDetails.details.nationalIDNumber = data.PassportSummary
            ?.personalNum
            ? data.PassportSummary.personalNum
            : '';

        applicationDetails.details.magicToken = data.MagicToken;

        applicationDetails.details.originCountry =
            this.utilityService.getCountryNameByCode(
                data.trip_info?.originCountry
            );

        applicationDetails.details.sex = this.utilityService.getGender(
            data.PassportSummary.sex
        );

        if (data.trip_info.nextDestination) {
            applicationDetails.details.nextDestination =
                this.utilityService.getCountryNameByCode(
                    data.trip_info.nextDestination
                );
        }
        applicationDetails.details.personUrl =
            this.utilityService.getProfileUrl(
                applicationDetails.galleryAttachments,
                applicationDetails.matchRecords,
                data.code
            );
        if (!this.utilityService.isOffline) {
            applicationDetails.details.passportImageUrl =
                this.utilityService.getAttachmentInfoByType(
                    applicationDetails.galleryAttachments,
                    'PASSPORTPAGEPHOTO'
                )?.attachments[0]?.previewUrl ||
                'src/assets/images/Broken-image-icon.svg';
        }
        if (data.intRef) {
            applicationDetails.details.intRef = data.intRef;
        }

        //Application Contact Info START

        const node: ContactInfoModel = data.applicantContactInfo; //field is initialized inside ApplicationDataModel AE

        if (node) {
            applicationDetails.details.profession = node.profession || '';
            applicationDetails.details.phone = node.phone || '';
            applicationDetails.details.address =
                this.utilityService.trimLeadingSpace(node.address_line_1) ||
                this.utilityService.trimLeadingSpace(node.address);
            applicationDetails.details.email =
                this.utilityService.trimLeadingSpace(node.email);
            applicationDetails.details.country =
                this.utilityService.trimLeadingSpace(node.country);
            applicationDetails.details.secondAddressLine =
                this.utilityService.trimLeadingSpace(node.address_line_2);
            applicationDetails.details.region = node.region
                ? this.utilityService.trimLeadingSpace(node.region)
                : '';
            applicationDetails.details.city = node.city
                ? this.utilityService.trimLeadingSpace(node.city)
                : '';

            if (node.google_places_id) {
                applicationDetails.details.addressGooglePin = {
                    id: node.google_places_id,
                };
            }
        }

        //Application Contact Info END

        //Details END

        //Language field
        if (data.language) {
            Object.entries(Languages).forEach(([key, value]) => {
                if (key === data.language) {
                    applicationDetails.language = value['nativeName'];
                }
            });
        }
        applicationDetails.details.enrollmentType = data.EnrollmentType || '';
        applicationDetails.tripInfo = data.trip_info || {};

        //used to not break test if residenceInCountry does not exist in trip info object CA
        if (data.trip_info?.residenceInCountry) {
            applicationDetails.tripInfo['residenceInCountry'] =
                data.trip_info.residenceInCountry.filter(
                    (element) =>
                        element['address_line_1'] !== '' &&
                        element['element.google_places_id'] !== ''
                );
        }
        applicationDetails.contactInfo = data.applicantContactInfo || {};
        applicationDetails.applicantInfo = data.PassportSummary || {};

        //setting vaccination and PCR result info RK

        applicationDetails.details.isVaccinated = !!appData.attachments?.find(
            (attachment) =>
                attachment.Validation?.validationData?.vaccinations?.length > 0
        );

        const pcrTest = appData.attachments?.find(
            (attachment) =>
                attachment.Validation?.validationData?.tests?.length > 0
        );
        applicationDetails.details.pcrTestResult =
            pcrTest?.Validation?.validationData?.tests
                ?.find(
                    (test) => test?.disease?.toLocaleLowerCase() === 'covid-19'
                )
                ?.result?.toLowerCase() || '';

        applicationDetails.watchlistMatches = new WatchlistMatches(
            data.ListMatches
        );
        return applicationDetails;
    }

    formatApplicationData(
        data: Array<ApplicationModel> | Array<OfflineApplicationModel> = [],
        purchaseType: string = ''
    ): Array<ApplicantCard> {
        const formattedData: Array<ApplicantCard> = [];

        for (let i = 0; i < data.length; i++) {
            const formatObj: ApplicantCard = new ApplicantCard();

            formatObj.index = i;

            const source: any = data[i]['_source']
                ? data[i]['_source']
                : data[i];

            formatObj.status = this.utilityService.getApplicationStatus({
                status: source.taStatus,
                flagCode: source.flagCode,
            });
            formatObj.autoApproveAt = source.autoApproveAt || '';
            formatObj.taStatus = source.taStatus || '';
            formatObj.flagCode = source.flagCode || '';
            switch (formatObj.taStatus) {
                case 'INVALID':
                    formatObj.statusClass = 'invalid-badge';
                    break;
                case 'VALID':
                    formatObj.statusClass = 'valid-badge';
                    break;

                default:
                    formatObj.statusClass = 'processing-badge';
                    break;
            }

            formatObj.appReference = source.taReference;
            formatObj.appCreation = source.createdTs
                ? this.timeConversionService.formatDate(
                      source.createdTs,
                      'YYYY-MM-DD HH:mm',
                      false
                  )
                : '';
            formatObj.decisionTs = source.decisionTs
                ? this.timeConversionService.formatDate(
                      source.decisionTs,
                      'YYYY-MM-DD HH:mm',
                      false
                  )
                : '';
            if (!this.utilityService.isOffline) {
                formatObj.personUrl = `${this.utilityService.baseUrl}taapi/file/x/${formatObj.appReference}/SELFIE`;
            } else {
                formatObj.personUrl = `https://${this.utilityService.ipOrFqdn}/api/selfie/${formatObj.appReference}`;
            }
            if (source.passportSummary) {
                formatObj.age = this.utilityService.getAge(
                    source.passportSummary.dob
                );
                formatObj.forenames = source.passportSummary.forenames || '';
                formatObj.surname = source.passportSummary.surname || '';
                formatObj.issuer = source.passportSummary.issuer;
                formatObj.number = source.passportSummary.number;
                formatObj.type = source.passportSummary.type;
                formatObj.sex = this.utilityService.getGender(
                    source.passportSummary.sex
                );
                formatObj.nationality = source.passportSummary.nationality;
                formatObj.expiry = source.passportSummary.expiry;
            }

            const appRequest = source.userSubmittedTravelApplicationRequest;

            if (appRequest && appRequest.applicant_contact_info) {
                formatObj.applicantContactInfo.addressLineOne =
                    this.utilityService.trimLeadingSpace(
                        appRequest.applicant_contact_info.address_line_1
                    ) ||
                    this.utilityService.trimLeadingSpace(
                        appRequest.applicant_contact_info.address
                    );

                formatObj.applicantContactInfo.addressLineTwo =
                    this.utilityService.trimLeadingSpace(
                        appRequest.applicant_contact_info.address_line_2
                    );

                formatObj.applicantContactInfo.country =
                    this.utilityService.trimLeadingSpace(
                        appRequest.applicant_contact_info.country
                    );

                formatObj.applicantContactInfo.phoneNumber =
                    appRequest.applicant_contact_info.phone;

                formatObj.applicantContactInfo.email =
                    appRequest.applicant_contact_info.email;
            }
            formatObj['flaggedAnswers'] =
                this.utilityService.flaggedAnswers(appRequest);
            if (
                appRequest &&
                (appRequest.health_info || appRequest.outboundCustoms_info)
            ) {
                if (appRequest.health_info) {
                    formatObj.healthInfo = appRequest.health_info;
                }
                if (appRequest.customs_info) {
                    formatObj.customsInfo = appRequest.customs_info;
                }
                if (appRequest.insurance_info) {
                    formatObj.insuranceInfo = appRequest.insurance_info;
                }
                if (appRequest.health_info?.answers) {
                    formatObj.visitedCountries = this.utilityService
                        .getVisitedCountries(appRequest.health_info?.answers)
                        .map((country) => country.countryCode);
                }

                if (appRequest.outboundCustoms_info) {
                    formatObj.outboundCustomsInfo =
                        appRequest.outboundCustoms_info;
                }
            }
            if (appRequest && appRequest.trip_info) {
                formatObj.departureDate = appRequest.trip_info.departureDate;
                formatObj.arrivalDate = appRequest.trip_info.arrivalDate;
                formatObj.expedited = appRequest.trip_info.expedited;

                formatObj.arrivalCarrier =
                    appRequest.trip_info.arrivalCarrier || '';
                formatObj.departureCarrier =
                    appRequest.trip_info.departureCarrier || '';
                if (
                    appRequest.trip_info.arrivalCarrier !== '' &&
                    this.utilityService.airlineCodes?.find(
                        (element) =>
                            element.key === appRequest.trip_info.arrivalCarrier
                    )
                ) {
                    formatObj.arrivalCarrierLogo = `${
                        this.utilityService.baseUrl
                    }${
                        !this.utilityService.baseUrl.includes('taapi/api/')
                            ? 'taapi/api/'
                            : ''
                    }logos/carrierLogos/${
                        appRequest.trip_info.arrivalCarrier
                    }.png`;
                }
                if (
                    appRequest.trip_info.departureCarrier !== '' &&
                    this.utilityService.airlineCodes?.find(
                        (element) =>
                            element.key ===
                            appRequest.trip_info.departureCarrier
                    )
                ) {
                    formatObj.departureCarrierLogo = `${
                        this.utilityService.baseUrl
                    }${
                        !this.utilityService.baseUrl.includes('taapi/api/')
                            ? 'taapi/api/'
                            : ''
                    }logos/carrierLogos/${
                        appRequest.trip_info.departureCarrier
                    }.png`;
                }

                formatObj.arrivalFlightNumber =
                    appRequest.trip_info.arrivalFlightNumber || '';
                formatObj.departureFlightNumber =
                    appRequest.trip_info.departureFlightNumber || '';

                formatObj.countryOrigin = appRequest.trip_info.originCountry
                    ? this.utilityService.getCountryNameByCode(
                          appRequest.trip_info.originCountry
                      )
                    : '';
                formatObj.originCountryCode =
                    appRequest.trip_info.originCountry;
                formatObj.arrivalAt = appRequest.trip_info.arrivalAt || '';
                formatObj.arrivalFrom = appRequest.trip_info.arrivalFrom || '';
                formatObj.departureFrom =
                    appRequest.trip_info.departureFrom || '';
                formatObj.purposeVisit =
                    appRequest.trip_info.purposeVisit || '';
            }

            if (source.taApproverInfo?.Notes) {
                let newestNote = '';
                let newestNoteDate = '';
                source.taApproverInfo.Notes.forEach((element) => {
                    const noteDate = element.dateTime || element.timestamp;
                    if (newestNoteDate === '') {
                        newestNoteDate = noteDate;
                    }
                    if (newestNoteDate <= noteDate) {
                        newestNoteDate = element.dateTime || element.timestamp;
                        newestNote = element.note;
                    }
                });
                if (newestNote.length > 20) {
                    newestNote = newestNote.substring(0, 20);
                }
                formatObj.notes = newestNote;
            }

            if (source.taApproverInfo?.TAValidation?.length) {
                const validationArray = source.taApproverInfo.TAValidation;
                const IDDocSummaryInfo =
                    this.utilityService.getValidationInformation(
                        'IDDocSummary',
                        validationArray
                    );
                const tripInfo = this.utilityService.getValidationInformation(
                    'trip_info',
                    validationArray
                );
                formatObj.taValidation.IDDocSummary = IDDocSummaryInfo
                    ? IDDocSummaryInfo.valid
                    : false;
                formatObj.taValidation.tripInfo = tripInfo
                    ? tripInfo.valid
                    : false;
            }

            formatObj.publicUpdatedTs = source.publicUpdatedTs
                ? this.timeConversionService.formatDate(
                      source.publicUpdatedTs,
                      'YYYY-MM-DD HH:mm',
                      false
                  )
                : '';
            formatObj.isUpdated = this.utilityService.isUpdated(
                source.createdTs,
                source.publicUpdatedTs,
                source.modifiedTs
            );

            if (source.redeemableItems || source.redeemedItems) {
                if (purchaseType === 'redeemedItems.type') {
                    formatObj.redeemItems = source.redeemedItems;
                } else {
                    formatObj.redeemItems = source.redeemableItems;
                }
            }

            formatObj.submitterIP = source.loggedCallerData?.RemoteIP || '';
            formatObj.isFlagged = this.utilityService.isFlaggedCard(
                source.enrollmentType,
                appRequest
            );
            formatObj.paymentData = source.paymentData;
            formatObj.enrollmentType = source.enrollmentType;
            formatObj.taApproverInfo = source.taApproverInfo;
            formatObj.tier = appRequest?.tier;
            formattedData.push(formatObj);
        }
        return formattedData;
    }

    mapGenderCases(gender: string = ''): string {
        if (gender.toLowerCase() === 'm' || gender.toLowerCase() === 'male') {
            return 'MALE';
        } else if (
            gender.toLowerCase() === 'f' ||
            gender.toLowerCase() === 'female'
        ) {
            return 'FEMALE';
        } else {
            return 'X';
        }
    }

    mapEncounterData(
        data: Array<EncountersDataModel> = []
    ): Array<MappedEncounters> {
        const encounterArray: Array<MappedEncounters> = [];

        data?.forEach((element) => {
            element = new EncountersDataModel(element);

            const additionalInfoTooltip: Array<string> = [];

            if (element.additionalInfo) {
                Object.entries(element.additionalInfo).forEach(
                    ([key, value]) => {
                        if (value) {
                            const translatedKey = this.translate.instant(
                                `additional_notes.${key}`
                            );
                            additionalInfoTooltip.push(
                                `${translatedKey}: ${value}`
                            );
                        }
                    }
                );
                element.additionalInfo = additionalInfoTooltip as {};
            }
            encounterArray.push(new MappedEncounters(element));
        });

        return encounterArray;
    }

    mapDistinctFlights(data: Array<NamedSearchData> = []): Array<FlightCard> {
        const distinctFlightsArray: Array<FlightCard> = [];

        for (const item of data) {
            if (item.key === '') {
                continue;
            }
            const splitData = item.key.split('|');

            if (splitData[0]) {
                const formattedFlightCounts = this.formatCardCounts(
                    item.aggregations?.asMap.decision?.buckets
                );

                distinctFlightsArray.push(
                    new FlightCard({
                        logo: splitData[0],
                        title: `${splitData[0]} ${
                            splitData[1] ? splitData[1] : ''
                        }`,
                        subtitle: this.timeConversionService.formatDate(
                            splitData[2] ? splitData[2] : null,
                            'YYYY-MM-DD'
                        ),
                        totalCount: item.docCount,
                        pillIcon:
                            'assets/styles/icons/material-icons/people.svg',
                        arrivalCarrier: splitData[0],
                        arrivalCarrierLogo: `${this.utilityService.baseUrl}${
                            !this.utilityService.baseUrl.includes('taapi/api/')
                                ? 'taapi/api/'
                                : ''
                        }logos/carrierLogos/${splitData[0]}.png`,
                        arrivalFlightNumber: splitData[1],
                        pendingCount: formattedFlightCounts.pendingCount,
                        approvedCount: formattedFlightCounts.approvedCount,
                        rejectedCount: formattedFlightCounts.rejectedCount,
                        questionCount: formattedFlightCounts.questionCount,
                        examineCount: formattedFlightCounts.examineCount,
                        quarantineCount: formattedFlightCounts.quarantineCount,
                    })
                );
            }
        }
        distinctFlightsArray.sort((item1, item2) => {
            if (
                this.timeConversionService.getDateDifference(
                    item1.subtitle,
                    item2.subtitle
                ) > 0
            ) {
                return 1;
            } else if (
                this.timeConversionService.getDateDifference(
                    item1.subtitle,
                    item2.subtitle
                ) < 0
            ) {
                return -1;
            } else {
                return 0;
            }
        });
        return distinctFlightsArray;
    }

    mappingEstablishmentUsers(userData: AllUsersModel): Array<UserInfoModel> {
        const userArray: Array<UserInfoModel> = [];

        // eslint-disable-next-line guard-for-in
        for (const i in userData) {
            userArray.push(
                new UserInfoModel({
                    ...userData[i],
                    uid: i,
                })
            );
        }

        return userArray;
    }

    mapHotelData(
        body: HotelBodyModel,
        data: Array<NamedSearchItem> = []
    ): Array<HotelDataModel> {
        const formattedArray: Array<HotelDataModel> = [];

        data.forEach((element) => {
            const node = this.utilityService.establishments.find(
                (establishment) =>
                    establishment.establishmentPlaceID === element.key
            );

            if (node && node.coordinates) {
                formattedArray.push({
                    establishmentName: node.establishmentName || '',
                    hotelPlaceID: element.key,
                    count: element.docCount,
                    date: body?.params?.date || '',
                    coordinates: node.coordinates,
                    enrollmentType: 'TA',
                });
            }
        });

        return formattedArray;
    }

    mapGalleryAttachments(
        attachments: Array<AttachmentModel> = [],
        records: Array<MatchRecord> = [],
        applicantName: string = ''
    ): Array<GalleryAttachmentModel> {
        let viewableAttachments = attachments.filter(
            (attachment) =>
                attachment.mimeType === 'application/pdf' ||
                attachment.mimeType === 'image/jpg' ||
                attachment.mimeType === 'image/jpeg' ||
                attachment.mimeType === 'image/png'
        );

        viewableAttachments = viewableAttachments.map((attachment) => {
            const confidenceInfo = this.utilityService.getImageRecordInfo(
                attachment,
                records
            );
            return new GalleryAttachmentModel({
                ...confidenceInfo,
                previewUrl: attachment.URL,
                originalUrl: attachment.OriginalFileUrl
                    ? attachment.OriginalFileUrl
                    : attachment.URL,
                mimeType: attachment.mimeType,
                type: attachment.fileType,
                taRef: attachment.taReference,
                fileName: attachment.fileName,
                fileType: attachment.fileType,
                title: this.utilityService.setDocumentTitles(attachment),
                applicantName,
            });
        });
        return viewableAttachments.slice();
    }

    mapPaymentData(
        paymentData: Array<PaymentAPIData> = []
    ): Array<PaymentFormattedData> {
        const paymentArray: Array<PaymentFormattedData> = [];

        for (let i = 0; i < paymentData.length; i++) {
            paymentArray.push(
                new PaymentFormattedData({
                    ...paymentData[i],
                    index: i,
                })
            );
        }

        return paymentArray;
    }

    mappingAirlines(airlines: Array<AirlinesModel> = []): Array<AirlinesModel> {
        const airlinesArray: Array<AirlinesModel> = [];

        airlines.forEach((element) => {
            airlinesArray.push(new AirlinesModel(element));
        });

        return airlinesArray;
    }

    formatProfiles(profilesData: Array<HCProfileAPI> = []): Array<ProfileData> {
        const formattedProfileArray: Array<ProfileData> = [];

        for (const data of profilesData) {
            const patientInfoAttributes = cloneDeep(data.codeMappings);
            delete patientInfoAttributes.defaultLocation;
            delete patientInfoAttributes.lifeTimeInDays;
            delete patientInfoAttributes.mrzLine2;

            formattedProfileArray.push(
                new ProfileData({
                    ...data,
                    patientInfo: this.formatInputAttributes(
                        patientInfoAttributes,
                        'patientInfo'
                    ),
                    healthCredential: this.formatInputAttributes(
                        data.codeMappings.mrzLine2,
                        'healthCredential'
                    ),
                })
            );
        }

        return formattedProfileArray;
    }
    titleCase(value: string): string {
        return value
            .toLowerCase()
            .split(' ')
            .map((word) => {
                return word.replace(word[0], word[0].toUpperCase());
            })
            .join(' ');
    }
    formatInputAttributes(
        inputData: Array<InputAttributes> | { [key: string]: any } = [],
        dataType: string = ''
    ): Array<MappedInputAttributes> {
        const mappedInputAttributes: Array<MappedInputAttributes> = [];

        // eslint-disable-next-line guard-for-in
        for (const i in inputData) {
            const options: Array<{ key: string; label: string }> = [];
            if (inputData[i]?.selectOptions) {
                inputData[i].selectOptions.forEach((node) => {
                    let translationKey;
                    if (dataType === 'patientInfo') {
                        translationKey = 'health_credential_patient_info.';
                    } else {
                        translationKey = 'health_credential_options.';
                    }

                    if (dataType === 'healthCredential') {
                        translationKey +=
                            this.utilityService.convertTextToUnderscoreSeparator(
                                inputData[i].name
                            );
                    } else {
                        translationKey +=
                            this.utilityService.convertTextToUnderscoreSeparator(
                                i
                            );
                    }
                    let label;
                    if (
                        this.utilityService.hasTranslationKey(
                            translationKey + '_' + node.value.toLowerCase()
                        )
                    ) {
                        label =
                            dataType === 'healthCredential' && node.description
                                ? node.description
                                : translationKey +
                                  '_' +
                                  node.value.toLowerCase();
                    } else {
                        label = node.description;
                    }

                    options.push({
                        key: node.value,
                        label,
                    });
                });
            }

            if (!inputData[i].type) {
                inputData[i].type = '';
            }
            let type: string;
            if (
                inputData[i].type.toLowerCase() === 'alphanumeric' ||
                inputData[i].type.toLowerCase() === 'iddocnumber'
            ) {
                type = 'textInput';
            } else if (
                inputData[i].type.toLowerCase() === 'iddoctype' ||
                inputData[i].type.toLowerCase() === 'select' ||
                inputData[i].type.toLowerCase() === 'sex'
            ) {
                type = 'dropdown';
            } else if (
                inputData[i].type.toLowerCase() === 'mrzbirthdate' ||
                inputData[i].type.toLowerCase() === 'mrzexpirydate' ||
                inputData[i].type.toLowerCase() === 'mrzdate'
            ) {
                type = 'calendar';
            } else if (inputData[i].type.toLowerCase() === 'isocountry') {
                type = 'countryDropdown';
            } else if (inputData[i].type.toLowerCase() === 'datetime') {
                type = 'dateTime';
            } else if (inputData[i].type.toLowerCase() === 'result') {
                type = 'tile';
            } else {
                type = '';
            }

            const mappedInput: MappedInputAttributes =
                new MappedInputAttributes({
                    ...inputData[i],
                    options,
                    type,
                    formControl:
                        dataType === 'healthCredential'
                            ? this.utilityService.convertTextToUnderscoreSeparator(
                                  inputData[i].name === 'SampleTime' ||
                                      inputData[i].name === 'TestNumber'
                                      ? inputData[i].name
                                      : this.titleCase(inputData[i].name)
                              )
                            : i,
                });

            if (mappedInput.formControl) {
                if (
                    this.utilityService.hasTranslationKey(
                        `health_credential.${this.utilityService.convertTextToUnderscoreSeparator(
                            mappedInput.formControl
                        )}`
                    )
                ) {
                    mappedInput.label = `health_credential.${this.utilityService.convertTextToUnderscoreSeparator(
                        mappedInput.formControl
                    )}`;
                }
            }
            if (options.length === 1) {
                mappedInput.visible = false;
            }

            mappedInputAttributes.push(mappedInput);
        }

        return mappedInputAttributes;
    }

    //function used to map the purchased items to ng-select items RK
    formatAncillariesOptions(
        ancillariesItems: Array<AncillariesItem> = []
    ): Array<AncillariesItem> {
        const formattedAncillaries: Array<AncillariesItem> = [];
        //empty the redeemableOptions in case function is called multiple times (getContents interval)
        this.utilityService.redeemableOptions = [];
        ancillariesItems.forEach((item) => {
            const formattedItem = new AncillariesItem(item);
            formattedAncillaries.push(formattedItem);
            if (item.isRedeemable) {
                this.utilityService.redeemableOptions.push(formattedItem);
            }
        });

        return formattedAncillaries;
    }

    formatPurchasedItemsData(data: any[], type: string): any[] {
        let tempPackageOptions = [];
        const appObject = {};
        const appArray = [];
        let apps = [];
        if (data) {
            data.forEach((item) => {
                tempPackageOptions = [];
                if (item.redeemItems) {
                    item.redeemItems.forEach((element) => {
                        if (element.type === type) {
                            if (
                                tempPackageOptions.findIndex(
                                    (res) =>
                                        res === element.package ||
                                        res === element.name
                                ) === -1
                            ) {
                                if (
                                    appObject[element.package || element.name]
                                ) {
                                    appObject[
                                        element.package || element.name
                                    ].push(item);
                                } else {
                                    appObject[element.package || element.name] =
                                        [item];
                                }
                            }
                            tempPackageOptions.push(
                                element.package || element.name
                            );
                        }
                    });
                }
            });

            Object.entries(appObject).forEach(([key, value]) => {
                appArray.push({ packageType: key, apps: value });
            });

            apps = cloneDeep(appArray);

            if (apps && apps.length > 0) {
                apps.forEach((item) => {
                    let index = 0;
                    item.apps.forEach((element) => {
                        element.index = index;
                        index = index + 1;
                    });
                });
            }
        }

        return apps;
    }

    mapRecentEmailLog(
        emailLog: Array<EmailLogAPIData> = []
    ): Array<EmailLogModel> {
        const mappedEmailLog: Array<EmailLogModel> = [];

        for (let i = 0; i < emailLog.length; i++) {
            mappedEmailLog.push(
                new EmailLogModel({ ...emailLog[i], index: i })
            );
        }

        return mappedEmailLog;
    }

    formatCardCounts(data = []): flightCounts {
        const dictionary = {
            QUARANTINE: 'quarantineCount',
            PENDING: 'pendingCount',
            QUESTION: 'questionCount',
            REJECTED: 'rejectedCount',
            EXAMINE: 'examineCount',
            APPROVED: 'approvedCount',
        };

        const obj: flightCounts = {};

        data.forEach((node) => {
            if (node.key !== '') {
                obj[dictionary[node.key]] = node.docCount;
            }
        });

        return obj;
    }

    mapDocumentMlLog(
        documentMlLog: Array<DocumentMlLog> = []
    ): Array<DocumentMlLog> {
        const mappedDocumentMlLog: Array<DocumentMlLog> = [];

        for (let i = 0; i < documentMlLog.length; i++) {
            mappedDocumentMlLog.push(
                new DocumentMlLog({ ...documentMlLog[i], ...{ index: i } })
            );
        }
        return mappedDocumentMlLog;
    }

    mapUserFileTypes(
        fileTypes: Array<string> = []
    ): Array<{ label: string; key: string }> {
        let mappedFileTypes: Array<{ label: string; key: string }> = [];

        mappedFileTypes = fileTypes.map((type) => {
            return { label: type, key: type };
        });

        return mappedFileTypes;
    }

    formatNotification(data: any): Object {
        const key = data.node;
        return {
            [key]: {
                title: `header_main.notifications_${data.node}_title`,
                subtitle: `header_main.notifications_${data.node}_subtitle`,
                count: data.count,
            },
        };
    }

    mapUserEmailLogs(
        emailLogs: Array<EmailLogAPIData> = []
    ): Array<EmailLogModel> {
        const hasFlaggedEmails = emailLogs.find(
            (log) =>
                log.status &&
                (log.status.toLowerCase() === 'complaint' ||
                    log.status.toLowerCase() === 'deliveryfailure')
        );
        if (hasFlaggedEmails) {
            this.store.dispatch(
                setApplicationNode({ node: 'hasFlaggedEmails', data: true })
            );
        }
        return emailLogs.map((log) => new EmailLogModel(log));
    }

    mapSupportedMlTemplates(
        templates: Array<SupportedTemplateModel>
    ): Array<SupportedTemplateModel> {
        const supportedTemplates = [];
        templates?.forEach((template) => {
            supportedTemplates.push(new SupportedTemplateModel(template));
        });

        return supportedTemplates;
    }

    mapEnrollmentTypes(
        enrollmentTypes: Array<string> = []
    ): Array<{ key: string; label: string; color: string }> {
        //maps the enrollment types to an array of key label pairs
        this.utilityService.mappedEnrollmentTypes = enrollmentTypes.map(
            (type, index) => {
                return {
                    key: type,
                    label: `enrollment_types.${type}`,
                    color: enrollmentTypesColors[index],
                };
            }
        );
        return this.utilityService.mappedEnrollmentTypes.slice();
    }
    mapBiometricSearchResult(response: any): Array<BiometricSearchResult> {
        const mappedSearchResult = [];
        response.passengers.map((node) => {
            mappedSearchResult.push(new BiometricSearchResult(node));
        });
        return mappedSearchResult;
    }

    mapNationalityRequirementBody(data: { [key: string]: any }): {
        node: string;
        formValue: EligibilityWidget;
    } {
        const formValue = data.nationalityRequirement.value;
        const defaultRow = {
            requirement: formValue.defaultRequirement,
        };
        if (formValue.defaultRestrictions?.length) {
            defaultRow['restrictions'] = formValue.defaultRestrictions;
        }
        delete formValue.defaultRequirement;
        delete formValue.defaultRestrictions;
        if (!formValue.rules) {
            formValue['rules'] = {};
        }
        formValue.rules['default'] = defaultRow;
        return { node: 'nationalityRequirement', formValue };
    }

    mapRulesRows(rules: Array<{ [key: string]: any }>): {
        [key: string]: any;
    } {
        const mappedRules = {};

        rules.forEach((rule) => {
            const issuer = rule.issuer;
            const formattedRule = cloneDeep(rule);
            delete formattedRule.issuer;
            if (
                !formattedRule.restrictions ||
                !formattedRule.restrictions.length
            ) {
                delete formattedRule.restrictions;
            }

            mappedRules[issuer] = formattedRule;
        });

        return cloneDeep(mappedRules);
    }

    mapOtherOverridesBody(data: any): {
        [key: string]: any;
    } {
        const values = {};
        Object.entries(data).forEach(([key, value]) => {
            if (key === 'purposeVisit' && Array.isArray(value)) {
                values[key] = value;
            } else {
                values[key] = (value as any).value;
            }
        });
        return { formValue: cloneDeep(values) };
    }

    mapIncompleteAuditLogs(
        logs: Array<IncompleteAuditLogModel>
    ): Array<MappedIncompleteAuditLogModel> {
        const mappedLogs = [];
        logs.forEach((log) => {
            mappedLogs.push(new MappedIncompleteAuditLogModel(log));
        });
        return mappedLogs;
    }

    formatTierOptions(data: Array<string> = []): Array<TierItems> {
        return (
            data
                .map((tier) => {
                    return new TierItems({ tier }, this.translate);
                })
                .slice() || []
        );
    }

    mapDistinctVessels(data: Array<NamedSearchData> = []): Array<MaritimeCard> {
        const distinctVesselsArray: Array<MaritimeCard> = [];

        for (const item of data) {
            if (item.key === '') {
                continue;
            }
            const splitData = item.key.split('|');

            if (splitData[0]) {
                const formattedVesselCounts = this.formatCardCounts(
                    item.aggregations?.asMap.decision?.buckets
                );

                distinctVesselsArray.push(
                    new MaritimeCard({
                        key: item.key,
                        logo: this.utilityService.airlineCodes?.find(
                            (code) => code.key === splitData[0]
                        )
                            ? splitData[0]
                            : '', //to be removed CA
                        title: `${splitData[0]} ${
                            splitData[1] ? splitData[1] : ''
                        }`,
                        subtitle: splitData[2].trim()
                            ? this.timeConversionService.formatDate(
                                  splitData[2].trim(),
                                  'YYYY-MM-DD'
                              )
                            : '',
                        totalCount: item.docCount,
                        pillIcon:
                            'assets/styles/icons/material-icons/people.svg',
                        pendingCount: formattedVesselCounts.pendingCount,
                        approvedCount: formattedVesselCounts.approvedCount,
                        rejectedCount: formattedVesselCounts.rejectedCount,
                        questionCount: formattedVesselCounts.questionCount,
                        examineCount: formattedVesselCounts.examineCount,
                        quarantineCount: formattedVesselCounts.quarantineCount,
                    })
                );
            }
        }
        distinctVesselsArray.sort((item1, item2) => {
            if (item1.subtitle && item2.subtitle) {
                if (
                    this.timeConversionService.getDateDifference(
                        item1.subtitle,
                        item2.subtitle
                    ) > 0
                ) {
                    return 1;
                } else if (
                    this.timeConversionService.getDateDifference(
                        item1.subtitle,
                        item2.subtitle
                    ) < 0
                ) {
                    return -1;
                } else {
                    return 0;
                }
            }
        });
        return distinctVesselsArray;
    }
}
