import { Action, createReducer, on } from '@ngrx/store';
import { UserState } from '../state';
import {
    getUserInformationFail,
    setUserInformation,
    clearLoginState,
    setUserTags,
    setTwilioToken,
    setPreferences,
    updateSettingsPreferences,
    updateLastUsedSearchPreferences,
    updatePagePreferences,
    updateSavedSearchesPreferencesFail,
    getPreferencesFail,
    setPinCodeSuccess,
} from '../actions';
import { PreferencesModel } from '@app/models/preferences.model';
import { cloneDeep } from 'lodash-es';
const md5 = require('md5');

const initialState: UserState = {
    accessToken: '',
    username: '',
    password: '',
    role: '',
    permitCountry: '',
    establishmentPlaceID: '',
    establishmentName: '',
    userTags: [],
    twilioToken: '',
    provider: '',
    image: { url: '', fallbackUrl: '' },
    hasPinCode: false,
    modifiedSinceUserPreference: '',
};

const userReducer = createReducer(
    initialState,
    on(setUserInformation, (state, action) => {
        const _u = { ...state };
        _u.accessToken = action.payload.userToken;
        _u.username = action.payload.username;
        _u.password = action.payload.password;
        _u.role = action.payload.role;
        _u.provider = action.payload.provider;
        _u.permitCountry = action.payload.permitCountry;
        _u.establishmentPlaceID = action.payload.establishmentPlaceID;
        _u.establishmentName = action.payload.establishmentName;
        _u.userTags = action.payload.userTags || [];
        _u.hasPinCode = action.payload.hasPinCode;
        _u.image = {
            url: action.payload.imageUrl,
            fallbackUrl: 'https://www.gravatar.com/avatar/?s=200&d=mp',
        };

        if (action.payload.provider === 'password') {
            _u.image.fallbackUrl = _u.image.url;
            _u.image.url = `https://www.gravatar.com/avatar/${md5(
                _u.username
            )}?s=200&d=404`;
        }

        return _u;
    }),
    on(getUserInformationFail, () => initialState),
    on(clearLoginState, () => initialState),
    on(setUserTags, (state, action) => {
        const _u = { ...state };

        _u.userTags = action.userTags || [];

        return _u;
    }),
    on(setTwilioToken, (state, action) => {
        return { ...state, twilioToken: action.twilioToken };
    }),
    on(setPreferences, (state, action) => {
        const _u = { ...state };
        _u.preferences = action.preferences;
        _u.modifiedSinceUserPreference = action.modifiedSinceUserPreference;
        return _u;
    }),
    on(getPreferencesFail, (state) => {
        return { ...state, preferences: new PreferencesModel({}) };
    }),
    on(updateSettingsPreferences, (state, action) => {
        const _u = { ...state };
        _u.preferences = new PreferencesModel(_u.preferences || {});
        _u.preferences.settings = {
            ..._u.preferences.settings,
            ...action.settings,
        };
        return _u;
    }),
    on(updateSavedSearchesPreferencesFail, (state) => state),
    on(updateLastUsedSearchPreferences, (state, action) => {
        const _u = { ...state };
        _u.preferences = new PreferencesModel(_u.preferences || {});
        if (_u.preferences.searchQueries) {
            _u.preferences.searchQueries.lastUsedSearch = action.lastUsedSearch;
        } else {
            _u.preferences.searchQueries = {
                lastUsedSearch: action.lastUsedSearch,
            };
        }

        return _u;
    }),
    on(updatePagePreferences, (state, action) => {
        const _u = { ...state };
        _u.preferences = new PreferencesModel(_u.preferences || {});
        if (action.subNode && action.node) {
            if (_u.preferences.pages[action.node]) {
                if (
                    _u.preferences.pages[action.node][action.subNode] &&
                    !action.replace
                ) {
                    _u.preferences.pages[action.node][action.subNode] = {
                        ..._u.preferences.pages[action.node][action.subNode],
                        ...action.data,
                    };
                } else {
                    _u.preferences.pages[action.node][action.subNode] =
                        action.data;
                }
            } else {
                _u.preferences.pages[action.node] = {
                    [action.subNode]: action.data,
                };
            }
        } else if (_u.preferences.pages[action.node] && !action.replace) {
            _u.preferences.pages[action.node] = {
                ..._u.preferences.pages[action.node],
                ...action.data,
            };
        } else {
            _u.preferences.pages[action.node] = action.data;
        }
        _u.preferences.pages = cloneDeep(_u.preferences.pages);
        return _u;
    }),
    on(setPinCodeSuccess, (state) => {
        return { ...state, hasPinCode: true };
    })
);

export default function reducer(state: UserState, action: Action): UserState {
    return userReducer(state, action);
}
