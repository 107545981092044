import { templateElasticSearches } from '@app/config/templateElasticSearch';
import { EventHubService } from '@app/services/event-hub.service';
import { MappingService } from '@app/services/mapping.service';
import { UtilityService } from '@app/services/utility.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { FlightService } from '@app/services/flight.service';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiService } from '@app/services/api.service';
import {
    ApplicationStatusCheckModel,
    EligibilityWidget,
    GetContentModel,
    PurposeOfVisit,
} from '@app/models/database';
import { Injectable } from '@angular/core';
import { types } from '../actions';
import { of } from 'rxjs';
import { ElasticSearchDataParams } from '@app/models/elasticSearch';
import { ProcessingCountsModel } from '@app/models/services/mappingService.model';

@Injectable()
export class DataBusEffects {
    getContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_CONTENT_ATTEMPT),
            mergeMap(() =>
                this.api.getApiCall('getContents', 'modifiedSince').pipe(
                    map((result: GetContentModel) => {
                        const response = result.body;
                        this.utilityService.gotContentsStatus = 'success';
                        const modifiedSince =
                            result.headers.get('last-modified');
                        return {
                            type: types.GET_CONTENT_SUCCESS,
                            modifiedSince,
                            questions: response.data.content_public
                                ? response.data.content_public.questions || []
                                : [],
                            supportingDocuments: response.data.content_public
                                ? this.mappingService.mappingDocuments(
                                      response.data.content_public
                                          .supportingDocuments || []
                                  )
                                : [],
                            countryWatchList: response.data.content_private
                                ? response.data.content_private
                                      .country_watch_list || []
                                : [],
                            countryWhiteList: response.data.content_private
                                ? response.data.content_private
                                      .country_whitelist || []
                                : [],
                            countryLowRisk: response.data.content_private
                                ? response.data.content_private
                                      .country_low_risk || []
                                : [],
                            countryMediumRisk: response.data.content_private
                                ? response.data.content_private
                                      .country_medium_risk || []
                                : [],
                            countryYellowFever: response.data.content_private
                                ? response.data.content_private
                                      .country_yellow_fever || []
                                : [],
                            countryPOX: response.data.content_private
                                ? response.data.content_private.country_pox ||
                                  []
                                : [],
                            countryEbola: response.data.content_private
                                ? response.data.content_private.country_ebola ||
                                  []
                                : [],
                            countryPolio: response.data.content_private
                                ? response.data.content_private.country_polio ||
                                  []
                                : [],
                            countryCustoms: response.data.content_private
                                ? response.data.content_private
                                      .country_customs || []
                                : [],
                            countryVisaExemption:
                                response.data.content_private
                                    ?.country_visaexemption || [],
                            countryVisaOnArrival:
                                response.data.content_private
                                    ?.country_visaonarrival || [],
                            languages:
                                response.data.content_public &&
                                response.data.content_public.languages
                                    ? this.mappingService.mappingLanguages(
                                          response.data.content_public
                                              .languages || {}
                                      )
                                    : [
                                          {
                                              isActive: true,
                                              languageCode: 'en',
                                              name: 'English',
                                              nativeName: 'English',
                                          },
                                      ],
                            pages:
                                response.data.content_public &&
                                response.data.content_public.pages
                                    ? this.mappingService.mappingPages(
                                          response.data.content_public.pages ||
                                              []
                                      )
                                    : [],
                            establishments: response.data.content_private
                                ? response.data.content_private
                                      .establishments || []
                                : [],
                            airlines:
                                response.data.content_public &&
                                response.data.content_public.airlines
                                    ? this.mappingService.mappingAirlines(
                                          response.data.content_public
                                              .airlines || []
                                      )
                                    : [],
                            version: response.version,
                            establishmentsTags:
                                response.data.content_private &&
                                response.data.content_private.establishmentsTags
                                    ? response.data.content_private
                                          .establishmentsTags || []
                                    : [],
                            questionsConfig:
                                response.data.content_public &&
                                response.data.content_public.questionsConfig
                                    ? response.data.content_public
                                          .questionsConfig
                                    : {},
                            appConfig: response.data.content_public.appConfig
                                ? response.data.content_public.appConfig
                                : {},
                            alertBanner:
                                response.data.content_public?.alertBanner || [],
                            favoriteCountries:
                                response.data.content_public
                                    ?.favoriteCountries || [],
                            airports:
                                response.data.content_public?.airports || [],
                            cruises:
                                response.data.content_public?.cruises || [],
                            purposeVisit: new PurposeOfVisit(
                                response.data.content_public?.purposeVisit
                            ),
                            currencies:
                                response.data.content_public?.currencies || [],
                            groupConfig:
                                response.data.content_public?.groupConfig || [],
                            popularAirlines:
                                response.data.content_public?.popularAirlines ||
                                [],
                            popularCurrencies:
                                response.data.content_public
                                    ?.popularCurrencies || [],
                            popularCruises:
                                response.data.content_public?.popularCruises ||
                                [],
                            allowedEnrollmentTypes:
                                response.data.content_public
                                    ?.allowedEnrollmentTypes || [],
                            nationalityRequirement:
                                response.data.content_public
                                    ?.nationalityRequirement ||
                                new EligibilityWidget(),
                            contactInfoConfig:
                                response.data.content_public
                                    ?.contactInfoConfig || [],
                            visaConfig:
                                response.data.content_public?.visaConfig || [],
                            defaultEnrollmentType:
                                response.data.content_public
                                    ?.defaultEnrollmentType || '',
                            tripInfoSections:
                                response.data.content_public
                                    ?.tripInfoSections || [],
                            applicationStatusCheck:
                                response.data.content_public
                                    ?.applicationStatusCheck ||
                                new ApplicationStatusCheckModel(),
                            otherPreferences:
                                response.data.content_public
                                    ?.otherPreferences || [],
                            otherOverrideConfig:
                                response.data.content_public
                                    ?.otherOverrideConfig || {},
                        };
                    }),
                    catchError((error) => {
                        if (error.status === 304 && error.statusText === 'OK') {
                            this.utilityService.gotContentsStatus = 'success';
                            return this.utilityService.catchError(
                                types.GET_CONTENTS_FAILED
                            );
                        } else {
                            this.utilityService.gotContentsStatus = 'failed';
                            return this.utilityService.catchError(
                                types.GET_CONTENT_FAILED
                            );
                        }
                    })
                )
            )
        )
    );

    getAppConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_APP_CONFIG_ATTEMPT),
            mergeMap(() =>
                this.api.getAppConfig().pipe(
                    map((response) => {
                        return {
                            type: types.GET_APP_CONFIG_SUCCESS,
                            appConfig: response,
                        };
                    }),
                    catchError(() => {
                        return this.utilityService.catchError(
                            types.GET_APP_CONFIG_FAILED
                        );
                    })
                )
            )
        )
    );
    refreshWatchlistMatches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.REFRESH_WATCHLIST_MATCHES),
            mergeMap((action: any) => {
                return this.api.refreshWatchlistMatches(action.ref).pipe(
                    map((response: any) => {
                        if (response.ErrorMessage) {
                            return {
                                type: types.REFRESH_WATCHLIST_MATCHES_FAILED,
                            };
                        } else {
                            return {
                                type: types.REFRESH_WATCHLIST_MATCHES_SUCCESS,
                                payload: response.ListMatches,
                            };
                        }
                    }),
                    catchError(() => {
                        return this.utilityService.catchError(
                            types.REFRESH_WATCHLIST_MATCHES_FAILED
                        );
                    })
                );
            })
        )
    );

    getAllUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_ALL_USERS_ATTEMPT),
            mergeMap(() =>
                this.api
                    .getApiCall('getAllUsers', 'modifiedSinceAllUsers')
                    .pipe(
                        map((response) => {
                            const modifiedSinceAllUsers =
                                response.headers.get('last-modified');
                            return {
                                type: types.GET_ALL_USERS_SUCCESS,
                                modifiedSinceAllUsers,
                                users:
                                    this.mappingService.mappingUsers(
                                        response.body.data
                                    ) || {},
                            };
                        }),
                        catchError(() => {
                            return this.utilityService.catchError(
                                types.GET_ALL_USERS_FAILED
                            );
                        })
                    )
            )
        )
    );

    getPendingApps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_PENDING_APPS),
            mergeMap((action) =>
                this.api.getApp(action['body']).pipe(
                    map((data) => {
                        const app = this.mappingService.formatApplicationData(
                            data.hits.hits
                        );

                        const pendingApps =
                            this.mappingService.groupApplications(
                                app,
                                'pending'
                            );
                        return {
                            type: types.GET_PENDING_APPS_SUCCESS,
                            isLoadMore: action['index'] === 0 ? false : true,
                            index: action['index'] + app.length,
                            apps: { ...pendingApps, allData: app },
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_APPS_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    getApps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_APPS),
            mergeMap((action) =>
                this.api.getApp(action['body']).pipe(
                    map((data) => {
                        let app = this.mappingService.formatApplicationData(
                            data.hits.hits,
                            action['moreInfo']?.key
                        );
                        let groupedApps = {};

                        if (
                            app &&
                            action &&
                            action['moreInfo'] &&
                            !action['moreInfo'].value &&
                            (action['moreInfo'].key ===
                                'redeemableItems.type' ||
                                action['moreInfo'].key === 'redeemedItems.type')
                        ) {
                            app = this.mappingService.formatPurchasedItemsData(
                                app,
                                action['moreInfo'].type
                            );
                            groupedApps = this.mappingService.groupApplications(
                                app,
                                action['storeNode'],
                                'purchased_items'
                            );
                        } else {
                            groupedApps = this.mappingService.groupApplications(
                                app,
                                action['storeNode']
                            );
                            groupedApps = { ...groupedApps, allData: app };
                        }

                        return {
                            type: types.GET_APPS_SUCCESS,
                            isLoadMore: action['index'] === 0 ? false : true,
                            storeNode: action['storeNode'],
                            index: action['index'] + app.length,
                            apps: groupedApps,
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_APPS_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    getAppsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_APPS_COUNT),
            mergeMap((action) =>
                this.api.getAppCount(action['body']).pipe(
                    map((data) => {
                        return {
                            type: types.GET_APPS_COUNT_SUCCESS,
                            storeNode: action['storeNode'],
                            count: data.count,
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_APPS_COUNT_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    getApplicationDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_APP_DETAILS_ATTEMPT),
            mergeMap((action) =>
                this.api.searchApplications(action['code']).pipe(
                    map((data) => {
                        if (data?.ErrorMessage) {
                            return {
                                type: types.GET_APP_DETAILS_FAILED,
                                payload: data,
                            };
                        } else {
                            if (action['clearTaChanges']) {
                                this.eventHub.clearTaChangeLogs.emit();
                            }
                            this.utilityService.generateElasticSearchBody({
                                templateId: 'ENCOUNTERS_BY_TA_REFERENCE',
                                extraParams: {
                                    taReference: action['code'],
                                },
                            });
                            this.utilityService.generateElasticSearchBody({
                                templateId: 'EMAILS_BY_TA_REFERENCE',
                                extraParams: {
                                    taReference: action['code'],
                                },
                            });
                            return {
                                type: types.GET_APP_DETAILS_SUCCESS,
                                payload:
                                    this.mappingService.getAppDetails(data),
                            };
                        }
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_APP_DETAILS_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    getApplicationGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_APP_DETAILS_SUCCESS),
            mergeMap((action) => {
                if (
                    (!action['payload']['groupReference'] &&
                        !action['payload']['enrollmentReference']) ||
                    (action['payload']['groupReference'] &&
                        action['payload']['TAReferences'].length <= 1)
                ) {
                    return of({
                        type: types.GET_GROUPED_APPS_SUCCESS,
                        data: [],
                    });
                }
                let key;
                let value;
                if (
                    action['payload']['enrollmentReference'] &&
                    action['payload']['TAReferences'].length > 1
                ) {
                    key = 'enrollmentReference';
                    value = action['payload']['enrollmentReference'];
                } else if (
                    !action['payload']['enrollmentReference'] &&
                    action['payload']['groupReference']
                ) {
                    key = 'groupReference';
                    value = action['payload']['groupReference'];
                }
                const body = this.utilityService.elasticSearchQueryBuilder(
                    {
                        index: 0,
                        queries: [
                            {
                                secondaryType: 'match_phrase',
                                primaryType: 'must',
                                key,
                                value,
                            },
                        ],
                    },
                    true
                );
                return this.api.getApp(body).pipe(
                    map((data) => {
                        const _d = data.hits.hits;

                        const index = _d.findIndex(
                            (node) =>
                                node._source.taReference ===
                                action['payload']['ref']
                        );
                        if (index > -1) {
                            _d.splice(index, 1);
                        }

                        const apps =
                            this.mappingService.formatApplicationData(_d);

                        return {
                            type: types.GET_GROUPED_APPS_SUCCESS,
                            data: apps,
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_GROUPED_APPS_FAILED,
                            error
                        );
                    })
                );
            })
        )
    );

    getAllEstablishmentUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_ALL_ESTABLISHMENT_USERS_ATTEMPT),
            mergeMap(() =>
                this.api
                    .getApiCall(
                        'getAllEstablishmentUsers',
                        'modifiedSinceAllEstablishmentUsers'
                    )
                    .pipe(
                        map((response) => {
                            const modifiedSinceAllEstablishmentUsers =
                                response.headers.get('last-modified');
                            return {
                                type: types.GET_ALL_ESTABLISHMENT_USERS_SUCCESS,
                                modifiedSinceAllEstablishmentUsers,
                                establishmentUsers:
                                    this.mappingService.mappingEstablishmentUsers(
                                        response.body.data || {}
                                    ) || [],
                            };
                        }),
                        catchError(() => {
                            return this.utilityService.catchError(
                                types.GET_ALL_ESTABLISHMENT_USERS_FAILED
                            );
                        })
                    )
            )
        )
    );

    validateTaInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.VALIDATE_TA_INFO_ATTEMPT),
            mergeMap((action) =>
                this.api
                    .validateTA(
                        action['validationType'],
                        action['taReference'],
                        action['extraParams']
                    )
                    .pipe(
                        map((data) => {
                            if (data.success) {
                                const fieldData =
                                    action['validationType'] !== 'attachment'
                                        ? this.utilityService.getTaNodeData(
                                              action['validationType'],

                                              data.TAApproverInfo.TAValidation
                                          )
                                        : this.utilityService.getAttachmentsValidationInfo(
                                              data.attachments,
                                              data.TAApproverInfo.TestSummary
                                          );
                                return {
                                    type: types.VALIDATE_TA_INFO_SUCCESS,
                                    validationData: fieldData,
                                    validationType: action['validationType'],
                                    extraParams: action['extraParams'],
                                };
                            } else {
                                let errorMessage =
                                    'error_messages.unexpected_error';
                                if (
                                    data.errorMessage &&
                                    data.errorMessage
                                        .toLowerCase()
                                        .includes('notfound')
                                ) {
                                    errorMessage =
                                        'error_messages.attachment_not_found';
                                }
                                return {
                                    type: types.VALIDATE_TA_INFO_FAILED,
                                    validationType: action['validationType'],
                                    errorMessage,
                                    extraParams: action['extraParams'],
                                };
                            }
                        }),
                        catchError((err) =>
                            of({
                                type: types.VALIDATE_TA_INFO_FAILED,
                                validationType: action['validationType'],
                                errorMessage:
                                    err.status === 0
                                        ? 'error_messages.network-request-failed'
                                        : 'error_messages.unexpected_error',
                                extraParams: action['extraParams'],
                            })
                        )
                    )
            )
        )
    );

    updateUniqueStatusOnFlight$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.UPDATE_FLIGHT_COUNT_ATTEMPT),
            mergeMap((action) => {
                const splitData = action['title'].split(' ');

                return this.api
                    .getUniqueStatusOnFlight(
                        splitData[0],
                        splitData[1],
                        action['subtitle']
                    )
                    .pipe(
                        map((data) => {
                            return {
                                type: types.SET_DISTINCT_FLIGHTS_COUNT_SUCCESS,
                                app: this.flightService.formatUpdateFlightCounts(
                                    data.aggregations.applicantStatus.buckets
                                ),
                                title: action['title'],
                                subtitle: action['subtitle'],
                            };
                        }),
                        catchError((error) => {
                            return this.utilityService.catchError(
                                types.SET_DISTINCT_FLIGHTS_COUNT_FAILED,
                                error
                            );
                        })
                    );
            })
        )
    );

    updateUniqueFlagCodeOnFlight$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.UPDATE_FLIGHT_COUNT_ATTEMPT),
            mergeMap((action) => {
                const splitData = action['title'].split(' ');

                return this.api
                    .getUniqueFlagCodeOnFlight(
                        splitData[0],
                        splitData[1],
                        action['subtitle']
                    )
                    .pipe(
                        map((data) => {
                            return {
                                type: types.SET_DISTINCT_FLIGHTS_COUNT_SUCCESS,
                                app: this.flightService.formatUpdateFlightCounts(
                                    data.aggregations.applicantStatus.buckets
                                ),
                                title: action['title'],
                                subtitle: action['subtitle'],
                            };
                        }),
                        catchError((error) => {
                            return this.utilityService.catchError(
                                types.SET_DISTINCT_FLIGHTS_COUNT_FAILED,
                                error
                            );
                        })
                    );
            })
        )
    );

    getVisitedCountries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_APPS_NAME_SEARCH),
            mergeMap((action) =>
                this.api.getVisitedCountriesApps(action['body']).pipe(
                    map((data) => {
                        let app;
                        app = this.mappingService.formatApplicationData(data);
                        const groupedApps =
                            this.mappingService.groupApplications(
                                app,
                                'searchResults'
                            );
                        app = { ...groupedApps, ...{ allData: app } };

                        return {
                            type: types.GET_APPS_SUCCESS,
                            isLoadMore: action['index'] === 0 ? false : true,
                            storeNode: action['storeNode'],
                            index: action['index'] + app.allData.length,
                            apps: app,
                            notElasticSearch: true,
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_APPS_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    validatePCRTestSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.VALIDATE_TEST_SUMMARY_ATTEMPT),
            mergeMap((action) =>
                this.api.validateTestSummary(action['body']).pipe(
                    map((data) => {
                        const currentAttachment = data.attachments.filter(
                            (item) => {
                                return (
                                    item.id ===
                                    action['body']?.note?.attachmentID
                                );
                            }
                        );
                        return {
                            type: types.VALIDATE_TEST_SUMMARY_SUCCESS,
                            attachmentId: action['body']?.note?.attachmentID,
                            isNegative:
                                action['body']?.note?.testResult === 'NEGATIVE'
                                    ? true
                                    : false,
                            testSummaryData:
                                this.utilityService.getAttachmentsValidationInfo(
                                    currentAttachment,
                                    data.TAApproverInfo.TestSummary
                                ),
                        };
                    }),
                    catchError((err) =>
                        of({
                            type: types.VALIDATE_TEST_SUMMARY_FAILED,
                            attachmentId: action['body']?.note?.attachmentID,
                            errorMessage:
                                err.status === 0
                                    ? 'error_messages.network-request-failed'
                                    : 'error_messages.unexpected_error',
                        })
                    )
                )
            )
        )
    );

    getTAPrivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_TA_PRIVATE_VERSION),
            mergeMap(() =>
                this.api.getTAPrivateVersion().pipe(
                    map((data) => {
                        return {
                            type: types.GET_TA_PRIVATE_VERSION_SUCCESS,
                            taPrivateVersion:
                                data['taPrivateBackend']['git.build.version'],
                        };
                    }),
                    catchError(() => {
                        return this.utilityService.catchError(
                            types.GET_TA_PRIVATE_VERSION_FAILED
                        );
                    })
                )
            )
        )
    );

    getHCProfiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_HC_PROFILES),
            mergeMap(() =>
                this.api.getHCProfiles().pipe(
                    map((data) => {
                        return {
                            type: types.GET_HC_PROFILES_SUCCESS,
                            hcProfiles:
                                this.mappingService.formatProfiles(data),
                        };
                    }),
                    catchError(() => {
                        return this.utilityService.catchError(
                            types.GET_HC_PROFILES_FAILED
                        );
                    })
                )
            )
        )
    );

    getAncillariesItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_APP_CONFIG_ATTEMPT),
            mergeMap(() =>
                this.api.getAncillaries().pipe(
                    map((response) => {
                        return {
                            type: types.GET_ANCILLARIES_SUCCESS,
                            ancillariesItems:
                                this.mappingService.formatAncillariesOptions(
                                    response
                                ),
                        };
                    }),
                    catchError(() => {
                        return this.utilityService.catchError(
                            types.GET_ANCILLARIES_FAILED
                        );
                    })
                )
            )
        )
    );

    getTierItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_APP_CONFIG_ATTEMPT),
            mergeMap(() =>
                this.api.getTiers().pipe(
                    map((response) => {
                        return {
                            type: types.GET_TIERS_SUCCESS,
                            tiers: this.mappingService.formatTierOptions(
                                response
                            ),
                        };
                    }),
                    catchError(() => {
                        return this.utilityService.catchError(
                            types.GET_TIERS_FAILED
                        );
                    })
                )
            )
        )
    );

    getUserFileTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_APP_CONFIG_ATTEMPT),
            mergeMap(() =>
                this.api.getUserFileTypes().pipe(
                    map((data) => {
                        const app = this.mappingService.mapUserFileTypes(data);
                        return {
                            type: types.GET_USER_FILE_TYPE_SUCCESS,
                            userFileTypes: app,
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_APPS_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    getSupportedMLTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_SUPPORTED_ML_TEMPLATES),
            mergeMap(() =>
                this.api.getSupportedMLTemplates().pipe(
                    map((data) => {
                        const templates =
                            this.mappingService.mapSupportedMlTemplates(
                                data?.supportedTemplates
                            );
                        return {
                            type: types.GET_SUPPORTED_ML_TEMPLATES_SUCCESS,
                            supportedTemplates: templates,
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_SUPPORTED_ML_TEMPLATES_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    getElasticSearchData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_ELASTIC_SEARCH_DATA),
            mergeMap((action: any) => {
                return this.api
                    .getElasticSearchData(
                        new ElasticSearchDataParams({
                            searchType: action.searchType,
                            body: action.body,
                        })
                    )
                    .pipe(
                        map((data) => {
                            let apps = [];
                            if (
                                action.body.templateId ===
                                'TAS_NON_PAYMENT_OVERRIDE'
                            ) {
                                apps =
                                    this.mappingService.mapIncompleteAuditLogs(
                                        data.hits
                                    );
                            } else if (
                                action.body.templateId === 'DOCUMENT_PARSE_LOGS'
                            ) {
                                apps = this.mappingService.mapDocumentMlLog(
                                    data.hits
                                );
                            } else if (action.searchType === 'encounters') {
                                apps = this.mappingService.mapEncounterData(
                                    data.hits
                                );
                            } else if (
                                action.body.templateId === 'EMAILS' ||
                                action.body.templateId ===
                                    'EMAILS_BY_EMAIL_ADDRESS'
                            ) {
                                apps = this.mappingService.mapRecentEmailLog(
                                    data.hits
                                );
                            } else if (
                                action.body.templateId ===
                                'EMAILS_BY_TA_REFERENCE'
                            ) {
                                apps = this.mappingService.mapUserEmailLogs(
                                    data.hits
                                );
                            } else if (action.searchType === 'payments') {
                                apps = this.mappingService.mapPaymentData(
                                    data.hits
                                );
                            } else if (
                                action.storeNode === 'previousApplications'
                            ) {
                                apps =
                                    this.mappingService.formatApplicationData(
                                        data.hits
                                    );
                            }

                            return {
                                type: types.GET_ELASTIC_SEARCH_DATA_SUCCESS,
                                data: apps,
                                parentStoreNode: action.parentStoreNode,
                                storeNode: action.storeNode,
                                dataNode: action.dataNode,
                                isLoadMore: action.body?.params?.from > 0,
                                doNotUpdateAppsUi: action?.doNotUpdateAppsUi,
                                count: !templateElasticSearches[
                                    action.body?.templateId
                                ].default
                                    ? apps.length || 0
                                    : null,
                            };
                        }),
                        catchError(() => {
                            return of({
                                type: types.GET_ELASTIC_SEARCH_DATA_FAILED,
                                payload: action.dataNode,
                                doNotUpdateAppsUi: action?.doNotUpdateAppsUi,
                            });
                        })
                    );
            })
        )
    );
    getEnrollmentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_ENROLLMENT_TYPES_ATTEMPT),
            mergeMap(() =>
                this.api.getEnrollmentTypes().pipe(
                    map((data) => {
                        const formattedEnrollmentTypes =
                            this.mappingService.mapEnrollmentTypes(data);
                        return {
                            type: types.GET_ENROLLMENT_TYPES_SUCCESS,
                            data: formattedEnrollmentTypes,
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_ENROLLMENT_TYPES_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    getNamedSearchData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_NAMED_SEARCH_DATA),
            mergeMap((action: any) => {
                return this.api.getNamedSearchData(action.body).pipe(
                    map((data) => {
                        let apps = [];
                        let groupedApps = {};
                        if (
                            action.storeNode === 'flightDashboard' ||
                            action.storeNode === 'outbound'
                        ) {
                            apps = this.mappingService.mapDistinctFlights(
                                data.aggregations.distinctFlights
                                    ? data.aggregations.distinctFlights.buckets
                                    : data.aggregations.distinctSectors.buckets
                            );
                            groupedApps = this.mappingService.groupApplications(
                                apps,
                                action['storeNode']
                            );
                            groupedApps = Object.keys(groupedApps).length
                                ? groupedApps
                                : { allData: apps };
                        } else if (action.storeNode === 'hotelDashboard') {
                            apps = this.mappingService.mapHotelData(
                                action['body'],
                                data.aggregations.countsPerResidence.buckets
                            );
                        } else if (action.storeNode === 'hotelApplications') {
                            apps = this.mappingService.formatApplicationData(
                                data.hits
                            );
                            groupedApps = this.mappingService.groupApplications(
                                apps,
                                'hotelApplications'
                            );
                            if (!Object.keys(groupedApps || {}).length) {
                                groupedApps = { allData: apps };
                            }
                        }
                        return {
                            type: types.GET_NAMED_SEARCH_DATA_SUCCESS,
                            apps: Object.keys(groupedApps).length
                                ? { ...{ allData: apps }, ...groupedApps }
                                : apps,
                            parentStoreNode: action.parentStoreNode,
                            storeNode: action.storeNode,
                            dataNode: action.dataNode,
                            index: action.index,
                            isLoadMore: action.index !== 0,
                            count: apps.length,
                        };
                    }),
                    catchError((error) => {
                        return of({
                            type: types.GET_NAMED_SEARCH_DATA_FAILED,
                            payload: error,
                        });
                    })
                );
            })
        )
    );

    getElasticSearchCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_ELASTIC_SEARCH_COUNT),
            mergeMap((action: any) =>
                this.api.getElasticSearchCount(action.node).pipe(
                    map((data) => {
                        return {
                            type: types.GET_APPS_COUNT_SUCCESS,
                            storeNode: action.storeNode,
                            count: data.Count,
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_APPS_COUNT_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );
    biometricSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.BIOMETRIC_SEARCH),
            mergeMap((action: any) =>
                this.api
                    .getElasticSearchData(
                        new ElasticSearchDataParams({
                            body: action.body,
                            apiURL: 'apipnr/search/image/base64',
                            contentType: 'text/plain',
                        })
                    )
                    .pipe(
                        map((response) => {
                            return {
                                type: types.BIOMETRIC_SEARCH_SUCCESS,
                                data: this.mappingService.mapBiometricSearchResult(
                                    response
                                ),
                            };
                        }),
                        catchError(() => {
                            return this.utilityService.catchError(
                                types.BIOMETRIC_SEARCH_FAILED
                            );
                        })
                    )
            )
        )
    );

    getProcessingAppsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_PROCESSING_APPS_COUNTS),
            mergeMap((action: any) =>
                this.api.getSearchData(action.url).pipe(
                    map((data) => {
                        return {
                            type: types.GET_PROCESSING_APPS_COUNTS_SUCCESS,
                            counts: new ProcessingCountsModel(data || {}),
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_PROCESSING_APPS_COUNTS_Failed,
                            error
                        );
                    })
                )
            )
        )
    );

    getNamedSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_NAMED_SEARCH),
            mergeMap((action: any) => {
                return this.api.getNamedSearchData(action.body).pipe(
                    map((data) => {
                        let apps = [];
                        let groupedApps = {};
                        if (
                            action.storeNode === 'vessels' &&
                            (action.childStoreNode === 'inbound' ||
                                action.childStoreNode === 'outbound')
                        ) {
                            apps = this.mappingService.mapDistinctVessels(
                                data.aggregations.distinctFlights?.buckets || []
                            );
                            groupedApps = this.mappingService.groupApplications(
                                apps,
                                action['storeNode'],
                                null,
                                action['childStoreNode']
                            );
                            groupedApps = Object.keys(groupedApps).length
                                ? groupedApps
                                : { allData: apps };
                        }
                        return {
                            type: types.GET_NAMED_SEARCH_SUCCESS,
                            apps: Object.keys(groupedApps).length
                                ? { ...{ allData: apps }, ...groupedApps }
                                : apps,
                            parentStoreNode: action.parentStoreNode,
                            storeNode: action.storeNode,
                            childStoreNode: action.childStoreNode,
                            dataNode: action.dataNode,
                            index: action.index,
                            isLoadMore: action.index !== 0,
                            count: apps.length,
                        };
                    }),
                    catchError((error) => {
                        return of({
                            type: types.GET_NAMED_SEARCH_FAILED,
                            payload: error,
                        });
                    })
                );
            })
        )
    );

    constructor(
        private utilityService: UtilityService,
        private mappingService: MappingService,
        private flightService: FlightService,
        private eventHub: EventHubService,
        private actions$: Actions,
        private api: ApiService
    ) {}
}
