export const enrollmentTypesColors = [
    '#38D2FF',
    '#ed1dd3',
    '#FCEB24',
    '#0EE736',
    '#FCAC00',
    '#7095F9',
    '#d70e17',
    '#cff800',
    '#fc6238',
    '#00cdac',
    '#93ae55',
];

export const emailStatusGlossary = {
    open: 'email_status_glossary.open',
    click: 'email_status_glossary.click',
    bounce: 'email_status_glossary.bounce',
    blocked: 'email_status_glossary.blocked',
    deferred: 'email_status_glossary.deferred',
    delivered: 'email_status_glossary.delivered',
    sent: 'email_status_glossary.sent',
    failed_to_send: 'email_status_glossary.failed_to_send',
};

export const bannerColors = [
    {
        key: 'NOTE',
        backgroundColor: '#eae6ff',
        textColor: '#333333',
    },
    {
        key: 'ANNOUNCEMENT',
        backgroundColor: '#fffae6',
        textColor: '#333333',
    },
    {
        key: 'ALERT',
        backgroundColor: '#ffebe6',
        textColor: '#333333',
    },
    {
        key: 'INFO',
        backgroundColor: '#ddecff',
        textColor: '#333333',
    },
    {
        key: 'SUCCESS',
        backgroundColor: '#e3fcef',
        textColor: '#333333',
    },
];

export const questionTypeCategory = {
    'country-select': {
        enrollmentQuestions: 'BIRTH-COUNTRY',
    },
    'country-select-multiple': {
        healthCardQuestions: 'VISITEDCOUNTRIES',
    },
    'marital-status': {
        enrollmentQuestions: 'MARITAL-STATUS',
    },
};

export const decisionChangeLogs = [
    'movedTAFromIncompleteToPending',
    'flagApplicationIfStatusMatches',
    'approveApplication',
    'rejectApplication',
    'resetApplicationToProcessing',
    'addTANote',
    'changeApplicationStatus',
    'markPrecheckComplete',
];

export const appDetailsLogs = [
    {
        what: ['updateTAAttachment'],
        section: 'attachments',
        New: 'TAAttachment',
    },
    { what: decisionChangeLogs, section: 'decision', New: 'Status' },
    {
        what: ['publicUpdateApplicationDetails'],
        section: 'health_info',
        New: 'health_info',
    },
    {
        what: ['publicUpdateApplicationDetails'],
        section: 'customs_info',
        New: 'customs_info',
    },
    {
        what: ['publicUpdateApplicationDetails'],
        section: 'insurance_info',
        New: 'insurance_info',
    },
    {
        what: ['saveWatchListMatchResults'],
        section: 'watchlists',
        New: 'Watchlists',
    },
    {
        what: ['saveWatchListMatchResults'],
        section: 'visas',
        New: 'Watchlists',
    },
];
