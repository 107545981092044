import { UtilityService } from '@app/services/utility.service';
import { ApplicantCard } from '../applicantCard';
import {
    TaValidationInfo,
    AnswersModel,
    TestSummary,
    Note,
    AttachmentModel,
    DecisionNote,
    PaymentInfo,
    PaymentInfoAPI,
} from './utilityService.model';
import {
    SupportingDocumentsModel,
    MappedLanguages,
    QuestionsModel,
    AirlinesModel,
    PageModel,
    PurposeOfVisit,
} from '../database';
import { TimeConversionService } from '@app/services/time-conversion.service';
import * as dayjs from 'dayjs';

export class IdDocumentsModel {
    dob?: string;
    forenames?: string;
    issuer?: string;
    number?: string;
    sex?: string;
    surname?: string;
    type?: string;
    name?: string;
    constructor(props: IdDocumentsModel) {
        return {
            dob: props.dob || '',
            forenames: props.forenames || '',
            issuer: props.issuer || '',
            number: props.number || '',
            sex: props.sex || '',
            surname: props.surname || '',
            type: props.type || '',
            name: `${props.surname ? props.surname : ''}${
                props.forenames && props.surname ? ', ' : ''
            }${props.forenames}`,
        };
    }
}
export class PaymentAPIData {
    paymentIntent?: string;
    arrivalDate?: string;
    timestamp?: string;
    enrollmentReference?: string;
    orderStatus?: string;
    currency?: string;
    totalDue?: number;
    paymentAttempts?: number;
    arrivalCarrier?: string;
    arrivalFLightNumber?: string;
    departureCarrier?: string;
    departureDate?: string;
    departureFLightNumber?: string;
    logType?: string;
    tasInGroup?: Array<string>;
    paymentAttemptList?: Array<PaymentInfoAPI>;
    index?: number;
    tier?: string;
    idDocuments?: Array<IdDocumentsModel>;
}

export class PaymentFormattedData {
    paymentIntent?: string;
    arrivalDate?: string;
    timestamp?: string;
    enrollmentReference?: string;
    orderStatus?: string;
    currency?: string;
    totalDue?: number;
    paymentAttempts?: number;
    arrivalCarrier?: string;
    arrivalFlightNumber?: string;
    departureCarrier?: string;
    departureDate?: string;
    departureFlightNumber?: string;
    logType?: string;
    tasInGroup?: Array<string>;
    paymentAttemptList?: Array<PaymentInfoAPI>;
    index?: number;
    tier?: string;
    idDocuments?: Array<IdDocumentsModel>;
    constructor(props: PaymentAPIData) {
        return {
            paymentIntent: props.paymentIntent || '',
            arrivalDate: props.arrivalDate || '',
            timestamp: props.timestamp || '',
            enrollmentReference: props.enrollmentReference || '',
            orderStatus: props.orderStatus || '',
            currency: props.currency || '',
            totalDue: props.totalDue || 0,
            paymentAttempts: props.paymentAttempts || 0,
            arrivalCarrier: props.arrivalCarrier || '',
            arrivalFlightNumber: props.arrivalFLightNumber || '',
            departureCarrier: props.departureCarrier || '',
            departureDate: props.departureDate || '',
            departureFlightNumber: props.departureFLightNumber || '',
            tasInGroup: props.tasInGroup || [],
            logType: props.logType || '',
            paymentAttemptList: props.paymentAttemptList || [],
            index: props.index || 0,
            tier: props.tier || '',
            idDocuments: props.idDocuments || [],
        };
    }
}
export class PaymentInfoDisplay {
    constructor(
        props: PaymentFormattedData,
        timeConversionService: TimeConversionService
    ) {
        return {
            arrivalDate: props.arrivalDate || '',
            timestamp: props.timestamp
                ? timeConversionService.formatDate(
                      props.timestamp,
                      'YYYY-MM-DD HH:mm',
                      false
                  )
                : '',
            orderStatus: props.orderStatus || '',
            currency: props.currency || '',
            totalDue: props.totalDue || 0,
            departureDate: props.departureDate || '',
            logType: props.logType || '',
            tier: props.tier || '',
            paymentIntent: props.paymentIntent || '',
            arrivalFlight:
                props.arrivalCarrier && props.arrivalFlightNumber
                    ? `${props.arrivalCarrier} ${props.arrivalFlightNumber}`
                    : '',
            departureFlight:
                props.departureCarrier && props.departureFlightNumber
                    ? `${props.departureCarrier} ${props.departureFlightNumber}`
                    : '',
        };
    }
}
export class CMSDataModel {
    supportingDocuments: SupportingDocumentsModel[];
    questions: QuestionsModel[];
    languages: MappedLanguages[];
    pages: PageModel[];
    airlines: AirlinesModel[];
    country_watch_list: string[];
    country_whitelist: string[];
    country_low_risk: string[];
    country_medium_risk: string[];
    country_yellow_fever: string[];
    country_pox: string[];
    country_ebola: string[];
    country_polio: string[];
    country_customs: string[];
    country_visaexemption: string[];
    country_visaonarrival: string[];
    establishments: Array<any>;
    establishmentsTags: Array<any>;

    constructor(props: {
        supportingDocuments?: SupportingDocumentsModel[];
        questions?: QuestionsModel[];
        languages?: MappedLanguages[];
        pages?: PageModel[];
        airlines?: AirlinesModel[];
        country_watch_list?: string[];
        country_whitelist?: string[];
        country_low_risk?: string[];
        country_medium_risk?: string[];
        country_yellow_fever?: string[];
        country_pox?: string[];
        country_ebola?: string[];
        country_polio?: string[];
        country_customs?: string[];
        country_visaexemption?: string[];
        country_visaonarrival?: string[];
        establishments?: Array<any>;
        establishmentsTags?: Array<any>;
    }) {
        return {
            supportingDocuments: props.supportingDocuments || [],
            questions: props.questions || [],
            languages: props.languages || [],
            pages: props.pages || [],
            airlines: props.airlines || [],
            country_watch_list: props.country_watch_list || [''],
            country_whitelist: props.country_whitelist || [''],
            country_low_risk: props.country_low_risk || [''],
            country_medium_risk: props.country_medium_risk || [''],
            country_yellow_fever: props.country_yellow_fever || [''],
            country_pox: props.country_pox || [''],
            country_ebola: props.country_ebola || [''],
            country_polio: props.country_polio || [''],
            country_customs: props.country_customs || [''],
            country_visaexemption: props.country_visaexemption || [''],
            country_visaonarrival: props.country_visaonarrival || [''],
            establishments: props.establishments || [],
            establishmentsTags: props.establishmentsTags || [],
        };
    }
}

export class CountryLists {
    countryWatchList: string;
    countryWhiteList: string;
    countryLowRisk: string;
    countryMediumRisk: string;
    countryYellowFever: string;
    countryPOX: string;
    countryEbola: string;
    countryPolio: string;
    countryCustoms: string;
    countryVisaExemption: string;
    countryVisaOnArrival: string;
}

export class HotelDataModel {
    establishmentName: string;
    hotelPlaceID: string;
    count: number;
    date: string;
    coordinates: Object;
    enrollmentType: string;
}

export class HotelBodyModel {
    elasticsearch_named_search_name: string;
    params: { date: string; enrollmentTypes: Array<string> };
}

//contains elements mapping TA encounters api response and Encounters page CA
export class EncountersDataModel {
    additionalInfo: { [key: string]: any };
    encounterType: string;
    encounterTime: string;
    location: {
        lat: number;
        lon: number;
    };
    taReference: string;
    who?: {
        name?: string;
        establishmentName?: string;
        identifier?: string;
    };
    arrivalFlightNumber?: string;
    arrivalCarrier?: string;
    departureFlightNumber?: string;
    departureCarrier?: string;
    nationality?: string;
    idDocument?: {
        nationality?: string;
        surname?: string;
    };
    enrollmentType?: string;
    approvedStayUntil?: string;

    constructor(props: EncountersDataModel) {
        return {
            additionalInfo: props.additionalInfo || {},
            encounterType: props.encounterType || '',
            encounterTime: props.encounterTime || '',
            location: {
                lat: props.location?.lat || 0,
                lon: props.location?.lon || 0,
            },
            taReference: props.taReference || '',
            who: {
                name: props.who?.name || '',
                establishmentName: props.who?.establishmentName || '',
                identifier: props.who?.identifier || '',
            },
            arrivalFlightNumber: props.arrivalFlightNumber || '',
            arrivalCarrier: props.arrivalCarrier || '',
            idDocument: {
                nationality: props.idDocument?.nationality || '',
                surname: props.idDocument?.surname || '',
            },
            nationality:
                props.nationality || props.idDocument?.nationality || '',
            departureFlightNumber: props.departureFlightNumber || '',
            departureCarrier: props.departureCarrier || '',
            enrollmentType: props.enrollmentType || '',
            approvedStayUntil:
                (props.additionalInfo as { [key: string]: any })
                    ?.approvedStayUntil || '',
        };
    }
}

//contains elements mapping TA encounters api response and Encounters page CA
export class MappedEncounters {
    encounterType: string;
    encounterTime: string;
    coordinates: {
        lat: number;
        lng: number;
    };
    additionalInfo: { [key: string]: any };
    appReference: string;
    name: string;
    establishmentName: string;
    identifier: string;
    who?: {
        name?: string;
        establishmentName?: string;
        identifier?: string;
    };
    arrivalFlightNumber?: string;
    arrivalCarrier?: string;
    nationality?: string;
    surname?: string;
    departureFlightNumber?: string;
    departureCarrier?: string;
    enrollmentType?: string;
    approvedStayUntil?: string;

    constructor(props: EncountersDataModel) {
        return {
            encounterType: props.encounterType || '',
            encounterTime:
                dayjs(props.encounterTime).format('YYYY-MM-DD HH:mm') || '',
            coordinates: {
                lat: props.location.lat || 0,
                lng: props.location.lon || 0,
            },
            additionalInfo: props.additionalInfo || {},
            appReference: props.taReference || '',
            name: props.who?.name || '',
            surname: props.idDocument?.surname,
            establishmentName: props.who?.establishmentName || '',
            identifier: props.who?.identifier || '',
            who: {
                name: props.who?.name || '',
                establishmentName: props.who?.establishmentName || '',
                identifier: props.who?.identifier || '',
            },
            arrivalFlightNumber: props.arrivalFlightNumber || '',
            arrivalCarrier: props.arrivalCarrier || '',
            nationality: props.nationality || '',
            departureFlightNumber: props.departureFlightNumber || '',
            departureCarrier: props.departureCarrier || '',
            enrollmentType: props.enrollmentType || '',
            approvedStayUntil: props.approvedStayUntil || '',
        };
    }
}
export class GroupedAppsModel {
    [key: string]: Array<ApplicantCard> | number;
}

export class PCRTest {
    patient?: {
        surname?: string;
        forenames?: string;
        firstName?: string;
        lastName?: string;
        documentNumber?: string;
        number?: string;
        testResult?: string;
    };
    result?: {
        result?: string;
        resultDate?: string;
        type?: string;
    };
    lab?: {
        name?: string;
        country?: string;
    };
    matchResults?: any;
    mimeType?: string;
    taReference?: string;
    base64File?: string;
}

export class PCRTestResultModel {
    PCRResult: string;
    applicantName: string;
    resultDate: string;
    labName: string;
    labCountry: string;
    type: string;
    documentNumber: string;
    matchResults: any;
    forenames: string;
    surname: string;
    mimeType: string;
    taReference: string;

    constructor(props: PCRTest) {
        return {
            PCRResult:
                props?.result?.result || props?.patient?.testResult || '',
            applicantName: `${
                props?.patient?.surname || props?.patient?.lastName || ''
            }${
                (props?.patient?.surname && props?.patient?.forenames) ||
                (props?.patient?.lastName && props?.patient?.firstName)
                    ? ', '
                    : ''
            }${props?.patient?.forenames || props?.patient?.firstName || ''}`,
            resultDate: props?.result?.resultDate || '',
            labName: props?.lab?.name || '',
            labCountry: props?.lab?.country || '',
            type: props?.result?.type || '',
            documentNumber:
                props?.patient?.documentNumber || props?.patient?.number || '',
            matchResults: props?.matchResults || '',
            forenames: props?.patient?.forenames || '',
            surname: props?.patient?.surname || '',
            mimeType: props?.mimeType || '',
            taReference: props?.taReference || '',
        };
    }
}

export class searchResultsTabs {
    [key: string]: Array<ApplicantCard> | number;
}

export class OfflineApplicationModel {
    enrollmentReference?: string;
    enrollmentType?: string;
    taStatus?: string;
    taReference?: string;
    decisionTs: string;
    redeemableItems?: Array<any>;
    redeemedItems?: Array<any>;
    createdTs?: string;
    flagCode?: string;
    publicUpdatedTs?: string;
    paymentData: {
        CardFingerprint: string;
    };
    loggedCallerData?: {
        RemoteIP?: string;
    };
    taApproverInfo?: {
        Notes?: Array<Note>;
        TAValidation?: Array<TaValidationInfo>;
        TestSummary?: Array<TestSummary>;
    };
    passportSummary?: {
        dob?: string;
        forenames?: string;
        issuer?: string;
        nationality?: string;
        number?: string;
        sex?: string;
        surname?: string;
        type?: string;
        expiry?: string;
    };
    userSubmittedTravelApplicationRequest?: {
        enrollmentType?: string;
        applicant_contact_info?: {
            address?: string;
            address_line_1?: string;
            address_line_2?: string;
            country?: string;
            phone?: string;
            email?: string;
            google_places_id?: string;
            profession?: string;
        };
        customs_info?: {
            answers: Array<AnswersModel>;
            healthCardVersion: string;
            tags: [];
        };
        health_info?: {
            answers: Array<AnswersModel>;
            healthCardVersion: string;
            tags: [];
        };
        insurance_info?: {
            answers: Array<AnswersModel>;
            healthCardVersion: string;
            tags: [];
        };
        trip_info?: {
            arrivalAt?: string;
            arrivalCarrier?: string;
            arrivalDate?: string;
            arrivalFlightNumber?: string;
            arrivalFrom?: string;
            departureCarrier?: string;
            departureDate?: string;
            departureFlightNumber?: string;
            departureFrom?: string;
            expedited?: boolean;
            originCountry?: string;
            purposeVisit?: string;
        };
        outboundCustoms_info?: { answers: Array<AnswersModel> };
        tier?: string;
        marketingOptInConsent?: boolean;
    };
    autoApproveAt?: string | number;
}

export class ApplicationModel {
    _id: string;
    _index: string;
    _score: number;
    _source: {
        redeemableItems?: Array<any>;
        redeemedItems?: Array<any>;
        createdTs?: string;
        flagCode?: string;
        passportSummary?: {
            dob?: string;
            forenames?: string;
            issuer?: string;
            nationality?: string;
            number?: string;
            sex?: string;
            surname?: string;
            type?: string;
            expiry?: string;
        };
        publicUpdatedTs?: string;
        taApproverInfo?: {
            Notes?: Array<Note>;
            TAValidation?: Array<TaValidationInfo>;
            TestSummary?: Array<TestSummary>;
        };
        taReference?: string;
        taStatus?: string;
        autoApproveAt?: string | number;
        userSubmittedTravelApplicationRequest?: {
            applicant_contact_info?: {
                address?: string;
                address_line_1?: string;
                address_line_2?: string;
                country?: string;
                phone?: string;
                email?: string;
            };
            customs_info?: {
                answers: Array<AnswersModel>;
                healthCardVersion: string;
                tags: [];
            };
            health_info?: {
                answers: Array<AnswersModel>;
                healthCardVersion: string;
                tags: [];
            };
            insurance_info?: {
                answers: Array<AnswersModel>;
                healthCardVersion: string;
                tags: [];
            };
            trip_info?: {
                arrivalAt?: string;
                arrivalCarrier?: string;
                arrivalDate?: string;
                arrivalFlightNumber?: string;
                arrivalFrom?: string;
                departureCarrier?: string;
                departureDate?: string;
                departureFlightNumber?: string;
                departureFrom?: string;
                expedited?: boolean;
                originCountry?: string;
                purposeVisit?: string;
            };
            tier?: string;
            marketingOptInConsent?: boolean;
        };
        paymentData: {
            CardFingerprint: string;
        };
        decisionTs: string;
    };
    _type: string;
    enrollmentType: string;
}

export class ApplicationDataModel {
    number?: string;
    attachments?: AttachmentModel[];
    PassportSummary?: {
        dob?: string;
        personalNum?: string;
        sex?: string;
    };
    emergencycontact_info?: {}[];
    PreCheckInfo?: {
        MatchRecords?: {}[];
        Notes?: Note[];
    };
    ApproverInfo?: {
        Notes?: DecisionNote[];
        TAValidation?: TaValidationInfo[];
        TestSummary?: TestSummary[];
    };
    UserComment?: string;
    SubmittedTs?: number;
    DecisionTs?: number;
    PublicUpdatedTs?: number;
    groupReference?: string;
    enrollmentReference?: string;
    TAReferences?: Array<string>;
    Status?: string;
    code?: string;
    app_version?: string;
    channel?: string;
    submitterIP?: string;
    Receipt?: {
        BCode?: string;
    };
    trip_info?: {
        expedited?: boolean;
        arrivalSeatNumber?: string;
        departureSeatNumber?: string;
        originCountry?: string;
        nextDestination?: string;
        residenceInCountry?: {}[];
        addressInCountry?: { main_text?: string; secondary_text?: string };
    };
    applicationDeclaration?: {
        selfDeclared: boolean;
        agentName: string;
        agentPhone: string;
        agentEmail: string;
    };
    MagicToken?: string;
    purchaseInformation?: PaymentInfo;
    FlagCode?: string;
    healthInfo?: { answers?: AnswersModel[] };
    customs_info?: { answers?: AnswersModel[] };
    health_info?: { answers?: AnswersModel[] };
    insurance_info?: { answers?: AnswersModel[] };
    customInfo?: { answers?: AnswersModel[] };
    insuranceInfo?: { answers?: AnswersModel[] };
    enrollment_info?: { answers?: AnswersModel[] };
    applicantContactInfo?: ContactInfoModel;
    applicant_contact_info?: ContactInfoModel;
    personal_info?: ContactInfoModel;
    personUrl?: string;
    intRef?: number;
    language?: string;
    Payments?: Array<PaymentInfoAPI>;
    RedeemableItems?: Array<any>;
    RedeemedItems?: Array<any>;
    EnrollmentType?: string;
    deletedAttachments?: Array<AttachmentModel>;
    ListMatches?: WatchlistMatchesAPI;
    tier?: string;
    marketingOptInConsent?: boolean;
    createdTs?: any;
    modifiedTs?: any;
    autoApproveAt?: any;
    smartApprovedByUsername?: string;
    smartApprovedAt?: any;

    constructor(props: ApplicationDataModel) {
        return {
            number: props.number || '',
            attachments: props.attachments || [],
            PassportSummary: props.PassportSummary || {
                dob: '',
                personalNum: '',
                sex: '',
            },
            emergencycontact_info: props.emergencycontact_info || [],
            PreCheckInfo: props.PreCheckInfo || {
                MatchRecords: [],
                Notes: [],
            },
            ApproverInfo: props.ApproverInfo || {
                Notes: [],
                TAValidation: [],
                TestSummary: [],
            },
            UserComment: props.UserComment || '',
            SubmittedTs: props.SubmittedTs || 0,
            DecisionTs: props.DecisionTs || 0,
            PublicUpdatedTs: props.PublicUpdatedTs || 0,
            groupReference: props.groupReference || '',
            enrollmentReference: props.enrollmentReference || '',
            TAReferences: props.TAReferences || [],
            Status: props.Status || '',
            code: props.code || '',
            app_version: props.app_version || '',
            channel: props.channel || '',
            submitterIP: props.submitterIP || '',
            Receipt: props.Receipt || {
                BCode: '',
            },
            trip_info: props.trip_info || {
                expedited: false,
                arrivalSeatNumber: '',
                departureSeatNumber: '',
                originCountry: '',
                nextDestination: '',
            },
            applicationDeclaration: props.applicationDeclaration || {
                selfDeclared: false,
                agentName: '',
                agentPhone: '',
                agentEmail: '',
            },
            MagicToken: props.MagicToken || '',
            purchaseInformation: props.purchaseInformation || {},
            FlagCode: props.FlagCode || '',
            healthInfo: props.healthInfo || { answers: [] },
            customs_info: props.customs_info || { answers: [] },
            health_info: props.health_info || { answers: [] },
            insurance_info: props.insurance_info || { answers: [] },
            customInfo: props.customInfo || { answers: [] },
            insuranceInfo: props.insuranceInfo || { answers: [] },
            personUrl: props.personUrl || '',
            intRef: props.intRef || 0,
            language: props.language || '',
            Payments: props.Payments || [],
            RedeemableItems: props.RedeemableItems || [],
            RedeemedItems: props.RedeemedItems || [],
            applicantContactInfo: props.applicantContactInfo
                ? props.applicantContactInfo
                : props.applicant_contact_info
                ? props.applicant_contact_info
                : props.personal_info
                ? props.personal_info
                : undefined,
            EnrollmentType: props.EnrollmentType || '',
            deletedAttachments: props.deletedAttachments || [],
            ListMatches: props.ListMatches || new WatchlistMatchesAPI(),
            tier: props.tier || '',
            marketingOptInConsent: props.marketingOptInConsent || false,
            enrollment_info: props.enrollment_info || {
                answers: [],
            },
            createdTs: props.createdTs || '',
            modifiedTs: props.modifiedTs || '',
            autoApproveAt: props.autoApproveAt || '',
            smartApprovedByUsername: props.smartApprovedByUsername || '',
            smartApprovedAt: props.smartApprovedAt || '',
        };
    }
}

export class ContactInfoModel {
    profession?: string;
    phone?: string;
    address?: string;
    address_line_1?: string;
    address_line_2?: string;
    email?: string;
    country?: string;
    region?: string;
    city?: string;
    google_places_id?: {};
}

export class EmailLogAPIData {
    index?: number;
    senderId: string;
    email: string;
    status: string;
    logged: string;
    updated: string;
    emailType: string;
    diagnostic: string;
    taReference: string;
}

export class EmailLogModel {
    senderID?: string;
    email?: string;
    status?: string;
    logged?: string;
    emailType?: string;
    appReference?: string;
    diagnostic?: string;
    index?: number;
    updated?: string;

    constructor(props: EmailLogAPIData) {
        return {
            senderID: props.senderId || '',
            email: props.email || '',
            status: props.status || '',
            logged: props.logged || '',
            emailType: props.emailType || '',
            appReference: props.taReference || '',
            diagnostic: props.diagnostic || '',
            updated: props.updated || '',
            index: props.index || 0,
        };
    }
}
export interface flightCounts {
    quarantineCount?: number;
    pendingCount?: number;
    questionCount?: number;
    rejectedCount?: number;
    examineCount?: number;
    approvedCount?: number;
}

export class DocumentMlLog {
    appReference?: string;
    attachmentId?: string;
    taFileType?: string;
    validated?: boolean;
    status?: string;
    taRef?: string;
    index?: number;
    mime?: string;
    when?: string;
    code?: string;

    constructor(props?: DocumentMlLog) {
        return {
            mime: props?.mime && props.mime != 'null' ? props.mime : '',
            status: !!props?.validated ? 'validated' : 'invalid',
            attachmentId: props?.attachmentId || '',
            taFileType: props?.taFileType || '',
            index: props?.index || 0,
            appReference: props?.taRef || '',
            when: props?.when || '',
            code: props?.code || '',
        };
    }
}

export class WatchlistMatchesAPI {
    visas?: Array<{ [key: string]: string | number | boolean }>;
    watchlists?: Array<{ [key: string]: string | number }>;

    constructor(props?: WatchlistMatchesAPI) {
        return {
            visas: props?.visas || [],
            watchlists: props?.watchlists || [],
        };
    }
}

export class WatchlistMatches {
    timestamp?: any;
    visas?: Array<WatchlistVisas>;
    watchlists?: Array<Watchlists>;
    constructor(props?: WatchlistMatches) {
        return {
            visas: props?.visas || [],
            watchlists: props?.watchlists || [],
            timestamp: props?.timestamp || null,
        };
    }
}

export class WatchlistVisas {
    visalist?: string;
    visaStatus?: string;
    category?: string;
    number?: string;
    entry?: string;
    firstNames?: string;
    lastName?: string;
    dob?: string;
    nationality?: string;
    gender?: string;
    employer?: string;
    occupation?: string;
    travelDocumentNumber?: string;
    travelDocumentIssuer?: string;
    applicationDate?: string;
    type?: string;
    issueDate?: string;
    effectiveDate?: string;
    expiryDate?: string;
    added?: string;
    lastSeen?: string;
    valid?: boolean;
    uuid?: string;
    name?: string;

    constructor(props?: WatchlistVisas) {
        return {
            visalist: props?.visalist || '',
            visaStatus: props?.visaStatus || '',
            category: props?.category || '',
            number: props?.number || '',
            name:
                props.firstNames || props.lastName
                    ? `${props.lastName || ''}${
                          props.firstNames && props.lastName ? ', ' : ''
                      }${props.firstNames || ''}`
                    : '',
            gender: props?.gender || '',
            dob: props?.dob || '',
            nationality: props?.nationality || '',
            travelDocumentNumber: props?.travelDocumentNumber || '',
            valid: props?.valid || false,
            travelDocumentIssuer: props?.travelDocumentIssuer || '',
            employer: props?.employer || '',
            occupation: props?.occupation || '',
            type: props?.type || '',
            issueDate: props?.issueDate || '',
            expiryDate: props?.expiryDate || '',
            effectiveDate: props?.effectiveDate || '',
        };
    }
}

export class Watchlists {
    watchlist?: string;
    category?: string;
    name?: string;
    score?: number;
    type?: string;
    remarks?: string;
    dobs?: any;
    sourceStatus?: string;
    url?: string;

    constructor(props?: Watchlists) {
        return {
            watchlist: props?.watchlist || '',
            category: props?.category || '',
            name: props?.name || '',
            score: props?.score ? Math.round(props?.score) : 0,
            type: props?.type || '',
            remarks: props?.remarks || '',
            dobs: props?.dobs?.length ? props?.dobs.join(', ') : '',
            sourceStatus: props.sourceStatus || '',
            url: props?.url || '',
        };
    }
}
export class NamedSearchItem {
    aggregations: {
        asMap: {};
    };
    docCount: number;
    docCountError: number;
    key: string;
}
export class NamedSearchData {
    aggregations: {
        asMap: {
            decision: {
                buckets: Array<NamedSearchItem>;
                docCountError: number;
                sumOfOtherDocCounts: number;
            };
        };
    };
    docCount: number;
    docCountError: number;
    key: string;
}
export class BiometricSearchResult {
    score: any;
    authref: string;
    image: string;
    name: any;
    gender: string;
    age: number;
    sent: string;
    appReference?: string;
    constructor(props?: BiometricSearchResult) {
        return {
            score:
                props?.score * 100 < 1
                    ? (Math.round(props?.score * 10000) / 100).toFixed(2)
                    : Number(props?.score * 100).toFixed(2) || 0,
            authref: props?.authref || '',
            image: props?.image || '',
            name: props?.name || '',
            gender: props?.gender || '',
            age: props?.age || null,
            sent: props?.sent || '',
            appReference: props?.authref || '',
        };
    }
}

export class ProcessingCountsAPIModel {
    EMBARKATION_SUBMITTED: number;
    IN_REVIEW: number;
    PROCESSING: number;
    WORKATION: number;
    AUTO_APPROVE: number;
}

export class ProcessingCountsModel {
    pending: number;
    inReview: number;
    workation: number;
    toReview: number;
    autoApprove: number;
    constructor(props: ProcessingCountsAPIModel) {
        return {
            pending: props.PROCESSING || 0,
            inReview: props.IN_REVIEW || 0,
            workation: props.WORKATION || 0,
            toReview: props.EMBARKATION_SUBMITTED || 0,
            autoApprove: props.AUTO_APPROVE || 0,
        };
    }
}

export class TripInfoSectionsModel {
    enrollmentType?: string;
    dates?: string;
    minArrivalDate?: number;
    maxArrivalDate?: number;
    minDepartureDate?: number;
    maxDepartureDate?: number;
    residenceInCountry?: boolean;
    multipleResidenceInCountry?: boolean;
    validateMultipleResidenceInCountry?: number;
    showInboundAirlines?: boolean;
    showOutboundAirlines?: boolean;
    purposeVisit?: boolean;
    showCruises?: boolean;
    constructor(props?: TripInfoSectionsModel) {
        return {
            enrollmentType: props?.enrollmentType || '',
            dates: props?.dates || '',
            minArrivalDate: props?.minArrivalDate,
            maxArrivalDate: props?.maxArrivalDate,
            minDepartureDate: props?.minDepartureDate,
            maxDepartureDate: props?.maxDepartureDate,
            residenceInCountry: props?.residenceInCountry || false,
            multipleResidenceInCountry:
                props?.multipleResidenceInCountry || false,
            validateMultipleResidenceInCountry:
                props?.validateMultipleResidenceInCountry || 0,
            showInboundAirlines: props?.showInboundAirlines || false,
            showOutboundAirlines: props?.showOutboundAirlines || false,
            purposeVisit: props?.purposeVisit || false,
            showCruises: props?.showCruises || false,
        };
    }
}
export class OtherOverrideMappingModel {
    tripInfoSections?: TripInfoSectionsModel;
    purposeVisit?: Array<PurposeOfVisit>;
    constructor(props?: OtherOverrideMappingModel) {
        return {
            tripInfoSections: props.tripInfoSections,
            purposeVisit: props.purposeVisit,
        };
    }
}
export class OtherOverrideConfigModel {
    documentIssuer?: { [subkey: string]: OtherOverrideMappingModel };
    purposeVisit?: { [subkey: string]: OtherOverrideMappingModel };
    residenceCountry?: { [subkey: string]: OtherOverrideMappingModel };
    constructor(props?: OtherOverrideConfigModel) {
        return props
            ? {
                  documentIssuer: props.documentIssuer || {},
                  purposeVisit: props.purposeVisit || {},
                  residenceCountry: props.residenceCountry || {},
              }
            : {};
    }
}

export class IncompleteAuditLogModel {
    tasInGroup?: Array<string>;
    userSubmittedTravelApplicationRequest?: {
        tier?: string;
        IDDocSummary?: {
            surname?: string;
            forenames?: string;
        };
        trip_info?: {
            arrivalCarrier?: string;
            arrivalFlightNumber?: string;
            arrivalDate?: string;
        };
    };
    paymentAgentOverride?: {
        dateTime?: string;
        agent?: string;
    };
    taReference?: string;
    enrollmentType?: string;
}

export class MappedIncompleteAuditLogModel {
    appReference?: string;
    applicant?: string;
    agent?: string;
    arrivalFlight?: string;
    arrivalDate?: string;
    numberOfApplications?: number;
    dateTime?: string;
    enrollmentType?: string;
    tier?: string;
    constructor(props: IncompleteAuditLogModel) {
        const surname =
            props.userSubmittedTravelApplicationRequest?.IDDocSummary
                ?.surname || '';
        const forenames =
            props.userSubmittedTravelApplicationRequest?.IDDocSummary
                ?.forenames || '';
        const arrivalCarrier =
            props.userSubmittedTravelApplicationRequest?.trip_info
                ?.arrivalCarrier || '';
        const arrivalFlightNumber =
            props.userSubmittedTravelApplicationRequest?.trip_info
                ?.arrivalFlightNumber || '';
        return {
            appReference: props.taReference || '',
            applicant:
                surname || forenames
                    ? surname + (surname && forenames ? ', ' : '') + forenames
                    : '',
            agent: props.paymentAgentOverride?.agent || '',
            arrivalFlight:
                arrivalCarrier || arrivalFlightNumber
                    ? arrivalCarrier +
                      (arrivalCarrier && arrivalFlightNumber ? ' ' : '') +
                      arrivalFlightNumber
                    : '',
            arrivalDate:
                props.userSubmittedTravelApplicationRequest?.trip_info
                    ?.arrivalDate || '',
            numberOfApplications: props.tasInGroup?.length || 1,
            dateTime: props.paymentAgentOverride?.dateTime || '',
            enrollmentType: props.enrollmentType || '',
            tier: props.userSubmittedTravelApplicationRequest?.tier || '',
        };
    }
}
