import { languageSelector, themeColorModeSelector } from './store/selectors';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ElectronService } from './core/services';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    changeThemeSubscription$: Subscription;
    currentLangSubscription$: Subscription;
    routerEventSubscription: Subscription;
    appConfig = AppConfig;
    userSubscription$: any;

    constructor(
        private electronService: ElectronService,
        private translate: TranslateService,
        private matDialog: MatDialog,
        private store: Store<any>,
        private router: Router,
        private ngZone: NgZone
    ) {
        this.translate.setDefaultLang('en');

        if (!window.document.getElementById('google-map-script')) {
            // Create the script tag, set the appropriate attributes
            const googleMapScript = document.createElement('script');
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.appConfig['googleMapsKey']}&libraries=geometry,places`;
            googleMapScript.id = 'google-map-script';
            googleMapScript.type = 'text/javascript';
            googleMapScript.defer = true;
            googleMapScript.async = true;

            // Append the 'script' element to 'head'
            document.head.appendChild(googleMapScript);
        }
    }

    ngOnInit(): void {
        if (this.electronService.isElectron) {
            this.electronService.ipcRenderer.send(
                'on-app-initialization',
                this.appConfig['flavorProtocol']
            );

            this.electronService.ipcRenderer.send(
                'on-app-ready',
                this.appConfig['localTravEndpoint']
            );

            this.electronService.ipcRenderer.on(
                'goto-deepLink',
                (event, arg) => {
                    this.ngZone.run(() => {
                        if (
                            this.matDialog.openDialogs &&
                            this.matDialog.openDialogs.length > 0
                        ) {
                            this.matDialog.closeAll();
                        }

                        this.router.navigate([`/taui/page/search`]);

                        setTimeout(() => {
                            if (arg[arg.length - 1]) {
                                const refinedArg = arg[arg.length - 1].replace(
                                    /[^t]*/,
                                    ''
                                );
                                this.router.navigate([refinedArg]);
                            }
                        }, 2000); //Timeout needed for deep link to close dialog and re-initialize search page
                    });
                }
            );
        }

        this.translate.addLangs(['fr', 'es', 'en', 'pt']);

        setTimeout(() => {
            this.currentLangSubscription$ = this.store
                .select(languageSelector)
                .subscribe((lang) => {
                    this.translate.setDefaultLang(lang || 'en');
                    this.translate.use(lang || 'en');
                });
        }, 10);
        this.changeThemeSubscription$ = this.store
            .select(themeColorModeSelector)
            .subscribe((themeMode) => {
                if (themeMode) {
                    document.body.className = themeMode;
                }
            });
    }

    ngOnDestroy(): void {
        this.currentLangSubscription$.unsubscribe();
        this.changeThemeSubscription$.unsubscribe();
        this.routerEventSubscription.unsubscribe();
    }
}
