import { ApplicationDetails } from './appDetails';
import { ApplicantCard } from './applicantCard';
import { OfflineApplicationModel } from './services/mappingService.model';

export class AppsManagerModel {
    processing: { hits: Array<OfflineApplicationModel> };
    valid: { hits: Array<OfflineApplicationModel> };
    invalid: { hits: Array<OfflineApplicationModel> };
}

export class MappedAppsManagerModel {
    pending: { hits: Array<OfflineApplicationModel> };
    active: { hits: Array<OfflineApplicationModel> };
    rejected: { hits: Array<OfflineApplicationModel> };
    constructor(props: AppsManagerModel) {
        return {
            pending: props.processing,
            active: props.valid,
            rejected: props.invalid,
        };
    }
}

export class OfflineAppsManagerModel {
    pending: {
        allData: ApplicantCard[];
        count: number;
    };
    active: {
        allData: ApplicantCard[];
        count: number;
    };
    rejected: {
        allData: ApplicantCard[];
        count: number;
    };
    constructor() {
        return {
            pending: {
                allData: [],
                count: 0,
            },
            active: {
                allData: [],
                count: 0,
            },
            rejected: {
                allData: [],
                count: 0,
            },
        };
    }
}

export class OfflineDatabusModel {
    appsManager: OfflineAppsManagerModel;
    appDetails: ApplicationDetails;
    ipPort: string;
    constructor() {
        return {
            appsManager: new OfflineAppsManagerModel(),
            appDetails: new ApplicationDetails(),
            ipPort: '',
        };
    }
}
