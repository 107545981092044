export class GalleryAttachmentModel {
    matchPercentage?: string;
    applicantName?: string;
    originalUrl?: string;
    statusColor?: string;
    previewUrl?: string;
    mimeType?: string;
    taRef?: string;
    type?: string;
    URL?: string;
    title?: {};
    fileType?: string;
    fileName?: string;

    constructor(params: {
        matchPercentage?: string;
        applicantName?: string;
        originalUrl?: string;
        statusColor?: string;
        previewUrl?: string;
        mimeType?: string;
        taRef?: string;
        type?: string;
        URL?: string;
        title?: {};
        fileType?: string;
        fileName?: string;
    }) {
        return {
            matchPercentage: params.matchPercentage
                ? params.matchPercentage
                : '',
            applicantName: params.applicantName ? params.applicantName : '',
            originalUrl: params.originalUrl ? params.originalUrl : '',
            statusColor: params.statusColor ? params.statusColor : '',
            previewUrl: params.previewUrl ? params.previewUrl : '',
            mimeType: params.mimeType ? params.mimeType : '',
            taRef: params.taRef ? params.taRef : '',
            type: params.type ? params.type : '',
            URL: params.URL ? params.URL : '',
            title: params.title ? params.title : null,
            fileType: params.fileType ? params.fileType : '',
            fileName: params.fileName ? params.fileName : '',
        };
    }
}
