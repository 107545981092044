import { AlertComponent } from '@app/components/alert/alert.component';
import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class EventHubService {
    public validateAttachmentSearchResult = new EventEmitter();
    public triggerResetPhoneNumber = new EventEmitter();
    public selectedApplications = new EventEmitter();
    public updateFlightCount = new EventEmitter();
    public triggerOpenModal = new EventEmitter();
    public doneThrottling = new EventEmitter();
    public validateInfo = new EventEmitter();
    public executeCall = new EventEmitter();
    public updateInfo = new EventEmitter();
    public apiProgress = new EventEmitter();
    public advanceApplication = new EventEmitter();
    public notificationsClick = new EventEmitter();
    public isPhoneOpen = new EventEmitter<boolean>();
    public isSavedSearchUpdated = new EventEmitter<{
        action: 'save' | 'delete' | 'rename';
        searchName?: string;
        showSnackBar?: boolean;
    }>();
    public isEventStreamOpen = new EventEmitter<boolean>();
    public isApplicationDetailsOpen = new EventEmitter<boolean>();
    public isExpeditedReceived = new EventEmitter();
    public triggerSearch = new EventEmitter();
    public openPinModal = new EventEmitter();
    public openActionModal = new EventEmitter();
    public dynamicModalBtnClick = new EventEmitter();
    public discardPageChanges = new EventEmitter();
    public historyModalActions = new EventEmitter();
    public clearTaChangeLogs = new EventEmitter();
    constructor(public dialog: MatDialog) {}

    connectionErrorDialog(): void {
        this.dialog.open(AlertComponent, {
            data: {
                title: 'dialog_messages.dialog_title_saving_error',
                description: 'dialog_messages.dialog_desc_connection_error',
                buttons: ['ok'],
            },
            backdropClass: 'dialog-backdrop',
        });
    }
}
