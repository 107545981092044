import {
    AncillariesItem,
    TierItems,
} from '@app/models/services/utilityService.model';
import { ApplicationDetails } from '@app/models/appDetails';
import { ApplicantCard } from '@app/models/applicantCard';
import { ProfileData } from '@app/models/Profiles.model';
import { FlightCard } from '@app/models/flightCard';
import { createAction, props } from '@ngrx/store';
import { types } from './types';
import {
    BiometricSearchResult,
    GroupedAppsModel,
    OtherOverrideConfigModel,
    TripInfoSectionsModel,
    WatchlistMatches,
} from '@app/models/services/mappingService.model';
import {
    AirportModel,
    AlertBanner,
    AppConfigModel,
    ApplicationStatusCheckModel,
    ContactInfoConfiguration,
    CruiseModel,
    EligibilityWidget,
    GroupSubmissionsConfig,
    OtherPreferencesModel,
    PurposeOfVisit,
    QuestionsConfigModel,
    SupportedTemplateModel,
    VisaConfiguration,
} from '@app/models/database';

export const getContent = createAction(types.GET_CONTENT_ATTEMPT);

export const getContentSuccess = createAction(
    types.GET_CONTENT_SUCCESS,
    props<{
        countryWatchList: Array<string>;
        countryWhiteList: Array<string>;
        countryLowRisk: Array<string>;
        countryMediumRisk: Array<string>;
        countryYellowFever: Array<string>;
        countryPOX: Array<string>;
        countryEbola: Array<string>;
        countryPolio: Array<string>;
        countryCustoms: Array<string>;
        countryVisaExemption: Array<string>;
        countryVisaOnArrival: Array<string>;
        supportingDocuments: {}[];
        questions: {}[];
        languages: {}[];
        pages: {}[];
        establishments: {}[];
        establishmentUsers: {}[];
        airlines: {}[];
        modifiedSince: string;
        version: string;
        establishmentsTags: [];
        questionsConfig: QuestionsConfigModel;
        appConfig: AppConfigModel;
        alertBanner: Array<AlertBanner>;
        favoriteCountries: Array<string>;
        airports: Array<AirportModel>;
        cruises: Array<CruiseModel>;
        purposeVisit: PurposeOfVisit;
        currencies: Array<string>;
        groupConfig: Array<GroupSubmissionsConfig>;
        popularAirlines: Array<string>;
        popularCurrencies: Array<string>;
        popularCruises: Array<string>;
        allowedEnrollmentTypes: Array<string>;
        nationalityRequirement: EligibilityWidget;
        contactInfoConfig: Array<ContactInfoConfiguration>;
        visaConfig: Array<VisaConfiguration>;
        defaultEnrollmentType: string;
        tripInfoSections: Array<TripInfoSectionsModel>;
        applicationStatusCheck: ApplicationStatusCheckModel;
        otherPreferences: Array<OtherPreferencesModel>;
        otherOverrideConfig: OtherOverrideConfigModel;
    }>()
);

export const getContentFailed = createAction(types.GET_CONTENT_FAILED);

export const getAllUsers = createAction(types.GET_ALL_USERS_ATTEMPT);

export const getAllUsersSuccess = createAction(
    types.GET_ALL_USERS_SUCCESS,
    props<{
        users: {}[];
        modifiedSinceAllUsers: string;
    }>()
);

export const getAppConfigFailed = createAction(types.GET_APP_CONFIG_FAILED);

export const getAppConfig = createAction(types.GET_APP_CONFIG_ATTEMPT);

export const getAppConfigSuccess = createAction(
    types.GET_APP_CONFIG_SUCCESS,
    props<{
        appConfig: {};
    }>()
);

export const getAllUsersFailed = createAction(types.GET_ALL_USERS_FAILED);

export const updateNodeData = createAction(
    types.UPDATE_NODE_DATA,
    props<{
        node: string;
        nodeData: any;
        index?: number;
        subNode?: string;
        childNode?: string;
        isPurposeVisitNode?: boolean;
    }>()
);

export const setTableRowData = createAction(
    types.SET_TABLE_ROW_DATA,
    props<{
        node: string;
        rowData: {}[] | {};
        subNode?: string;
        childNode?: string;
        updateNode?: string;
    }>()
);

export const selectTableRowData = createAction(
    types.SELECT_TABLE_ROW_DATA,
    props<{ node: string; rowData: {} }>()
);

//PENDING START

export const getPendingApps = createAction(
    types.GET_PENDING_APPS,
    props<{ body: any; index: number }>()
);

export const getPendingAppsSuccess = createAction(
    types.GET_PENDING_APPS_SUCCESS,
    props<{
        apps: { [key: string]: Array<ApplicantCard> };
        isLoadMore: boolean;
        index: number;
    }>()
);

export const getPendingAppsFailed = createAction(
    types.GET_PENDING_APPS_FAILED,
    props<{ payload: any }>()
);

//PENDING END

//Rejection START
export const getAppsCount = createAction(
    types.GET_APPS_COUNT,
    props<{
        storeNode: string;
        body: any;
    }>()
);

export const getAppsCountSuccess = createAction(
    types.GET_APPS_COUNT_SUCCESS,
    props<{ count: number; storeNode: string }>()
);

export const getAppsCountFailed = createAction(
    types.GET_APPS_COUNT_FAILED,
    props<{ token: string }>()
);

export const loadMoreApps = createAction(types.LOAD_MORE_APPS);

export const getApps = createAction(
    types.GET_APPS,
    props<{
        index: number;
        storeNode: string;
        body: any;
        moreInfo?: any;
    }>()
);

export const getAppsSuccess = createAction(
    types.GET_APPS_SUCCESS,
    props<{
        isLoadMore: boolean;
        storeNode: string;
        index: number;
        notElasticSearch: boolean;
        apps: ApplicantCard[] | { [key: string]: Array<ApplicantCard> };
    }>()
);

export const getAppsFailed = createAction(
    types.GET_APPS_FAILED,
    props<{ payload: any }>()
);

//Rejection END

//APP DETAILS START

export const getAppDetails = createAction(
    types.GET_APP_DETAILS_ATTEMPT,
    props<{ code: string; clearTaChanges: boolean }>()
);

export const getAppDetailsSuccess = createAction(
    types.GET_APP_DETAILS_SUCCESS,
    props<{ payload: ApplicationDetails }>()
);

export const setApplicationNode = createAction(
    types.SET_APPLICATION_NODE,
    props<{ data: any; node: string }>()
);

export const getGroupedAppsSuccess = createAction(
    types.GET_GROUPED_APPS_SUCCESS,
    props<{ data: any }>()
);
export const getGroupedAppsFailed = createAction(
    types.GET_GROUPED_APPS_FAILED,
    props<{ payload: any }>()
);

export const setAppDetailsParams = createAction(
    types.SET_APP_DETAILS_PARAMS,
    props<{
        flag: string;
        applicationStatus: string;
        submittedTs: string | number;
        code: string;
        index: number;
        flagCode?: string;
    }>()
);

export const getAppDetailsFailed = createAction(
    types.GET_APP_DETAILS_FAILED,
    props<{ payload: any }>()
);

export const clearAppDetails = createAction(types.CLEAR_APP_DETAILS);

export const validateTaInfo = createAction(
    types.VALIDATE_TA_INFO_ATTEMPT,
    props<{ taReference: string; validationType: string; extraParams: any }>()
);

export const validateTaInfoSuccess = createAction(
    types.VALIDATE_TA_INFO_SUCCESS,
    props<{ validationData: any; validationType: string; extraParams: any }>()
);

//APP DETAILS END

//Establishment Users Start
export const getAllEstablishmentUsers = createAction(
    types.GET_ALL_ESTABLISHMENT_USERS_ATTEMPT
);

export const getAllEstablishmentUsersSuccess = createAction(
    types.GET_ALL_ESTABLISHMENT_USERS_SUCCESS,
    props<{
        establishmentUsers: {}[];
        modifiedSinceAllEstablishmentUsers: string;
    }>()
);
export const getAllEstablishmentUsersFailed = createAction(
    types.GET_ALL_ESTABLISHMENT_USERS_FAILED
);
//Establishment Users End

export const getAppsNameSearch = createAction(
    types.GET_APPS_NAME_SEARCH,
    props<{ index: number; storeNode: string; body: any; searchNode: string }>()
);

export const setDistinctFlightsCountSuccess = createAction(
    types.SET_DISTINCT_FLIGHTS_COUNT_SUCCESS,
    props<{
        subtitle: string;
        app: FlightCard;
        title: string;
    }>()
);

export const setDistinctFlightsCountFailed = createAction(
    types.SET_DISTINCT_FLIGHTS_COUNT_FAILED
);

export const updateFlightCount = createAction(
    types.UPDATE_FLIGHT_COUNT_ATTEMPT,
    props<{
        title: string;
        subtitle: string;
    }>()
);
//GET FLIGHT DASHBOARD END

//CLEAR DATABUS DATA

export const clearDatabusData = createAction(types.CLEAR_DATABUS_DATA);

export const clearAppManagerNode = createAction(
    types.CLEAR_APP_MANAGER_NODE,
    props<{ node: string; subNode?: string; dataToClear: {} }>()
);

export const validateTestSummary = createAction(
    types.VALIDATE_TEST_SUMMARY_ATTEMPT,
    props<{ body: any; attachmentId: string }>()
);

export const validateTestSummarySuccess = createAction(
    types.VALIDATE_TEST_SUMMARY_SUCCESS,
    props<{ attachmentId: string; isNegative: boolean }>()
);

export const validateTestSummaryFailed = createAction(
    types.VALIDATE_TEST_SUMMARY_FAILED,
    props<{ attachmentId: string; errorMessage: string }>()
);

//Get TV Private Version
export const getTAPrivateVersion = createAction(types.GET_TA_PRIVATE_VERSION);

export const getTAPrivateVersionSuccess = createAction(
    types.GET_TA_PRIVATE_VERSION_SUCCESS,
    props<{
        taPrivateVersion: string;
    }>()
);

export const getTAPrivateVersionFailed = createAction(
    types.GET_TA_PRIVATE_VERSION_FAILED
);

//Get Health Credential
export const getHCProfiles = createAction(types.GET_HC_PROFILES);

export const getHCProfilesSuccess = createAction(
    types.GET_HC_PROFILES_SUCCESS,
    props<{
        hcProfiles: Array<ProfileData>;
    }>()
);

// PURCHASED ITEMS
export const getAncillariesSuccess = createAction(
    types.GET_ANCILLARIES_SUCCESS,
    props<{
        ancillariesItems: Array<AncillariesItem>;
    }>()
);

export const getAncillariesFailed = createAction(
    types.GET_ANCILLARIES_FAILED,
    props<{
        payload: any;
    }>()
);

// Tiers
export const getTiersSuccess = createAction(
    types.GET_TIERS_SUCCESS,
    props<{
        tiers: Array<TierItems>;
    }>()
);

export const getTiersFailed = createAction(
    types.GET_TIERS_FAILED,
    props<{
        payload: any;
    }>()
);

export const updateFlightDashboardNode = createAction(
    types.SET_FLIGHT_DASHBOARD_NODES,
    props<{ nodes: GroupedAppsModel }>()
);

export const getHCProfilesFailed = createAction(types.GET_HC_PROFILES_FAILED);

export const getUserFileTypesSuccess = createAction(
    types.GET_USER_FILE_TYPE_SUCCESS,
    props<{
        userFileTypes: Array<{ label: string; key: string }>;
    }>()
);

export const getEmailLogsByRefFailed = createAction(
    types.GET_REFERENCE_EMAIL_LOGS_FAILED
);

export const getSupportedMLTemplates = createAction(
    types.GET_SUPPORTED_ML_TEMPLATES
);

export const getSupportedMLTemplatesSuccess = createAction(
    types.GET_SUPPORTED_ML_TEMPLATES_SUCCESS,
    props<{
        supportedTemplates: Array<SupportedTemplateModel>;
    }>()
);

export const getSupportedMLTemplatesFailed = createAction(
    types.GET_SUPPORTED_ML_TEMPLATES_FAILED
);

export const getElasticSearchData = createAction(
    types.GET_ELASTIC_SEARCH_DATA,
    props<{
        body: any;
        searchType: string;
        parentStoreNode: string;
        storeNode: string;
        dataNode: string;
        doNotUpdateAppsUi?: boolean;
    }>()
);

export const getElasticSearchDataSuccess = createAction(
    types.GET_ELASTIC_SEARCH_DATA_SUCCESS,
    props<{
        data: any;
        searchType: string;
        parentStoreNode: string;
        storeNode: string;
        dataNode: string;
        isLoadMore: boolean;
        count: number;
        doNotUpdateAppsUi?: boolean;
    }>()
);
export const getElasticSearchDataFailed = createAction(
    types.GET_ELASTIC_SEARCH_DATA_FAILED,
    props<{ payload: any; doNotUpdateAppsUi?: boolean }>()
);
export const getEnrollmentTypesSuccess = createAction(
    types.GET_ENROLLMENT_TYPES_SUCCESS,
    props<{ data: Array<{ key: string; label: string }> }>()
);
export const getEnrollmentTypes = createAction(
    types.GET_ENROLLMENT_TYPES_ATTEMPT
);

export const getNamedSearchData = createAction(
    types.GET_NAMED_SEARCH_DATA,
    props<{
        body: any;
        index: number;
        parentStoreNode: string;
        storeNode: string;
        dataNode: string;
    }>()
);
export const getNamedSearchDataSuccess = createAction(
    types.GET_NAMED_SEARCH_DATA_SUCCESS,
    props<{
        apps: any;
        parentStoreNode: string;
        storeNode: string;
        dataNode: string;
        isLoadMore: boolean;
        count: number;
        index: number;
    }>()
);
export const getNamedSearchDataFailed = createAction(
    types.GET_NAMED_SEARCH_DATA_FAILED,
    props<{ payload: any }>()
);

export const refreshWatchlistMatches = createAction(
    types.REFRESH_WATCHLIST_MATCHES,
    props<{ ref: string }>()
);
export const refreshWatchlistMatchesSuccess = createAction(
    types.REFRESH_WATCHLIST_MATCHES_SUCCESS,
    props<{ payload: WatchlistMatches }>()
);
export const refreshWatchlistMatchesFailed = createAction(
    types.REFRESH_WATCHLIST_MATCHES_FAILED
);
export const getElasticSearchCount = createAction(
    types.GET_ELASTIC_SEARCH_COUNT,
    props<{ node: string; storeNode: string }>()
);
export const biometricSearch = createAction(
    types.BIOMETRIC_SEARCH,
    props<{ body: string }>()
);
export const biometricSearchSuccess = createAction(
    types.BIOMETRIC_SEARCH_SUCCESS,
    props<{ data: Array<BiometricSearchResult> }>()
);
export const biometricSearchFailed = createAction(
    types.BIOMETRIC_SEARCH_FAILED
);
export const restoreBiometricSearchState = createAction(
    types.RESTORE_BIOMETRIC_SEARCH_STATE
);
export const getProcessingAppsCount = createAction(
    types.GET_PROCESSING_APPS_COUNTS,
    props<{ url: string }>()
);

export const getProcessingAppsCountSuccess = createAction(
    types.GET_PROCESSING_APPS_COUNTS_SUCCESS,
    props<{ counts: { [key: string]: number } }>()
);

export const getNamedSearch = createAction(
    types.GET_NAMED_SEARCH,
    props<{
        body: any;
        index: number;
        parentStoreNode: string;
        storeNode: string;
        childStoreNode: string;
        dataNode: string;
    }>()
);
export const getNamedSearchSuccess = createAction(
    types.GET_NAMED_SEARCH_SUCCESS,
    props<{
        apps: any;
        parentStoreNode: string;
        storeNode: string;
        childStoreNode: string;
        dataNode: string;
        isLoadMore: boolean;
        count: number;
        index: number;
    }>()
);
export const getNamedSearchFailed = createAction(
    types.GET_NAMED_SEARCH_FAILED,
    props<{ payload: any }>()
);

export const removeApplication = createAction(
    types.REMOVE_APPLICATION,
    props<{ path: string; ref: string }>()
);
