import { MappedAppsManagerModel } from '@app/models/offlineDatabus.model';
import { MappingService } from '@app/services/mapping.service';
import { UtilityService } from '@app/services/utility.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiService } from '@app/services/api.service';
import { Injectable } from '@angular/core';
import { types } from '../actions';

@Injectable()
export class OfflineDatabusEffects {
    getOfflineApps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_OFFLINE_APPS),
            mergeMap((action: any) =>
                this.api.getAllDetails(action.ipOrFqdn).pipe(
                    map((data) => {
                        delete data.version;
                        const mappedData = new MappedAppsManagerModel(data);
                        let groupedApps = {};
                        const result = {};
                        Object.entries(mappedData).forEach(([key, value]) => {
                            const apps =
                                this.mappingService.formatApplicationData(
                                    value.hits
                                );
                            groupedApps = this.mappingService.groupApplications(
                                apps,
                                key
                            );
                            groupedApps = { ...groupedApps, allData: apps };
                            result[key] = {
                                ...groupedApps,
                                count: apps.length,
                            };
                        });
                        return {
                            type: types.GET_OFFLINE_APPS_SUCCESS,
                            apps: result,
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_OFFLINE_APPS_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    getOfflineAppDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(types.GET_OFFLINE_APP_DETAILS),
            mergeMap((action: any) =>
                this.api.getOfflineAppDetails(action.taRef).pipe(
                    map((data) => {
                        return {
                            type: types.GET_OFFLINE_APP_DETAILS_SUCCESS,
                            data: this.mappingService.getAppDetails(
                                data.detail
                            ),
                        };
                    }),
                    catchError((error) => {
                        return this.utilityService.catchError(
                            types.GET_OFFLINE_APP_DETAILS_FAILED,
                            error
                        );
                    })
                )
            )
        )
    );

    constructor(
        private utilityService: UtilityService,
        private mappingService: MappingService,
        private actions$: Actions,
        private api: ApiService
    ) {}
}
